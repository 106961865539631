///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3
*/



/*
プログレス表示

購入完了までの手順や、現在の進捗状況をプログレス表示します。

使用箇所：
Cart/index.twig
Shopping/index.twig
Shopping/confirm.twig
Shopping/complete.twig
Shopping/nonmember.twig

Markup:
../styleguide/tmpl/7.3.1.html

Styleguide 7.3.1
*/
.ec-progress {
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  @include media_sphone {
    // スマホ時少しだけ左右領域を拡張してゆとりを
    width: calc(100% + 20px);
    transform: translateX(-10px);
  }

  @include media_tablet {
    // バランス的にmax-widthを調整
    max-width: 62.5vw;
  }

  @include media_desktop {
    max-width: 600px;
  }

  & &__item {
    font-family: "Poppins";
    font-size: 1.3rem;// 横線がずれないようここでfont-sizeを指定
    @include media_sphone {
      font-size: 1.2rem;
    }

    display:table-cell;
    text-align: center;
    position: relative;
    z-index: 10;

    // 横線
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #ddd;//lighten($noactive-color, 20%);//←非アクティブカラーを薄め

      position: absolute;
      top: 1em;// __numberの大きさが2emなので
      left: 50%;
      transform: translate(0,-50%);
      z-index: -1;
    }

    &:last-child:after {
      display: none;
    }
  }

  // 丸数字
  & &__number{
    background-color: $noactive-color;
    color: #fff;

    display: inline-block;
    line-height: 2em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 0.25em;
  }

  // ラベルテキスト
  & &__label {
    font-family: $ff-default;
    font-size: 0.8rem;
    @include media_sphone {
      font-size: 0.7rem;
    }

    padding: 0;
    line-height: 1.2;
    white-space: nowrap;// 改行されないように（崩れるようなら再調整）
  }

  // 現在の進捗段階（厳密にはcompleteではないが）
  .is-complete {
    .ec-progress__number {
      background-color: $active-color;
    }

    .ec-progress__label {
      color: $active-color;
    }
  }
}



/*
EC-CUBEオリジナルの「カートナビゲーション」Styleguide 7.3.5～7.3.7は、Styleguide 11.2.8～11.2.10へ移動
*/



/*
総計

会計時の合計金額、総計を表示します。

使用箇所：
Mypage/history.twig
Shopping/index.twig
Shopping/confirm.twig

Markup:
../styleguide/tmpl/7.3.8.html

Styleguide 7.3.8
*/
.ec-totalBox {
  background-color: #fbfbfb;
  border: solid 1px #ddd;
  padding: 1.5em;
  padding-top: 1em;

  @include media_sphone {
    padding: 0.5em 1em 1em;
  }

  @include media_tablet {
    padding: 0.5em 1em 1em;
  }

  /* 各項目 */
  & &__spec {
    display: flex;
    justify-content: space-between;
    @include borderBottom;
    padding: 0.5em 0.25em;
    margin: 0;
    line-height: 1;

    dt {
      font-weight: normal;

      .ec-font-bold {
        font-weight: normal;//「加算ポイント」ラベルは細字で
      }
    }

    dd {
      text-align: right;
    }
  }

  /* 合計額 */
  & &__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include borderBottom;
    padding: 0.5em 0.25em;
    line-height: 1;
    font-weight: 500;
    margin: 1em 0 1.5em;
  }

  /* 合計額：価格 */
  & &__price {
    flex: 1 1 auto;
    font-size: 1.6rem;
    color: #000;
    text-align: right;
    margin-bottom: -3px;// 少しだけ字下げしてバランス
  }

  /* 合計額：「税込み」ラベル */
  & &__taxLabel {
    margin-left: 5px;
    font-size: 0.85rem;
    font-weight: normal;
  }

  /* お支払い合計 */
  & &__paymentTotal{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include borderBottom;
    padding: 0.5em 0.25em;
    line-height: 1;
    font-weight: 500;
    margin: 1em 0 1.5em;

    .ec-totalBox__price,
    .ec-totalBox__taxLabel{
      color: $clrRed;
    }
  }

  /* 税率表示 */
  & &__taxRate {
    display: flex;
    justify-content: flex-end;
    margin-bottom: .5em;
    font-size: 0.85rem;

    dt {
      font-weight: normal;

      &::before {
        //content: "[ ";
      }
    }

    dd {
      &::after {
        //content: " ]";
      }
    } 
  }

  /* ポイント利用 */
  & &__pointBlock{
  }

  /* ボタンエリア */
  & &__btn {
    margin-top: 2em;
    .ec-blockBtn--action {
      margin-bottom: 10px;
    }
  }
}
