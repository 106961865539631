///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

フッターガイド、フッターナビ、フッターソーシャル、フッタータイトルで構成されています。

※ページコンテンツ本体（.ec-layoutRole__contents）とフッターとの余白は、`.ec-layoutRole__footer`に持たせてあります。

Markup:
../styleguide/tmpl/11.3.html

Styleguide 11.3
*/
.ec-layoutRole__footer {

  // ページコンテンツとフッターとの余白をスタイリングします
  @include media_sphone {
    margin-top: $padding-sphone;
  }

  @include media_tablet {
    margin-top: $padding-tablet;
  }

  @include media_desktop {
    margin-top: 100px;
  }
}

.ec-footerRole {
  background-color: $clrFooterBase;
  color: #aaa;// メイン文字色

  @include media_sphone {
    font-size: 0.9285rem;
  }

  @include media_tablet {
    font-size: 0.8666rem;
  }

  @include media_desktop {
    font-size: 0.8666rem;
  }
}



/*
フッターガイド

Markup:
../styleguide/tmpl/11.3.1.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerGuide {
  background-color: lighten($clrFooterBase, 12%);

  & &__inner {
    @include container;
    max-width: $container-maxwidth2;
    margin: 0 auto;
  }

  & &__wrap {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }

  & &__item {
    flex: 0 0 33.3%;

    // for IE11
    @include hack-ie11 {
      max-width: 33.3%;
    }

    display: flex;
    align-items: center;
    border-left: solid 1px hsla(0,0%,100%,0.2);

    &:first-child {
      border-left: none;
    }
  }

  & &__itemIcon {
    font-size: 1.8rem;
    margin-right: 20px;
  }

  & &__itemText {

    h3 {
      font-family: $ff-en_thin;
      font-weight: 400;
      font-size: 1.154em;
      color: #fff;
      margin: 0;
      margin-bottom: 0.5em;
    }

    p {
      margin: 0;
    }
  }

  @include media_sphone {
    padding-top: $padding-sphone;

    & &__item {
      flex-basis: 100%;
      padding: 0;
      border-left: none;
      margin-bottom: $padding-sphone;
      flex-wrap: wrap;
    }

    & &__itemIcon {
      width: 2rem;
    }

    & &__itemText {
      flex: 1;

      p {
        line-height: 1.3;
      }
    }
  }

  @include media_tablet {
    padding-top: $padding-tablet;
    padding-bottom: $padding-tablet;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: -20px;
      margin-right: -20px;
    }

    & &__item {
      padding: 0 20px;
      // アイコンとテキストを上下配置にするためflexボックスを解除します
      display: block;
    }

    & &__itemIcon {
      margin-bottom: .5em;
    }

    & &__itemText {
      p {
        line-height: 1.3;
      }
    }
  }

  @include media_desktop {
    padding-top: $padding-desktop;
    padding-bottom: $padding-desktop;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: -20px;
      margin-right: -20px;
    }

    & &__item {
      padding: 0 20px;
      flex-wrap: wrap;
    }

    & &__itemIcon {
      width: 2rem;
      font-size: 28px;
    }

    & &__itemText {
      flex: 1;
    }

  }
}



/*
フッターナビ

Markup:
../styleguide/tmpl/11.3.2.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerNavi {
  @include container;
  max-width: $container-maxwidth2;

  & &__wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  /* 各カラム */
  & &__item {
    flex: 0 0 auto;

    h3 {
      font-family: $ff-en_thin;
      font-weight: 400;
      font-size: 1.5em;
      color: #fff;
      margin: 0;
      margin-bottom: 1em;
    }
  }

  /* リスト */
  & &__itemList {
    margin: 0;
    padding: 0;
    list-style: none;

    li {

      a {
        display: block;
        padding: 0.3em 0;
        color: inherit;
      }
    }
  }

  @include media_sphone {
    padding-top: $padding-sphone;

    & &__wrap {
      margin-top: -20px;
    }

    & &__item {
      width: 100%;
      margin-top: 20px;
    }
  }

  @include media_tablet {
    padding-top: $padding-tablet;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: -15px;
      margin-right: -15px;
    }

    /* フッターロゴ以降のカラムを3分割 */
    & &__item {
      width: 33.33%;// 3等分
      padding: 0 15px;
    }

    /* 区切り罫線 */
    & &__item:nth-child(n+2) {
      border-left: solid 1px hsla(0, 0%, 100%, 0.15);
    }
  }

  @include media_desktop {
    padding-top: $padding-desktop;

    & &__wrap {
      // itemの両局左右の余白をプラマイ0に補うためのネガティブマージン
      margin-left: -20px;
      margin-right: -20px;
    }

    & &__item {
      padding: 0 20px;
    }

    /* 3等分にはせず「CONTACT US」は最小幅で、リンクナビ側は大きく */
    & &__item:nth-child(1),
    & &__item:nth-child(2) {
      flex: 1 0 auto;      
    }
    & &__item:nth-child(3) {
      flex: 0 1 auto;
    }

    /* 区切り罫線 */
    & &__item:nth-child(n+2) {
      border-left: solid 1px hsla(0, 0%, 100%, 0.15);
    }

    /* リンクナビを2カラム表示 */
    & &__itemList {
      columns: 2;
      column-gap: 1em;
    }
  }
}


/*
CONTACT-US
フッターに配置される「CONTACT US」のスタイリングになります。
*/
.ec-contactUsRole {

  & &__address {
    margin: .75em 0;

    display: flex;
    align-items: flex-start;
    line-height: 1.35;
    
    i {
      display: block;
      font-size: 1.35em;
      opacity: 0.5;
      flex: 0 0 1em;
    }

    span {
      display: block;
      margin-left: .5em;
    }

    a {
      color: inherit;
    }
  }
}



/*
フッター ソーシャルボタン

Markup:
../styleguide/tmpl/11.3.3.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.3
*/
.ec-footerSocial {
  @include container;
  max-width: 1680px;

  padding-top: 20px;

  & &__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;

    li {
      display: inline-block;
      margin: 5px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;

        &:hover {
          text-decoration: none;
          color: #fff;
        }
      }

      i {
        display: block;
      }

      /* 各ソーシャルボタン毎に色付け（スマホ・タブレット時のみ） */
      @media only screen and ( max-width: $desktop - 1 ) {
        a {
          color: #fff;
        }
        &.facebook a {
          background-color: $clr-facebook;
        }
        &.twitter a {
          background-color: $clr-twitter;
        }
        &.instagram a {
          background-color: $clr-instagram;
        }
      }

      /* デスクトップ時はマウスオーバーで色が付くようにします */
      @include media_desktop {
        a {
          color: #fff;
          background-color: hsla(0, 0%, 100%, 0.2);
          transition: .2s;
        }
        &.facebook a:hover {
          background-color: $clr-facebook;
        }
        &.twitter a:hover {
          background-color: $clr-twitter;
        }
        &.instagram a:hover {
          background-color: $clr-instagram;
        }
      }
    }
  }
}



/*
フッタータイトル

Markup:
../styleguide/tmpl/11.3.4.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.4
*/
.ec-footerTitle {
  @include container;
  max-width: 1680px;

  font-family: $ff-en;
  text-align: center;
  line-height: 1.2;

  @include media_sphone {
    padding-top: ($padding-sphone / 2);
    padding-bottom: ($padding-sphone / 2);
  }

  @include media_tablet {
    padding-top: ($padding-tablet / 2);
    padding-bottom: ($padding-tablet / 2);
  }

  @include media_desktop {
    padding-top: ($padding-desktop / 2);
    padding-bottom: ($padding-desktop / 2);
  }

  & &__logo {
    font-size: 1.5em;
    color: #fff;
    margin-bottom: 0.5em;

    a {
      color: inherit;
    }
  }

  & &__copyright {
    font-size: 12px;
  }
}
