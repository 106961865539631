///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/



/*
テキストリンク

テキストリンクのスタイルです。

使用箇所：
Entry/index.twig
Mypage/login.twig
Shopping/login.twig

※ログインページ（Mypage,Shopping）では上書き調整しておりますのでこちらのスタイリングは反映されない形です。

Markup:
../styleguide/tmpl/1.2.1.html

Styleguide 1.2.1
*/
.ec-link {
  //color: #0092C4;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    //color: #33A8D0;
    text-decoration: none;
  }
}



/*
テキスト（太字）

テキストを太くするためのスタイルです。

使用箇所：
Product/list.twig
Shopping/index.twig
Shopping/confirm.twig

Markup:
../styleguide/tmpl/1.2.2.html

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: 500;
}



/*
テキスト（グレー）（未使用）

テキストをグレーにするためのスタイルです。

使用箇所：なし

Markup:
../styleguide/tmpl/1.2.3.html

Styleguide 1.2.3
*/
.ec-color-grey {
  color: $clrDefaultThin;
}



/*
テキスト（赤）

テキストを赤にするためのスタイルです。

使用箇所：
Cart/index.twig
Mypage/history.twig

Markup:
../styleguide/tmpl/1.2.4.html

Styleguide 1.2.4
*/
.ec-color-red {
  color: $clrRed;
}

.ec-color-accent {
  color: $clrRed;
}



/*
フォントサイズ（未使用）

フォントサイズを指定するためのスタイルです。

EC-CUBE4ではカートブロックで使用しているようですが、当テンプレートでは未使用です。

※当テンプレートではモバイル時はデバイス幅に比例して文字サイズが変化するよう（1行あたりの文字数が固定）vw単位でスタイリングしていますし、
デスクトップにおいてもrem、emを用いた相対的なスタイリングを考えていますので、
文字装飾でOOCSS的にpx指定のスタイリングは使わない考えです。このスタイリング自体を削除しておきたい所ですが一応残してあります。

※.mini {font-size:0.9em;}みたいな適用する場所において相対的に働くOOCSSならスタイリングしておきたいところです。

使用箇所：なし

Markup:
../styleguide/tmpl/1.2.5.html

Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px;
}

.ec-font-size-2 {
  font-size: 14px;
}

.ec-font-size-3 {
  font-size: 16px;
}

.ec-font-size-4 {
  font-size: 20px;
}

.ec-font-size-5 {
  font-size: 32px;
}

.ec-font-size-6 {
  font-size: 40px;
}



/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

使用箇所：
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/change_complete.twig
Shopping/complete.twig

Markup:
../styleguide/tmpl/1.2.6.html

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center;
}



/*
価格テキスト

価格を表示するテキストです。

使用箇所は商品詳細・一覧のみです。
こちらのページでは数字→価格表示の加工が動的に行われ（price関数）、「税込」表示もtransによる翻訳可能となっており、
それぞれが要素に囲まれた形になっています。
動的なので問題はないですが、別のブロック等で静的に商品リストをコーディングする際は簡単な後述の「.ec-price_in」の方を使用します。

font-familyと、価格部分と税込み表示部分の文字バランスだけにとどめています。
使う場面で親要素にfont-sizeを指定することでバランスを保ちつつ大きさを調整できるかと思います。

使用箇所：
* Product/list.twig
* Product/detail.twig

Markup:
../styleguide/tmpl/1.2.7.html

Styleguide 1.2.7
*/
.ec-price {
  font-family: $ff-en_thin;
  //font-sizeは各所において、.ec-priceかその親要素にて指定

  & &__price {
    display: inline-block;
    vertical-align: baseline;
    font-size: 1em;
  }

  & &__tax {
    display: inline-block;
    vertical-align: baseline;
    font-size: 0.65em;
    color: $clrDefaultThin;
  }
}



/*
価格テキスト（簡易版）

価格表示のスタイルはサイト内各ページで統一しておきたいところです。
ちなみに、商品詳細ページでは金額部分ではTwigの「price」関数で数値を価格表示テキストに変換し、さらに「税込」の表示も「trans」関数で翻訳可能としてありますので、
その分の要素数も多く複雑になっています。
対して、トップページの商品アイテムを含む各ブロックでは動的ではなく静的にHTMLを記述しなくてはなりませんのでその意味で価格部分が複雑になるのは避けたいと思います。
（「税込」や「￥」の文字を多数記述するのも避けたいです。変更が入ったときに作業量が増え、ミスも誘発しかねないので）
そこで簡易にコーディングできるよう、専用のスタイリングを用意し商品詳細と同じ見た目になるようにします。
ただし、翻訳機能を考慮するのであればもう一工夫する必要があるかと思います。

Styleguide 1.2.7.2
*/
.ec-price_in {
  font-family: $ff-en_thin;

  &:before {
    content: "￥";
  }

  &:after {
    content: "（税込）";
    font-size: 0.65em;
    color: $clrDefaultThin;
  }
}



/*
テキストの位置

テキストや、入れ子にしたインライン要素を「左揃え」「中央揃え」「右揃え」に設定することができます。

使用箇所は無い様子

Markup:
../styleguide/tmpl/1.2.8.html

Styleguide 1.2.8
*/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}



/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

使用箇所：
error.twig
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/change_complete.twig
Mypage/history.twig
Mypage/withdraw_complete.twig
Shopping/complete.twig

Markup:
../styleguide/tmpl/1.2.9.html

Styleguide 1.2.9
*/
.ec-reportDescription {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 2em;
  text-align: left;

  @include media_desktop {
    text-align: center;
  }
}



/*
テキスト下部のスペース

テキストの下に余白を追加することができます。

使用箇所：
Contact/index.twig
Forgot/index.twig
Mypage/delivery.twig
Mypage/index.twig

Markup:
../styleguide/tmpl/1.2.10.html

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 1em;
}