///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
レイアウトフレーム

管理画面のレイアウト管理のブロック配置をレイアウトするスタイリングです。

「.ec-layoutRole」下層の各レイアウトフレームについて定義しています。

* .ec-layoutRole
* .ec-layoutRole\_\_contentTop
* .ec-layoutRole\_\_contents
* .ec-layoutRole\_\_main
* .ec-layoutRole\_\_mainWithColumn
* .ec-layoutRole\_\_mainBetweenColumn
* .ec-layoutRole\_\_left
* .ec-layoutRole\_\_right

Styleguide 11.1
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: transparent;

  & &__contentTop {
    padding: 0;
  }

  & &__contents {
    width: 100%;
  }

  & &__main {
    width: 100%;
  }
}



/*
レイアウトフレーム（2～3カラム）

当テンプレートはTOPページと商品一覧ページのみ2カラムレイアウトとなっています。
その他のページは1カラムレイアウトです。

Styleguide 11.1.1
*/
.ec-layoutRole {

  @include media_sphone {
    & &__contents {
      // 左右カラムとメインカラムの配置順をコントールさせるためにflexボックスを用いてます
      display: flex;
      flex-flow: row wrap;
    }

    & &__contentBottom {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__mainWithColumn,
    & &__mainBetweenColumn {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__left,
    & &__right {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-top: $padding-sphone;
      padding-left: $padding-sphone;
      padding-right: $padding-sphone;
    }

    & &__right {
      order: 3;
    }
  }

  @include media_tablet {
    & &__contents {
      // 左右カラムとメインカラムの配置順をコントールさせるためにflexボックスを用いてます
      display: flex;
      flex-flow: row wrap;
    }

    & &__contentBottom {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__mainWithColumn,
    & &__mainBetweenColumn {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    & &__left,
    & &__right {
      order: 2;
      width: 100%;
      max-width: 100%;
      margin-top: $padding-tablet;
      padding-left: $padding-tablet;
      padding-right: $padding-tablet;
    }

    & &__right {
      order: 3;
    }
  }

  @include media_desktop {
    & &__contents {

      /*
       * TOPページと商品一覧ページは2カラムレイアウトなので、max-widthと左右余白をスタイリングします。
       * 他のページは1カラムレイアウトなので影響を与えないようにします。他のページではこの.ec-rayoutRole__contentsの中に含まれる各コンテナ自体が左右余白を持っているため2重余白にならないようにします。
       * 補足ですが、EC-CUBE本来だと.ec-rayoutRole__contentsにmax-widthが定義されていますが、テンプレートではブラウザのフル幅で背景を敷いたデザインも可能とするため、
       * .ec-layoutRole__contents側のmax-widthは外しその中の各コンテナでmax-widthと余白を持たせ必要に応じて柔軟にmax-widthが解除できるようにしています。
       */
      @at-root {
        body#page_homepage &,
        body#page_product_list &,
        .ec-wp-content &
        {
          display: flex;
          flex-flow: row;
          max-width: $container-maxwidth2;
          width: auto;
          margin-left: auto;
          margin-right: auto;
          padding-left: 30px;
          padding-right: 30px;
          box-sizing: content-box;
        }
      }
    }

    & &__contentBottom {
      max-width: $container-maxwidth2 + 60px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: content-box;
      position: relative;
      margin-top: 80px;
      border-top: 1px solid transparent;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 60px);
        height: 1px;
        left: 30px;
        top: 0;
        background-color: $clrBorderColor;
      }

    }

    // 2カラム時コンテンツ本体
    & &__mainWithColumn {
      flex: 0 0 75%;
      max-width: 75%;
      order: 2;
      margin-left: 30px;

      /* flex-boxにおいてslickを使う場合に表示が崩れる対策 */
      min-height: 0;
      min-width: 0;
    }

    & &__left {
      flex: 0 0 25%;
      max-width: 25%;
      order: 1;

      /* flex-boxにおいてslickを使う場合に表示が崩れる対策 */
      min-height: 0;
      min-width: 0;
    }

    // 3カラム時コンテンツ本体
    /*
    & &__mainBetweenColumn {
      flex: 0 0 50%;
      order: 2;
      max-width: 50%;// for IE11
      margin-left: 30px;
      margin-right: 30px;
    }

    & &__right {
      order: 3;
      flex: 1 1 auto;
    }
    */
  }
}



/*
汎用コンテナ

汎用的なコンテナブロックです。

mixin「コンテナ」を@includeしています。
mixin「コンテナ」は、コンテナ自体のmax-width、左右余白などブロックスタイルの共通スタイリングを施しています。
各セクションの左右余白の統一感はコンテナによってもたらされます。

背景色をブラウザ幅フルに敷く場合は「max-width:100%;」上書きしておいて、コンテナの中にinner的要素を設けてそちらで改めてmax-widthを指定します。

Styleguide 11.1.2
*/
.ec-role {
  @include container;

  @include media_sphone {
    margin-top: $padding-sphone * 3;
  }

  @include media_tablet {
    margin-top: $padding-tablet * 3;
  }

  @include media_desktop {
    margin-top: $padding-desktop * 2;
  }
}



/*
マイページコンテナ

マイページ専用のコンテナブロックです。

※当テンプレートでは汎用コンテナと同一です。

Styleguide 11.1.3
*/
.ec-mypageRole {
  @include container;
}



/*
その他コンテナ

その都度、セクションごとに「@include container;」してコンテナ化させています。
上記、マイページコンテナもマイページ定義のscss側に移動したほうがいいかとも思います。

Styleguide 11.1.4
*/
