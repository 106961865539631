///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
トップセール

売れ筋商品やセール商品といった3カテゴリで３点ずつ、9点の商品を表示するブロックのスタイリングです。

使用箇所：
* Block/eyecatch.twig

Markup:
../styleguide/tmpl/12.2.html

Styleguide 12.2
*/
.ec-topSaleRole {
  @include container;

  /* セクション見出しを上書き調整（セクション見出しは「_12.4.heading.scss」で基本スタイリングされています） */
  .ec-secHeading {

    .ec-secHeading__maintitle:before,
    .ec-secHeading__maintitle:after {
      //width: 1em;
      display: none;
    }
  }

  @include media_sphone {

    & &__category {
      margin-top: $padding-sphone;
    }

    /* セクション見出しを上書き調整 */
    .ec-secHeading {
      text-align: left;
      margin-bottom: 1rem;
      
      .ec-secHeading__maintitle {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.3rem;
      }

      .ec-secHeading__subtitle {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 0.5em;
      }
    }
  }

  @include media_tablet {

    & &__category {
      margin-top: $padding-tablet;
    }

    /* セクション見出しを上書き調整 */
    .ec-secHeading {
      text-align: left;
      margin-bottom: 1rem;

      .ec-secHeading__maintitle {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.3rem;
      }

      .ec-secHeading__subtitle {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 0.5em;
      }
    }

    /* 商品レイアウトを横3列表示 */
    & &__list {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      .ec-productItemRole {
        flex: 0 0 32%;
      }
    }
  }
  
  @include media_desktop {
    margin-top: $padding-desktop * 2;
    display: flex;
    justify-content: space-between;

    /* セールカテゴリを3列表示 */
    & &__category {
      flex: 0 0 32%;
    }

    /* セクション見出しを上書き調整 */
    .ec-secHeading {
      text-align: left;

      .ec-secHeading__maintitle {
        font-size: 1.4rem;
      }
    }
  }

  /* 商品アイテムを上書き調整（商品アイテムは「_13.2.shelf.scss」にて基本スタイリングされています） */

  /* 先にタブレット・デスクトップ用のスタイリングを施します。スマホはレイアウトが違うのでそのあとで上書き定義します。 */
  .ec-productItemRole {
    display: flex;
    flex-flow: row;
    margin: 0;

    .ec-productItemRole__image {
    }

    .ec-productItemRole__content {
      flex: 1 1 auto;
      padding: 0;
      padding-left: .75em;
      text-align: left;

      .productItem_name {
        font-size: 0.933rem;
        margin-bottom: .5em;
      }
      
      .productItem_price {
        font-size: 0.933rem;
      }
    }

    @include media_tablet {

      .ec-productItemRole__image {
        flex: 0 0 40%;
      }
    }

    @include media_desktop {
      margin-bottom: 20px;

      .ec-productItemRole__image {
        flex: 0 0 40%;
      }
    }
  }

  /* スマホ用（商品情報を非表示とし商品画像を横に3点並べる） */
  @include media_sphone {
    .ec-topSaleRole__list {
      @include shelfLayout(3, 5px, 0px, div);
    }

    .ec-productItemRole {
      display: block;
    }

    .ec-productItemRole__content {
      display: none;
    }
  }
}
