///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フォントファミリー

使用するフォントファミリー

Styleguide 0.2
*/
/*
 * MEMO
 * IE11でNoto表示するには「&subset=japanese」が必要。
 * Notoは字詰めカーニングが効かない。カーニングが必要であれば游ゴシックなど別フォントに変更。
 * Google Fontsが「display=swap」対応（フォントが読み込まれるまで代替フォントを表示）
*/
@import 'https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&subset=japanese&display=swap';
@import 'https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500&subset=japanese&display=swap';
@import 'https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap';
@import 'https://fonts.googleapis.com/css?family=Oswald:400,500&display=swap';
@import 'https://fonts.googleapis.com/css?family=Roboto:400&display=swap';

$ff-default:    'Noto Sans JP', sans-serif;
$ff-min:        'Noto Serif JP', serif;
$ff-en:         'Poppins', 'Noto Sans JP', sans-serif;
$ff-en_thin:    'Oswald', 'Noto Sans JP', sans-serif;
$ff-roboto:     'Roboto', sans-serif;

body {
  font-family: $ff-default;
  font-weight: 400;
}

button, input, select, textarea {
  font-family: $ff-default;
}
