///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
注文内容確認

ショッピングフロー（入力、確認）と、マイページの注文履歴詳細

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/15.2.html

Styleguide 15.2
*/

/*
ページレイアウト

タブレット・デスクトップは注文内容とカートサマリーを左右2分割。スマホは縦1列配置。
*/
.ec-orderRole {
  @include container;

  @include media_sphone {
    display: block;

    & &__detail {
    }

    & &__summary {
    }
  }

  @include media_tablet {
    display: flex;
    justify-content: space-between;

    & &__detail {
      flex: 1 1 auto;
      padding-right: 30px;
    }

    & &__summary {
      flex: 0 0 250px;

      /*
      スクロールしてもサマリー欄をページ内に粘着（sticky）させる
      EC-CUBE4標準ではJavascriptで実装している機能ですが、CSSの「position:sticky;」を使って実装してみます。
      当テンプレートではヘッダーがページ上部に固定させる仕様のため、そのままではヘッダーに隠れてしまいます。
      スクリプトを調整するよりはstickyでの実装が簡単だと判断しました。（※IE11では動作しません。）
      */
      .ec-totalBox {
        margin: 0;
        position: sticky;
        top: $tablet-header-height + 20px;
      }
    }
  }
  
  @include media_desktop {
    display: flex;
    justify-content: space-between;

    & &__detail {
      flex: 1 1 auto;
      padding-right: 40px;
    }

    & &__summary {
      flex: 0 0 27%;

      /* スクロール粘着（タブレット時と同様） */
      .ec-totalBox {
        margin: 0;
        position: sticky;
        top: $desktop-header-height + 30px;
      }
    }
  }
}

/* 注文内容各項目の共通（下マージン） */
.ec-orderOrder,
.ec-orderAccount,
.ec-orderDelivery,
.ec-orderPayment {

  @include media_sphone {
    margin-bottom: $padding-sphone;
  }

  @include media_tablet {
    margin-bottom: $padding-tablet;    
  }

  @include media_desktop {
    margin-bottom: $padding-desktop;
  }
}

.ec-orderConfirm {
  margin-bottom: 0;
  
  @include media_sphone {
    margin-bottom: $padding-sphone;
  }
}



/*
オーダー情報（注文履歴詳細）

マイページ 注文履歴詳細の冒頭、注文日時や注文番号の記載の部分

使用箇所：
* Mypage/history.twig

※メモ：dt,dd要素で組まれているがdl親要素が無いのでできれば調整しておいたほうがベスト

Markup:
../styleguide/tmpl/15.2.1.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.1
*/
.ec-orderOrder {
  font-size: 0.933rem;

  // 中身本体の「.ec-definitions」は_1.3.list.scssにて
  .ec-definitions {
    //@include borderBottom;
    padding-bottom: 0.5em;
    border-bottom: dotted 1px #ccc;

    dt {
      font-weight: normal;
    }
  }
}



/*
お客様情報（ショッピングフロー）

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig

Markup:
../styleguide/tmpl/15.2.2.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.2
*/
.ec-orderAccount {

  /* お客様情報（確定情報） */
  & &__account {

    border: solid 1px #ddd;
    padding: 10px;

    // 入力フォームでないテキスト表示の場合もフォーム用classが使われているので（気持ちは悪いが構造は同じというメリット？）調整しつつ活用
    .ec-halfInput,
    .ec-input,
    .ec-zipInput,
    .ec-telInput {
      margin: 0;
      margin-bottom: 0.5em;
    }

    .ec-zipInput{
      margin-bottom: 0;
    }

    .ec-telInput {
      &:before {
        //font-family: "jeiafont";
        //content: "\e910 TEL　";
        content: "電話番号　";
      }
    }

    .customer-email:before {
      //font-family: "jeiafont";
      //content: "\e911 Email　";
      content: "メールアドレス　";
    }
  }

  /* ゲスト購入時の編集入力枠 */
  .ec-borderedDefs {
    margin-bottom: 1.5rem;

    @include media_sphone {
      margin-bottom: 0;
    }
  }

  /* 変更ボタン */
  & &__change {
    text-align: right;
    margin-bottom: 10px;
  }
}



/*
配送情報

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

※メモ：dt,dd要素で組まれているがdl親要素が無いのでできれば調整しておいたほうがベスト

Markup:
../styleguide/tmpl/15.2.3.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.3
*/
.ec-orderDelivery {

  /* タイトル「お届け先」 */
  & &__title {
    font-size: 1rem;
    line-height: 40px;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem;
  }

  /* 変更ボタン */
  & &__change {
    position: absolute;
    right: 0;
    top:0;
  }

  /* 商品内容 */
  & &__item {
    // ここでの.ec-orderDelivery__itemには何もスタリングしません。
    // → _1.3.list.scssにてマイページ用のスタイリングを考慮してそちらにて（理由はそちらのメモ書きに）

    /* 軽減税率対象商品である事のコメント */
    ul + p {
      font-size: 0.933rem;
      margin-top: .5em;
    }
  }

  /* お届け先住所 */
  & &__address {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    p {
      margin:0;
    }
  }

  /* 配送方法・お届け日時 */
  & &__actions {

    .ec-selects {
      // .ec-selects以下、__delivery,__timeは、_3.2.inputMisc.scssにて定義
    }

    label {
      font-weight: normal;
      min-width: 6em;

      @include media_desktop {
        min-width: 5em;
      }
    }
  }

  /* お届け先追加ボタンエリア */
  & &__edit {
    @include borderTop;
    padding-top: 20px;
    margin-top: 20px;
  }


  /*
  複数配送先の場合　各お届け先ごとのエリア区分が判別しやすいように調整
  少し太めのborderを敷いています。
  
  ※お届け先ごとにグルーピングされる要素が無いので、2つ目以降のお届け先は、
  「__actions」に続く「__title」でしか判別できません
  */
  .ec-orderDelivery__actions + .ec-orderDelivery__title {
    margin-top: 1em;
    border-top: solid 2px #ddd;
    padding-top: 1em;

    // 変更ボタンの位置調整
    .ec-orderDelivery__change {
      top: 1em;
    }
  }
}



/*
注文詳細 お支払情報

※「お支払い方法」欄のほか、「利用ポイント」も同じclassを使用

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/15.2.4.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.4
*/
.ec-orderPayment {

  // 利用ポイント：入力フォームの調整
  .ec-input {

    input {
      width: 10em;
      text-align: right;
    }
  }
}



/*
注文詳細 お問い合わせ

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/15.2.5.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.5
*/
.ec-orderConfirm {
}



/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [ショッピングページ　お届け先の複数指定](http://eccube-4.0.2.local/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

使用箇所：
* Shopping/shipping_multiple.twig

Markup:
../styleguide/tmpl/15.2.6.html

Styleguide 15.2.6
*/
.ec-AddAddress {
  @include container;

  & &__info {
  }

  /* 新規お届け先追加ボタンエリア */
  & &__new {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    @include borderBottom;

    @include media_sphone {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;      
    }
  }
  
  /* 商品単位のフレーム */
  & &__add {
    @include borderBottom;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem;

    @include media_sphone {
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }

  /* 商品アイテム（画像とコンテンツ） */
  & &__item {
    display: flex;
  }

  /* 商品アイテム：画像 */
  & &__itemThumb {
    flex: 0 0 auto;
    width: 22vw;
    max-width: 120px;

    img {
      width: 100%;
    }
  }

  /* 商品アイテム：コンテンツ */
  & &__itemtContent {
    flex: 1 1 auto;
    padding-left: 1em;
  }

  /* 商品アイテムコンテンツ：商品名 */
  & &__itemtTitle {
    font-weight: 500;
  }

  /* 商品アイテムコンテンツ：商品規格名 */
  & &__itemtSize {
    font-size: 0.9em;
    margin-left: 0.75em;
  }

  /* 商品アイテムコンテンツ：商品価格 */
  & &__itemtPrice {
    margin-top: 0.5em;// __itemSizeがある場合と無い場合があるので、__itemTileとの間隔はこちらで制御
    display: inline-block;
  }

  /* 商品アイテムコンテンツ：数量 */
  & &__itemtNumber {
    display: inline-block;
    margin-left: 1em;
  }

  /* 住所・数量選択欄 */
  & &__select {
    margin: 20px 0;

    background-color: $cart-bgcolor;
    padding: 1em;

    /* 削除ボタン */
    .ec-inlineBtn.delete {
      display: inline-block;
      margin-left: 1em;
    }

    // スマホ用調整（削除ボタン配置）
    @include media_sphone {
      position: relative;

      .ec-inlineBtn.delete {
        position: absolute;
        bottom: 1em;
        right: 1em;
        padding: 8px 1em;
      }      
    }
  }

  /* 住所選択 */
  & &__selectAddress {
    display: inline-block;

    label {
      font-weight: normal;
      margin-right: .5em;
    }

    .select_ui {
      margin: 0;
    }

    // スマホ用に調整
    @include media_sphone {
      display: block;

      label {
        margin: 0 0 1em;
      }
      select {
        max-width: 100%;
      }
    }

    // タブレット用調整
    @include media_tablet {
      select {
        // 住所が長い場合、タブレット時はレイアウトが崩れる可能性がありますので最大幅を指定しておきます
        max-width: 50vw;
      }
    }
  }

  /* 数量指定 */
  & &__selectNumber {
    display: inline-block;
    margin-left: 1em;

    label {
      font-weight: normal;
      margin-right: .5em;
    }

    input {
      display: inline-block;
      width: 80px;
    }

    // スマホ用に調整
    @include media_sphone {
      display: block;
      margin-left: 0;
      margin-top: 1em;
    }
  }

  /* お届け先追加ボタンエリア */
  & &__btn {
  }

  /* 選択したお届け先に送るボタンエリア */
  & &__actions {

    .ec-blockBtn--action {
      margin-bottom: 10px;
    }
  }
}




/*
補正

EC-CUBE4標準では、フォーム送信系のページの確認ページにおいて、入力フォームではない部位に「.ec-input」などのフォーム部品包括要素が
そのまま入力ページと同じ構成で使われています。
これが仕様なのかコーディングミスなのかは判別つきませんが、それにより少々CSSスタイリングがしづらい面があります。

※フォームパーツの特性がそれぞれにあるからこそ包括要素もそれぞれにあるのだろうと思うのですが、確認ページはそもそもテキスト表示のみですから、
そこは意図するものが違いますから別物としてコーディングすべきかとも思います。


ショッピングフローの「確認ページ」において、「お支払い方法」「利用ポイント」は「.ec-blockRadio」が使われていますがそれにより崩れが生じます。
（※「.ec-blockRadio」はラジオ部品を縦に並べる際に部品間に与えた余白を、ネガティブマージンで全体調整しています）
「.ec-blockRadio」のネガティブマージンを別の手法に置き換えるか考えるところですが、しかし、「お支払い方法」は入力ページでは別要素「.ec-radio」で
括られており入力ページと確認ページとで包括要素が別物になってるあたりコーディングミスなのだろうと思いますので、
.ec-blockRadioを調整するよりは、部分的な補正にとどめるのがベストかと思います。
さらに言えば「利用ポイント」はblockRadioの要素はまったくないわけですし…。次期バージョンアップで修正を希望します。

確認ページ：お支払い方法、利用ポイントの補正

Styleguide 15.2.90
*/
body#page_shopping_confirm {
  .ec-blockRadio {
    margin-top: 0;
  }
}
