.product_collection .ec-breadcrumbRole {
  display: none;
}

.ec-productIntroductionRole {
  font-size: 71.4%;
  color: #000;
  font-family: $ff-min;
  div, h2, h3, h4, h5, h6, i, img, li, nav, ol, p, section, small, span, strong, ul, video {
    background: 0 0;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    text-align: left;
  }
  small {
    display: inline-block;
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
  }
  ul {
    list-style: none;
  }
  a {
    background: 0 0;
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 10px;
    vertical-align: baseline;
    text-decoration: none;
    cursor: pointer!important;
  }
  @mixin link() {
    a {
      text-decoration: underline;
      letter-spacing: .08em;
      transition: opacity .3s;
      @media (max-width: 767px) {
        color: #666;
        font-size: 3.47vw;
      }
      @media (min-width: 768px) {
        color: #222;
        font-size: 14px;
      }
      &:hover {
        opacity: .7
      }
    }
  }
  .-indent {
    text-indent: -.5em;
  }
  .mt-1 {
    @media (min-width: 768px) {
      margin-top: 10px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      margin-top: .73vw;
    }
  }
  .intro-image,
  .block_6 .item>li:nth-of-type(1),
  .block_8 .scene,
  .block_5 .scene {
    margin-left: auto;
    margin-right: auto;
  }
  .js-fadeIn {
    transform: translate3d(0,40px,0);
    opacity: 0;
    transition: transform 1s,opacity 1s;
  }
  .js-fadeIn.is-active {
    transform: translate3d(0,0,0);
    opacity: 1;
  }
  br.forPC {
    @media (max-width: 767px) {
      display: none;
    }
    @media (min-width: 768px) {
      display:inline;
    }
  }
  br.forSP {
    @media (max-width: 767px) {
      display: inline;
    }
    @media (min-width: 768px) {
      display: none;
    }
  }
  .main,
  .main.forPC {
    @media (max-width: 767px) {
      display: none !important;
    }
  }
  .main.forSP {
    @media (max-width: 767px) {
      display: block!important;
    }
    @media (min-width: 768px) {
      display: none!important;
    }
  }
  .mainvisual {
      width: 100%;
      .kv {
        position: relative;
        video {
          display: block;
          width: 100%;
        }
      }
  }
  .main.forSP {
    .intro-text {
      padding-left: 14.67vw;
      margin-top: 13.33vw;
      .base-text {
        color: #666;
        letter-spacing: .1em;
      }
      .small {
        font-size: 2.93vw;
      }
      .base-text {
        font-size: 3.73vw;
        line-height: 1.43;
      }
      .base-text+.base-text {
        margin-top: 6.67vw;
      }
    }
  }
  .intro {
    @media (min-width: 768px) {
      padding-top: 100px;
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
      padding-top: 7.32vw;
    }
    .base-text {
      color: #666;
      letter-spacing: .1em;
      @media (max-width: 767px) {
        font-size: 3.73vw;
        line-height: 1.43;
      }
      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 1.71;
      }
      +.base-text {
        @media (min-width: 768px) {
          margin-top: 25px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          margin-top: 1.83vw;
        }
      }
    }
    .small {
      @media (min-width: 768px) {
        font-size: 12px;
      }
    }
  }
  .intro-inner {
    @media (max-width: 767px) {
      padding-top: 12.67vw;
    }
    @media (min-width: 768px) {
      display: flex;
    }
  }
  .intro-image {
    @media (max-width: 767px) {
      width: 71.33vw;
    }
    @media (min-width: 768px) {
      width: 46.11%;
      padding-left: 185px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      padding-left: 13.54vw;
    }
    img {
      @media (min-width: 768px) {
        width: 210px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        width: 15.37vw;
      }
    }
  }
  .intro-text {
    @media (min-width: 768px) {
      width: 53.89%;
    }
  }
  .collection-wrap {
    @media (max-width: 767px) {
      padding-top: 24vw;
    }
    @media (min-width: 768px) {
      padding-top: 100px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      padding-top: 7.32vw;
    }
  }
  .block {
    font-family: 'Playfair Display', serif;
    .price {
      font-family: 'Dynalight', serif;
    }
    .left,
    .right {
      @media (max-width: 767px) {
        color: #666;
        font-size: 2.93vw;
      }
      @media (min-width: 768px) {
        letter-spacing: .08em;
        color: #222;
        font-size: 11px;
      }
    }
    .brand {
      @media (max-width: 767px) {
        color: #666;
        font-size: 2.93vw;
      }
      @media (min-width: 768px) {
        letter-spacing: .08em;
        color: #222;
        font-size: 12px;
      }
    }
    .item-info {
      @media (max-width: 767px) {
        margin-top: 8vw;
        padding: 0 5.33vw;
      }
      li {
        letter-spacing: .08em;
        @include link;
        @media (max-width: 767px) {
          color: #666;
          font-size: 3.47vw;
          line-height: 1.15;
        }
        @media (min-width: 768px) {
          color: #222;
          font-size: 14px;
          line-height: 1.5;
        }
        +li {
          @media (max-width: 767px) {
            margin-top: 4vw;
          }
          @media (min-width: 768px) {
            margin-top: 9px;
          }
        }
      }
    }
    .item-color {
      margin-top: 2vw;
      @media (min-width: 768px) {
        margin-top: 9px;
      }
      p + ul {
        margin-top: 2vw;
        @media (min-width: 768px) {
          margin-top: 8px;
        }
      }
      ul {
        display: flex;
        gap: 2vw;
        @media (min-width: 768px) {
          gap: 8px;
        }
        li {
          width: 8vw;
          margin: 0 !important;
          @media (min-width: 768px) {
            width: 60px;
          }
        }
      }
    }
  }
  .block_1 {
    @media (max-width: 767px) {
      margin-top: 32vw;
    }
    @media (max-width: 767px) {
      margin-bottom: 28.27vw;
    }
    @media (min-width: 768px) {
      max-width: 792px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 182px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 57.98vw;
    }
    .item {
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
      @media (min-width: 768px) {
        position: relative;
      }
      >li:nth-of-type(1) {
        @media (max-width: 767px) {
          margin: 0 auto 16.53vw;
          padding: 0 10vw;
        }
        @media (min-width: 768px) {
          width: 400px;
          margin: 0 auto 63px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          width: 29.28vw;
          margin: 0 auto 4.61vw;
        }
      }
      >li:nth-of-type(2) {
        @media (max-width: 767px) {
          order: -1;
        }
        @media (min-width: 768px) {
          width: 100%;
        }
      }
    }
    .item-info {
      @media (min-width: 768px) {
        margin-top: 30px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        margin-top: 2.2vw;
      }
    }
  }
  .block_2 {
    @media (max-width: 767px) {
      margin-top: 32vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 182px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
    }
    .item {
      @media (min-width: 768px) {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
      >li:nth-of-type(1) {
        @media (max-width: 767px) {
          order: -1;
          padding: 0 10vw;
        }
        @media (min-width: 768px) {
          width: 468px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          width: 34.26vw;
        }
      }
      >li:nth-of-type(2) {
        @media (max-width: 767px) {
          margin-top: 16.67vw;
        }
        @media (min-width: 768px) {
          width: 582px;
          padding-top: 120px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          padding-top: 8.78vw;
          width: 42.61vw;
        }
      }
    }
    .item-info {
      @media (min-width: 768px) {
        position: absolute;
        bottom: -7px;
        left: 612px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        left: 44.8vw;
        bottom: -.51vw;
      }
    }
  }
  .block_3 {
    @media (max-width: 767px) {
      margin-top: 32vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 182px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
    }
    .item {
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        position: relative;
      }
      >li:nth-of-type(1) {
        @media (max-width: 767px) {
          margin-top: 8vw;
          padding: 0 10vw;
        }
        @media (min-width: 768px) {
          width: 43.33%;
          padding-top: 400px;
          display: block;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          padding-top: 29.28vw;
        }
      }
      >li:nth-of-type(2) {
        @media (max-width: 767px) {
          margin-top: 16.67vw;
        }
        @media (min-width: 768px) {
          width: 53.89%;
        }
      }
    }
    .item-info {
      @media (min-width: 768px) {
        position: absolute;
        bottom: -7px;
        left: 500px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        left: 36.6vw;
        bottom: -.51vw;
      }
      li {
        @media (min-width: 768px) and (max-width:1365px) {
          font-size: 1.02vw;
        }
        a {
          @media (min-width: 768px) and (max-width:1365px) {
            font-size: 1.02vw;
          }
        }
        +li,
        .item-color {
          @media (min-width: 768px) and (max-width:1365px) {
            margin-top: 0;
          }
        }
      }
    }
  }
  .block_3-2 {
    @media (max-width: 767px) {
      margin-top: 32vw;
      margin-bottom: 28.53vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 212px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
    }
    .item {
      @media (max-width: 767px) {
        margin-bottom: 16vw;
      }
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 84px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        margin-bottom: 6.15vw;
      }
      >li:nth-of-type(1) {
        @media (max-width: 767px) {
          margin-top: 8vw;
          padding: 0 10vw;
        }
        @media (min-width: 768px) {
          width: 43.33%;
          padding-top: 267px;
          display: block;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          padding-top: 19.55vw;
        }
      }
      >li:nth-of-type(2) {
        @media (max-width: 767px) {
          margin-top: 16.67vw;
        }
        @media (min-width: 768px) {
          width: 53.89%;
        }
      }
    }
    .item-info {
      @media (min-width: 768px) {
        position: absolute;
        bottom: -7px;
        left: 500px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        left: 36.6vw;
        bottom: -.51vw;
      }
      li {
        +li,
        .item-color {
          @media (min-width: 768px) and (max-width:1365px) {
            margin-top: 0;
          }
        }
      }
    }
    .item2 {
      margin: 0 auto;
      @media (max-width: 767px) {
        padding: 0 5.33vw;
      }
      @media (min-width: 768px) {
        width: 400px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        width: 29.28vw;
      }
    }
    .item2-info {
      @media (max-width: 767px) {
        margin-top: 8vw;
      }
      @media (min-width: 768px) {
        margin-top: 28px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        margin-top: 2.05vw;
      }
      p {
        letter-spacing: .08em;
        @include link;
        @media (max-width: 767px) {
          color: #666;
          font-size: 3.47vw;
          line-height: 1.15;
        }
        @media (min-width: 768px) {
          color: #222;
          font-size: 14px;
          line-height: 1.5;
        }
      }
    }
  }
  .block_4 {
    @media (max-width: 767px) {
      margin-top: 32vw;
      margin-bottom: 28.53vw
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 207px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
      margin-bottom: 15.15vw;
    }
    .item {
      @media (min-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 84px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        margin-bottom: 6.15vw;
      }
      >li:nth-of-type(1) {
        @media (max-width: 767px) {
          margin-top: 8vw;
          padding: 0 10vw;
        }
        @media (min-width: 768px) {
          width: 100%;
          padding-top: 60px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          padding-top: 4.39vw;
        }
        img {
          @media (min-width: 768px) {
            width: 480px;
          }
          @media (min-width: 768px) and (max-width:1365px) {
            width: 35.14vw;
          }
        }
      }
      >li:nth-of-type(2) {
        @media (max-width: 767px) {
          margin-top: 16.67vw;
        }
        @media (min-width: 768px) {
          width: 600px;
          margin: 0 auto;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          width: 43.92vw;
        }
      }
    }
    .item-info {
      @media (min-width: 768px) {
        position: absolute;
        bottom: -7px;
        left: 510px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        left: 37.34vw;
        bottom: -.51vw;
      }
    }
  }
  .block_5 {
    @media (max-width: 767px) {
      margin-top: 29.33vw;
      margin-bottom: 31.2vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
    }
    .item {
      @media (max-width: 767px) {
        margin-bottom: 29.73vw;
      }
      @media (min-width: 768px) {
        display: flex;
        align-items: flex-end;
        margin-bottom: 215px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        margin-bottom: 15.74vw;
      }
    }
    .item-image {
      @media (max-width: 767px) {
        margin-top: 29.33vw;
      }
      @media (min-width: 768px) {
        width: 582px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        width: 42.61vw;
      }
    }
    .item-info {
      @media (min-width: 768px) {
        padding-left: 30px;
        margin-bottom: -7px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        padding-left: 2.2vw;
        margin-bottom: -.51vw;
      }
    }
    .scene {
      @media (max-width: 767px) {
        width: 60vw;
      }
      @media (min-width: 768px) {
        width: 33.33%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .block_6 {
    @media (max-width: 767px) {
      margin-top: 29.33vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
    }
    .item {
      position: relative;
      >li:nth-of-type(1) {
        @media (max-width: 767px) {
          width: 80vw;
        }
        @media (min-width: 768px) {
          width: 44.44%;
        }
      }
      >li:nth-of-type(2) {
        position: absolute;
        @media (max-width: 767px) {
          width: 66.67vw;
          right: 0;
          top: 94.67vw;
        }
        @media (min-width: 768px) {
          width: 37.04%;
          left: 640px;
          top: 575px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          left: 46.85vw;
          top: 42.09vw;
        }
      }
      >li:nth-of-type(3) {
        @media (max-width: 767px) {
          margin-top: 93.33vw;
        }
        @media (min-width: 768px) {
          display: flex;
          align-items: flex-end;
          margin-top: 415px;
        }
        @media (min-width: 768px) and (max-width:1365px) {
          margin-top: 30.38vw;
        }
      }
    }
    .item-image {
      @media (min-width: 768px) {
        width: 68.52%;
        padding-left: 140px;
        flex-shrink: 0;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        padding-left: 10.25vw;
      }
    }
    .item-info {
      @media (min-width: 768px) {
        padding-left: 30px;
        margin-bottom: -7px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        padding-left: 2.2vw;
        margin-bottom: -.51vw;
      }
    }
  }
  .block_7 {
    @media (max-width: 767px) {
      margin-top: 29.33vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px;
    }
    .item {
      @media (min-width: 768px) {
        width: 73.61%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .item-info {
      @media (min-width: 767px) {
        margin-top: 30px;
      }
    }
  }
  .block_8 {
    @media (max-width: 767px) {
      margin-top: 29.33vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      max-width: 79.06vw;
    }
    .item {
      @media (min-width: 768px) {
        display: flex;
        align-items: flex-end;
        margin-top: 200px;
      }
    }
    .item-info {
      @media (min-width: 768px) {
        padding-left: 30px;
        margin-bottom: -7px;
      }
      @media (min-width: 768px) and (max-width:1365px) {
        padding-left: 2.2vw;
        margin-bottom: -.51vw;
      }
    }
    .scene {
      @media (max-width: 767px) {
        width: 60vw;
      }
      @media (min-width: 768px) {
        width: 33.33%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .item-image {
      @media (max-width: 767px) {
        margin-top: 29.33vw;
      }
      @media (min-width: 768px) {
        width: 52.41%;
      }
    }
  }
  .block_9 {
    @media (max-width: 767px) {
      margin-top: 28vw;
      margin-bottom: 18.67vw;
    }
    @media (min-width: 768px) {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 100px;
    }
    @media (min-width: 768px) and (max-width:1365px) {
      margin-bottom: 7.32vw;
    }
    .item {
      @media (max-width: 767px) {
        margin-top: 14.67vw;
      }
      @media (min-width: 768px) {
        width: 52.41%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 90px;
      }
    }
    .item-info {
      @media (min-width: 768px) {
        font-size: 26px;
        line-height: 1.15;
        margin-top: 30px;
      }
    }
    .scene {
      @media (max-width: 767px) {
        padding: 0 10vw;
      }
      @media (min-width: 768px) {
        width: 44.44%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .notes {
    color: #666;
    display: flex;
    justify-content: center;
    letter-spacing: .08em;

    @media (max-width: 767px) {
      margin-top: 32vw;
      font-size: 3.47vw;
      line-height: 1.15;
    }
    @media (min-width: 768px) {
      font-size: 14px;
      line-height: 2.14;
    }
  }
}
