///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
マイページ　注文履歴一覧

使用箇所：
Mypage/index.twig

Markup:
../styleguide/tmpl/16.1.html

Styleguide 16.1
*/
.ec-historyRole {
  font-size: .933rem;

  & &__contents{
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    @include borderTop;

    @include media_sphone {
      display: block;      
    }
  }

  /* 左枠：注文データ */
  & &__header{
    flex: 0 0 25%;
    background-color: #f7f6f6;
    padding: 1em;

    @include media_tablet {
      flex-basis: 33%;
    }

    @include media_sphone {
      width: 100%;
      padding: 10px;
    }
  }

  /* 右枠：商品内容 */
  & &__detail{
    flex: 1 1 auto;
    padding-left: 10px;

    @include media_sphone {
      width: 100%;
      padding: 0;
      margin-top: 10px;
    }

    /* 商品内容 */
    // 商品内容本体「.ec-imageGrid」は、_5.2.layout.scssにて
    .ec-imageGrid + .ec-imageGrid {
        margin-top: 10px;
    }

    /* 商品名称 */
    .ec-historyRole__detailTitle {
    }

    /* 商品価格＆数量 */
    .ec-historyRole__detailPrice {
    }
  }
}

/*
注文履歴一覧ヘッダ内容
*/
.ec-historyListHeader {

  /* 日付 */
  & &__date{
  }

  /* ご注文番号・状況*/
  .ec-definitions {
    // 中身本体「.ec-definitions」は_1.3.list.scssにて

    // 上書きして調整
    dt {
      font-weight: normal;
      min-width: auto;
    }
  }

  & &__action{
    margin: 0;
    margin-top: 1.5em;
  }

  @include media_sphone {
    position: relative;

    .ec-definitions {
      margin: 0;
    }

    & &__action {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
    }
  }
}
