.ec-productOptions {
  > div {
    padding: 15px 0 10px;

    + div {
      border-top: 1px solid $clrBorderColor;
      margin-top: 5px !important;
    }

    > div {
      + div {
        margin-top: 5px;
      }
    }

    &:last-child {
      border-bottom: 1px solid $clrBorderColor;
    }
  }
}