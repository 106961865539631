///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

注文履歴詳細の構造は`.ec-orderRole`と同じで、これはスタイルガイド15.2「注文内容確認」でスタイリングが完了しておりここでは追加定義は何もありません。

Markup:
../styleguide/tmpl/16.2.html

Styleguide 16.2
*/



/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

使用箇所：
* Mypage/history.twig

Styleguide 16.2.5
*/
.ec-orderMails {
}



/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

使用箇所：
* Mypage/history.twig

メモ：
メール本文の`.ec-orderMail__body`は初期時は「display:none;」されており、function.jsにより`.ec-orderMail__link`クリックで展開されるようです。

Markup:
../styleguide/tmpl/16.2.6.html

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  @include borderBottom();

  & &__time{
    margin: 0;
  }

  & &__body{
    display: none;
  }

  & &__time {
    margin-bottom: .5em;
  }

  & &__link {
    a {
      color: #0092C4;
      text-decoration: none;
      cursor: pointer;
    }

    a:hover {
      color: #33A8D0;
    }

    margin-bottom: 4px;
  }

  & &__close{
    a {
      color: #0092C4;
      text-decoration: none;
      cursor: pointer;
    }
    
    a:hover {
      color: #33A8D0;
    }
  }
}
