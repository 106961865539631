///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

使用箇所：
Product/list.twig

Markup:
../styleguide/tmpl/13.2.html

Styleguide 13.2
*/
.ec-shelfRole {
  @include container;
  max-width: 100%;

  &#RelatedProduct-product_area {
    max-width: $container-maxwidth2;
    margin-top: 80px;
  }

}



/*
商品一覧グリッド

横1列あたりの商品配置数を2～5個で、デバイス幅のブレイクポイントを4パターンで定義します。

陳列レイアウト用のmixinを使用します。（_projects.scss）
@mixin shelfLayout($amount: 5, $gutter_w: 20px, $gutter_h: 20px, $item: li) {}
* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
* $item: 子要素のタグ名称・class名称（デフォルトはli要素）

使用箇所：
Product/list.twig

Markup:
../styleguide/tmpl/13.2.1.html

Styleguide 13.2.1
*/
.ec-shelfGrid {

  /* 商品レイアウト */
  @media ( max-width: 767px ) {
    @include shelfLayout(2, 15px, 20px);
  }

  @media ( min-width: 768px) and ( max-width: 979px ) {
    @include shelfLayout(3, 15px, 30px);
  }

  @media ( min-width: 980px) and ( max-width: 1199.99px ) {
    @include shelfLayout(4, 20px, 40px);
  }

  @media ( min-width: 1200px) {
    @include shelfLayout(3, 20px, 40px);// 左カラムがあり領域は狭まるので4つでレイアウト
  }

  #RelatedProduct-product_area & {
    margin-top: 0;
  }
  #page_product_detail & {
    display: block;
    flex-flow: unset;
  }
}



/*
商品アイテム

商品一覧だけでなく特集商品など、一覧表示される商品アイテムの共通スタイリングになります。

sg-wrapper:
<div class="ec-shelfRole">
  <ul class="ec-shelfGrid">
    <li class="ec-shelfGrid__item">
      <sg-wrapper-content/>
    </li>
  </ul>
</div>

Markup:
../styleguide/tmpl/13.2.2.html

Styleguide 13.2.2
*/
.ec-productItemRole {
  height: 100%;
  display: flex;
  flex-flow: column;

  /* 商品画像ブロック */
  & &__image {
    position: relative;// 被せ商品タグ・売り切れ表示用

    min-height: 0%;// for IE11（flexでcolumnレイアウトした子要素に画像があるとスペースが開いてしまう対策）

    a {
      display: block;
      @include imageHover;

      img {
        width: 100%;
        height: auto;
      }
    }

    /* 商品属性アイコンタグ（商品画像の上に被せて表示する静的タグ） */
    .productItem_tags {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
      pointer-events: none;

      /* タグ形状 */
      .tag {
        margin: 0;
        margin-top: 3px;
        margin-right: 3px;
        font-family: $ff-roboto;
        font-size: 0.85rem;
        line-height: 1;
        padding: .5em .75em;
        white-space: nowrap;
        background-color: #119bdf;
        color: #fff;

        /* 「NEW」タグ */
        &.tag-new {
          background-color: #ff8000;
        }

        /* 「SALE」タグ */
        &.tag-sale {
          background-color: #EB3D98;
        }

        @include media_desktop {
          font-size: 0.933rem;
          padding: .55em 0.75em;
        }
      }
    }

    /* 品切れ表示 */
    .productItem_stockout {
      position: absolute;
      bottom: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 3.3rem;
      font-family: 'Dynalight', serif;
      font-style: italic;
      font-size: 1rem;
      background-color: hsla(0, 100%, 37%, 0.4);
      color: #fff;
      text-align: center;
      pointer-events: none;

      @include media_sphone {
        font-size: 0.9rem;
      }
    }
  }

  /* 商品説明欄欄ブロック */
  & &__content {
    padding-top: 1em;
    background-color: #fff;
    /* 中央寄せ */
    text-align: center;

    /* 商品名称 */
    .productItem_name {
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;

      /* テキストが長くて改行される場合は左寄せになるよう */
      display: inline-block;
      text-align: left;
    }

    /* 商品属性タグ（商品説明欄内に表示されるタグ機能による動的タグ） */
    .productItem_tags {
      margin: 0;
      padding: 0;
      margin-left: -3px;
      display: flex;
      justify-content: center;
      flex-flow: row wrap;

      .tag {
        border: solid 1px #ddd;
        margin: 0;
        margin-left: 3px;
        margin-bottom: 3px;
        border-radius: 3px;
        font-size: 0.8rem;
        padding: 0.4em 0.75em;
        line-height: 1;
        white-space: nowrap;
      }
    }

    /* 商品説明（一覧用） */
    .productItem_description {
      font-size: 0.933rem;
      margin-bottom: 10px;

      /* テキストが長くて改行される場合は左寄せになるよう */
      display: inline-block;
      text-align: left;
    }

    /* 商品価格 */
    .productItem_price {
      font-size: 1rem;
      line-height: 1.2;

      // 中身「.ec-price」or「.ec-price_in」は、_1.2.type.scssにて
    }
  }

  /* 底配置ブロック */
  & &__bottom {
    //padding-top: 1em;// 必要に応じて有効にしてください
    margin: auto 0 0;
  }
}



/*
商品アイテム内 カートインアクション

当テンプレートではコメントアウトにて除去している部分ですが、最低限のスタイリングをしております。
カートインアクション機能が必要な場合はlist.twigのカートイン部分とモーダル表示部分のコメントを解除しCSSスタイリングを調整してください。

sg-wrapper:
<div class="ec-shelfRole">
  <ul class="ec-shelfGrid">
    <li class="ec-shelfGrid__item">
      <sg-wrapper-content/>
    </li>
  </ul>
</div>

Markup:
../styleguide/tmpl/13.2.3.html

Styleguide 13.2.3
*/
.ec-productItemRole {

  & &__actions {

    select {
      max-width: 100%;
    }
  }

  /* タイトルラベル */
  & &__label {
    margin: 0;
    margin-top: 1em;
  }

  /* 数量エリア */
  .quantityLabel {
    display: inline-block;
    vertical-align: baseline;
    margin-top: 0;
    margin-right: .5em;
  }
  .ec-quantityRole {
    margin-top: 1em;
    display: inline-flex;
    vertical-align: baseline;

    // スマホ用調整（「数量」のテキストラベルとボタンとが改行されず横に並ぶようボタンサイズを調整）
    @include media_sphone {
      $size: 2.65rem;

      .ec-quantityRole__btn {
        width: $size;
        height: $size;
        line-height: $size;
      }

      .ec-numberInput {
        height: $size;
        input {
          height: $size;
          width: $size;
        }
      }
    }
  }

  /* カートに入れるボタン */
  & &__btn {
    margin-top: 1em;

    .ec-blockBtn--cartin {
      $size: 3rem;
      height: $size;
      line-height: $size;
    }
  }
}
