///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/



/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

使用箇所：
Mypage/history.twig
Mypage/index.twig
Shopping/confirm.twig
Shopping/index.twig

Markup:
../styleguide/tmpl/5.2.1.html

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.2.1
*/
.ec-imageGrid {
  // 画像サイズをブラウザ幅に依存させつつmax-widthを使いたいのでflexで構成（table-cellだとmax-widthが効かない-chrome）
  display: flex;
  width: 100%;
  
  & &__img {
    flex: 0 0 auto;
    width: 22vw;
    max-width: 120px;

    img {
      width: 100%;
    }
  }

  & &__content {
    flex: 1 1 auto;
    padding-left: 1em;

    // （※商品名や規格、サイズ等すべてclass無しなので残念ながら個別スタイリング不可）
    p {
      font-size: 0.9em;
      margin: 0;

      // 判別classが無いので、1つのp要素が商品名称だと捉えて
      &:first-child {
        font-weight: 700;
      }

      // 「小計」部分
      span {
        display: inline-block;
        margin-left: 1em;
      }
    }
  }
}