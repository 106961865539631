///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
slick

トッププロモーションや商品ビジュアル画像、商品リストのスライド表示にはEC-CUBE4標準と同じく「slick」を使用しています。

slickを適用する要素は共通してclass「.ec-slickContainer」を設置してその要素に対して適用させるようにしています。

slick適用要素は、slickの準備ができるまではエリアを非表示としておき、準備が整ってからフェードイン表示としています。
slickの準備が完了するまでは画像が一瞬大きく表示されてしまう事がありますのでそれを見せないための処置です。

メモ：
slickドットとslickアロー（prev,next）と両方適用させると、アローボタンは上下中央よりずれてしまいます。

Markup:
../styleguide/tmpl/12.1.html

Styleguide 12.1
*/
.ec-slickContainer {
  // slick準備整うまでは非表示
  visibility: hidden;
  opacity: 0;
  filter: blur(5px);// ぼかしエフェクト
  transition: 1s;

  // slick準備完了で表示開始
  &.slick-initialized {
    visibility: visible;
    opacity: 1;
    filter: blur(0);
  }
}



/*
slick用パーツ

slick用パーツの共通スタイリングです。

* prev,nextボタン
* ドットボタン
* slickガター
* キャプション表示

Styleguide 12.1.1
*/



/*
prev,nextボタン

ボタンを画像に被せて左右に配置するるタイプのスタイリングです。

Markup:
../styleguide/tmpl/12.1.2.html

Styleguide 12.1.2
*/
.slick-slider {
  position: relative;// prev,nextボタン用  

  .slick-arrow {
    // ボタンエリア（クリック領域）を上下フルに伸ばしておく
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    padding: 10px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;

    opacity: .5;
    transition: .2s;

    &.slick-prev {
      left: 0;
    }

    &.slick-next {
      right: 0;
    }

    &:hover {
      opacity: 1;
    }

    i {
      display: block;
    }
  }

  .slide-item {
    img {
      margin: auto;
    }
  }
}



/*
ドットボタン

Markup:
../styleguide/tmpl/12.1.3.html

Styleguide 12.1.3
*/
.slick-slider {

  .slick-dots {
    $size: 15px;

    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    justify-content: center;
    padding-top: $size * 1.5;

    li {
      display: block;
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: $noactive-color;
      cursor: pointer;

      button {
        display: none;
      }

      &.slick-active {
        background-color: $active-color;
      }

      & + li {
        margin-left: $size * 0.7;
      }
    }
  }
}


/*
slickガター

slick適用内のアイテム間にガター（間隔）を設けます。

slickを適用する要素（基本は.ec-ec-slickContainer）に併せてclass「slickGutter」を記述しておきます。

※なお、レスポンシブでデバイス事にガター幅を可変できるようにmixin化しています。

メモ：
普通に、.slick-slide要素にマージンを設定し、先祖要素である.slick-listにネガティブマージンを設定して調整します。
しかし、それだけではスライド時に左側にわずかに商品アイテムがはみ出した形で見えてしまいます。これを防ぐ為には、
slick適用の要素（基本は.ec-ec-slickContainer）に「overflow:hidden;」すれば解決しますが、
prev,nectボタンをslick領域の外側に配置したい場合があり（position:absolute;で外に出しています）、overflowだと
この外側に配置したpreve,nextボタンが隠されてしまい見えない事になります。
ですので、ネガティブマージンをかけた.slick-list要素に「clip-path」プロパティを使い表示領域をクリップすることで対応しています。

※IE11、Edgeはclip-path非対応なのでクリップパスは適用されず結果、スライド時に左側が少しはみ出して見えます（2019/09）

Markup:
../styleguide/tmpl/12.1.4.html

Styleguide 12.1.4
*/
@mixin slickGutter($gutter: 10px) {

  .slick-list {
    margin-left: $gutter * -1;// ネガティブマージン
    clip-path: inset( 0 0 0 $gutter );// clip-pathを使ってはみ出す領域をクリップ

    .slick-slide {
      margin-left: $gutter;
    }
  }
}

.slickGutter {
  // ガターを10px
  @include slickGutter(10px);

  // デスクトップ時はガター20px
  @include media_desktop {
    @include slickGutter(20px);    
  }
}



/*
slick キャプション表示

slick適用の各アイテムの中に「.caption」要素（説明文）を記述しておくと画像に被さる形でテキストが表示されます。

商品詳細ページでは、slickを適用する画像ビジュアル部分が動的に出力されますのでキャプション要素を追加できませんが、
フリーエリア内にてキャプション要素を記述しておき、javascirptで各キャプションを各画像アイテム内に挿入させて実装しています。

Markup:
../styleguide/tmpl/12.1.5.html

Styleguide 12.1.5
*/
.slick-slider {

  .slick-slide {
    position: relative;

    .caption {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;

      font-size: .933rem;
      color: #000;
      background-color: hsla(0, 0%, 100%, 0.5);
      padding: 1em;
      box-sizing: border-box;

      visibility: hidden;
      opacity: 0;
      transform: translate3d(0,100%,0);
      transition: .5s;
      transition-delay: .5s;
    }

    &.slick-current {

      .caption {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0,0,0);
      }
    }
  }
}



/*
動画スライダー

slickスライダーに動画を含める事のできる「jeia_movieslider.js」用のスタイリングです。

通常のslickスライダーのスタイリングに対して、動画スライダー用にいくつか上書きと追加スタイリングを施しています。

Markup:
../styleguide/tmpl/12.1.6.html

Styleguide 12.1.6
*/
.ec-movieslider {
  position: relative;

  .ec-slickContainer {
    transition: height 0s, opacity 0s;
		opacity: 0;

    &.is-ready {
      transition: opacity 1s;
      opacity: 1;
    }
  }

  /* スライドitem */
  .slick-slide {
    overflow: hidden;
    transition: filter 1s;
    pointer-events: none;// ドラッグスライドができるように

    /* 切り替わり中のエフェクト */
    &.is-change {
      filter: blur(5px);// saturate(0.5);
    }

    .itemContainer {

      > a {
        display: block;
        pointer-events: auto;
      }

      /* iframe要素 */
      iframe {
        border: none;
      }

      /* video要素 */
      video {
        display: block;
      }

    }
  }

  /* loader */
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
    }
  }

  /* SOUNDボタン */
  .soundBtn {
    position: absolute;
    bottom: 1em;
    left: 1em;
    font-size: 25px;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.3));
    z-index: 10;

    @include media_sphone {
      top: 10px;
      right: 10px;
      left: auto;
      bottom: auto;
    }

    visibility: hidden;
    opacity: 0;
    transition: 1s;

    &.is-ready {
      visibility: visible;
      opacity: 0.5;
    }

    &:hover {
      opacity: 1;
    }

    /* SOUND-ONボタン */
    &:before {
      font-family: "jeiafont";
      content: "\e926";
      display: none;
    }

    /* SOUND-OFFボタン */
    &:after {
      font-family: "jeiafont";
      content: "\e925";
      display: block;
    }

    /* SOUND出力中のアイコン切り替え */
    &.sound-on {
      &:before {
        display: block;
      }
      &:after {
        display: none;
      }
    }
  }
}

/* 動画メディアキャプション */
.mediaCaption {
  font-family: $ff-en;
  font-weight: 400;
  font-size: 1rem;
  max-width: 350px;
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translate(0,-50%);
  z-index: 9;
  overflow: hidden;

  .inner {
    background-color: rgba(255, 255, 255, 0.75);
    color: #000;
    padding: 1.5em;
    opacity: 0;
  }

  .text_small {
    font-size: 0.9333em;
  }

  .text_big {
    font-size: 2em;
    line-height: 1.2;
    margin: .25em 0;
    opacity: 0;
  }

  .text_description {
    font-size: 1em;
  }

  .linkBtn {
    display: inline-block;
    font-size: 1em;
    margin-top: 1em;
    line-height: 1;
    padding: 1em 1.5em;
    background-color: #000;
    color: #fff;
    pointer-events: auto;
    transition: .2s;

    &:hover {
      text-decoration: none;
      background-color: #333;
    }
  }

  .closeBtn {
    display: none;
  }

  /* キャプション表示 */
  &.is-active {

    .inner {
      // キャプション出現はanimationを使用します（animationを使わないと初回表示時にキャプションがアニメしてくれない場合あり）
      animation: viewCaption .5s forwards 1s;
      @include media_sphone {
        animation: viewCaptionSp .5s forwards 1s;
      }
    }
    
    .text_big {
      animation: viewCaption .8s forwards 1s;
    }
  }
  @keyframes viewCaption {
    0% {
      opacity: 0;
      transform: translate3d(-100%,0,0);  
    }
    100% {
      opacity: 1;
      transform: translate3d(0,0,0);  
    }          
  }
  @keyframes viewCaptionSp {
    0% {
      opacity: 0;
      transform: translate3d(0,100%,0);  
    }
    100% {
      opacity: 1;
      transform: translate3d(0,0,0);  
    }          
  }

  /* スマホ用調整（.mediaCaption） */
  @include media_sphone {
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    transform: translate(0,0);
    font-size: 0.9rem;
    transition: transform 0.5s;

    .text_big {
      font-size: 1.2em;
    }

    .linkBtn {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
    }

    /* キャプション折り畳みボタン */
    .closeBtn {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      font-size: 18px;
      line-height: 1.5;
      width: 1.5em;
      height: 1.5em;
      text-align: center;
      color: #000;
      margin-left: -0.5em;
      pointer-events: auto;

      &:before {
        font-family: 'jeiafont';
        content: "\e902";
        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transform: rotate(90deg);
        //transition: 0.5s;
      }
    }

    /* キャプション折り畳み状態 */
    &.is-close {
      transform: translate3d(0,100%,0);
      overflow: visible;

      .closeBtn {
        transform: translate3d(0,-100%,0);

        &:before {
          transform: rotate(-90deg);
          background-color: rgba(255, 255, 255, 0.75);
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}
