.ec-breadcrumbRole {
  @include container;

  .product_page & {
    max-width: $container-maxwidth2;
  }

  #page_product_detail & {
    @include media_desktop {
      margin-bottom: 40px;
    }
  }

  .full_screen_page & {
    padding: 0;
    max-width: none;
  }

  /*
  inner
  トピックパス枠（__topicspath）と表示件数＆並び替え枠（__infos）の2つのエリアをflexにて左右両極に配置
  */
  & &__inner {
    background-color: #f4f4f4;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* トピックパス枠 */
  & &__breadcrumb {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: .5em;
    font-size: 1rem;
  }

  @include media_sphone {
    & &__inner {
      // ネガティブマージンにてフル幅に拡張
      margin-left: $padding-sphone * -1;
      margin-right: $padding-sphone * -1;
      padding: 10px 10px;
      flex-flow: row wrap;
    }

    & &__breadcrumb {
      width: 100%;
      padding: 0;
      font-size: 0.9rem;
    }

  }
  
  @include media_tablet {
    & &__inner {
      // ネガティブマージンにてフル幅に拡張
      margin-left: $padding-tablet * -1;
      margin-right: $padding-tablet * -1;
      padding: 10px $padding-tablet;
      flex-flow: row wrap;
    }

    & &__breadcrumb {
      width: 100%;
      padding: 0;
      font-size: 0.9rem;
    }

  }
}

/*
トピックパス本体

sg-wrapper:
<div class="ec-searchnavRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/13.1.2.html

Styleguide 13.1.2
*/
.ec-breadcrumb {
  line-height: 1;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }

  & &__item {

    // スマホ時などパスが長くて改行されてしまうケースを考慮
    white-space: nowrap;
    padding: 4px 0;

    a {
      color: inherit;
      @include hoverBottomLine;
    }

    /* 連結の「>」 */
    &:before {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 0.85em;
      margin-left: 4px;
    }

    /* タイトルラベル */
    &.titleLabel {
      font-family: "Oswald";
    }

    &.titleFirst {
      &:before {
        display: none;
      }
    }

    /* 最後のli要素を太字 */
    &:last-child {
      
      a,
      span {
        font-weight: 500;
        pointer-events: none;
      }
    }
  }
}

