///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
フォーム部品(ラジオ、チェックボックス、セレクト)

ラジオ、チェックボックス、セレクト要素の基本整形をスタイリングします。

Styleguide 3.2
*/



/*
ラジオボタン（横配置）

各要素をlabelでくくって、コーディングします。←マチガイ（ver4.0.2では、input + label の構造になっている様子（全部がそうかは未確認））<br>
input要素をlabelでくくってしまうと独自UIは表示されないので注意

ラジオボタンの独自UIはアイコンフォントを使っています。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.2.html

Styleguide 3.2.2
*/
.ec-radio {
  margin-top: -0.5em;
  
  input {
    //display: none;// ユーザビリティでtab focusに対応するため別の方法で
    opacity: 0;
    appearance: none;
  }

  input + label {
    display: inline-block;
    margin: 0;
    font-size: 1.05rem;
    font-weight: normal;
    line-height: 1;
    // 選択項目が複数で改行される前提での余白（親要素ではネガティブマージン調整）
    margin-right: 1em;
    margin-top: 0.5em;
    
    &:before {
      font-family: "jeiafont";
      content: "\e90c";
      font-size: 22px;
      display: inline-block;
      vertical-align: -3px;// 位置微調整
      margin-right: 0;
      color: #999;
      transition: .3s;
    }

    span {
      display: inline-block;
    }
  }

  input:checked + label {
    &:before {
      font-family: "jeiafont";
      content: "\e90d";
      color: $radio-checked-color;
    }
  }

  input:focus + label {
      @include tab-focus;
  }

  // エラー状態
  &.error {
    //border: solid 1px $input-error-border;
    background-color: $input-error-bg;  
  }
}



/*
ラジオボタン(縦配置)

垂直に並ぶラジオボタンフィールドです。

※ショッピングページ「shopping/index.twig」においてはinputとlabelごとをdiv要素（ブロック要素）でくくる形でコーディングされているためCSSスタイリングせずとも縦配置になる。
一応、このブロック要素がない場合でも縦配置になるスタイリングは準備しておく。

EC-CUBE4標準において`.ec-blockRadio`が使われている箇所はshopping/confirm.twigだけの様子だが（お支払いとポイント）、確認画面ページなので表示要素は1点のみであるし、
またラジオボタンですらないので、厳密にいえばここで`.ec-blockRadio`を使うのはミスではないかと思われる。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.3.html

Styleguide 3.2.3
*/
.ec-blockRadio {
  @extend .ec-radio;
  margin-right: 0;
  margin-top: -1em;

  input + label {
    display: block;
    margin-right: 0;
    margin-top: 1em;
  }
}



/*
チェックボックス （横配置）

各要素をlabelでくくって、コーディングします。←マチガイ<br>
input要素をlabelでくくってしまうと独自UIは表示されないので注意

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.4.html

Styleguide 3.2.4
*/
.ec-checkbox {
  margin-top: -0.5em;

  input {
    //display: none;// ユーザビリティでtab focusに対応するため別の方法で
    opacity: 0;
    appearance: none;
  }

  input + label {
    display: inline-block;
    margin: 0;
    font-size: 1.05rem;
    font-weight: normal;
    line-height: 1;
    // 選択項目が複数で改行される前提での余白（親要素ではネガティブマージン調整）
    margin-right: 1em;
    margin-top: 0.5em;
    
    &:before {
      font-family: "jeiafont";
      content: "\e90e";
      font-size: 22px;
      display: inline-block;
      vertical-align: -3px;// 位置微調整
      margin-right: 0.2em;
      color: #999;
      transition: .3s;
    }

    span {
      display: inline-block;
    }
  }

  input:checked + label {
    &:before {
      font-family: "jeiafont";
      content: "\e90f";
      color: $radio-checked-color;
    }
  }

  input:focus + label {
      @include tab-focus;
  }

  // エラー状態
  &.error {
    //border: solid 1px $input-error-border;
    background-color: $input-error-bg;  
  }
}

/* 会員登録の利用規約チェックだけlabelがつけ方が通常のコーディングルールと違うのでCSS側で吸収 */
// Twigの変更はテンプレート的になるべく避けたいのでCSS側で調整
.ec-registerRole__actions .ec-checkbox {
  input + label {
    margin-right: 0;
  }
}



/*
チェックボックス (縦配置)

垂直に並ぶチェックボックス フィールドです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.5.html

Styleguide 3.2.5
*/
.ec-blockCheckbox {
  @extend .ec-checkbox;
  margin-right: 0;
  margin-top: -1em;

  input + label {
    display: block;
    margin-right: 0;
    margin-top: 1em;
  }
}



/*
セレクトボックス

独自UIを施しています。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.6.html

Styleguide 3.2.6
*/
.ec-select{

  select {
    @include _form-control;
    width: auto;
    background-color: rgb(248, 248, 248);
    cursor: pointer;

    // 独自UI用
    padding-right: calc(20px + 1em);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    .ec-productOptions & {
      width: 100%;
    }

    &::-ms-expand {
      display: none;
    }
  }

  // 独自UIのためselect要素をlabel.select_ui要素で囲んである。（Form/form_div_layout.twigを編集）
  .select_ui {
    display: inline-block;
    font-weight: normal;
    margin: 0;
    position: relative;
    .ec-productOptions & {
      width: 100%;
    }

    &:after {
      font-family: "jeiafont";
      content: "\e913";
      font-size: 16px;

      line-height: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: calc(100% - 2px);
      background: #ddd;
      color: #555;
      position: absolute;
      top: 1px;
      right: 1px;
      border-radius: 0;
      pointer-events: none;
    }
  }

  // 連続する.ec-select
  & + & {
    margin-top: 1rem;
  }

  // エラー状態
  &.error {

    select {
      border-color: $input-error-border;
      background-color: $input-error-bg;  
    }
  }

  // 別途labelが付けられている場合
  label {
    margin: 0;

    & + .select_ui {
      margin-left: .5em;
    }
  }
}

// お届け日（shopping/index.twig, shopping/confirm.twig）
.ec-select__delivery {
  display: block;

  @include media_desktop {
    display: inline-block;
  }
}

// お届け時間（shopping/index.twig, shopping/confirm.twig）
.ec-select__time {
  display: block;

  @include media_desktop {
    display: inline-block;
    margin-left: 1rem;
  }
}

// select群（shopping/index.twig, shopping/confirm.twig）
.ec-selects {
}



/*
生年月日選択

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.7.html

Styleguide 3.2.7
*/
.ec-birth{
  @extend .ec-select;

  select {
  }

  span {
    display: inline-block;
    margin: 0 .5em;
  }
}
