///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

使用箇所：
Product/detail.twig

Markup:
../styleguide/tmpl/14.1.html

Styleguide 14.1
*/
.ec-productRole {
  @include container;
  max-width: $container-maxwidth2;

  /* デスクトップ時のみ左右2分割レイアウト */
  & &__grid {

    @include media_desktop {
      display: flex;
      justify-content: space-between;
    }
  }

  & &__gridCell {

    // スマホ用レイアウト
    @include media_sphone {

      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-sphone * -1;
        margin-right: $padding-sphone * -1;
      }

      &.cellRight {
        margin-top: 20px;
      }
    }

    // タブレット用レイアウト
    @include media_tablet {

      &.cellLeft {
        // 画像ビジュアル枠をフル幅にするためネガティブマージン使用
        margin-left: $padding-tablet * -1;
        margin-right: $padding-tablet * -1;
      }

      &.cellRight {
        margin-top: 30px;
      }
    }

    // デスクトップ用レイアウト（左右2分割）
    @include media_desktop {

      &.cellLeft {
        flex: 0 0 auto;
        width: 57%;
      }

      &.cellRight {
        width: auto;
        margin-left: 40px;
        overflow: hidden;
        flex: 1;
      }
    }
  }
}



/*
商品ビジュアル表示

EC-CUBE4標準と同じく、slickを利用して画像ビジュアルを実装しています

管理機能「フリーエリア」に、HTMLコードを記述するとビジュアル画像それぞれに説明文を表示させることができます。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.1.html

Styleguide 14.1.1
*/
.ec-productRole__visual {

  @include media_tablet {
    display: flex;
    justify-content: space-between;
  }

  @include media_desktop {
    display: flex;
    justify-content: space-between;
  }

  /* メインビジュアル格納枠 */
  .ec-productVisualMain {
    // キャプション（説明文）表示に必要
    position: relative;
    overflow: hidden;

    @include media_tablet {
      flex: 0 0 auto;
      width: 83.334%;
    }

    @include media_desktop {
      flex: 0 0 auto;
      width: 83.25%;
    }

    // slick矢印ボタンの色を変更したい場合（slickについては、_12.1.slider.scssにて共通定義しています）
    .slick-arrow {
      //color: #aaa;
    }
  }

  /* ビジュアルナビ */
  .ec-productVisualNav {

    /* デスクトップ・タブレット時はビジュアルナビをメインビジュアルの横に配置して縦スライド機能をつけています。
     * スマホ時はスライド機能は使わずにメインビジュアルの下にタイルレイアウトで配置します。
     * レスポンシブにおいて、スライド機能のON/OFFやレイアウト変更はロジックが煩雑になるかと思いましたので、
     * ビジュアルナビは、あらかじめデスクトップ・タブレット用のナビと、スマホ用のナビをそれぞれ組み込んでおきレスポンシブで表示を切り替えています。
     */

    /* スマホ用ナビ */
    &.sphone {
      // グリッドレイアウト
      @include media_sphone {
        @include shelfLayout(5, 1px, 1px, '.slideThumb');
        margin-top: 0;// 上余白ネガティブマージンを無効
      }

      @include media_tablet {
        display: none;
      }

      @include media_desktop {
        display: none;
      }
    }

    /* デスクトップ・タブレット用ナビ */
    &.desktop {
      flex: 0 0 auto;
      overflow: hidden;// prev,nextボタン隠ぺい用
      // 高さをメインビジュアルと同じにします→これはjs側（detail.twig内）で実装しています

      @include media_tablet {
        // 余白なくぴっちり配置します。メインとナビの高さが揃う割合にしています
        width: 16.666%;
      }

      @include media_desktop {
        // メインとナビの間に約4pxほどの余白が生まれる割合にしています
        width: 16.35%;
      }

      opacity: 0;
      visibility: hidden;

      /* slickの準備ができたら表示 */
      &.slick-initialized {
        opacity: 1;
        visibility: visible;
      }

      /* prev,nextボタンのスタイリングを上書き調整します */
      .slick-arrow {
        font-size: 18px;
        padding: 0;
        width: 100%;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        color: #333;
        opacity: 1;

        &:hover {
          background-color: rgba(255, 255, 255, 0.85);
        }

        &.slick-prev {
          top: 0;
          left: 0;
          transform: translate3d(0,-100%,0);// ナビエリアにマウスオーバーしてから表示させるため隠しておきます

          i {
            transform: rotate(-90deg);
          }
        }

        &.slick-next {
          top: auto;
          bottom: 0;
          left: 0;
          transform: translate3d(0,100%,0);// ナビエリアにマウスオーバーしてから表示させるため隠しておきます

          i {
            transform: rotate(90deg);
          }
        }
      }

      /* ナビエリアにマウスオーバーしたらprev,nextボタンを表示します */
      &:hover .slick-arrow {
        transform: translate3d(0,0,0);
      }

      @include media_sphone {
        display: none;
      }

      /* サムネイル */
      .slideThumb {
        border: none;
        cursor: pointer;
        @include imageHover;

        @include media_tablet {
          margin-bottom: 0px;
        }

        @include media_desktop {
          margin-bottom: 4px;
        }
      }
    }
  }
}



/*
商品プロフィール（商品データ）

画像ビジュアル枠に対してこちらは右枠の（デスクトップ時）、商品データ枠になります。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.2.html

Styleguide 14.1.2
*/
.ec-productRole {

  /* 商品プロフィール枠 */
  & &__profile {
  }

  /* 商品タイトル */
  & &__title {
    margin-bottom: 1.5rem;

    h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
    }
  }

  &__subTitle-en {
    font-family: 'Dynalight', serif;
    margin-top: 7px;
    display: block;
    line-height: 1.4;
  }

  /* 通常価格 */
  & &__priceRegular {
    margin-bottom: 0.5em;
  }

  /* 通常価格：価格部分 */
  & &__priceRegularPrice {

    span {
      margin-left: .5em;
    }
  }

  /* 通常価格：税込 */
  & &__priceRegularTax {
    font-size: .8em;
  }

  /* 販売価格 */
  & &__price {
    font-size: 1.5rem;

    .ec-price__price {
      font-weight: 500;
    }

    // 中身「.ec-price」は、_1.2.type.scssにて
  }

  /* 詳細コメント */
  & &__description {
    font-size: 1rem;
    margin: 30px 0;
    padding-top: 30px;
    line-height: 1.7;
    color: $clrDefaultThin;
    border-top: 1px solid $clrBorderColor;

    .header {
      margin-top: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid $clrBorderColor;


      > br {
        display: none;
      }

      h1, h2 {
        text-align: center;
        line-height: 1;
        margin-bottom: 2rem;
      }
      h1 {
        font-family: $ff-en;
        font-size: 1.5rem;
        font-weight: normal;
        margin: 0;

      }

      h2 {
        font-size: 0.933rem;
        color: $clrDefaultThin;
        margin-top: .5em;
      }
    }

    [class^="img-flex-"] {
      margin-bottom: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
    }

    .img-flex-2 > img,
    .img-flex-2 a { width: 49%; height: 49%; }
    .img-flex-3 > img,
    .img-flex-3 a { width: 32%; height: 32%; }
    .img-flex-4 > img,
    .img-flex-4 a { width: 24%; height: 24%; }


    table {
      width: 100%;
      font-size: 0.8rem;

      @include media_desktop {
        font-size: 1rem;
      }

      tr {

      }
      th, td {
        border: 1px solid #ccc;
        padding: 10px 5px;
        line-height: 2em;

        @include media_desktop {
          padding: 20px;
        }
      }
    }

    video {
      width: 300px;
    }
  }

  /* 商品コード（商品タイトルの中で展開） */
  & &__code {
    font-family: 'Dynalight', serif;
    font-size: 0.933rem;
    color: $clrDefaultThin;
    margin: 0.5rem 0;
    padding-left: 0.4rem;
  }

  /* 属性タグ */
  & &__tags {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin: 0.5rem 0;
    margin-left: -7px;
    font-size: 0.933rem;

    li {
      margin-left: 7px;
      line-height: 1;
      padding: 0.4em 0.7em;
      border: solid 1px #ccc;
      color: $clrDefaultThin;
    }
  }

  /* 関連カテゴリ */
  & &__category {
    margin: 1rem 0;
    font-size: 0.933rem;

    .titleLabel {
      color: $clrDefaultThin;
    }

    // 商品一覧で使用した.ec-topicpathを利用して、関連カテゴリ用に調整
    .ec-topicpath {

      .ec-topicpath__item {

        &:first-child {
          &:before {
            //display: none;
            content: "・";
          }
        }

        /* 最後のli要素を太字 */
        &:last-child {

          a {
            font-weight: normal;
            pointer-events: auto;
          }
        }
      }
    }
  }

  /* アクション枠 */
  & &__actions {
    @include borderTop;
    margin: 1rem 0;
  }

  /* 規格枠 */
  & &__class {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.classCategory1 {
      margin-top: 10px;
    }

    &.classCategory2 {
      margin-top: 10px;
    }

    .classLabel {
      margin: 0;
      margin-right: 1em;
      min-width: 6em;

      &:after {
        content: "：";
      }
    }

    .ec-select {

      select {
        max-width: 100%;
      }
    }
  }

  /* カートイン枠数量・カートイン・お気に入り） */
  & &__cartinRow {
    display: flex;
    align-items: flex-end;
    margin-top: 1em;

    /* 数量タイトルラベル */
    .quantityLabel {
      margin: 0;
      margin-right: 1em;
      display: none;
    }

    /* 数量 */
    .ec-quantityRole {
      //本体「.ec-quantityRole」は、_3.1.inputText.scssにて
      margin-right: 10px;
    }

    /* 次回入荷予定日 */
    .ec-nextArrival {
      width: 100%;
      text-align: center;
      font-family: 'Dynalight', serif;
      font-style: italic;

      @include media_sphone {
        font-size: 0.9rem;
      }
    }
    .ec-nextArrivals .ec-nextArrival {
      display: none;
    }

    /* カートインボタン・品切れ */
    .ec-productRole__btn {
      flex: 1 1 auto;// 伸縮
      //max-width: 350px;// お気に入りボタンが無い場合を考慮して一応最大幅を定義

      // ボタン定義を上書きして調整
      button {
        height: $cartinRow-size;
        line-height: $cartinRow-size;
        width: 100%;
      }
    }

    /* お気に入りボタン */
    .ec-blockBtn--favorite {
      // 本体のボタン定義は、_2.1.buttonsize.scssにて
      margin-left: 10px;
    }

    // スマホ用調整
    @include media_sphone {
      flex-flow: row wrap;

      .quantityLabel {
        display: block;
      }

      .ec-quantityRole {
        margin-bottom: 10px;
      }
    }
  }

  &__contactBtn {

    .contact {
      background-color: #fff;
      border: 2px solid #888888;
      text-decoration: none;
      border-radius: 40px;
      padding: 5px 10px;
      width: 100%;

      &:hover {
        opacity: 0.7;
      }
    }

  }


  /* シェアボタン */
  & &__share {
    margin-top: 2rem;

    @include media_tablet {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    @include media_desktop {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__left {

    }

    &__right {

      @include media_sphone {
        padding-left: 0;
        margin-top: 20px;
      }

      @include media_tablet {
        padding-left: 20px;
        flex: 1;
      }

      @include media_desktop {
        padding-left: 20px;
        flex: 1;
      }
    }

    .shareLabel {
      margin: 0;
      margin-right: .5em;
      display: inline-block;
    }

    .shareBtnList {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-left: 3px;

        a {
          display: block;
          font-size: 1.1rem;
          width: 2em;
          height: 2em;
          line-height: 2em;
          text-align: center;

          background:#666;
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
          transition: .3s;

          i {

            & + span {
              display: none;
            }
          }

          &.twitter:hover {
            background-color: $clr-twitter;
          }
          &.facebook:hover {
            background-color: $clr-facebook;
          }
          &.googleplus:hover {
            background-color: $clr-googleplus;
          }
          &.pinterest:hover {
            background-color: $clr-pinterest;
          }
          &.line:hover {
            background-color: #20c962;
          }
        }
      }
    }
  }
}



/*
商品フリーエリア

フリーエリアのスタイリングになります。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.90.html

Styleguide 14.1.90
*/
.ec-productRole {

  & &__freearea {
    margin-top: 30px;

    @include media_desktop {
      margin-top: 60px;
    }
  }
}


/*
商品詳細タブ切り替えコンテンツ

管理機能「フリーエリア」に、HTMLコードを記述するとタブ切り替えコンテンツを表示させることができます。

サンプルコンテンツの、table要素、dl-table要素、dlレスポンシブの表組みスタイリングは、_0.1.base.scssにて行っています。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.91.html

Styleguide 14.1.91
*/
.ec-productDetailTab {

  /* タブ */
  & &__nav {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;
    margin-bottom: 2rem;
    @include borderBottom;

    li {
      font-size: 1rem;
      margin-right: 1.5em;
      padding: 0.25em 0;
      color: $clrDefaultThin;
      border-bottom: solid 2px transparent;
      cursor: pointer;
      transition: .2s;

      &:hover {
        border-color: #000;
      }

      &.is-active {
        color: $clrDefaultDark;
        border-color: #000;
      }
    }
  }

  /* コンテンツ枠 */
  & &__wrap {
  }

  /* コンテンツ */
  & &__content {
    display: none;

    &.is-active {
      display: block;
      @include fadeIn;
    }

    h2 {
      font-size: 1.4rem;
      margin: 0;
      margin-bottom: 1em;
    }
  }
}
