///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
マイページ

マイページで利用するためのスタイルガイド群です。

メモ：
マイページのほぼすべてのページ（login.twig, navi.twig以外）において、一番外枠に「ec-layoutRole__main」要素が挿入されています。
「ec-layoutRole__main」は、__default_frame.twig__が出力するフレームですので、「ec-layoutRole__main」が2重になっています。
レイアウトには全く影響はないのでスルーしておいてもいいとは思いますが、一応…

「ec-layoutRole__main」は1カラムレイアウト時に出力されるフレームで、2～3カラム時は別のフレームが出力されます。
2～3カラム時は、そのメインカラムの中で1カラム用「ec-layoutRole__main」が入る形になりますが、デザインレイアウト上の問題はありません。
気になる場合はマイページに挿入された「ec-layoutRole__main」を削除するといいと思います。（時期バージョンで解消希望）

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 9.1
*/



/*
マイページ メニューナビ

マイページで表示するメニューナビです。

スマホ時はこのメニューナビを非表示にしています。
代わりに、MENUボタンを配置しそのボタンによりドロワーをOPEN、ドロワー内にマイページのメニューが含まれています。

※スマホ用MENUボタンはjsで生成して挿入するようにしていますが、navi.twigにHTML記述しておいてもいいかもしれません。

使用箇所：
Mypage/navi.twig

Markup:
../styleguide/tmpl/9.1.1.html

Styleguide 9.1.1
*/
.ec-navlistRole {
  font-size: 1rem;
  margin-bottom: 1em;

  & &__navlist {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: .933rem;
    display: flex;
    @include borderBottom;
    padding-bottom: 1em;
    margin-bottom: 1em;
  }

  & &__item{
    @include shortLeftBorder(45%,#ccc);// ボタン高さより短い区切り線を入れる
  
    a {
      display: block;
      color: inherit;
      padding: .65em 1em;
      text-align: center;
      transition: .3s;

      &:hover {
        text-decoration: none;
        background-color: #eee;
      }
    }

    &.active {

      a {
        background-color: #333;
        color: #fff;
      }
    }
  }

  // スマホ用MENUボタン（スマホ時以外は非表示）
  .mypageMenuBtn {
    display: none;
  }

  // スマホ用調整
  @include media_sphone {
    position: relative;

    // 通常のナビメニューを非表示
    & &__navlist {
      display: none;
    }

    // スマホ用MENUボタン
    .mypageMenuBtn {
      display: block;
      font-size: 0.8rem;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;

      padding-left: .5em;

      i {
        display: inline-block;
        vertical-align: -0.18em;
        font-size: 1.4em;
        margin-right: 3px;
        opacity: 0.6;
      }
    }
  }
}



/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

使用箇所：
Mypage/navi.twig

Markup:
../styleguide/tmpl/9.1.3.html

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-bottom: 2em;
  margin-left: -1em;
  font-size: .933rem;

  @include media_desktop {
    margin-bottom: 60px;
  }

  p {
    display: inline-block;
    margin: 0;
    margin-left: 1em;
  }
}



/*
マイページ：注文履歴一覧

→ _16.1.history.scss
*/



/*
マイページ：お気に入り一覧

使用箇所：
Mypage/favorite.twig

Markup:
../styleguide/tmpl/9.1.4.html

Styleguide 9.1.4
*/
.ec-favoriteRole {

  & &__header {
    margin-bottom: 1em;
  }
  
  & &__detail {
  }
  
  & &__itemList {
    list-style: none;
    margin: 0;
    padding: 0;

    @include media_sphone {
      // グリッドレイアウト
      @include shelfLayout(2, 10px, 20px, li);
    }

    @include media_tablet {
      // グリッドレイアウト
      @include shelfLayout(4, 20px, 20px, li);
    }

    @include media_desktop {
      // グリッドレイアウト
      @include shelfLayout(5, 30px, 20px, li);
    }
  }

  & &__item{
    position: relative;

    .ec-closeBtn--circle {
      position: absolute;
      right: 7px;
      top: 7px;
      z-index: 9;
    }

    &-image {
      margin: 0;

      img {
        width: 100%;
        height: auto;
      }
    }    
  }

  & &__itemThumb {
    display: block;
    @include imageHover;
  }

  & &__itemTitle{
    margin: 0;
    margin-top: .5em;
    font-weight: 700;
  }
  
  & &__itemPrice{
    margin: 0;
  }
}
