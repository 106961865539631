///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
アニメーションkeyframes

_animation.scss

定義mixin：<br>
@mixin fadeIn<br>
@mixin fadeOut<br>

<br>
定義CSS：<br>
@keyframes fadeIn<br>
@keyframes fadeOut<br>
.bg-load-overlay

Styleguide 24.6
*/
@keyframes fadeIn{
  0%{
    opacity: 0;
    visibility: hidden;
  }
  100%{
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut{
  0%{
    opacity: 1;
    visibility: visible;
  }
  100%{
    opacity: 0;
    visibility: hidden;
  }
}

@mixin fadeIn($display:block,$time:150ms) {
  display: $display;
  opacity: 1;
  visibility: visible;
  animation: fadeIn $time linear 0s;
}

@mixin fadeOut($time:150ms) {
  opacity: 0;
  visibility:hidden;
  animation: fadeOut $time linear 0s;
}

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1;
}
