//faqプラグイン

.ec-faqRole {
  .faq {

    .tabContainer {
      ul.tabMenu {
        @media screen and (min-width: 768px) {
          display: flex;
          margin: 0 -1px;
        }

        li {
          background: transparent;
          @media screen and (min-width: 768px) {
            min-width: auto;
            flex: 1;
            margin: 0 1px 1px 1px;
          }

          a {
            background: #999999;
            white-space: initial;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            &:hover {
              color: #fff;
              background: #000;
            }
          }
          &:first-child {
            @media screen and (min-width: 768px) {
              min-width: auto;
            }
          }

          &.active{
            background: transparent;
            a {
              background: #000;
            }
          }

        }
      }

      dl {

        dt, dd {
          &:before {
            font-size: 28px;
            background: transparent;
            -webkit-text-fill-color: initial;
            padding-top: 0;
            padding-bottom: 0;
            font-family: 'Playfair Display', serif;
            font-weight: 400;
          }
        }

        dt {
          position: relative;
          padding: 0 5px 10px 45px;
          margin-bottom: 10px;
          &:before {
            position: absolute;
            left: 5px;
            top: -5px;
            @include media_desktop {
              top: -10px;
            }
          }
        }

        dd {
          position: relative;
          padding: 0 5px 10px 45px;
          margin-bottom: 20px;
          border-bottom: 1px solid #ebebeb;

          &:before {
            position: absolute;
            left: 5px;
            top: -3px;
            color: red;
            @include media_desktop {
              top: -7px;
            }
          }

          p {
            margin-bottom: 10px;
          }
        }

      }

    }
    h3 {
      &.title {
        @media screen and (min-width: 768px) {
          text-align: center;
        }
      }
    }
    //.tabMenu {
    //  @media screen and (min-width: 768px) {
    //    display: flex;
    //  }
    //}
    //
    //.tabContainer {
    //  ul.tabMenu li {
    //    @media screen and (min-width: 768px) {
    //      min-width: auto;
    //      flex: 1;
    //    }
    //  }
    //}
  }
}


