///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
セクション見出し

トップページで使用されているセクションの見出しのスタイルです。

当テンプレートでは特集商品ブロック（Block/new_item.twig）等で使用しています。
トップページ用となっていますが、他ページのセクションブロックでも流用される事は十分にあるものと思います。
（※その意味では「_1.1.heading.scss」に属させるべきものかもしれませんが）

使用箇所：
* Block/category.twig
* Block/category_nav_pc.twig
* Block/eyecatch.twig
* Block/news.twig
* Block/new_item.twig

Markup:
../styleguide/tmpl/12.4.html

Styleguide 12.4
*/
.ec-secHeading {
  text-align: center;
  line-height: 1;
  margin-bottom: 2rem;

  & &__maintitle {
    display: inline-block;
    font-family: $ff-en;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    position: relative;
    @include media_sphone {
      max-width: 80%;
    }

    /* 横に付く罫線 */
    &:before,
    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 2em;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      @include media_sphone {
        width: 1em;
      }
    }

    &:before {
      margin-right: .5em;
      left: -2.3em;

      @include media_sphone {
        left: -1.3em;
      }

    }

    &:after {
      margin-left: .5em;
      right: -2.3em;

      @include media_sphone {
        right: -1.3em;
      }

    }

    @include media_desktop {
      font-size: 1.7rem;
    }
  }

  & &__subtitle {
    font-size: 0.933rem;
    color: $clrDefaultThin;
    margin-top: .5em;
  }
}

/* 2カラムレイアウトの左カラム内のセクション見出し */
.ec-layoutRole__left {

  .ec-secHeading {

    /* スマホ・タブレット時 */
    @media screen and ( max-width: $desktop - 1) {
      text-align: left;
      margin-bottom: 1rem;

      .ec-secHeading__maintitle:before,
      .ec-secHeading__maintitle:after {
        display: none;
      }
  
      .ec-secHeading__maintitle {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.3rem;
      }

      .ec-secHeading__subtitle {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 0.5em;
      }
    }

    /* デスクトップ時 */
    @include media_desktop {
      text-align: left;
      border-bottom: solid 2px #000;
      padding-bottom: 1em;

      .ec-secHeading__maintitle {
        font-size: 1rem;
        font-weight: 400;

        &:before,
        &:after {
          display: none;
        }
      }

      .ec-secHeading__subtitle {
        display: none;
      }
    }
  }
}
