///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/



/*
ページャ

ページャは商品一覧ページ、マイページ注文履歴、お気に入りページで使用されています。

使用箇所：
pager.twig（ブロック）

pager.twigブロックは以下のページからincludeされます。
* Mypage\favorite.twig
* Mypage\index.twig
* Product\list.twig

Markup:
../styleguide/tmpl/7.2.2.html

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  margin: 30px 0 0;
  padding: 0;

  font-family: "Oswald";
  font-size: 1rem;

  display: flex;
  justify-content: center;

  @include media_desktop {
    margin-top: 60px;
  }

  // ボタンアイテム
  & &__item {
    margin: 0 3px;

    a {
      display: block;
      
      $size: 40px;//ボタン最小サイズ
      min-width: $size;
      height: $size;
      line-height: $size;
      text-align: center;
      padding: 0 0.75em;

      background-color: $noactive-color;
      color: #fff;
      
      transition: .2s;

      &:hover {
        text-decoration: none;
        background-color: #000;
      }
    }

    // activeボタン
    &.is-active {

      a {
        background-color: $active-color;
        pointer-events: none;
      }
    }

    // PREVボタン装飾
    &.item--prev {

      a {
        padding-left: 0.3em;

        &:before {
          font-family: "jeiafont";
          content: "\e91b";
          margin-right: 0.2em;
        }
      }
    }

    // NEXTボタン装飾
    &.item--next {

      a {
        padding-right: 0.3em;

        &:after {
          font-family: "jeiafont";
          content: "\e902";
          margin-left: 0.2em;
        }
      }
    }
  }
}
