///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/



/*
ログインフォーム

ログインフォームを表示します。

使用箇所：
Mypage/login.twig
Shopping/login.twig

sg-wrapper:
<div class="ec-role">
  <div class="ec-off2Grid">
    <div class="ec-off2Grid__cell">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/6.1.1.html

Styleguide 6.1.1
*/
.ec-login {

  // アイコン
  & &__icon {
    .ec-icon {
      margin-bottom: 10px;
      text-align: center;

      @include media_desktop {
        text-align: left;
      }

      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  // 入力欄
  & &__input {
    margin-bottom: 10px;

    input + input {
      margin-top: 1em;
    }

    // 自動ログインチェック
    .ec-checkbox {
      margin-top: 0.5em;

      label {
        font-size: 0.9rem;
      }
    }

    @include media_desktop {
      margin-bottom: 20px;
    }
  }

  // ログインボタン
  & &__actions {
  }

  // その他アクション
  & &__link {
    margin-top: .5em;

    @include media_desktop {
      margin-top: 0;
      margin-left: 1.5em;
      margin-bottom: 0.5em;
    }

    a {
      color: inherit;
      @include hoverBottomLine;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}



/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

使用箇所：
Shopping/login.twig

Markup:
../styleguide/tmpl/6.1.2.html

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  width: 100%;
  margin-top: 20px;
  padding: 1.5em;
  box-sizing: border-box;
  background: #f0f0f0;

  @include media_desktop {
    width: auto;
    margin: 0;
    height: 100%;
    margin-left: 40px;
  }

  & &__inner{
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    p {
      margin-bottom: 1em;
    }
  }

  & &__actions {
    display: block;
    text-align: center;
  }
}
