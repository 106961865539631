@charset "UTF-8";
/*
///////////////////////////////////////////////////////////////////////

This template is part of EC-CUBE Design template.

Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.

http://www.jeia.co.jp/

For the full copyright and license information, please view the LICENSE
file that was distributed with this source code.

本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。

※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
別途、ご利用費用をお支払いただく必要がございます。
※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
※本テンプレートは、すべての設置環境で動作する事は保証しておりません。

///////////////////////////////////////////////////////////////////////
*/
@import url("../../../../../node_modules/normalize.css/normalize.css");
/*
Sass変数

_variables.scss

各パーツの色やサイズを変数として定義しています。

Styleguide 24.7
*/
/*
ヘッダーロゴ
*/
/*
デバイス別設定
*/
/*
ボタン
*/
/*
文字色・背景色
*/
/*
ヘッダー・ドロワー
当テンプレートでは白系（ヘッダーは白背景、ドロワーはライトグレー）でデザインしていますがフラグにより黒系に切り替える事ができます
*/
/*
フッター
*/
/*
シェア・SNSカラー
*/
/*
フォーム部品
*/
/*
カート
*/
/*
アニメーションkeyframes

_animation.scss

定義mixin：<br>
@mixin fadeIn<br>
@mixin fadeOut<br>

<br>
定義CSS：<br>
@keyframes fadeIn<br>
@keyframes fadeOut<br>
.bg-load-overlay

Styleguide 24.6
*/
@import 'https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&subset=japanese&display=swap';
@import 'https://fonts.googleapis.com/css?family=Noto+Serif+JP:400,500&subset=japanese&display=swap';
@import 'https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap';
@import 'https://fonts.googleapis.com/css?family=Oswald:400,500&display=swap';
@import 'https://fonts.googleapis.com/css?family=Roboto:400&display=swap';
@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden; }
  100% {
    opacity: 1;
    visibility: visible; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible; }
  100% {
    opacity: 0;
    visibility: hidden; } }

.bg-load-overlay {
  background: rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-around;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
  opacity: 1; }

/*
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
  padding: $padding-vertical $padding-horizontal;
  font-size: $font-size;
  line-height: $line-height;
  border-radius: $border-radius;
}
*/
/*
ボタン

_btn.scss

Styleguide 24.2
*/
/*
ボタンひな形

* @mixin _btn($color, $background, $border)

Styleguide 24.2.1
*/
/*
インラインボタン

* @mixin btn-default<br>
* @mixin btn-action<br>
* @mixin btn-cancel<br>
* @mixin btn-primary

Styleguide 24.2.2
*/
/*
ブロックボタン

* @mixin blockBtn-default<br>
* @mixin blockBtn-action<br>
* @mixin blockBtn-cancel<br>
* @mixin blockBtn-primary

Styleguide 24.2.3
*/
/*
User select

ボタンひな形（@mixin _btn()）から使用されてるmixinで、テキストを選択不可にしている様子。
ほかからの使用は無い様子。

* @mixin user-select

Styleguide 24.2.4
*/
/*
リンクボタン

使用箇所は無い様子

* @mixin linkBtn

Styleguide 24.2.5
*/
/*
Clearfix

_clearfix.scss

Styleguide 24.4
*/
/*
フォーム

_form.scss

Styleguide 24.3
*/
/*
フォームリセット

* @mixin forms-reset

Styleguide 24.3.1
*/
/*
フォームコントールひな形

* @mixin _form-control

Styleguide 24.3.2
*/
/*
メディア

_media.scss

Styleguide 24.1
*/
/*
メディアクエリ

* @mixin media_desktop

EC-CUBE4標準ではモバイルファースト（スマートフォン）でスタイリングしたのち適宜、デスクトップ用にメディアクエリで上書きする形でスタイリングしている様子ですが、
当テンプレートではタブレットも加えた3デバイスのスタイリングしています。

スタイリングの仕方はケースバイケースで、上書きや汚染が起きないようメディアクエリの内容を範囲を大きくとってがっつり分離してスタイリングする場合や、
一度、デスクトップ用（あるいはスマートフォン用）のスタイリングを施してから、適宜上書き用のメディアクエリを書く場合もあります。

メディアクエリに関しては、後からの変更・修正の際に「ごちゃごちゃしてどこをいじればいいのか判りづらい」コードにならないよう気を付けたいと思っています。
ですので上書きの多用は避けたいとしております、が、上書きスタイリングのほうがコードがすっきりするならそのようにします。ケースバイケースです。

* スマホ：320px～767px
* タブレット：768px～1279px
* PC：1200px以上

※デバイスごとのブレイクポイントは_variables.scssにて変数で設定。

Styleguide 24.1.1
*/
/*
コンテナ

コンテナブロックのmax-width、左右余白などブロックスタイルの共通定義。
各セクションの左右余白の統一感はコンテナによってもたらされます。

※ブラウザのフル幅で背景を敷いたデザインも可能とするため、`.ec-layoutRole__contents`側のmax-widthを解除し、こちらの「コンテナ」でmax-widthを定義。
コンテナを適用するセクションでmax-widthを変更したい場合は、そのコンテナで随時上書きします。

※コンテナの左右余白については、コンテナを「box-sizing:content-box;」としておいてから、希望のままの値でmax-widthを指定し、デバイスごとの左右余白を定義。

EC-CUBE4ではマイページ用に調整されたコンテナがありましたが、当テンプレートではマイページも汎用コンテナでスタイリングしています。

* @mixin container

Styleguide 24.1.2
*/
/*
ECCUBE 固有のスタイルユーティリティ

_projects.scss

* @mixin borderTop
* @mixin borderBottom
* @mixin reset_link

borderTop, borderBottomは、サイト内で一貫したborder線を敷くために使います。
一応、引数で色も指定できますが、一貫とは別種のborderなら直接borderプロパティでスタイリングしたほうがいいとは思います。

reset_linkは、.ec-link（リンクテキストの表現）をリセットさせる目的のmixinでしょうか。当テンプレートではその場その場で、
同等のスタイリングをしていますので、結果このmixinは使用していません。

Styleguide 24.5
*/
/*
追加mixin

当テンプレート用にmixinを追加しています。

Styleguide 24.5.1
*/
/*
offGridを無効にするmixin

テンプレート上、レイアウト的に幅狭め（offGird）を無効にしたい場合に適用

※offGrid自体の機能は崩さずそのまま残しておきたいので、部分的に挙動を無効化するmixinを用意。
※offGrid自体の挙動は変更していないが、タブレット時の挙動は別途追加している。
↓
結果的にoffGridの挙動を変更する形にした

Styleguide 24.5.2
*/
/*
画像マウスオーバー

マウスオーバーで画像を拡大・明度上げします。
mixinはa要素に適用してください。

Markup:
../styleguide/tmpl/24.5.3.html

Styleguide 24.5.3
*/
/*
画像マウスオーバー（カラーオーバーレイ）

マウスオーバーで画像にカラーフィルターをオーバーレイします。
mixinはa要素に適用してください。

* $color: フィルターの色

Markup:
../styleguide/tmpl/24.5.4.html

Styleguide 24.5.4
*/
/*
横並びメニューのボタンに短い区切り線を入れる

マイページのメニュー部分に使用しています。

* $length: ボーダー長さ（%のみ）
* $color: ボーダー色

Markup:
../styleguide/tmpl/24.5.5.html

Styleguide 24.5.5
*/
/*
商品一覧等の陳列レイアウト

商品一覧やお気に入り一覧など子アイテム（商品）を陳列レイアウト（縦横に並べる）スタイリングを施します。
横に配置する数、アイテム間の余白、子要素のタグを引数で指定できます。

商品詳細の画像ビジュアルナビでも使用しています。

* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
* $item: 子要素のタグ名称・class名称（デフォルトはli要素）

Styleguide 24.5.6
*/
/*
閉じる・削除ボタン

ヘッダーのカート状況のカート削除ボタン、マイぺージお気に入り削除ボタンなど、画像の上に被さるタイプの丸型の削除系ボタンです。

* $size ボタン自体のサイズを指定

Styleguide 24.5.7
*/
/*
テキストhoverで下線が伸びるエフェクト

* $color: 下線の色

Markup:
../styleguide/tmpl/24.5.8.html

Styleguide 24.5.8
*/
/*
ユーザビリティ タブフォーカス

タブフォーカスのデザインをスタイリングします。
タブフォーカス時だけでなくクリックした時も効果が出てしまいます。デフォルトでは効果をオフにしています。ユーザビリティを優先する場合はコメントアウトを解除してください。
いい具合に実装できるライブラリ「what-input」なるものもあるみたいですね。

Styleguide 24.5.9
*/
/*
ブラウザハック IE11

Styleguide 24.5.10
*/
/*
商品アイテム マウスオーバーアクション

商品名称や価格表示を包含する商品情報要素を最初に非表示にしておき、商品画像にマウスオーバーしたときに商品情報をオーバーレイ表示させるスタイリングです。

商品アイテム「.ec-productItemRole」（_13.2.shelf.scss）に適用する専用のmixinとなっています。
マウスオーバーアクションを与えたいブロックのスタイリングにおいて「.ec-productItemRole」に対してmixinを適用してください。
当テンプレートでは「特集商品（_12.6.newItem.scss）」「ピックアップカテゴリ（_12.7.category.scss）」ブロックで使用しています。

MEMO
マウスオーバーアクション実装について今回のようにmixinで実装するかそれともOOCSSで実装するか悩みましたが、OOCSSだとTwig（HTML）側に
OOCSSのclassを記述する必要がありますができるだけTwig（HTML）には無駄にclassを増やさないと方が良いかと思いmixinによる実装としました。

Markup:
../styleguide/tmpl/24.5.11.html

Styleguide 24.5.11
*/
/*
ベース

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 0.1
*/
@media only screen and (max-width: 767px) {
  html {
    font-size: 3.73333vw; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  html {
    font-size: 1.95312vw; } }

@media only screen and (min-width: 1200px) {
  html {
    font-size: 15px; } }

body {
  background: transparent;
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #444; }

a:focus {
  text-decoration: none;
  /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }

pre {
  /*
  background-color: transparent;
  border: none;
  padding: 16px 0;
  */ }

p {
  margin-bottom: 1em; }

img {
  max-width: 100%; }

label {
  margin: 0;
  font-weight: normal; }

/*
表組み（シンプル）

table要素、dl要素による表組レイアウト

商品詳細ページのフリーエリアや利用ガイド等の記事の中で差し込まれてくるシンプルな表組みについて基本スタイリングします。

特商や会員登録フォームといったコンテンツの表組みレイアウトは、_1.3.list.scssにて定義しています。

Markup:
../styleguide/tmpl/0.1.2.html

Styleguide 0.1.2
*/
/*
.table_basic 
*/
table.table_basic {
  width: 100%;
  table-layout: fixed;
  font-size: 0.933rem;
  margin: 2rem 0;
  border-top: 1px solid #eee; }
  table.table_basic tr {
    border-bottom: 1px solid #eee; }
    table.table_basic tr:nth-child(odd) {
      background-color: #f8f8f8; }
  table.table_basic th {
    padding: 0.5em;
    width: 10em;
    white-space: nowrap;
    vertical-align: top; }
  table.table_basic td {
    padding: 0.5em;
    width: auto;
    vertical-align: top; }

/* .caption */
.caption {
  margin: 0;
  padding: 0.5em 0;
  font-size: 0.933rem;
  color: #666; }

/*
dl要素をtable風に
*/
.dl_table {
  width: 100%;
  font-size: 0.933rem;
  margin: 2rem 0; }
  .caption + .dl_table {
    margin-top: 0; }
  .dl_table dl {
    margin: 0;
    border-bottom: 1px solid #eee; }
    .dl_table dl:nth-child(odd) {
      background-color: #f8f8f8; }
    .dl_table dl:first-child {
      border-top: 1px solid #eee; }
  .dl_table dt {
    display: inline-block;
    padding: 0.5em;
    min-width: 10em; }
  .dl_table dd {
    display: inline-block;
    padding: 0.5em; }

/*
dl要素レスポンシブ（タブレット・デスクトップ時はtable風で、スマホ時はdtとddを改行して表示）
*/
@media only screen and (max-width: 767px) {
  .dl_table.rwd dl {
    margin: 0;
    border: none !important;
    background-color: transparent !important; }
  .dl_table.rwd dt {
    display: block;
    padding: 0.5em;
    margin-bottom: 0.5em;
    background-color: #f0f0f0; }
  .dl_table.rwd dd {
    display: block;
    padding: 0 0 1em; } }

/*
ページスクロールで対象画像をエフェクト出現

Markup:
../styleguide/tmpl/0.1.3.html

ページスクロールに応じて対象画像をエフェクト出現表示させるスタイリングです。スクリプトはfunction.jsにあります。

エフェクト出現させたいimg要素の親または先祖要素に対してclass「.appear_item」を指定しておきます。

「.appear_item」要素がスクロールに応じてブラウザ領域に入ってきたら対象画像がエフェクト出現表示されます。

事前にスクリプトにより対象画像（img要素）に対して「.appear_itemImage」要素で包括されるようにしています。
この包括要素に対してanimationがスタイリングされます。
※包括要素を入れずにimg要素に対して直接アニメーションさせてしまうと、表示完了後にhoverアクション（transformを使った拡大表示等）が効かない事があります。
表示完了後に.appear_itemを外したり、アニメーションを破棄すれば解決しますが、iOSでは解決しきらない事があったので包括要素を付ける仕様としました。

スクロールされブラウザ領域に入ってきたらclass「.appear_active」がスクリプトにより付与されます。

* パーツA：対象画像本体
* パーツB：グレーのマスク要素
* パーツC：点滅するロゴ

Styleguide 0.1.3
*/
.appear_item {
  position: relative;
  overflow: hidden;
  visibility: hidden;
  /* パーツA: 対象画像本体 */
  /* パーツB: マスク */
  /* パーツC: ロゴ */
  /*
  &:after {
    content: "";
    display: block;
    background: url(../img/common/appear_logo.svg) no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 50%;
    position: absolute;
    top: 25%;
    left: 25%;
    z-index: 2;
  }
  */
  /* アクティブ化表示状態 */ }
  .appear_item .appear_itemImage {
    transform: translate3d(0, -100%, 0); }
  .appear_item:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #ccc;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translate3d(0, -100%, 0); }
  .appear_item.appear_active {
    visibility: visible;
    /* パーツA: 商品画像本体 */
    /* パーツB: マスク */
    /* パーツC: ロゴ */
    /*
    &:after {
      visibility: hidden;
      animation-duration: 0.8s;//パーツBマスクのdurationと同じ値
      animation-fill-mode: forwards;
      animation-name: appearAnimation_c;
    }
    @keyframes appearAnimation_c {
      0% {opacity: 0; visibility: visible;}
      10% {opacity: 1;}
      20% {opacity: 0;}
      30% {opacity: 1;}
      40% {opacity: 0;}
      50% {opacity: 1;}
      60% {opacity: 0;}
      70% {opacity: 1;}
      80% {opacity: 0;}
      90% {opacity: 1;}
      100% {opacity: 0; visibility: hidden; }
    }
    */ }
    .appear_item.appear_active .appear_itemImage {
      animation-duration: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-delay: 0.3s;
      /*長くとると動きが増える*/
      animation-name: appearAnimation_a; }

@keyframes appearAnimation_a {
  0% {
    transform: translate3d(0, -100%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }
    .appear_item.appear_active:before {
      visibility: hidden;
      animation-duration: 0.8s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      animation-name: appearAnimation_b; }

@keyframes appearAnimation_b {
  /* [0%] IN→ [30%] Stay [70%] OUT→ [100%] */
  0% {
    visibility: visible;
    transform: translate3d(0, -100%, 0); }
  30% {
    transform: translate3d(0, 0, 0); }
  70% {
    transform: translate3d(0, 0, 0); }
  100% {
    transform: translate3d(0, 100%, 0); } }

/*
フォントファミリー

使用するフォントファミリー

Styleguide 0.2
*/
/*
 * MEMO
 * IE11でNoto表示するには「&subset=japanese」が必要。
 * Notoは字詰めカーニングが効かない。カーニングが必要であれば游ゴシックなど別フォントに変更。
 * Google Fontsが「display=swap」対応（フォントが読み込まれるまで代替フォントを表示）
*/
body {
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 400; }

button, input, select, textarea {
  font-family: "Noto Sans JP", sans-serif; }

/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

Styleguide 1.1
*/
/*
商品詳細ページ見出し（未使用）

EC-CUBE4では商品詳細ページのみ見出しに使用されているスタイルですが、当テンプレートでは使用していません。

使用箇所：なし

Markup:
../styleguide/tmpl/1.1.1.html

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263; }

/*
ページヘッダ（h1見出し）

各種ページで用いられるページヘッダのデザインです。

EC-CUBE4のHTML構成でページヘッダ見出しは「コンテナ」の中に含まれており、コンテナには左右余白が定義されています。<br>
当テンプレートではh1見出しには背景色を敷いたデザインですが、スマホ・タブレット時ではブラウザ幅フルにフィットさせたいので、
これをネガティブマージンを用いて左右余白を相殺することでスタイリングしています。

もし背景色を敷かないデザインに変更するのであればネガティブマージンによる余白相殺と余白の再定義は不要です。

h1要素に下マージンを持たせて、h1タイトル部とページコンテンツ本体との余白としています。

使用箇所：
Cart/index.twig
Contact/complete.twig
Contact/confirm.twig
Contact/index.twig
Entry/activate.twig
Entry/complete.twig
Entry/confirm.twig
Entry/index.twig
Forgot/complete.twig
Forgot/index.twig
Forgot/reset.twig
Help/about.twig
Help/agreement.twig
Help/guide.twig
Help/privacy.twig
Help/tradelaw.twig
Mypage/change.twig
Mypage/change_complete.twig
Mypage/delivery.twig
Mypage/delivery_edit.twig
Mypage/favorite.twig
Mypage/history.twig
Mypage/index.twig
Mypage/login.twig
Mypage/withdraw.twig
Mypage/withdraw_complete.twig
Mypage/withdraw_confirm.twig
Shopping/complete.twig
Shopping/confirm.twig
Shopping/index.twig
Shopping/login.twig
Shopping/nonmember.twig
Shopping/shipping.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple.twig
Shopping/shipping_multiple_edit.twig
Shopping/shopping_error.twig

Markup:
../styleguide/tmpl/1.1.2.html

Styleguide 1.1.2
*/
.ec-pageHeader h1 {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  margin: 0;
  background-color: #f4f4f4;
  font-weight: 500;
  margin-bottom: 1.5em; }
  @media only screen and (max-width: 767px) {
    .ec-pageHeader h1 {
      margin-left: -3.125vw;
      margin-right: -3.125vw;
      padding: 0.85em 3.125vw;
      font-size: 1.2rem;
      border-bottom: solid 1px #ddd;
      margin-bottom: 1em; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-pageHeader h1 {
      margin-left: -3.906vw;
      margin-right: -3.906vw;
      padding: 0.85em 3.906vw;
      font-size: 1.2rem;
      border-bottom: solid 1px #ddd; } }
  @media only screen and (min-width: 1200px) {
    .ec-pageHeader h1 {
      padding: 0.75em 0.75em;
      font-size: 1.3em; } }

.ec-pageHeader--en {
  display: inline-block;
  font-family: 'Dynalight', serif;
  margin-left: 10px;
  font-size: 1rem; }

/*
サブ見出し（未使用）

文字主体のページで用いられるサブ見出しです。

使用箇所：なし

後述の「.ec-heading-bold」に対して太字でないスタイリングという位置づけでしょうか。
使用箇所はありませんが一応、スタイリングとして調整して残しておきます。

Markup:
../styleguide/tmpl/1.1.3.html

Styleguide 1.1.3
*/
.ec-heading {
  font-weight: 1.1rem;
  font-weight: normal;
  margin-bottom: 1em; }

/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

EC-CUBE4では「プライバシーポリシー」の太字見出しで使用されているスタイルですが、当テンプレートでは規約条文用のコンポーネントを用意しましたので、
結果、使用箇所なしとなります。

使用箇所：なし

Markup:
../styleguide/tmpl/1.1.4.html

Styleguide 1.1.4
*/
.ec-heading-bold {
  font-weight: 1.1rem;
  font-weight: 500;
  margin-bottom: 1em; }

/*
背景付き見出し

h2の見出し要素を装飾するスタイリングです。<br>
h3以降の使用箇所はありません。使う場面があればh3以降も追加スタイリングしてください。

使用箇所：
Mypage/history.twig
Shopping/index.twig
Shopping/confirm.twig
Help/guide.twig

Markup:
../styleguide/tmpl/1.1.5.html

Styleguide 1.1.5
*/
.ec-rectHeading {
  margin: 0;
  margin-bottom: 1rem; }
  .ec-rectHeading h2 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: normal;
    background-color: #f3f1eb;
    padding: 0.6em 0.75em; }
  .ec-rectHeading--en {
    display: inline-block;
    font-family: 'Dynalight', serif;
    margin-left: 10px;
    font-size: 0.8rem; }

/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

使用箇所：
error.twig
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/change_complete.twig
Mypage/withdraw_complete.twig
Shopping/complete.twig

Markup:
../styleguide/tmpl/1.1.6.html

Styleguide 1.1.6
*/
.ec-reportHeading {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1em;
  padding: 1em 0;
  text-align: center;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }
  .ec-reportHeading h2 {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit; }
  @media only screen and (max-width: 767px) {
    .ec-reportHeading {
      text-align: left; } }

/*
文字装飾

文字装飾をするためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.2
*/
/*
テキストリンク

テキストリンクのスタイルです。

使用箇所：
Entry/index.twig
Mypage/login.twig
Shopping/login.twig

※ログインページ（Mypage,Shopping）では上書き調整しておりますのでこちらのスタイリングは反映されない形です。

Markup:
../styleguide/tmpl/1.2.1.html

Styleguide 1.2.1
*/
.ec-link {
  text-decoration: none;
  cursor: pointer; }
  .ec-link:hover {
    text-decoration: none; }

/*
テキスト（太字）

テキストを太くするためのスタイルです。

使用箇所：
Product/list.twig
Shopping/index.twig
Shopping/confirm.twig

Markup:
../styleguide/tmpl/1.2.2.html

Styleguide 1.2.2
*/
.ec-font-bold {
  font-weight: 500; }

/*
テキスト（グレー）（未使用）

テキストをグレーにするためのスタイルです。

使用箇所：なし

Markup:
../styleguide/tmpl/1.2.3.html

Styleguide 1.2.3
*/
.ec-color-grey {
  color: #666; }

/*
テキスト（赤）

テキストを赤にするためのスタイルです。

使用箇所：
Cart/index.twig
Mypage/history.twig

Markup:
../styleguide/tmpl/1.2.4.html

Styleguide 1.2.4
*/
.ec-color-red {
  color: #e2251f; }

.ec-color-accent {
  color: #e2251f; }

/*
フォントサイズ（未使用）

フォントサイズを指定するためのスタイルです。

EC-CUBE4ではカートブロックで使用しているようですが、当テンプレートでは未使用です。

※当テンプレートではモバイル時はデバイス幅に比例して文字サイズが変化するよう（1行あたりの文字数が固定）vw単位でスタイリングしていますし、
デスクトップにおいてもrem、emを用いた相対的なスタイリングを考えていますので、
文字装飾でOOCSS的にpx指定のスタイリングは使わない考えです。このスタイリング自体を削除しておきたい所ですが一応残してあります。

※.mini {font-size:0.9em;}みたいな適用する場所において相対的に働くOOCSSならスタイリングしておきたいところです。

使用箇所：なし

Markup:
../styleguide/tmpl/1.2.5.html

Styleguide 1.2.5
*/
.ec-font-size-1 {
  font-size: 12px; }

.ec-font-size-2 {
  font-size: 14px; }

.ec-font-size-3 {
  font-size: 16px; }

.ec-font-size-4 {
  font-size: 20px; }

.ec-font-size-5 {
  font-size: 32px; }

.ec-font-size-6 {
  font-size: 40px; }

/*
テキスト水平位置

テキストをセンタリングするためのスタイルです。

使用箇所：
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/change_complete.twig
Shopping/complete.twig

Markup:
../styleguide/tmpl/1.2.6.html

Styleguide 1.2.6
*/
.ec-text-ac {
  text-align: center; }

/*
価格テキスト

価格を表示するテキストです。

使用箇所は商品詳細・一覧のみです。
こちらのページでは数字→価格表示の加工が動的に行われ（price関数）、「税込」表示もtransによる翻訳可能となっており、
それぞれが要素に囲まれた形になっています。
動的なので問題はないですが、別のブロック等で静的に商品リストをコーディングする際は簡単な後述の「.ec-price_in」の方を使用します。

font-familyと、価格部分と税込み表示部分の文字バランスだけにとどめています。
使う場面で親要素にfont-sizeを指定することでバランスを保ちつつ大きさを調整できるかと思います。

使用箇所：
* Product/list.twig
* Product/detail.twig

Markup:
../styleguide/tmpl/1.2.7.html

Styleguide 1.2.7
*/
.ec-price {
  font-family: "Oswald", "Noto Sans JP", sans-serif; }
  .ec-price .ec-price__price {
    display: inline-block;
    vertical-align: baseline;
    font-size: 1em; }
  .ec-price .ec-price__tax {
    display: inline-block;
    vertical-align: baseline;
    font-size: 0.65em;
    color: #666; }

/*
価格テキスト（簡易版）

価格表示のスタイルはサイト内各ページで統一しておきたいところです。
ちなみに、商品詳細ページでは金額部分ではTwigの「price」関数で数値を価格表示テキストに変換し、さらに「税込」の表示も「trans」関数で翻訳可能としてありますので、
その分の要素数も多く複雑になっています。
対して、トップページの商品アイテムを含む各ブロックでは動的ではなく静的にHTMLを記述しなくてはなりませんのでその意味で価格部分が複雑になるのは避けたいと思います。
（「税込」や「￥」の文字を多数記述するのも避けたいです。変更が入ったときに作業量が増え、ミスも誘発しかねないので）
そこで簡易にコーディングできるよう、専用のスタイリングを用意し商品詳細と同じ見た目になるようにします。
ただし、翻訳機能を考慮するのであればもう一工夫する必要があるかと思います。

Styleguide 1.2.7.2
*/
.ec-price_in {
  font-family: "Oswald", "Noto Sans JP", sans-serif; }
  .ec-price_in:before {
    content: "￥"; }
  .ec-price_in:after {
    content: "（税込）";
    font-size: 0.65em;
    color: #666; }

/*
テキストの位置

テキストや、入れ子にしたインライン要素を「左揃え」「中央揃え」「右揃え」に設定することができます。

使用箇所は無い様子

Markup:
../styleguide/tmpl/1.2.8.html

Styleguide 1.2.8
*/
.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

/*
メッセージテキスト

ユーザが行った操作に対する、完了報告やエラー表示のページで使用されるテキストのスタイルです。

使用箇所：
error.twig
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/change_complete.twig
Mypage/history.twig
Mypage/withdraw_complete.twig
Shopping/complete.twig

Markup:
../styleguide/tmpl/1.2.9.html

Styleguide 1.2.9
*/
.ec-reportDescription {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 2em;
  text-align: left; }
  @media only screen and (min-width: 1200px) {
    .ec-reportDescription {
      text-align: center; } }

/*
テキスト下部のスペース

テキストの下に余白を追加することができます。

使用箇所：
Contact/index.twig
Forgot/index.twig
Mypage/delivery.twig
Mypage/index.twig

Markup:
../styleguide/tmpl/1.2.10.html

Styleguide 1.2.10
*/
.ec-para-normal {
  margin-bottom: 1em; }

/*
リスト

シンプルなリストを構成するためのスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 1.3
*/
/*
水平定義リスト

シンプルな定義リストのスタイルを定義します。

dl要素を用いてコーディングします。

使用Twig：
* Mypage/index.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/1.3.1.html

Styleguide 1.3.1
*/
.ec-definitions, .ec-definitions--soft {
  margin: 0.5em 0;
  display: block; }
  .ec-definitions dt, .ec-definitions--soft dt, .ec-definitions dd, .ec-definitions--soft dd {
    display: inline-block;
    margin: 0; }
  .ec-definitions dt, .ec-definitions--soft dt {
    font-weight: 500;
    min-width: 8em;
    margin-right: 1em; }

.ec-definitions--soft dt {
  font-weight: normal; }

/*
定義リスト

線が添えられた定義リストのスタイルを定義します。（dlリスト）

フォーム入力ページや、「当サイトについて」「特商表記」など、タイトルと本文のあるコンテンツのスタイル。

メモ：
dtの中では「.ec-label」とセットで使われるのが常のようですが、これはフォームページの名残ともとれますが（違うかもしれませんが）、
「.ec-label」という名前上、フォーム周りのタイトルという先入観が拭えませんのでいささか気になりますが、当定義リストのdt要素が、
あくまでレイアウト役割のみという要素で割り切るなら、見た目のスタイルを当てている「.ec-label」は汎用的ならラベル要素とも取れます。
（ただ、_3.3.form.scssの中で定義されているのでやはり本来はフォームラベルの目的でコーディングされたような気がします）

使用箇所：
* Contact/index.twig
* Contact/confirm.twig
* Entry/index.twig
* Entry/confirm.twig
* Forgot/index.twig
* Forgot/reset.twig
* Help/about.twig
* Help/tradelaw.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/index.twig
* Shopping/nonmember.twig
* Shopping/shipping_edit.twig
* Shopping/shipping_multiple_edit.twig

Markup:
../styleguide/tmpl/1.3.2.html

Styleguide 1.3.2
*/
.ec-borderedDefs dl {
  width: 100%;
  margin: 0; }

@media only screen and (max-width: 767px) {
  .ec-borderedDefs dl {
    padding: 0.5em 0; }
  .ec-borderedDefs dt {
    display: block;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #eee; }
  .ec-borderedDefs dd {
    display: block;
    padding: 1rem 0; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-borderedDefs dl {
    display: table;
    border-bottom: 1px solid #eee; }
  .ec-borderedDefs dt {
    display: table-cell;
    width: 30%;
    vertical-align: middle; }
  .ec-borderedDefs dd {
    display: table-cell;
    width: auto;
    vertical-align: middle;
    padding: 1.5rem 0; } }

@media only screen and (min-width: 1200px) {
  .ec-borderedDefs dl {
    display: table;
    border-bottom: 1px solid #eee; }
    .ec-borderedDefs dl:first-child {
      border-top: 1px solid #eee; }
  .ec-borderedDefs dt {
    display: table-cell;
    width: 30%;
    vertical-align: middle; }
  .ec-borderedDefs dd {
    display: table-cell;
    width: auto;
    vertical-align: middle;
    padding: 1.5rem 0; } }

/*
ボーダーリスト

線が添えられたリストを表示します。

使用場面はショッピングページのカート商品内容部分で、その中身は商品画像と商品詳細の「.ec-imageGrid」（_5.2.layout.scss）が入ります。

「.ec-borderedLis」も「.ec-imageGrid」もそのパターンでしか使われていない様子なので、
まとめて一つのコンポーネントにしても良いかとも思いますが、今のところはそのままにします。

それともう一点、
「.ec-imageGrid」の使用場面は先のショッピングページの商品内容と、マイページ注文履歴詳細の商品内容とになりますが、
ショッピングページではこれをリスト化（ul li要素）して括っているのが「.ec-borderedList」であるのに対して、
注文履歴詳細のほうではそのリストが含まれておりません。

どちらもさらにその外側で「.ec-orderDelivery__item」で括っているのですが、さらに、
ショッピングページ側では、「.ec-orderDelivery__item」は1つのみでその中にリストのul要素があり、商品ごとにli要素で
括っているのに対して、注文履歴詳細はリストが無く、商品ごとに「.ec-orderDelivery__item」で括っているという違いがあります。

意味合い的にもスタイリングにしても同一なものなので統一してもらいたい所ですが、今のところはCSSで統一させるに留めます。

まず、「.ec-orderDelivery__item」には何もスタイリングしなようにしておき、注文履歴詳細ページのみに働くように
「.ec-orderDelivery__item」に「.ec-borderedList」と同じ形のスタイリングを施しています。

〇ショッピングページ側
構成（※スタイルガイドページでは崩れますのでscssファイルでご覧ください）
.ec-orderDelivery__item
  > ul.ec-borderedList
    > li > .ec-imageGrid
    > li > .ec-imageGrid
    > li > .ec-imageGrid


〇注文履歴詳細ページ側
構成（※スタイルガイドページでは崩れますのでscssファイルでご覧ください）
.ec-orderDelivery__item > .ec-imageGrid
.ec-orderDelivery__item > .ec-imageGrid
.ec-orderDelivery__item > .ec-imageGrid

Markup:
../styleguide/tmpl/1.3.3.html

Styleguide 1.3.3
*/
.ec-borderedList {
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #eee; }
  .ec-borderedList li {
    padding: 10px 0;
    border-bottom: 1px solid #eee; }

/*
注文履歴詳細用
本来は_15.2.order.scssにてスタイリングすべきですが（※これも厳密にいえばショッピングとマイページコンテンツとで分けておくべきかとも思いますが）、
上記のメモ書きの理由により、混雑しないようにこちらで「.ec-borderedList」と合わせてスタイリングします。
*/
body#page_mypage_history .ec-orderDelivery__item {
  padding: 10px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }
  body#page_mypage_history .ec-orderDelivery__item + .ec-orderDelivery__item {
    border-top: none; }

/*
ボタン生成

mixinを利用して各パターンのボタンを生成します。

Styleguide 2.1
*/
/*
通常ボタン（インラインボタン）

インラインの要素としてボタンを定義出来ます。

〇.ec-inlineBtn
使用箇所：
* Mypage/delivery.twig
* Mypage/history.twig
* Mypage/index.twig
* Shopping/index.twig
* Shopping/shipping.twig
* Shopping/shipping_multiple.twig

〇.ec-inlineBtn--primary
使用箇所：なし

〇.ec-inlineBtn--action
使用箇所：
* Product/list.twig
* Product/detail.twig

〇.ec-inlineBtn--cancel
使用箇所：
* Product/list.twig
* Product/detail.twig

Markup:
../styleguide/tmpl/2.1.1.html

Styleguide 2.1.1
*/
.ec-inlineBtn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #242930;
  border-color: #444;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  font-size: 0.9rem; }
  .ec-inlineBtn:focus, .ec-inlineBtn.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-inlineBtn:hover {
    text-decoration: none;
    color: #fff;
    background-color: #0e1013;
    border-color: #252525; }
  .ec-inlineBtn.disabled, .ec-inlineBtn[disabled],
  fieldset[disabled] .ec-inlineBtn {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-inlineBtn.disabled:hover, .ec-inlineBtn.disabled:focus, .ec-inlineBtn.disabled.focus, .ec-inlineBtn[disabled]:hover, .ec-inlineBtn[disabled]:focus, .ec-inlineBtn[disabled].focus,
    fieldset[disabled] .ec-inlineBtn:hover,
    fieldset[disabled] .ec-inlineBtn:focus,
    fieldset[disabled] .ec-inlineBtn.focus {
      background-color: #999;
      border-color: #999; }
  .ec-inlineBtn .badge {
    color: #242930;
    background-color: #fff; }

.ec-inlineBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */ }
  .ec-inlineBtn--primary:focus, .ec-inlineBtn--primary.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-inlineBtn--primary:hover {
    text-decoration: none;
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-inlineBtn--primary.disabled, .ec-inlineBtn--primary[disabled],
  fieldset[disabled] .ec-inlineBtn--primary {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-inlineBtn--primary.disabled:hover, .ec-inlineBtn--primary.disabled:focus, .ec-inlineBtn--primary.disabled.focus, .ec-inlineBtn--primary[disabled]:hover, .ec-inlineBtn--primary[disabled]:focus, .ec-inlineBtn--primary[disabled].focus,
    fieldset[disabled] .ec-inlineBtn--primary:hover,
    fieldset[disabled] .ec-inlineBtn--primary:focus,
    fieldset[disabled] .ec-inlineBtn--primary.focus {
      background-color: #999;
      border-color: #999; }
  .ec-inlineBtn--primary .badge {
    color: #5CB1B1;
    background-color: #fff; }

.ec-inlineBtn--action {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #d41d17;
  border-color: #d41d17;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */ }
  .ec-inlineBtn--action:focus, .ec-inlineBtn--action.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-inlineBtn--action:hover {
    text-decoration: none;
    color: #fff;
    background-color: #a61712;
    border-color: #9d1511; }
  .ec-inlineBtn--action.disabled, .ec-inlineBtn--action[disabled],
  fieldset[disabled] .ec-inlineBtn--action {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-inlineBtn--action.disabled:hover, .ec-inlineBtn--action.disabled:focus, .ec-inlineBtn--action.disabled.focus, .ec-inlineBtn--action[disabled]:hover, .ec-inlineBtn--action[disabled]:focus, .ec-inlineBtn--action[disabled].focus,
    fieldset[disabled] .ec-inlineBtn--action:hover,
    fieldset[disabled] .ec-inlineBtn--action:focus,
    fieldset[disabled] .ec-inlineBtn--action.focus {
      background-color: #999;
      border-color: #999; }
  .ec-inlineBtn--action .badge {
    color: #d41d17;
    background-color: #fff; }
  .ec-inlineBtn--action:hover {
    background-color: #e60c05;
    border-color: #e60c05; }

.ec-inlineBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #333333;
  border-color: #333333;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */ }
  .ec-inlineBtn--cancel:focus, .ec-inlineBtn--cancel.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-inlineBtn--cancel:hover {
    text-decoration: none;
    color: #fff;
    background-color: #1a1a1a;
    border-color: #141414; }
  .ec-inlineBtn--cancel.disabled, .ec-inlineBtn--cancel[disabled],
  fieldset[disabled] .ec-inlineBtn--cancel {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-inlineBtn--cancel.disabled:hover, .ec-inlineBtn--cancel.disabled:focus, .ec-inlineBtn--cancel.disabled.focus, .ec-inlineBtn--cancel[disabled]:hover, .ec-inlineBtn--cancel[disabled]:focus, .ec-inlineBtn--cancel[disabled].focus,
    fieldset[disabled] .ec-inlineBtn--cancel:hover,
    fieldset[disabled] .ec-inlineBtn--cancel:focus,
    fieldset[disabled] .ec-inlineBtn--cancel.focus {
      background-color: #999;
      border-color: #999; }
  .ec-inlineBtn--cancel .badge {
    color: #333333;
    background-color: #fff; }

/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

使用箇所：
error.twig
Block/cart.twig
Block/eyecatch.twig
Cart/index.twig
Contact/index.twig
Contact/complete.twig
Contact/confirm.twig
Entry/index.twig
Entry/activate.twig
Entry/complete.twig
Entry/confirm.twig
Forgot/index.twig
Forgot/reset.twig
Mypage/change.twig
Mypage/change_complete.twig
Mypage/delivery_edit.twig
Mypage/history.twig
Mypage/login.twig
Mypage/withdraw.twig
Mypage/withdraw_complete.twig
Mypage/withdraw_confirm.twig
Product/detail.twig
Product/list.twig
Shopping/index.twig
Shopping/complete.twig
Shopping/confirm.twig
Shopping/login.twig
Shopping/nonmember.twig
Shopping/shipping.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple.twig
Shopping/shipping_multiple_edit.twig
Shopping/shopping_error.twig

Markup:
../styleguide/tmpl/2.1.2.html

Styleguide 2.1.2
*/
.ec-blockBtn {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #242930;
  border-color: #444;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn:focus, .ec-blockBtn.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-blockBtn:hover {
    text-decoration: none;
    color: #fff;
    background-color: #0e1013;
    border-color: #252525; }
  .ec-blockBtn.disabled, .ec-blockBtn[disabled],
  fieldset[disabled] .ec-blockBtn {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-blockBtn.disabled:hover, .ec-blockBtn.disabled:focus, .ec-blockBtn.disabled.focus, .ec-blockBtn[disabled]:hover, .ec-blockBtn[disabled]:focus, .ec-blockBtn[disabled].focus,
    fieldset[disabled] .ec-blockBtn:hover,
    fieldset[disabled] .ec-blockBtn:focus,
    fieldset[disabled] .ec-blockBtn.focus {
      background-color: #999;
      border-color: #999; }
  .ec-blockBtn .badge {
    color: #242930;
    background-color: #fff; }
  .ec-blockBtn__name--en {
    display: block;
    width: 100%;
    margin-top: 5px;
    text-align: center;
    font-family: 'Dynalight', serif;
    font-size: 0.8rem; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-blockBtn__name--en {
        font-size: 0.8rem; } }
    @media only screen and (min-width: 1200px) {
      .ec-blockBtn__name--en {
        font-size: 0.8rem; } }

.ec-blockBtn--primary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #5CB1B1;
  border-color: #5CB1B1;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--primary:focus, .ec-blockBtn--primary.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-blockBtn--primary:hover {
    text-decoration: none;
    color: #fff;
    background-color: #479393;
    border-color: #438d8d; }
  .ec-blockBtn--primary.disabled, .ec-blockBtn--primary[disabled],
  fieldset[disabled] .ec-blockBtn--primary {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-blockBtn--primary.disabled:hover, .ec-blockBtn--primary.disabled:focus, .ec-blockBtn--primary.disabled.focus, .ec-blockBtn--primary[disabled]:hover, .ec-blockBtn--primary[disabled]:focus, .ec-blockBtn--primary[disabled].focus,
    fieldset[disabled] .ec-blockBtn--primary:hover,
    fieldset[disabled] .ec-blockBtn--primary:focus,
    fieldset[disabled] .ec-blockBtn--primary.focus {
      background-color: #999;
      border-color: #999; }
  .ec-blockBtn--primary .badge {
    color: #5CB1B1;
    background-color: #fff; }

.ec-blockBtn--action {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #d41d17;
  border-color: #d41d17;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--action:focus, .ec-blockBtn--action.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-blockBtn--action:hover {
    text-decoration: none;
    color: #fff;
    background-color: #a61712;
    border-color: #9d1511; }
  .ec-blockBtn--action.disabled, .ec-blockBtn--action[disabled],
  fieldset[disabled] .ec-blockBtn--action {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-blockBtn--action.disabled:hover, .ec-blockBtn--action.disabled:focus, .ec-blockBtn--action.disabled.focus, .ec-blockBtn--action[disabled]:hover, .ec-blockBtn--action[disabled]:focus, .ec-blockBtn--action[disabled].focus,
    fieldset[disabled] .ec-blockBtn--action:hover,
    fieldset[disabled] .ec-blockBtn--action:focus,
    fieldset[disabled] .ec-blockBtn--action.focus {
      background-color: #999;
      border-color: #999; }
  .ec-blockBtn--action .badge {
    color: #d41d17;
    background-color: #fff; }
  .ec-blockBtn--action:hover {
    background-color: #e60c05;
    border-color: #e60c05; }

.ec-blockBtn--cancel {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #333333;
  border-color: #333333;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--cancel:focus, .ec-blockBtn--cancel.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-blockBtn--cancel:hover {
    text-decoration: none;
    color: #fff;
    background-color: #1a1a1a;
    border-color: #141414; }
  .ec-blockBtn--cancel.disabled, .ec-blockBtn--cancel[disabled],
  fieldset[disabled] .ec-blockBtn--cancel {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-blockBtn--cancel.disabled:hover, .ec-blockBtn--cancel.disabled:focus, .ec-blockBtn--cancel.disabled.focus, .ec-blockBtn--cancel[disabled]:hover, .ec-blockBtn--cancel[disabled]:focus, .ec-blockBtn--cancel[disabled].focus,
    fieldset[disabled] .ec-blockBtn--cancel:hover,
    fieldset[disabled] .ec-blockBtn--cancel:focus,
    fieldset[disabled] .ec-blockBtn--cancel.focus {
      background-color: #999;
      border-color: #999; }
  .ec-blockBtn--cancel .badge {
    color: #333333;
    background-color: #fff; }

/*
カートインボタン

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/2.1.3.html

Styleguide 2.1.3
*/
.ec-blockBtn--cartin {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 0.6em 1em;
  font-size: 1rem;
  line-height: 1;
  border-radius: 0px;
  font-weight: normal;
  text-decoration: none;
  transition: .3s;
  color: #fff;
  background-color: #000;
  border-color: #000;
  /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
  display: block;
  width: 100%;
  height: 3.33rem;
  line-height: 3.33rem;
  padding-top: 0;
  padding-bottom: 0; }
  .ec-blockBtn--cartin:focus, .ec-blockBtn--cartin.focus {
    color: #fff;
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-blockBtn--cartin:hover {
    text-decoration: none;
    color: #fff;
    background-color: black;
    border-color: black; }
  .ec-blockBtn--cartin.disabled, .ec-blockBtn--cartin[disabled],
  fieldset[disabled] .ec-blockBtn--cartin {
    background-color: #999;
    border-color: #999;
    cursor: not-allowed; }
    .ec-blockBtn--cartin.disabled:hover, .ec-blockBtn--cartin.disabled:focus, .ec-blockBtn--cartin.disabled.focus, .ec-blockBtn--cartin[disabled]:hover, .ec-blockBtn--cartin[disabled]:focus, .ec-blockBtn--cartin[disabled].focus,
    fieldset[disabled] .ec-blockBtn--cartin:hover,
    fieldset[disabled] .ec-blockBtn--cartin:focus,
    fieldset[disabled] .ec-blockBtn--cartin.focus {
      background-color: #999;
      border-color: #999; }
  .ec-blockBtn--cartin .badge {
    color: #000;
    background-color: #fff; }
  .ec-blockBtn--cartin:before {
    font-family: "jeiafont";
    content: "\e900";
    margin-right: 0.5em;
    display: inline-block;
    transform: scale(1.4); }
  .ec-blockBtn--cartin:hover {
    background-color: #d41d17;
    border-color: #d41d17; }

/*
お気に入りボタン

お気に入りボタンのサイズは商品詳細ページの「カートイン枠（横一列）」に合わせて、$cartinRow-sizeで生成しています。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/2.1.4.html

Styleguide 2.1.4
*/
.ec-blockBtn--favorite {
  display: block; }
  .ec-blockBtn--favorite i {
    display: block;
    margin: 0;
    width: 3.33rem;
    height: 3.33rem;
    text-align: center;
    border-radius: 50%;
    background-color: #666;
    color: #fff;
    transition: .2s;
    position: relative;
    /* ポップアップ */ }
    .ec-blockBtn--favorite i:before {
      font-size: 1.665rem;
      line-height: 3.663rem; }
    .ec-blockBtn--favorite i span {
      position: absolute;
      bottom: calc(100% + 12px);
      left: calc(50% - 18px);
      display: block;
      padding: 0.75em 1em;
      background-color: #333;
      color: #fff;
      font-size: 0.8rem;
      line-height: 1;
      white-space: nowrap;
      border-radius: 2px;
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0, 10px, 0);
      transition: .2s; }
      @media (max-width: 1450px) {
        .ec-blockBtn--favorite i span {
          left: auto;
          right: 0; } }
      .ec-blockBtn--favorite i span:after {
        content: "";
        width: 0;
        height: 0;
        border: solid 8px transparent;
        border-top-color: #333;
        position: absolute;
        top: 100%;
        left: 10px; }
        @media (max-width: 1450px) {
          .ec-blockBtn--favorite i span:after {
            left: auto;
            right: 10px; } }
    .ec-blockBtn--favorite i:hover {
      background-color: #ff5555; }
      .ec-blockBtn--favorite i:hover span {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0); }
  .ec-blockBtn--favorite.added i {
    background-color: #ff5555; }

/*
アイコンボタン

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 2.2
*/
/*
閉じるボタン

使用箇所：
Mypage/favorite.twig

Markup:
../styleguide/tmpl/2.2.2.html

Styleguide 2.2.2
*/
.ec-closeBtn--circle:after {
  font-family: "jeiafont";
  content: "\e918";
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 21px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  color: #333;
  border: solid 1px #aaa;
  box-sizing: border-box;
  cursor: pointer;
  transform: translate3d(0, 0, 0);
  transition: .2s; }

.ec-closeBtn--circle:hover:after {
  text-decoration: none;
  transform: translate3d(0, 1px, 0);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3); }

.ec-closeBtn--circle:hover {
  text-decoration: none; }

.ec-closeBtn--circle .ec-icon {
  display: none; }

/*
その他のボタン

通常のボタンや、アイコンボタン以外のボタンを定義します。

Styleguide 2.3
*/
/*
ページトップボタン

ページトップボタンのスタイリングです。

Markup:
../styleguide/tmpl/2.3.1.html

Styleguide 2.3.1
*/
.ec-blockTopBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 22px;
  width: 2.182em;
  height: 2.182em;
  color: #999;
  border: solid 3px #999;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  position: fixed;
  right: 20px;
  bottom: 20px;
  visibility: hidden;
  opacity: 0;
  transition: .3s;
  /* ボタン表示 */ }
  @media only screen and (max-width: 767px) {
    .ec-blockTopBtn {
      font-size: 19px;
      right: 10px;
      bottom: 10px; } }
  body.scrolled .ec-blockTopBtn {
    visibility: visible;
    opacity: 1; }
  .ec-blockTopBtn i {
    transform: translate3d(0, 1px, 0);
    transition: .5s; }
  .ec-blockTopBtn:hover {
    filter: brightness(1.2); }
    .ec-blockTopBtn:hover i {
      transform: translate3d(0, -1px, 0); }

/*
フォーム部品（入力要素）

テキストや数値の入力項目に関するフォーム要素の基本整形をスタイリングします。

Styleguide 3.1
*/
/*
テキスト入力フォーム

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.1.1.html

Styleguide 3.1.1
*/
.ec-input input, .ec-halfInput input, .ec-numberInput input, .ec-zipInput input, .ec-telInput input {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 1rem;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .ec-input input:focus, .ec-halfInput input:focus, .ec-numberInput input:focus, .ec-zipInput input:focus, .ec-telInput input:focus {
    border-color: #3c8dbc;
    outline: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .ec-input input::-moz-placeholder, .ec-halfInput input::-moz-placeholder, .ec-numberInput input::-moz-placeholder, .ec-zipInput input::-moz-placeholder, .ec-telInput input::-moz-placeholder {
    color: #ddd;
    opacity: 1; }
  .ec-input input:-ms-input-placeholder, .ec-halfInput input:-ms-input-placeholder, .ec-numberInput input:-ms-input-placeholder, .ec-zipInput input:-ms-input-placeholder, .ec-telInput input:-ms-input-placeholder {
    color: #ddd; }
  .ec-input input::-webkit-input-placeholder, .ec-halfInput input::-webkit-input-placeholder, .ec-numberInput input::-webkit-input-placeholder, .ec-zipInput input::-webkit-input-placeholder, .ec-telInput input::-webkit-input-placeholder {
    color: #ddd; }
  .ec-input input::-ms-expand, .ec-halfInput input::-ms-expand, .ec-numberInput input::-ms-expand, .ec-zipInput input::-ms-expand, .ec-telInput input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled], .ec-input input[readonly], .ec-halfInput input[readonly], .ec-numberInput input[readonly], .ec-zipInput input[readonly], .ec-telInput input[readonly],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input {
    background-color: #eee;
    opacity: 1; }
  .ec-input input[disabled], .ec-halfInput input[disabled], .ec-numberInput input[disabled], .ec-zipInput input[disabled], .ec-telInput input[disabled],
  fieldset[disabled] .ec-input input,
  fieldset[disabled] .ec-halfInput input,
  fieldset[disabled] .ec-numberInput input,
  fieldset[disabled] .ec-zipInput input,
  fieldset[disabled] .ec-telInput input {
    cursor: not-allowed; }

.ec-input textarea, .ec-halfInput textarea, .ec-numberInput textarea, .ec-zipInput textarea, .ec-telInput textarea {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 1rem;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  height: auto;
  min-height: 8em; }
  .ec-input textarea:focus, .ec-halfInput textarea:focus, .ec-numberInput textarea:focus, .ec-zipInput textarea:focus, .ec-telInput textarea:focus {
    border-color: #3c8dbc;
    outline: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .ec-input textarea::-moz-placeholder, .ec-halfInput textarea::-moz-placeholder, .ec-numberInput textarea::-moz-placeholder, .ec-zipInput textarea::-moz-placeholder, .ec-telInput textarea::-moz-placeholder {
    color: #ddd;
    opacity: 1; }
  .ec-input textarea:-ms-input-placeholder, .ec-halfInput textarea:-ms-input-placeholder, .ec-numberInput textarea:-ms-input-placeholder, .ec-zipInput textarea:-ms-input-placeholder, .ec-telInput textarea:-ms-input-placeholder {
    color: #ddd; }
  .ec-input textarea::-webkit-input-placeholder, .ec-halfInput textarea::-webkit-input-placeholder, .ec-numberInput textarea::-webkit-input-placeholder, .ec-zipInput textarea::-webkit-input-placeholder, .ec-telInput textarea::-webkit-input-placeholder {
    color: #ddd; }
  .ec-input textarea::-ms-expand, .ec-halfInput textarea::-ms-expand, .ec-numberInput textarea::-ms-expand, .ec-zipInput textarea::-ms-expand, .ec-telInput textarea::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled], .ec-input textarea[readonly], .ec-halfInput textarea[readonly], .ec-numberInput textarea[readonly], .ec-zipInput textarea[readonly], .ec-telInput textarea[readonly],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea {
    background-color: #eee;
    opacity: 1; }
  .ec-input textarea[disabled], .ec-halfInput textarea[disabled], .ec-numberInput textarea[disabled], .ec-zipInput textarea[disabled], .ec-telInput textarea[disabled],
  fieldset[disabled] .ec-input textarea,
  fieldset[disabled] .ec-halfInput textarea,
  fieldset[disabled] .ec-numberInput textarea,
  fieldset[disabled] .ec-zipInput textarea,
  fieldset[disabled] .ec-telInput textarea {
    cursor: not-allowed; }

.ec-input + p, .ec-halfInput + p, .ec-numberInput + p, .ec-zipInput + p, .ec-telInput + p {
  margin: 0.75em 0 0; }

.ec-input + .ec-input, .ec-halfInput + .ec-input, .ec-numberInput + .ec-input, .ec-zipInput + .ec-input, .ec-telInput + .ec-input, .ec-input + .ec-halfInput, .ec-halfInput + .ec-halfInput, .ec-numberInput + .ec-halfInput, .ec-zipInput + .ec-halfInput, .ec-telInput + .ec-halfInput, .ec-input + .ec-numberInput, .ec-halfInput + .ec-numberInput, .ec-numberInput + .ec-numberInput, .ec-zipInput + .ec-numberInput, .ec-telInput + .ec-numberInput, .ec-input + .ec-zipInput, .ec-halfInput + .ec-zipInput, .ec-numberInput + .ec-zipInput, .ec-zipInput + .ec-zipInput, .ec-telInput + .ec-zipInput, .ec-input + .ec-telInput, .ec-halfInput + .ec-telInput, .ec-numberInput + .ec-telInput, .ec-zipInput + .ec-telInput, .ec-telInput + .ec-telInput {
  margin-top: 1rem; }

.ec-input.error input, .error.ec-halfInput input, .error.ec-numberInput input, .error.ec-zipInput input, .error.ec-telInput input, .ec-input.error textarea, .error.ec-halfInput textarea, .error.ec-numberInput textarea, .error.ec-zipInput textarea, .error.ec-telInput textarea {
  border-color: #aa0b00;
  background-color: #FDF1F0; }

/*
テキスト入力フォーム（左右2分割）

姓名など入力フォームが分割されている項目で使用します。

入力フォームを半分で用意したいときにも利用可能です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.1.2.html

Styleguide 3.1.2
*/
.ec-halfInput {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap; }
  .ec-halfInput > input,
  .ec-halfInput > span.customer-form {
    flex: 0 0 auto;
    width: calc( (100% - 15px ) / 2);
    margin-left: 15px; }
    .ec-halfInput > input:first-child,
    .ec-halfInput > span.customer-form:first-child {
      margin-left: 0; }
  .ec-halfInput > span.customer-form input {
    width: 100%;
    margin: 0; }
  .ec-halfInput .ec-errorMessage {
    flex: 0 0 100%; }

/*
数量ボタン

数量を表示するための小さなコンポーネントです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.1.3.html

Styleguide 3.1.3
*/
.ec-numberInput input[type='number'] {
  width: auto;
  max-width: 5em;
  text-align: center; }
  .ec-productOptions .ec-numberInput input[type='number'] {
    width: 100%; }

/*
数量ボタン（増減ボタンセット）
*/
.ec-quantityRole {
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  box-sizing: border-box; }
  .ec-quantityRole .ec-quantityRole__btn {
    width: 3.33rem;
    height: 3.33rem;
    line-height: 3.33rem;
    text-align: center;
    font-size: 1.2em;
    background-color: #f0f0f0;
    border: solid 1px #ccc;
    cursor: pointer; }
  .ec-quantityRole .ec-numberInput {
    height: 3.33rem;
    padding: 0;
    border: none; }
    .ec-quantityRole .ec-numberInput input {
      height: 3.33rem;
      border-right: none;
      border-left: none;
      width: 3.33rem;
      font-family: "Oswald"; }

/*
郵便番号フォーム

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.1.4.html

Styleguide 3.1.4
*/
.ec-zipInput input {
  display: inline-block;
  width: auto;
  max-width: 8em;
  margin-left: .5em; }

.ec-zipInput > span {
  display: inline-block; }

.ec-zipInputHelp {
  display: inline-block;
  margin-left: 1em; }
  .ec-zipInputHelp .ec-zipInputHelp__icon {
    display: none; }
  .ec-zipInputHelp a {
    font-size: 12px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    height: 2em;
    border-radius: 3px;
    background-color: #16457e;
    color: #fff;
    padding-left: .5em;
    padding-right: .5em;
    transition: .3s; }
    .ec-zipInputHelp a:before {
      font-family: "jeiafont";
      content: "\e901";
      margin-right: .5em;
      padding-right: .5em;
      border-right: solid 1px rgba(255, 255, 255, 0.3); }
    .ec-zipInputHelp a:hover {
      text-decoration: none;
      filter: brightness(1.2); }

.ec-zipInput + .ec-select, .ec-zipInput + .ec-birth {
  margin-top: 1em;
  margin-bottom: 1em; }

/*
電話番号ボタン

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.1.5.html

Styleguide 3.1.5
*/
.ec-telInput input {
  max-width: 10em; }

/*
エラーメッセージ

エラー項目にポップアップ形式で表示するタイプにしたいが、.ec-harfInputの場合に2つ目のエラーも左に出てしまう。これを制御するにはTwig側に手をいれないといけないので断念。
普通にエラー項目の下にテキスト表示するに留めます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.1.6.html

Styleguide 3.1.6
*/
.ec-errorMessage {
  font-size: 0.9rem;
  margin: .5em 0 0;
  color: #ca0000; }
  .ec-errorMessage:before {
    font-family: "jeiafont";
    font-weight: normal;
    content: "\e915";
    font-size: 20px;
    display: inline-block;
    vertical-align: -4px;
    margin-right: 3px; }

/* その他 */
/*
input[type="number"]のスピンボタンを非表示

使用箇所：
利用ポイント、商品数量入力

Styleguide 3.1.90
*/
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

/*
フォーム部品(ラジオ、チェックボックス、セレクト)

ラジオ、チェックボックス、セレクト要素の基本整形をスタイリングします。

Styleguide 3.2
*/
/*
ラジオボタン（横配置）

各要素をlabelでくくって、コーディングします。←マチガイ（ver4.0.2では、input + label の構造になっている様子（全部がそうかは未確認））<br>
input要素をlabelでくくってしまうと独自UIは表示されないので注意

ラジオボタンの独自UIはアイコンフォントを使っています。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.2.html

Styleguide 3.2.2
*/
.ec-radio, .ec-blockRadio {
  margin-top: -0.5em; }
  .ec-radio input, .ec-blockRadio input {
    opacity: 0;
    appearance: none; }
  .ec-radio input + label, .ec-blockRadio input + label {
    display: inline-block;
    margin: 0;
    font-size: 1.05rem;
    font-weight: normal;
    line-height: 1;
    margin-right: 1em;
    margin-top: 0.5em; }
    .ec-radio input + label:before, .ec-blockRadio input + label:before {
      font-family: "jeiafont";
      content: "\e90c";
      font-size: 22px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 0;
      color: #999;
      transition: .3s; }
    .ec-radio input + label span, .ec-blockRadio input + label span {
      display: inline-block; }
  .ec-radio input:checked + label:before, .ec-blockRadio input:checked + label:before {
    font-family: "jeiafont";
    content: "\e90d";
    color: #0049a3; }
  .ec-radio input:focus + label, .ec-blockRadio input:focus + label {
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-radio.error, .error.ec-blockRadio {
    background-color: #FDF1F0; }

/*
ラジオボタン(縦配置)

垂直に並ぶラジオボタンフィールドです。

※ショッピングページ「shopping/index.twig」においてはinputとlabelごとをdiv要素（ブロック要素）でくくる形でコーディングされているためCSSスタイリングせずとも縦配置になる。
一応、このブロック要素がない場合でも縦配置になるスタイリングは準備しておく。

EC-CUBE4標準において`.ec-blockRadio`が使われている箇所はshopping/confirm.twigだけの様子だが（お支払いとポイント）、確認画面ページなので表示要素は1点のみであるし、
またラジオボタンですらないので、厳密にいえばここで`.ec-blockRadio`を使うのはミスではないかと思われる。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.3.html

Styleguide 3.2.3
*/
.ec-blockRadio {
  margin-right: 0;
  margin-top: -1em; }
  .ec-blockRadio input + label {
    display: block;
    margin-right: 0;
    margin-top: 1em; }

/*
チェックボックス （横配置）

各要素をlabelでくくって、コーディングします。←マチガイ<br>
input要素をlabelでくくってしまうと独自UIは表示されないので注意

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.4.html

Styleguide 3.2.4
*/
.ec-checkbox, .ec-blockCheckbox {
  margin-top: -0.5em; }
  .ec-checkbox input, .ec-blockCheckbox input {
    opacity: 0;
    appearance: none; }
  .ec-checkbox input + label, .ec-blockCheckbox input + label {
    display: inline-block;
    margin: 0;
    font-size: 1.05rem;
    font-weight: normal;
    line-height: 1;
    margin-right: 1em;
    margin-top: 0.5em; }
    .ec-checkbox input + label:before, .ec-blockCheckbox input + label:before {
      font-family: "jeiafont";
      content: "\e90e";
      font-size: 22px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 0.2em;
      color: #999;
      transition: .3s; }
    .ec-checkbox input + label span, .ec-blockCheckbox input + label span {
      display: inline-block; }
  .ec-checkbox input:checked + label:before, .ec-blockCheckbox input:checked + label:before {
    font-family: "jeiafont";
    content: "\e90f";
    color: #0049a3; }
  .ec-checkbox input:focus + label, .ec-blockCheckbox input:focus + label {
    /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
  .ec-checkbox.error, .error.ec-blockCheckbox {
    background-color: #FDF1F0; }

/* 会員登録の利用規約チェックだけlabelがつけ方が通常のコーディングルールと違うのでCSS側で吸収 */
.ec-registerRole__actions .ec-checkbox input + label, .ec-registerRole__actions .ec-blockCheckbox input + label {
  margin-right: 0; }

/*
チェックボックス (縦配置)

垂直に並ぶチェックボックス フィールドです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.5.html

Styleguide 3.2.5
*/
.ec-blockCheckbox {
  margin-right: 0;
  margin-top: -1em; }
  .ec-blockCheckbox input + label {
    display: block;
    margin-right: 0;
    margin-top: 1em; }

/*
セレクトボックス

独自UIを施しています。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.6.html

Styleguide 3.2.6
*/
.ec-select select, .ec-birth select {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 1rem;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  width: auto;
  background-color: #f8f8f8;
  cursor: pointer;
  padding-right: calc(20px + 1em);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .ec-select select:focus, .ec-birth select:focus {
    border-color: #3c8dbc;
    outline: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .ec-select select::-moz-placeholder, .ec-birth select::-moz-placeholder {
    color: #ddd;
    opacity: 1; }
  .ec-select select:-ms-input-placeholder, .ec-birth select:-ms-input-placeholder {
    color: #ddd; }
  .ec-select select::-webkit-input-placeholder, .ec-birth select::-webkit-input-placeholder {
    color: #ddd; }
  .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-select select[disabled], .ec-birth select[disabled], .ec-select select[readonly], .ec-birth select[readonly],
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    background-color: #eee;
    opacity: 1; }
  .ec-select select[disabled], .ec-birth select[disabled],
  fieldset[disabled] .ec-select select,
  fieldset[disabled] .ec-birth select {
    cursor: not-allowed; }
  .ec-productOptions .ec-select select, .ec-productOptions .ec-birth select {
    width: 100%; }
  .ec-select select::-ms-expand, .ec-birth select::-ms-expand {
    display: none; }

.ec-select .select_ui, .ec-birth .select_ui {
  display: inline-block;
  font-weight: normal;
  margin: 0;
  position: relative; }
  .ec-productOptions .ec-select .select_ui, .ec-productOptions .ec-birth .select_ui {
    width: 100%; }
  .ec-select .select_ui:after, .ec-birth .select_ui:after {
    font-family: "jeiafont";
    content: "\e913";
    font-size: 16px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: calc(100% - 2px);
    background: #ddd;
    color: #555;
    position: absolute;
    top: 1px;
    right: 1px;
    border-radius: 0;
    pointer-events: none; }

.ec-select + .ec-select, .ec-birth + .ec-select, .ec-select + .ec-birth, .ec-birth + .ec-birth {
  margin-top: 1rem; }

.ec-select.error select, .error.ec-birth select {
  border-color: #aa0b00;
  background-color: #FDF1F0; }

.ec-select label, .ec-birth label {
  margin: 0; }
  .ec-select label + .select_ui, .ec-birth label + .select_ui {
    margin-left: .5em; }

.ec-select__delivery {
  display: block; }
  @media only screen and (min-width: 1200px) {
    .ec-select__delivery {
      display: inline-block; } }

.ec-select__time {
  display: block; }
  @media only screen and (min-width: 1200px) {
    .ec-select__time {
      display: inline-block;
      margin-left: 1rem; } }

/*
生年月日選択

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.2.7.html

Styleguide 3.2.7
*/
.ec-birth span {
  display: inline-block;
  margin: 0 .5em; }

/*
フォーム部品（ラベル、他）

フォームのラベルに関する要素を定義します。

Styleguide 3.3
*/
/*
タイトルラベル

リストレイアウトでのタイトル部分など、会員登録ページでいえば「お名前」の部分のスタイリングになります。

メモ：
フォーム周りのタイトルラベルという位置づけの印象が強いですが、EC-CUBE4では「当サイトについて」や「特商」のページでも使われており、
どちらかというと汎用的なタイトルラベルという使い方なのかもしれません。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.3.1.html

Styleguide 3.3.1
*/
.ec-label {
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  margin: 0;
  color: #222;
  letter-spacing: 0.05em; }
  @media only screen and (max-width: 767px) {
    .ec-label {
      font-weight: 500; } }
  .ec-label--en {
    font-family: 'Dynalight', serif;
    margin-left: 5px;
    font-size: 0.8rem; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-label--en {
        display: block;
        font-size: 0.8rem;
        margin-left: 0; } }
    @media only screen and (min-width: 1200px) {
      .ec-label--en {
        display: block;
        font-size: 0.8rem;
        margin-left: 0; } }

/*
必須ラベル

上記のタイトルラベルに付随する「必須」項目を示す部位になります。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/3.3.2.html

Styleguide 3.3.2
*/
.ec-required {
  display: inline-block;
  vertical-align: middle;
  font-size: 10px;
  font-weight: normal;
  line-height: 1;
  padding: 0.4em 0.4em;
  background-color: #e2251f;
  color: #fff;
  margin-left: 1em; }

/*
その他の調整

コーディングルールに則っていない（？）部位の調整。<br>
ミスなのか意図的なのかは不明ですがこれまでのフォーム構成（ルール）とは逸れている箇所があり、ミスであるならば本来はTwigを修正すべきですが、
テンプレート的にはTwigの編集は必要以外は極力しないでおきたいですので（バージョンアップの事も考慮しつつ）CSS側で吸収を試みます。

* なるべくピンポイントに働くように、他の部位に影響・汚染の無いよう心掛ける。
* もしバージョンアップでTwigの修正が施された際に悪影響のないようにしておきたい。

Styleguide 3.3.90
*/
/*
ショッピングページ「お客様情報」の変更フォーム

「都道府県」入力欄がここではテキスト入力だが、これを括る要素が.ec-selectとなっておりCSSルールから外れてしまっているので補正

Styleguide 3.3.91
*/
.ec-select .customer-address_pref input, .ec-birth .customer-address_pref input {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.5em 0.75em;
  font-size: 1rem;
  line-height: 1.3;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
  .ec-select .customer-address_pref input:focus, .ec-birth .customer-address_pref input:focus {
    border-color: #3c8dbc;
    outline: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05); }
  .ec-select .customer-address_pref input::-moz-placeholder, .ec-birth .customer-address_pref input::-moz-placeholder {
    color: #ddd;
    opacity: 1; }
  .ec-select .customer-address_pref input:-ms-input-placeholder, .ec-birth .customer-address_pref input:-ms-input-placeholder {
    color: #ddd; }
  .ec-select .customer-address_pref input::-webkit-input-placeholder, .ec-birth .customer-address_pref input::-webkit-input-placeholder {
    color: #ddd; }
  .ec-select .customer-address_pref input::-ms-expand, .ec-birth .customer-address_pref input::-ms-expand {
    border: 0;
    background-color: transparent; }
  .ec-select .customer-address_pref input[disabled], .ec-birth .customer-address_pref input[disabled], .ec-select .customer-address_pref input[readonly], .ec-birth .customer-address_pref input[readonly],
  fieldset[disabled] .ec-select .customer-address_pref input,
  fieldset[disabled] .ec-birth .customer-address_pref input {
    background-color: #eee;
    opacity: 1; }
  .ec-select .customer-address_pref input[disabled], .ec-birth .customer-address_pref input[disabled],
  fieldset[disabled] .ec-select .customer-address_pref input,
  fieldset[disabled] .ec-birth .customer-address_pref input {
    cursor: not-allowed; }

/*
問い合わせフォーム

問い合わせフォームで、.ec-zipInputHelpが、.ec-zipInputの中ではなく外に出てしまっている

Styleguide 3.3.92
*/
#page_contact .ec-zipInput {
  display: inline-block; }

#page_contact .ec-zipInputHelp + .ec-select, #page_contact .ec-zipInputHelp + .ec-birth {
  margin-top: 1em;
  margin-bottom: 1em; }

/*
アイコン・WEBフォント

EC-CUBE4ではアイコンに[Font Awesome 5](https://fontawesome.com/) を使用しています。<br>
Font Awesome 5 で利用可能なフォント群については、 Font Awesome 5 公式サイト をご確認ください。

当テンプレートではそのほか、独自のWEBフォントと[Google Material Design](https://material.io/" target="_blank">Material Design)を使用しています。

Styleguide 4.1
*/
/*
SVGアイコン

デフォルトテンプレートのアイコンは`.ec-icon`>`img`タグで使用することができます

使用箇所：
error.twig
Block/search_product.twig
Cart/index.twig
Contact/index.twig
Entry/index.twig
Mypage/change.twig
Mypage/delivery.twig
Mypage/delivery_edit.twig
Mypage/favorite.twig
Mypage/login.twig
Mypage/withdraw.twig
Mypage/withdraw_confirm.twig
Product/detail.twig
Product/list.twig
Shopping/index.twig
Shopping/login.twig
Shopping/nonmember.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple_edit.twig

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/4.2.html

Styleguide 4.2
*/
.ec-icon img {
  max-width: 80px;
  max-height: 80px; }

/*
JEIA WEBフォント

当テンプレート用に独自のWEBフォントを使用しています。

WEBフォントの読み込みは__default_frame.twig__のheadの中で行っています。

当テンプレートのWEBフォントには[Apache 2.0ライセンス](http://www.apache.org/licenses/LICENSE-2.0)の
[Google Material Design](https://material.io/" target="_blank">Material Design)のアイコンと、
[FontAwesome](https://fontawesome.com/ target="_blank")のアイコンを含めています。

[アイコンリスト](/html/template/dtJEIA4006/assets/icon/jeiafont/demo.html)

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/4.3.html

Styleguide 4.3
*/
i.jeiafont {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'jeiafont';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  vertical-align: middle; }

/* アイコン回転 */
i.rotate-90 {
  transform-origin: center center;
  transform: rotate(90deg); }

/* 以下アイコン追加があれば適宜追加 */
.jeia-cart:before {
  content: "\e900"; }

.jeia-search:before {
  content: "\e901"; }

.jeia-arrow:before {
  content: "\e902"; }

.jeia-heart:before {
  content: "\e903"; }

.jeia-heart_fill:before {
  content: "\e904"; }

.jeia-user:before {
  content: "\e905"; }

.jeia-user_fill:before {
  content: "\e906"; }

.jeia-lock:before {
  content: "\e907"; }

.jeia-lock_fill:before {
  content: "\e908"; }

.jeia-cross:before {
  content: "\e909"; }

.jeia-menu:before {
  content: "\e90a"; }

.jeia-more:before {
  content: "\e90b"; }

.jeia-radio:before {
  content: "\e90c"; }

.jeia-radio_checked:before {
  content: "\e90d"; }

.jeia-checkbox:before {
  content: "\e90e"; }

.jeia-checkbox_checked:before {
  content: "\e90f"; }

.jeia-call:before {
  content: "\e910"; }

.jeia-mail:before {
  content: "\e911"; }

.jeia-place:before {
  content: "\e912"; }

.jeia-more_angle:before {
  content: "\e913"; }

.jeia-warning:before {
  content: "\e914"; }

.jeia-error:before {
  content: "\e915"; }

.jeia-add:before {
  content: "\e916"; }

.jeia-remove:before {
  content: "\e917"; }

.jeia-clear:before {
  content: "\e918"; }

.jeia-star:before {
  content: "\e919"; }

.jeia-caret:before {
  content: "\e91a"; }

.jeia-arrow-left:before {
  content: "\e91b"; }

.jeia-arrow_b1:before {
  content: "\e91c"; }

.jeia-arrow_b2:before {
  content: "\e91d"; }

.jeia-truck:before {
  content: "\e91e"; }

.jeia-coins:before {
  content: "\e91f"; }

.jeia-creditcard:before {
  content: "\e920"; }

.jeia-twitter:before {
  content: "\e921"; }

.jeia-facebook:before {
  content: "\e922"; }

.jeia-googleplus:before {
  content: "\e923"; }

.jeia-instagram:before {
  content: "\e924"; }

.jeia-volume-mute:before {
  content: "\e925"; }

.jeia-volume-up:before {
  content: "\e926"; }

.jeia-angleup:before {
  content: "\e927"; }

/*
グリッド

画面を12分割し、グリッドレイアウトに対応するためのスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.1
*/
/*
2分割グリッド

画面２分割のグリッドです。
Bootstrap の col-sm-6 相当のグリッドを提供します。

使用箇所：
Mypage/login.twig
Product/detail.twig
Shopping/login.twig

Markup:
../styleguide/tmpl/5.1.1.html

Styleguide 5.1.1
*/
.ec-grid2 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-grid2 {
      display: flex;
      justify-content: center; } }
  .ec-grid2 .ec-grid2__cell {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid2 .ec-grid2__cell {
        width: 50%; } }
  .ec-grid2 .ec-grid2__cell2 {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid2 .ec-grid2__cell2 {
        width: 100%; } }

/*
3分割グリッド

画面３分割のグリッドです。

使用箇所：
Shopping/login.twig

Markup:
../styleguide/tmpl/5.1.2.html

Styleguide 5.1.2
*/
.ec-grid3 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-grid3 {
      display: flex;
      justify-content: center; } }
  .ec-grid3 .ec-grid3__cell {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid3 .ec-grid3__cell {
        width: 33.33333%; } }
  .ec-grid3 .ec-grid3__cell2 {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid3 .ec-grid3__cell2 {
        width: 66.66667%; } }
  .ec-grid3 .ec-grid3__cell3 {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid3 .ec-grid3__cell3 {
        width: 100%; } }

/*
4分割グリッド

画面４分割のグリッドです。

ex [※使用箇所は無し]

Markup:
../styleguide/tmpl/5.1.3.html

Styleguide 5.1.3
*/
.ec-grid4 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-grid4 {
      display: flex;
      justify-content: center; } }
  .ec-grid4 .ec-grid4__cell {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid4 .ec-grid4__cell {
        width: 25%; } }

/*
6分割グリッド

2つにまとめた cell2 や 3つをまとめた cell3 タグも使用可能です。

ex [※使用箇所は無し]

Markup:
../styleguide/tmpl/5.1.4.html

Styleguide 5.1.4
*/
.ec-grid6 {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-grid6 {
      display: flex;
      justify-content: center; } }
  .ec-grid6 .ec-grid6__cell {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid6 .ec-grid6__cell {
        width: 16.66667%; } }
  .ec-grid6 .ec-grid6__cell2 {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid6 .ec-grid6__cell2 {
        width: 33.33333%; } }
  .ec-grid6 .ec-grid6__cell3 {
    min-height: 1px; }
    @media only screen and (min-width: 1200px) {
      .ec-grid6 .ec-grid6__cell3 {
        width: 50%; } }

/*
中央寄せグリッド 10/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の10グリッドです<br>

※当テンプレートでは、この.ec-off1Gridのスタイリングはオフにしております。
EC-CUBE標準で備わっている機能は殺したくない所ですが、この.ec-off1Gridはデザイン的に外しておきたく、本来であればTwig側で調整すべきですが、
テンプレート的にTwigの編集は必要以外、極力しない方向でいきたいと考えております。

使用箇所：
Contact/index.twig
Contact/confirm.twig
Entry/index.twig
Entry/confirm.twig
Forgot/index.twig
Forgot/reset.twig
Help/about.twig
Help/agreement.twig
Help/privacy.twig
Help/tradelaw.twig
Mypage/change.twig
Mypage/delivery.twig
Mypage/delivery_edit.twig
Shopping/nonmember.twig
Shopping/shipping.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple_edit.twig

Markup:
../styleguide/tmpl/5.1.5.html

Styleguide 5.1.5
*/
/*
中央寄せグリッド 8/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の8グリッドです

使用箇所：
Mypage/change_complete.twig
Mypage/login.twig

Markup:
../styleguide/tmpl/5.1.6.html

Styleguide 5.1.6
*/
.ec-off2Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-off2Grid {
      display: flex;
      justify-content: center; } }
  .ec-off2Grid .ec-off2Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 1200px) {
      .ec-off2Grid .ec-off2Grid__cell {
        min-height: 1px; } }
  @media only screen and (min-width: 1200px) and (min-width: 1200px) {
    .ec-off2Grid .ec-off2Grid__cell {
      width: 66.66667%; } }

/*
中央寄せグリッド 6/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の6グリッドです

使用箇所：
Cart/index.twig
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/withdraw.twig
Mypage/withdraw_confirm.twig
Shopping/complete.twig
Shopping/shopping_error.twig

Markup:
../styleguide/tmpl/5.1.7.html

Styleguide 5.1.7
*/
.ec-off3Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-off3Grid {
      display: flex;
      justify-content: center; } }
  .ec-off3Grid .ec-off3Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 1200px) {
      .ec-off3Grid .ec-off3Grid__cell {
        min-height: 1px; } }
  @media only screen and (min-width: 1200px) and (min-width: 1200px) {
    .ec-off3Grid .ec-off3Grid__cell {
      width: 50%; } }

/*
中央寄せグリッド 4/12

左右にマージンを持つ、中央寄せグリッドを提供します。12分の4グリッドです

使用箇所：
error.twig
Contact/confirm.twig
Contact/complete.twig
Contact/index.twig
Entry/index.twig
Entry/activate.twig
Entry/complete.twig
Entry/confirm.twig
Forgot/index.twig
Forgot/reset.twig
Mypage/change.twig
Mypage/change_complete.twig
Mypage/delivery_edit.twig
Mypage/withdraw.twig
Mypage/withdraw_complete.twig
Mypage/withdraw_confirm.twig
Shopping/complete.twig
Shopping/complete.twig
Shopping/nonmember.twig
Shopping/shipping.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple.twig
Shopping/shipping_multiple_edit.twig
Shopping/shopping_error.twig

Markup:
../styleguide/tmpl/5.1.8.html

Styleguide 5.1.8
*/
.ec-off4Grid {
  display: block;
  margin: 0; }
  @media only screen and (min-width: 1200px) {
    .ec-off4Grid {
      display: flex;
      justify-content: center; } }
  .ec-off4Grid .ec-off4Grid__cell {
    margin: 0; }
    @media only screen and (min-width: 1200px) {
      .ec-off4Grid .ec-off4Grid__cell {
        min-height: 1px; } }
  @media only screen and (min-width: 1200px) and (min-width: 1200px) {
    .ec-off4Grid .ec-off4Grid__cell {
      width: 33.33333%; } }

/*
グリッドオプション

グリッドのセルに対して「左寄せ」「中央寄せ」「右寄せ」のオプションを付与することができます。

Styleguide 5.1.9
*/
/*
グリッドセルの左寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

ex [※使用箇所は無し]

Markup:
../styleguide/tmpl/5.1.10.html

Styleguide 5.1.10
*/
.ec-grid--left {
  justify-content: flex-start; }

/*
グリッドセルの右寄せ

.ec-gridに.ec-grid--leftを付与すると内包してるセルを左寄せにすることができます。

ex [※使用箇所は無し]

Markup:
../styleguide/tmpl/5.1.11.html

Styleguide 5.1.11
*/
.ec-grid--right {
  justify-content: flex-end; }

/*
グリッドセルの中央寄せ

.ec-gridに.ec-grid--centerを付与すると内包してるセルを中央寄せにすることができます。（※デフォルトで中央寄せなので不要かと思われる）

ex [※使用箇所は無し]

Markup:
../styleguide/tmpl/5.1.12.html

Styleguide 5.1.12
*/
.ec-grid--center {
  justify-content: center; }

/*
グリッドのタブレット時の挙動を追加

offGridを使う場面とは、メインコンテンツ幅に対して少し幅の狭いブロックでレイアウトデザインを組みたい場面と言えると思います。
（EC-CUBE標準ではメインコンテンツ自体を少し幅を狭めるorr1Gridも多用されておりますが…）
offGridはデスクトップ向けのスタイリングですのでタブレットでは適用されずフル幅になってしまいます。
ここではタブレット時もoffGridのスタイリングが加味されるよう調整しています。

Styleguide 5.1.90
*/
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-off2Grid,
  .ec-off3Grid,
  .ec-off4Grid {
    display: block;
    margin: 0; }
    .ec-off2Grid .ec-off2Grid__cell,
    .ec-off3Grid .ec-off2Grid__cell,
    .ec-off4Grid .ec-off2Grid__cell, .ec-off2Grid
    .ec-off3Grid__cell,
    .ec-off3Grid
    .ec-off3Grid__cell,
    .ec-off4Grid
    .ec-off3Grid__cell, .ec-off2Grid
    .ec-off4Grid__cell,
    .ec-off3Grid
    .ec-off4Grid__cell,
    .ec-off4Grid
    .ec-off4Grid__cell {
      width: 450px;
      margin: 0 auto; } }

/*
レイアウト

様々なレイアウトを変更する為のスタイル群です。

Styleguide 5.2
*/
/*
画像レイアウト

画像とテキストを水平に並べるレイアウトです。

使用箇所：
Mypage/history.twig
Mypage/index.twig
Shopping/confirm.twig
Shopping/index.twig

Markup:
../styleguide/tmpl/5.2.1.html

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 5.2.1
*/
.ec-imageGrid {
  display: flex;
  width: 100%; }
  .ec-imageGrid .ec-imageGrid__img {
    flex: 0 0 auto;
    width: 22vw;
    max-width: 120px; }
    .ec-imageGrid .ec-imageGrid__img img {
      width: 100%; }
  .ec-imageGrid .ec-imageGrid__content {
    flex: 1 1 auto;
    padding-left: 1em; }
    .ec-imageGrid .ec-imageGrid__content p {
      font-size: 0.9em;
      margin: 0; }
      .ec-imageGrid .ec-imageGrid__content p:first-child {
        font-weight: 700; }
      .ec-imageGrid .ec-imageGrid__content p span {
        display: inline-block;
        margin-left: 1em; }

/*
ログイン

主にログインフォームのスタイルを表示します。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 6.1
*/
/*
ログインフォーム

ログインフォームを表示します。

使用箇所：
Mypage/login.twig
Shopping/login.twig

sg-wrapper:
<div class="ec-role">
  <div class="ec-off2Grid">
    <div class="ec-off2Grid__cell">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/6.1.1.html

Styleguide 6.1.1
*/
.ec-login .ec-login__icon .ec-icon {
  margin-bottom: 10px;
  text-align: center; }
  @media only screen and (min-width: 1200px) {
    .ec-login .ec-login__icon .ec-icon {
      text-align: left; } }
  .ec-login .ec-login__icon .ec-icon img {
    width: 50px;
    height: 50px; }

.ec-login .ec-login__input {
  margin-bottom: 10px; }
  .ec-login .ec-login__input input + input {
    margin-top: 1em; }
  .ec-login .ec-login__input .ec-checkbox, .ec-login .ec-login__input .ec-blockCheckbox {
    margin-top: 0.5em; }
    .ec-login .ec-login__input .ec-checkbox label, .ec-login .ec-login__input .ec-blockCheckbox label {
      font-size: 0.9rem; }
  @media only screen and (min-width: 1200px) {
    .ec-login .ec-login__input {
      margin-bottom: 20px; } }

.ec-login .ec-login__link {
  margin-top: .5em; }
  @media only screen and (min-width: 1200px) {
    .ec-login .ec-login__link {
      margin-top: 0;
      margin-left: 1.5em;
      margin-bottom: 0.5em; } }
  .ec-login .ec-login__link a {
    color: inherit;
    padding-top: 2px;
    padding-bottom: 2px;
    position: relative;
    text-decoration: none; }
    .ec-login .ec-login__link a:hover {
      text-decoration: none !important; }
    .ec-login .ec-login__link a:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #000;
      position: absolute;
      bottom: 0;
      left: 0;
      transform-origin: left bottom;
      transform: scaleX(0);
      transition: transform .3s; }
    .ec-login .ec-login__link a:hover:after {
      transform: scaleX(1); }
    .ec-login .ec-login__link a:hover {
      text-decoration: underline; }

/*
ゲスト購入

ゲスト購入ボタンとそのフォームを表示します。

使用箇所：
Shopping/login.twig

Markup:
../styleguide/tmpl/6.1.2.html

Styleguide 6.1.2
*/
.ec-guest {
  display: table;
  width: 100%;
  margin-top: 20px;
  padding: 1.5em;
  box-sizing: border-box;
  background: #f0f0f0; }
  @media only screen and (min-width: 1200px) {
    .ec-guest {
      width: auto;
      margin: 0;
      height: 100%;
      margin-left: 40px; } }
  .ec-guest .ec-guest__inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .ec-guest .ec-guest__inner p {
      margin-bottom: 1em; }
  .ec-guest .ec-guest__actions {
    display: block;
    text-align: center; }

/*
商品掲載アイテムバナー？（使用箇所なし）

トップページに商品掲載するスタイルガイド群です。

Styleguide 7.1
*/
/*
検索・一覧表示

検索欄や、一覧表示に使用するスタイル群です。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.2
*/
/*
ページャ

ページャは商品一覧ページ、マイページ注文履歴、お気に入りページで使用されています。

使用箇所：
pager.twig（ブロック）

pager.twigブロックは以下のページからincludeされます。
* Mypage\favorite.twig
* Mypage\index.twig
* Product\list.twig

Markup:
../styleguide/tmpl/7.2.2.html

Styleguide 7.2.2
*/
.ec-pager {
  list-style: none;
  margin: 30px 0 0;
  padding: 0;
  font-family: "Oswald";
  font-size: 1rem;
  display: flex;
  justify-content: center; }
  @media only screen and (min-width: 1200px) {
    .ec-pager {
      margin-top: 60px; } }
  .ec-pager .ec-pager__item {
    margin: 0 3px; }
    .ec-pager .ec-pager__item a {
      display: block;
      min-width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      padding: 0 0.75em;
      background-color: #c8c8c8;
      color: #fff;
      transition: .2s; }
      .ec-pager .ec-pager__item a:hover {
        text-decoration: none;
        background-color: #000; }
    .ec-pager .ec-pager__item.is-active a {
      background-color: #000000;
      pointer-events: none; }
    .ec-pager .ec-pager__item.item--prev a {
      padding-left: 0.3em; }
      .ec-pager .ec-pager__item.item--prev a:before {
        font-family: "jeiafont";
        content: "\e91b";
        margin-right: 0.2em; }
    .ec-pager .ec-pager__item.item--next a {
      padding-right: 0.3em; }
      .ec-pager .ec-pager__item.item--next a:after {
        font-family: "jeiafont";
        content: "\e902";
        margin-left: 0.2em; }

/*
カート

ショッピングカートに関するスタイルです。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 7.3
*/
/*
プログレス表示

購入完了までの手順や、現在の進捗状況をプログレス表示します。

使用箇所：
Cart/index.twig
Shopping/index.twig
Shopping/confirm.twig
Shopping/complete.twig
Shopping/nonmember.twig

Markup:
../styleguide/tmpl/7.3.1.html

Styleguide 7.3.1
*/
.ec-progress {
  list-style: none;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin: 0 auto;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .ec-progress {
      width: calc(100% + 20px);
      transform: translateX(-10px); } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-progress {
      max-width: 62.5vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-progress {
      max-width: 600px; } }
  .ec-progress .ec-progress__item {
    font-family: "Poppins";
    font-size: 1.3rem;
    display: table-cell;
    text-align: center;
    position: relative;
    z-index: 10; }
    @media only screen and (max-width: 767px) {
      .ec-progress .ec-progress__item {
        font-size: 1.2rem; } }
    .ec-progress .ec-progress__item:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #ddd;
      position: absolute;
      top: 1em;
      left: 50%;
      transform: translate(0, -50%);
      z-index: -1; }
    .ec-progress .ec-progress__item:last-child:after {
      display: none; }
  .ec-progress .ec-progress__number {
    background-color: #c8c8c8;
    color: #fff;
    display: inline-block;
    line-height: 2em;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 0.25em; }
  .ec-progress .ec-progress__label {
    font-family: "Noto Sans JP", sans-serif;
    font-size: 0.8rem;
    padding: 0;
    line-height: 1.2;
    white-space: nowrap; }
    @media only screen and (max-width: 767px) {
      .ec-progress .ec-progress__label {
        font-size: 0.7rem; } }
  .ec-progress .is-complete .ec-progress__number {
    background-color: #000000; }
  .ec-progress .is-complete .ec-progress__label {
    color: #000000; }

/*
EC-CUBEオリジナルの「カートナビゲーション」Styleguide 7.3.5～7.3.7は、Styleguide 11.2.8～11.2.10へ移動
*/
/*
総計

会計時の合計金額、総計を表示します。

使用箇所：
Mypage/history.twig
Shopping/index.twig
Shopping/confirm.twig

Markup:
../styleguide/tmpl/7.3.8.html

Styleguide 7.3.8
*/
.ec-totalBox {
  background-color: #fbfbfb;
  border: solid 1px #ddd;
  padding: 1.5em;
  padding-top: 1em;
  /* 各項目 */
  /* 合計額 */
  /* 合計額：価格 */
  /* 合計額：「税込み」ラベル */
  /* お支払い合計 */
  /* 税率表示 */
  /* ポイント利用 */
  /* ボタンエリア */ }
  @media only screen and (max-width: 767px) {
    .ec-totalBox {
      padding: 0.5em 1em 1em; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-totalBox {
      padding: 0.5em 1em 1em; } }
  .ec-totalBox .ec-totalBox__spec {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 0.5em 0.25em;
    margin: 0;
    line-height: 1; }
    .ec-totalBox .ec-totalBox__spec dt {
      font-weight: normal; }
      .ec-totalBox .ec-totalBox__spec dt .ec-font-bold {
        font-weight: normal; }
    .ec-totalBox .ec-totalBox__spec dd {
      text-align: right; }
  .ec-totalBox .ec-totalBox__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #eee;
    padding: 0.5em 0.25em;
    line-height: 1;
    font-weight: 500;
    margin: 1em 0 1.5em; }
  .ec-totalBox .ec-totalBox__price {
    flex: 1 1 auto;
    font-size: 1.6rem;
    color: #000;
    text-align: right;
    margin-bottom: -3px; }
  .ec-totalBox .ec-totalBox__taxLabel {
    margin-left: 5px;
    font-size: 0.85rem;
    font-weight: normal; }
  .ec-totalBox .ec-totalBox__paymentTotal {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid #eee;
    padding: 0.5em 0.25em;
    line-height: 1;
    font-weight: 500;
    margin: 1em 0 1.5em; }
    .ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__price,
    .ec-totalBox .ec-totalBox__paymentTotal .ec-totalBox__taxLabel {
      color: #e2251f; }
  .ec-totalBox .ec-totalBox__taxRate {
    display: flex;
    justify-content: flex-end;
    margin-bottom: .5em;
    font-size: 0.85rem; }
    .ec-totalBox .ec-totalBox__taxRate dt {
      font-weight: normal; }
  .ec-totalBox .ec-totalBox__btn {
    margin-top: 2em; }
    .ec-totalBox .ec-totalBox__btn .ec-blockBtn--action {
      margin-bottom: 10px; }

/*
お知らせ（未使用）

新着情報やバナーなどの掲載項目を紹介していきます。

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.1
*/
/*
バナー

※定義なし

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 8.2
*/
/*
マイページ

マイページで利用するためのスタイルガイド群です。

メモ：
マイページのほぼすべてのページ（login.twig, navi.twig以外）において、一番外枠に「ec-layoutRole__main」要素が挿入されています。
「ec-layoutRole__main」は、__default_frame.twig__が出力するフレームですので、「ec-layoutRole__main」が2重になっています。
レイアウトには全く影響はないのでスルーしておいてもいいとは思いますが、一応…

「ec-layoutRole__main」は1カラムレイアウト時に出力されるフレームで、2～3カラム時は別のフレームが出力されます。
2～3カラム時は、そのメインカラムの中で1カラム用「ec-layoutRole__main」が入る形になりますが、デザインレイアウト上の問題はありません。
気になる場合はマイページに挿入された「ec-layoutRole__main」を削除するといいと思います。（時期バージョンで解消希望）

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 9.1
*/
/*
マイページ メニューナビ

マイページで表示するメニューナビです。

スマホ時はこのメニューナビを非表示にしています。
代わりに、MENUボタンを配置しそのボタンによりドロワーをOPEN、ドロワー内にマイページのメニューが含まれています。

※スマホ用MENUボタンはjsで生成して挿入するようにしていますが、navi.twigにHTML記述しておいてもいいかもしれません。

使用箇所：
Mypage/navi.twig

Markup:
../styleguide/tmpl/9.1.1.html

Styleguide 9.1.1
*/
.ec-navlistRole {
  font-size: 1rem;
  margin-bottom: 1em; }
  .ec-navlistRole .ec-navlistRole__navlist {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: .933rem;
    display: flex;
    border-bottom: 1px solid #eee;
    padding-bottom: 1em;
    margin-bottom: 1em; }
  .ec-navlistRole .ec-navlistRole__item {
    property: 27.5%, 72.5%;
    border-left: solid 1px transparent;
    border-image-width: 0 0 0 1px;
    border-image: linear-gradient(to top, transparent 27.5%, #ccc 27.5%, #ccc 72.5%, transparent 72.5%);
    border-image-slice: 1;
    transition: .2s; }
    .ec-navlistRole .ec-navlistRole__item:first-child, .ec-navlistRole .ec-navlistRole__item:hover,
    .ec-navlistRole .ec-navlistRole__item:hover + li, .ec-navlistRole .ec-navlistRole__item.active,
    .ec-navlistRole .ec-navlistRole__item.active + li {
      border-image: linear-gradient(to top, transparent 0%, transparent 100%); }
    .ec-navlistRole .ec-navlistRole__item a {
      display: block;
      color: inherit;
      padding: .65em 1em;
      text-align: center;
      transition: .3s; }
      .ec-navlistRole .ec-navlistRole__item a:hover {
        text-decoration: none;
        background-color: #eee; }
    .ec-navlistRole .ec-navlistRole__item.active a {
      background-color: #333;
      color: #fff; }
  .ec-navlistRole .mypageMenuBtn {
    display: none; }
  @media only screen and (max-width: 767px) {
    .ec-navlistRole {
      position: relative; }
      .ec-navlistRole .ec-navlistRole__navlist {
        display: none; }
      .ec-navlistRole .mypageMenuBtn {
        display: block;
        font-size: 0.8rem;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        padding-left: .5em; }
        .ec-navlistRole .mypageMenuBtn i {
          display: inline-block;
          vertical-align: -0.18em;
          font-size: 1.4em;
          margin-right: 3px;
          opacity: 0.6; } }

/*
Welcome メッセージ

マイページで表示するログイン名の表示コンポーネントです。

使用箇所：
Mypage/navi.twig

Markup:
../styleguide/tmpl/9.1.3.html

Styleguide 9.1.3
*/
.ec-welcomeMsg {
  margin-bottom: 2em;
  margin-left: -1em;
  font-size: .933rem; }
  @media only screen and (min-width: 1200px) {
    .ec-welcomeMsg {
      margin-bottom: 60px; } }
  .ec-welcomeMsg p {
    display: inline-block;
    margin: 0;
    margin-left: 1em; }

/*
マイページ：注文履歴一覧

→ _16.1.history.scss
*/
/*
マイページ：お気に入り一覧

使用箇所：
Mypage/favorite.twig

Markup:
../styleguide/tmpl/9.1.4.html

Styleguide 9.1.4
*/
.ec-favoriteRole .ec-favoriteRole__header {
  margin-bottom: 1em; }

.ec-favoriteRole .ec-favoriteRole__itemList {
  list-style: none;
  margin: 0;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .ec-favoriteRole .ec-favoriteRole__itemList {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -20px; }
      .ec-favoriteRole .ec-favoriteRole__itemList > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 10px ) / 2);
        margin-left: 10px;
        margin-top: 20px; }
        .ec-favoriteRole .ec-favoriteRole__itemList > li:nth-child(2n+1) {
          margin-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-favoriteRole .ec-favoriteRole__itemList {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -20px; }
      .ec-favoriteRole .ec-favoriteRole__itemList > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 60px ) / 4);
        margin-left: 20px;
        margin-top: 20px; }
        .ec-favoriteRole .ec-favoriteRole__itemList > li:nth-child(4n+1) {
          margin-left: 0; } }
  @media only screen and (min-width: 1200px) {
    .ec-favoriteRole .ec-favoriteRole__itemList {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -20px; }
      .ec-favoriteRole .ec-favoriteRole__itemList > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 120px ) / 5);
        margin-left: 30px;
        margin-top: 20px; }
        .ec-favoriteRole .ec-favoriteRole__itemList > li:nth-child(5n+1) {
          margin-left: 0; } }

.ec-favoriteRole .ec-favoriteRole__item {
  position: relative; }
  .ec-favoriteRole .ec-favoriteRole__item .ec-closeBtn--circle {
    position: absolute;
    right: 7px;
    top: 7px;
    z-index: 9; }
  .ec-favoriteRole .ec-favoriteRole__item-image {
    margin: 0; }
    .ec-favoriteRole .ec-favoriteRole__item-image img {
      width: 100%;
      height: auto; }

.ec-favoriteRole .ec-favoriteRole__itemThumb {
  display: block;
  display: block;
  overflow: hidden;
  /* HTML構造によってはIE11でちらつく事があるので保険 */ }
  .ec-favoriteRole .ec-favoriteRole__itemThumb * {
    overflow: hidden; }
  .ec-favoriteRole .ec-favoriteRole__itemThumb img {
    transition: .6s; }
  .ec-favoriteRole .ec-favoriteRole__itemThumb:hover img {
    transform: scale(1.1);
    filter: brightness(1.05); }

.ec-favoriteRole .ec-favoriteRole__itemTitle {
  margin: 0;
  margin-top: .5em;
  font-weight: 700; }

.ec-favoriteRole .ec-favoriteRole__itemPrice {
  margin: 0; }

/*
レイアウトフレーム

管理画面のレイアウト管理のブロック配置をレイアウトするスタイリングです。

「.ec-layoutRole」下層の各レイアウトフレームについて定義しています。

* .ec-layoutRole
* .ec-layoutRole\_\_contentTop
* .ec-layoutRole\_\_contents
* .ec-layoutRole\_\_main
* .ec-layoutRole\_\_mainWithColumn
* .ec-layoutRole\_\_mainBetweenColumn
* .ec-layoutRole\_\_left
* .ec-layoutRole\_\_right

Styleguide 11.1
*/
.ec-layoutRole {
  width: 100%;
  transition: transform 0.3s;
  background: transparent; }
  .ec-layoutRole .ec-layoutRole__contentTop {
    padding: 0; }
  .ec-layoutRole .ec-layoutRole__contents {
    width: 100%; }
  .ec-layoutRole .ec-layoutRole__main {
    width: 100%; }

/*
レイアウトフレーム（2～3カラム）

当テンプレートはTOPページと商品一覧ページのみ2カラムレイアウトとなっています。
その他のページは1カラムレイアウトです。

Styleguide 11.1.1
*/
@media only screen and (max-width: 767px) {
  .ec-layoutRole .ec-layoutRole__contents {
    display: flex;
    flex-flow: row wrap; }
  .ec-layoutRole .ec-layoutRole__contentBottom {
    order: 1;
    width: 100%;
    max-width: 100%; }
  .ec-layoutRole .ec-layoutRole__mainWithColumn,
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    order: 1;
    width: 100%;
    max-width: 100%; }
  .ec-layoutRole .ec-layoutRole__left,
  .ec-layoutRole .ec-layoutRole__right {
    order: 2;
    width: 100%;
    max-width: 100%;
    margin-top: 3.125vw;
    padding-left: 3.125vw;
    padding-right: 3.125vw; }
  .ec-layoutRole .ec-layoutRole__right {
    order: 3; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-layoutRole .ec-layoutRole__contents {
    display: flex;
    flex-flow: row wrap; }
  .ec-layoutRole .ec-layoutRole__contentBottom {
    order: 1;
    width: 100%;
    max-width: 100%; }
  .ec-layoutRole .ec-layoutRole__mainWithColumn,
  .ec-layoutRole .ec-layoutRole__mainBetweenColumn {
    order: 1;
    width: 100%;
    max-width: 100%; }
  .ec-layoutRole .ec-layoutRole__left,
  .ec-layoutRole .ec-layoutRole__right {
    order: 2;
    width: 100%;
    max-width: 100%;
    margin-top: 3.906vw;
    padding-left: 3.906vw;
    padding-right: 3.906vw; }
  .ec-layoutRole .ec-layoutRole__right {
    order: 3; } }

@media only screen and (min-width: 1200px) {
  .ec-layoutRole {
    /*
    & &__mainBetweenColumn {
      flex: 0 0 50%;
      order: 2;
      max-width: 50%;// for IE11
      margin-left: 30px;
      margin-right: 30px;
    }

    & &__right {
      order: 3;
      flex: 1 1 auto;
    }
    */ }
    .ec-layoutRole .ec-layoutRole__contents {
      /*
       * TOPページと商品一覧ページは2カラムレイアウトなので、max-widthと左右余白をスタイリングします。
       * 他のページは1カラムレイアウトなので影響を与えないようにします。他のページではこの.ec-rayoutRole__contentsの中に含まれる各コンテナ自体が左右余白を持っているため2重余白にならないようにします。
       * 補足ですが、EC-CUBE本来だと.ec-rayoutRole__contentsにmax-widthが定義されていますが、テンプレートではブラウザのフル幅で背景を敷いたデザインも可能とするため、
       * .ec-layoutRole__contents側のmax-widthは外しその中の各コンテナでmax-widthと余白を持たせ必要に応じて柔軟にmax-widthが解除できるようにしています。
       */ }
      body#page_homepage .ec-layoutRole .ec-layoutRole__contents,
      body#page_product_list .ec-layoutRole .ec-layoutRole__contents,
      .ec-wp-content .ec-layoutRole .ec-layoutRole__contents {
        display: flex;
        flex-flow: row;
        max-width: 1480px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: content-box; }
    .ec-layoutRole .ec-layoutRole__contentBottom {
      max-width: 1540px;
      width: auto;
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px;
      box-sizing: content-box;
      position: relative;
      margin-top: 80px;
      border-top: 1px solid transparent; }
      .ec-layoutRole .ec-layoutRole__contentBottom:before {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 60px);
        height: 1px;
        left: 30px;
        top: 0;
        background-color: #eee; }
    .ec-layoutRole .ec-layoutRole__mainWithColumn {
      flex: 0 0 75%;
      max-width: 75%;
      order: 2;
      margin-left: 30px;
      /* flex-boxにおいてslickを使う場合に表示が崩れる対策 */
      min-height: 0;
      min-width: 0; }
    .ec-layoutRole .ec-layoutRole__left {
      flex: 0 0 25%;
      max-width: 25%;
      order: 1;
      /* flex-boxにおいてslickを使う場合に表示が崩れる対策 */
      min-height: 0;
      min-width: 0; } }

/*
汎用コンテナ

汎用的なコンテナブロックです。

mixin「コンテナ」を@includeしています。
mixin「コンテナ」は、コンテナ自体のmax-width、左右余白などブロックスタイルの共通スタイリングを施しています。
各セクションの左右余白の統一感はコンテナによってもたらされます。

背景色をブラウザ幅フルに敷く場合は「max-width:100%;」上書きしておいて、コンテナの中にinner的要素を設けてそちらで改めてmax-widthを指定します。

Styleguide 11.1.2
*/
.ec-role {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-role {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-role {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-role {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-role,
      .ec-layoutRole__mainBetweenColumn .ec-role {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-role {
      margin-top: 9.375vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-role {
      margin-top: 11.718vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-role {
      margin-top: 80px; } }

/*
マイページコンテナ

マイページ専用のコンテナブロックです。

※当テンプレートでは汎用コンテナと同一です。

Styleguide 11.1.3
*/
.ec-mypageRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-mypageRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-mypageRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-mypageRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-mypageRole,
      .ec-layoutRole__mainBetweenColumn .ec-mypageRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

/*
その他コンテナ

その都度、セクションごとに「@include container;」してコンテナ化させています。
上記、マイページコンテナもマイページ定義のscss側に移動したほうがいいかとも思います。

Styleguide 11.1.4
*/
/*
ヘッダー＆ドロワー

Styleguide 11.2
*/
/*
ヘッダーフレーム構造

まずはヘッダーのフレーム構造について定義しています。
ヘッダーは横2列のフレーム構造になっております。

sg-wrapper:
<div class="ec-layoutRole__header">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/11.2.2.html

Styleguide 11.2.2
*/
.ec-layoutRole__header {
  background-color: #ffffff; }

/* .ec-headerRole フレーム構造 */
.ec-headerRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 1480px;
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 767px) {
    .ec-headerRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-headerRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-headerRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-headerRole,
      .ec-layoutRole__mainBetweenColumn .ec-headerRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-headerRole {
      height: 45px;
      padding: 0; }
      .ec-headerRole .ec-headerRole__left {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start; }
      .ec-headerRole .ec-headerRole__right {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-headerRole {
      height: 50px;
      padding: 0; }
      .ec-headerRole .ec-headerRole__left {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start; }
      .ec-headerRole .ec-headerRole__right {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px; } }
  @media only screen and (min-width: 1200px) {
    .ec-headerRole {
      height: 120px; }
      .ec-headerRole .ec-headerRole__left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-right: 20px; }
      .ec-headerRole .ec-headerRole__right {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center; } }

/*
ヘッダーナビ

ヘッダーナビは、ヘッダー左フレーム、ロゴ横に配置されます。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__center">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.3.html

Styleguide 11.2.3
*/
.ec-headerNav {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-size: 14px;
  color: #444;
  margin-left: 40px; }
  @media only screen and (max-width: 767px) {
    .ec-headerNav {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-headerNav {
      display: none; } }
  .ec-headerNav__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    /* サブメニュー枠組み */ }
    .ec-headerNav__item:last-child {
      margin-right: 0;
      margin-right: 0; }
    .ec-headerNav__item > a {
      display: block;
      color: inherit;
      padding-top: 2px;
      padding-bottom: 2px;
      position: relative;
      text-decoration: none; }
      .ec-headerNav__item > a:hover {
        text-decoration: none !important; }
      .ec-headerNav__item > a:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #444;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: left bottom;
        transform: scaleX(0);
        transition: transform .3s; }
      .ec-headerNav__item > a:hover:after {
        transform: scaleX(1); }
    .ec-headerNav__item.has-submenu {
      position: relative; }
      .ec-headerNav__item.has-submenu .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
        min-width: 300px;
        padding-top: 14px;
        /*くちばし分の余白*/
        pointer-events: none;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transform: translate3d(0, -15px, 0);
        transition: .2s; }
        .ec-headerNav__item.has-submenu .submenu__body {
          background-color: #fff;
          color: #000;
          padding: 20px;
          filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
          position: relative;
          /* くちばし */ }
          .ec-headerNav__item.has-submenu .submenu__body:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: 100%;
            left: 15px;
            border: solid 10px transparent;
            border-bottom-color: #fff; }
      .ec-headerNav__item.has-submenu:hover .submenu {
        visibility: visible;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        pointer-events: auto; }

/* サブメニュー内容（カテゴリツリー） */
.ec-categoryTree {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .ec-categoryTree li {
    margin: 0;
    padding: 0;
    border-bottom: solid 1px #eee;
    white-space: nowrap;
    position: relative; }
    .ec-categoryTree li a {
      display: block;
      padding: 0.4em 0;
      transition: .2s;
      color: inherit; }
      .ec-categoryTree li a:hover {
        text-decoration: none;
        background-color: #f0f1f2;
        padding-left: 3px; }
    .ec-categoryTree li ul.child {
      margin: 0;
      padding: 0;
      list-style-type: none;
      min-width: 10em;
      position: absolute;
      top: -10px;
      left: calc(100% - .5em);
      background-color: #fff;
      color: inherit;
      padding: 20px;
      z-index: 9;
      filter: drop-shadow(-5px 0 5px rgba(0, 0, 0, 0.1));
      visibility: hidden;
      opacity: 0;
      transition: .2s; }
    .ec-categoryTree li:hover > ul.child {
      visibility: visible;
      opacity: 1; }

/*
ドロワー開閉ボタン

ヘッダー左枠にドロワー開閉ボタンが格納されます。
ドロワー開閉ボタンはスマホ・タブレット時に表示されます。

Markup:
../styleguide/tmpl/11.2.4.html

Styleguide 11.2.4
*/
/* ドロワーボタン（サイドメニュー開閉ボタン） */
.ec-drawerButton {
  display: block;
  cursor: pointer;
  position: relative; }
  @media only screen and (max-width: 767px) {
    .ec-drawerButton {
      width: 45px;
      height: 45px;
      padding: 10px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-drawerButton {
      width: 50px;
      height: 50px;
      padding: 12px; } }
  @media only screen and (min-width: 1200px) {
    .ec-drawerButton {
      display: none; } }
  .ec-drawerButton .burger {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #666;
    position: relative;
    top: 50%;
    text-indent: -9999px;
    transition: .3s;
    /* ドロワー（サイドメニュー）表示時 */ }
    .ec-drawerButton .burger:before, .ec-drawerButton .burger:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #666;
      position: absolute;
      left: 0;
      transition: .3s; }
    .ec-drawerButton .burger:before {
      top: -8px; }
    .ec-drawerButton .burger:after {
      bottom: -8px; }
    body.have_curtain .ec-drawerButton .burger {
      background-color: transparent; }
      body.have_curtain .ec-drawerButton .burger:before {
        top: 0;
        transform: rotate(225deg); }
      body.have_curtain .ec-drawerButton .burger:after {
        bottom: 0;
        transform: rotate(135deg); }

/*
ヘッダータイトル

ヘッダー左枠にヘッダータイトルが格納されます。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__left">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.5.html

Styleguide 11.2.5
*/
.ec-headerTitle {
  display: block;
  /*
   * デスクトップ時はページスクロールされるとヘッダー高さを縮小させますので、縮小に伴い、
   * ヘッダーロゴ画像が連動して縮小されるようにスタイリングします。
   * ロゴ画像自体の高さ（Sass変数）をmax-heightに設定しておくことでロゴ画像が引き延ばされて大きくならないようにしています。
   *
   * ロゴ画像のデザインにもよりますが、モバイル時のロゴの収まりも優先して画像生成して、デスクトップ時はそれに合わせて調整（上下中央配置）できた方がいいと思います。
   */ }
  .ec-headerTitle a {
    transition: .3s; }
    .ec-headerTitle a:hover {
      opacity: 0.8; }
  @media only screen and (max-width: 767px) {
    .ec-headerTitle {
      height: 100%;
      max-width: 92px; }
      .ec-headerTitle a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-headerTitle {
      height: 100%;
      max-width: 118px; }
      .ec-headerTitle a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        width: 100%; } }
  @media only screen and (min-width: 1200px) {
    .ec-headerTitle {
      height: 100%;
      max-width: 210px;
      transition: 0.6s; }
      .scrolled .ec-headerTitle {
        max-width: 130px; }
      .ec-headerTitle a {
        display: flex;
        height: 100%;
        justify-content: flex-start;
        align-items: center; } }

/*
ヘッダーメニュー（ユーザメニュー）

ヘッダーメニューは、ヘッダー右枠フレームに配置されます。<br>
会員登録、ログインや、商品検索、カートといったユーザメニューが含まれます。<br>
またマウスホバーでボタンガイド的なポップアップを表示させる事もできます。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.6.html

Styleguide 11.2.6
*/
.ec-headerMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: #444;
  width: 100%; }
  .ec-headerMenu .ec-headerMenu__item {
    padding: 0;
    /* スマホ時に非表示とするボタン */
    /* 最後のitemは右余白を除去 */ }
    .ec-headerMenu .ec-headerMenu__item > a {
      display: block;
      color: inherit;
      line-height: 1;
      padding: 7px 7px;
      border-radius: 18px;
      border: solid 1px transparent;
      transition: .2s; }
      .ec-headerMenu .ec-headerMenu__item > a:hover {
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.07); }
    @media only screen and (max-width: 767px) {
      .ec-headerMenu .ec-headerMenu__item.hidden_mobile {
        display: none; } }
    .ec-headerMenu .ec-headerMenu__item:last-child {
      padding-right: 0; }
  .ec-headerMenu .ec-headerMenu__itemIcon {
    font-size: 20px; }
  .ec-headerMenu .ec-headerMenu__itemText {
    display: none; }
    @media only screen and (max-width: 767px) {
      .ec-headerMenu .ec-headerMenu__itemText {
        display: none; } }

/* ポップアップ */
.ec-headerMenu .ec-headerMenu__item.has-popup {
  position: relative; }
  .ec-headerMenu .ec-headerMenu__item.has-popup:hover .ec-headerMenu__itemPopup {
    visibility: visible;
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
    pointer-events: auto; }

.ec-headerMenu .ec-headerMenu__itemPopup {
  position: absolute;
  top: 100%;
  left: 50%;
  width: auto;
  min-width: 200px;
  padding-top: 15px;
  /*くちばし分の余白*/
  pointer-events: none;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(-50%, -15px, 0);
  transition: .2s;
  /* ポップアップ内のブロックボタン（ログアウトボタン）.ec-blockBtnを上書き調整します */ }
  .ec-headerMenu .ec-headerMenu__itemPopup .itemPopupInner {
    background-color: #fff;
    color: #000;
    padding: 20px;
    text-align: center;
    white-space: nowrap;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
    position: relative;
    /* くちばし */ }
    .ec-headerMenu .ec-headerMenu__itemPopup .itemPopupInner:after {
      content: "";
      width: 0;
      height: 0;
      border: solid 10px transparent;
      border-bottom-color: #fff;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%); }
  @media only screen and (max-width: 767px) {
    .ec-headerMenu .ec-headerMenu__itemPopup {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-headerMenu .ec-headerMenu__itemPopup {
      display: none; } }
  .ec-headerMenu .ec-headerMenu__itemPopup .ec-blockBtn {
    font-size: inherit;
    height: 2.7em;
    line-height: 2.7em; }

/*
商品検索ボックス

ヘッダーメニューにある虫眼鏡ボタンをクリックする事で表示される商品検索ボックスのコンポーネントです。

※当テンプレートでは商品検索ボックスはヘッダーのみに実装する考えです。モバイル時でも検索ボックスの開閉ボタンがヘッダーにあります。

一応、ドロワー内にも「商品検索」ブロックは設置可能です（最低限のスタイリングはしています）。

Markup:
../styleguide/tmpl/11.2.7.html

Styleguide 11.2.7
*/
.ec-headerSearch {
  /* 検索ボックス開閉ボタン */
  /* 検索ボックス全体枠 */
  /* 検索ボックス：カテゴリ&入力欄 レイアウト枠 */
  /* 検索ボックス：カテゴリ選択 */
  /* 検索ボックス：入力欄 */
  /* 検索ボタン */ }
  @media only screen and (min-width: 1200px) {
    .ec-headerSearch {
      flex: 1; } }
  .ec-headerSearch .ec-headerSearch__body {
    background-color: #ffffff;
    color: #444;
    padding: 10px;
    padding-top: 0; }
  .ec-headerSearch .ec-headerSearch__wrap {
    display: flex;
    align-items: center;
    height: 36px;
    border-radius: 18px;
    border: solid 1px rgba(0, 0, 0, 0.4);
    padding: 4px;
    overflow: hidden; }
  .ec-headerSearch .ec-headerSearch__category {
    border-right: solid 1px rgba(0, 0, 0, 0.4); }
    .ec-headerSearch .ec-headerSearch__category .ec-select, .ec-headerSearch .ec-headerSearch__category .ec-birth {
      margin: 0; }
      .ec-headerSearch .ec-headerSearch__category .ec-select .select_ui:after, .ec-headerSearch .ec-headerSearch__category .ec-birth .select_ui:after {
        background-color: transparent;
        content: "\e902";
        transform: rotate(90deg);
        color: inherit;
        font-size: .7em;
        right: 3px; }
      .ec-headerSearch .ec-headerSearch__category .ec-select .select_ui select, .ec-headerSearch .ec-headerSearch__category .ec-birth .select_ui select {
        display: block;
        font-size: 13px;
        height: 100%;
        padding: 0px 0.7em !important;
        background-color: transparent;
        color: inherit;
        border: none; }
  .ec-headerSearch .ec-headerSearch__keyword {
    flex: 1 1 auto;
    position: relative; }
    .ec-headerSearch .ec-headerSearch__keyword input[type="search"] {
      border: none;
      margin: 0;
      border-radius: 0;
      font-size: 13px;
      height: 100%;
      padding-left: 1em;
      padding-right: 36px;
      background-color: transparent;
      color: inherit; }
  .ec-headerSearch .ec-headerSearch__keywordBtn {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    font-size: 20px;
    color: inherit; }

/*
ヘッダーメニューに設置した商品検索コンポーネントのスタイリング
「商品検索ブロック」はレイアウト管理でドロワー内にも設置可能なので、お互いにスタイリングが汚染影響しないようヘッダーメニュー内に限定したスタイリングを施す
*/
.ec-headerMenu {
  /* 検索ボックス全体枠 */ }
  .ec-headerMenu .ec-headerSearch__body {
    position: absolute;
    z-index: 998;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -15px, 0);
    transition: .3s; }
    .ec-headerMenu .ec-headerSearch__body.is-active {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0); }

@media only screen and (max-width: 767px) {
  .ec-headerRole {
    position: relative; }
  .ec-headerMenu .ec-headerSearch__body {
    top: 100%;
    width: 100%;
    left: 0; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-headerRole {
    position: relative; }
  .ec-headerMenu .ec-headerSearch__body {
    top: 100%;
    width: 100%;
    left: 0; } }

@media only screen and (min-width: 1200px) {
  /* デスクトップ時は開閉ギミック無しの出っぱなし状態 */
  /* 開閉用ボタン不要 */
  .ec-headerSearch__button {
    display: none !important; }
  .ec-headerMenu {
    position: relative; }
    .ec-headerMenu .ec-headerSearch__body {
      top: 0%;
      right: calc(100% + 10px);
      width: 100%;
      max-width: 380px;
      padding: 0 7px;
      visibility: visible;
      opacity: 1;
      transform: translate3d(0, 0, 0);
      transition: 0s;
      position: initial;
      margin-left: auto; } }

/*
ドロワー内に設置した商品検索コンポーネントのスタイリング
※当テンプレートでは商品検索はヘッダーにある物を使用する仕様ですが、一応、ドロワー用スタイリングも用意しています。
*/
.ec-drawerRole .ec-headerSearch {
  padding: 10px 0;
  /* 検索ボックス全体枠 */ }
  .ec-drawerRole .ec-headerSearch .ec-headerSearch__button {
    display: none; }
  .ec-drawerRole .ec-headerSearch .ec-headerSearch__body {
    padding: 0; }

/*
カートナビゲーション

カートインがあればカートアイコンに数量をバッジ表示します。<br>
カートボタンにマウスオンでカートナビゲーション（カート内容詳細）を表示します。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <div class="ec-headerMenu">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.8.html

Styleguide 11.2.8
*/
.ec-cartNavi {
  position: relative;
  /* カート数バッジ */
  /* カート内容詳細ポップアップ枠組み */ }
  .ec-cartNavi a {
    /* ボタンが小さい分、クリックカブル領域を少し拡張しておきたい理由と、同時に、
     * この余白分がバッジの右へずらす量、位置決めにもなっています。
     * これによりバッジ全体がクリック領域に含まれるので収まりもいいと思います。
     */
    padding-right: 0.75em; }
  .ec-cartNavi .ec-cartNavi__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", "Noto Sans JP", sans-serif;
    font-size: 10px;
    line-height: 1;
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    background-color: #f55;
    color: #fff;
    pointer-events: none;
    position: absolute;
    top: 0px;
    right: 0px; }
  .ec-cartNavi .ec-cartNavi__body {
    position: absolute;
    top: 100%;
    right: 0;
    width: 320px;
    display: inline-block;
    padding-top: 14px;
    /*くちばし分の余白*/
    pointer-events: none;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -15px, 0);
    transition: .2s; }
    .ec-cartNavi .ec-cartNavi__body .ec-cartNaviWrap {
      background-color: #fff;
      color: #000;
      padding: 20px;
      filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
      position: relative;
      /* くちばし */ }
      .ec-cartNavi .ec-cartNavi__body .ec-cartNaviWrap:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 10px;
        border: solid 10px transparent;
        border-bottom-color: #fff; }
  .ec-cartNavi:hover .ec-cartNavi__body {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: auto; }

/*
カートナビゲーションのポップアップ(カートイン在り)

カートに商品が追加されている場合のカートナビゲーションのスタイリングです。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <div class="ec-headerMenu">
        <div class="ec-headerMenu__item ec-cartNavi">
          <sg-wrapper-content/>
        </div>
      </div>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.9.html

Styleguide 11.2.9
*/
.ec-cartNaviIsset {
  font-size: 13px;
  /* 商品画像 */
  /* カート削除ボタン */
  /* 商品詳細 */
  /* 商品名 */
  /* 商品規格 */
  /* 商品価格 */ }
  .ec-cartNaviIsset .ec-cartNaviIsset__cart {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px #eee; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartImage {
    flex: 0 0 90px;
    order: 2;
    position: relative; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartImage > a {
      display: block; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a {
    position: absolute;
    top: -10px;
    right: -10px; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a:after {
      font-family: "jeiafont";
      content: "\e918";
      display: block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      text-align: center;
      border-radius: 50%;
      background-color: #fff;
      color: #333;
      border: solid 1px #aaa;
      box-sizing: border-box;
      cursor: pointer;
      transform: translate3d(0, 0, 0);
      transition: .2s; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a:hover:after {
      text-decoration: none;
      transform: translate3d(0, 1px, 0);
      box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.3); }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartDelete a:hover {
      text-decoration: none; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContent {
    flex: 0 1 auto;
    order: 1;
    padding-right: 10px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentTitle {
    margin-bottom: 0.5em;
    font-weight: 700; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentClass {
    margin: 0;
    margin-left: 10px;
    margin-bottom: 0.5em;
    font-size: 0.85em; }
  .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice .price {
      display: inline-block; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice .tax {
      display: inline-block;
      font-size: 0.85em; }
    .ec-cartNaviIsset .ec-cartNaviIsset__cartContentPrice .quantity {
      display: inline-block;
      margin-left: 10px; }
  .ec-cartNaviIsset .ec-cartNaviIsset__TotalPrice {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 500; }
    .ec-cartNaviIsset .ec-cartNaviIsset__TotalPrice dt {
      font-weight: inherit; }
  .ec-cartNaviIsset .ec-cartNaviIsset__action {
    margin-top: 30px; }
    .ec-cartNaviIsset .ec-cartNaviIsset__action .ec-blockBtn--action {
      background-color: #000;
      color: #fff;
      border: none; }

/*
カートナビゲーションのポップアップ(カートイン無し)

カートが空の状態でのカートナビゲーションのスタイリングです。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <div class="ec-headerMenu">
        <div class="ec-headerMenu__item ec-cartNavi">
          <sg-wrapper-content/>
        </div>
      </div>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.10.html

Styleguide 11.2.10
*/
.ec-cartNaviNull {
  font-size: 13px; }
  .ec-cartNaviNull .ec-cartNaviNull__message {
    text-align: center; }
    .ec-cartNaviNull .ec-cartNaviNull__message p {
      margin: 0; }

/*
ドロワー

スマホ・タブレット時に出現するドロワー（サイドメニュー）コンポーネントです。

ここではドロワーの枠組みを定義しています。<br>
EC-CUBE標準ではドロワーOPEN時はドロワー閉ボタンも表示されますが当テンプレートではヘッダーにあるドロワー開閉ボタンがありますので他の閉ボタンは排除しています。

ドロワーOPEN時にはページコンテンツを暗くするカーテンが表示されます。<br>
またその際、ページコンテンツをぼかすエフェクトも追加しています（デバイス幅が420px以上でコンテンツが見えます）。不要なら削除してください。

Markup:
../styleguide/tmpl/11.2.11.html

Styleguide 11.2.11
*/
.ec-drawerRole {
  position: fixed;
  width: 100%;
  max-width: 420px;
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: #f4f4f4;
  color: #444;
  visibility: hidden;
  transform: translate3d(-100%, 0, 0);
  transition: .3s; }
  @media only screen and (max-width: 767px) {
    .ec-drawerRole {
      height: calc(100vh - 45px);
      top: 45px;
      padding: 5px 20px 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-drawerRole {
      height: calc(100vh - 50px);
      top: 50px;
      padding: 10px 30px 50px; } }
  @media only screen and (min-width: 1200px) {
    .ec-drawerRole {
      display: none; } }
  .ec-drawerRole.is-active {
    visibility: visible;
    transform: translate3d(0, 0, 0); }

/* ドロワー閉ボタン（※不使用） */
.ec-drawerRoleClose {
  display: none; }

/* ドロワー背景カーテン */
.ec-overlayRole {
  position: fixed;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.75);
  visibility: hidden;
  opacity: 0;
  transition: .4s;
  /* ドロワーと同様、ヘッダーが上部固定仕様でありヘッダー内にあるドロワー開閉ボタンを活かしたいので、
   * 背景かーテンもヘッダーの高さ分だけ短くしておく
   */
  /* 表示状態 */ }
  @media only screen and (max-width: 767px) {
    .ec-overlayRole {
      height: calc(100vh - 45px);
      top: 45px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-overlayRole {
      height: calc(100vh - 50px);
      top: 50px; } }
  @media only screen and (min-width: 1200px) {
    .ec-overlayRole {
      display: none; } }
  .have_curtain .ec-overlayRole {
    visibility: visible;
    opacity: 1; }

/* 背景下のページコンテンツをぼかす（不要なら削除） */
.ec-layoutRole > *:not(.ec-layoutRole__header) {
  transition: .4s; }
  .have_curtain .ec-layoutRole > *:not(.ec-layoutRole__header) {
    filter: blur(3px); }

/*
ドロワーメニュー

Styleguide 11.2.12
*/
.ec-drawerMenu {
  font-size: 0.9285rem;
  /*
  カテゴリ折り畳み表示
  商品カテゴリとマイページカテゴリにて使用
  */
  /* ドロワーフッターナビ */ }
  .ec-drawerMenu .ec-drawerMenu__item {
    border-bottom: dotted 1px rgba(0, 0, 0, 0.4); }
    .ec-drawerMenu .ec-drawerMenu__item a {
      display: block;
      padding: 0.75em 0;
      color: inherit;
      position: relative; }
  .ec-drawerMenu .ec-drawerMenu__itemIcon {
    display: inline-block;
    vertical-align: middle;
    color: #666;
    margin-left: 5px; }
  .ec-drawerMenu .ec-drawerMenu__itemBadge {
    display: inline-block;
    font-size: 10px;
    line-height: 1.6em;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    border-radius: 50%;
    background-color: #f55;
    color: #fff;
    font-family: "Poppins", "Noto Sans JP", sans-serif;
    pointer-events: none; }
  .ec-drawerMenu .ec-drawerMenu__item.has-category {
    position: relative; }
  .ec-drawerMenu .ec-drawerMenu__category ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    .ec-drawerMenu .ec-drawerMenu__category ul li {
      margin: 0;
      padding: 0; }
      .ec-drawerMenu .ec-drawerMenu__category ul li a {
        display: block;
        padding: .5em 0;
        color: inherit; }
      .ec-drawerMenu .ec-drawerMenu__category ul li.has-child {
        position: relative; }
  .ec-drawerMenu .ec-drawerMenu__category__name-en {
    display: block;
    padding-left: 1em;
    margin-top: 2px;
    margin-left: 1em;
    font-family: 'Dynalight', serif;
    position: relative;
    line-height: 1.4; }
    .ec-drawerMenu .ec-drawerMenu__category__name-en:before {
      content: "";
      position: absolute;
      top: 0.6em;
      left: 1px;
      height: 1px;
      width: 0.5em;
      background: #000; }
  .ec-drawerMenu .ec-drawerMenu__category .child .ec-drawerMenu__category__name-en {
    margin-left: 0; }
  .ec-drawerMenu .ec-drawerMenu__category ul.parent {
    display: none;
    margin-bottom: 1em; }
    .ec-drawerMenu .ec-drawerMenu__category ul.parent > li > a:before {
      font-family: "jeiafont";
      content: "\e91a";
      color: #666; }
  .ec-drawerMenu .ec-drawerMenu__category ul.child {
    margin-left: .75em;
    padding-left: .75em;
    border-left: solid 1px rgba(0, 0, 0, 0.2);
    display: none; }
  .ec-drawerMenu .ec-drawerMenu__category .categoyOpenBtn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 0.5em;
    transition: .2s; }
    .ec-drawerMenu .ec-drawerMenu__category .categoyOpenBtn:after {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 1em;
      display: block;
      width: 2em;
      height: 2em;
      line-height: 2em;
      cursor: pointer;
      text-align: center; }
    .ec-drawerMenu .ec-drawerMenu__category .categoyOpenBtn.is-open {
      transform: rotate(90deg); }
  .ec-drawerMenu.footerNav {
    margin-top: 20px; }

/*
ヘッダー挙動 その１

当テンプレートではヘッダーをページ上部に固定させる仕様となっております。
ヘッダーを固定しますので、.ec-layoutRoleに上部余白を設けておく必要があります。

またデスクトップ時はページスクロールが発生するとヘッダーの高さを縮小させます。

Styleguide 11.2.90
*/
.ec-layoutRole .ec-layoutRole__header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1001; }

@media only screen and (max-width: 767px) {
  .ec-layoutRole:not(.full_screen_page .ec-layoutRole) {
    padding-top: 45px; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-layoutRole:not(.full_screen_page .ec-layoutRole) {
    padding-top: 50px; } }

@media only screen and (min-width: 1200px) {
  .ec-layoutRole:not(.full_screen_page .ec-layoutRole) {
    /* ヘッダー＆ドロワーの背景色を黒系か白系かに切り替えできる仕組みを「_variables.scss」にて実装していますが、
     * 黒系ヘッダー（色付きヘッダー）の場合はヘッダーとコンテンツの間に少し余白を設けたほうがバランスがいいと思います。
     * 白系ヘッダー（ヘッダーとメインコンテンツが同色）の場合はヘッダーとコンテンツの間は空けないほうがまとまってみえるかもしれません。
     * ですので、黒系・白系切り替えフラグをみてその余白を切り替えます。
     */
    padding-top: 120px; }
    .ec-layoutRole  .ec-headerRole {
      transition: 0.6s;
      /* 縮小ヘッダー高さ */ }
      body.scrolled .ec-layoutRole  .ec-headerRole {
        height: 50px; } }

@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, body.scrolled .ec-layoutRole__header .ec-headerTitle img {
    width: 130px; } }

/*
ヘッダー挙動　その２
 .product_collection ページ
 */
.product_collection .ec-layoutRole {
  padding-top: 0 !important; }
  .product_collection .ec-layoutRole__header {
    background-color: transparent; }

.product_collection .ec-headerSearch__body {
  background-color: transparent; }

.product_collection .ec-headerSearch input::placeholder {
  color: inherit;
  transition: color 0.3s; }

.product_collection.header-white .ec-layoutRole__header {
  background-color: #ffffff; }

.product_collection.header-white .ec-headerSearch input::placeholder {
  color: #ddd; }

/*
フッター

全ページで使用されるフッターのプロジェクトコンポーネントです。

フッターガイド、フッターナビ、フッターソーシャル、フッタータイトルで構成されています。

※ページコンテンツ本体（.ec-layoutRole__contents）とフッターとの余白は、`.ec-layoutRole__footer`に持たせてあります。

Markup:
../styleguide/tmpl/11.3.html

Styleguide 11.3
*/
@media only screen and (max-width: 767px) {
  .ec-layoutRole__footer {
    margin-top: 3.125vw; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-layoutRole__footer {
    margin-top: 3.906vw; } }

@media only screen and (min-width: 1200px) {
  .ec-layoutRole__footer {
    margin-top: 100px; } }

.ec-footerRole {
  background-color: #000;
  color: #aaa; }
  @media only screen and (max-width: 767px) {
    .ec-footerRole {
      font-size: 0.9285rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerRole {
      font-size: 0.8666rem; } }
  @media only screen and (min-width: 1200px) {
    .ec-footerRole {
      font-size: 0.8666rem; } }

/*
フッターガイド

Markup:
../styleguide/tmpl/11.3.1.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.1
*/
.ec-footerGuide {
  background-color: #1f1f1f; }
  .ec-footerGuide .ec-footerGuide__inner {
    margin: 0 auto;
    box-sizing: content-box;
    width: auto !important;
    max-width: 1200px;
    max-width: 1480px;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .ec-footerGuide .ec-footerGuide__inner {
        padding-left: 3.125vw;
        padding-right: 3.125vw; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-footerGuide .ec-footerGuide__inner {
        padding-left: 3.906vw;
        padding-right: 3.906vw; } }
    @media only screen and (min-width: 1200px) {
      .ec-footerGuide .ec-footerGuide__inner {
        /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
        padding-left: 40px;
        padding-right: 40px;
        /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
        .ec-layoutRole__mainWithColumn .ec-footerGuide .ec-footerGuide__inner,
        .ec-layoutRole__mainBetweenColumn .ec-footerGuide .ec-footerGuide__inner {
          max-width: auto;
          padding-left: 0;
          padding-right: 0; } }
  .ec-footerGuide .ec-footerGuide__wrap {
    display: flex;
    justify-content: center;
    flex-flow: row wrap; }
  .ec-footerGuide .ec-footerGuide__item {
    flex: 0 0 33.3%;
    display: flex;
    align-items: center;
    border-left: solid 1px rgba(255, 255, 255, 0.2); }
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, .ec-footerGuide .ec-footerGuide__item {
        max-width: 33.3%; } }
    .ec-footerGuide .ec-footerGuide__item:first-child {
      border-left: none; }
  .ec-footerGuide .ec-footerGuide__itemIcon {
    font-size: 1.8rem;
    margin-right: 20px; }
  .ec-footerGuide .ec-footerGuide__itemText h3 {
    font-family: "Oswald", "Noto Sans JP", sans-serif;
    font-weight: 400;
    font-size: 1.154em;
    color: #fff;
    margin: 0;
    margin-bottom: 0.5em; }
  .ec-footerGuide .ec-footerGuide__itemText p {
    margin: 0; }
  @media only screen and (max-width: 767px) {
    .ec-footerGuide {
      padding-top: 3.125vw; }
      .ec-footerGuide .ec-footerGuide__item {
        flex-basis: 100%;
        padding: 0;
        border-left: none;
        margin-bottom: 3.125vw;
        flex-wrap: wrap; }
      .ec-footerGuide .ec-footerGuide__itemIcon {
        width: 2rem; }
      .ec-footerGuide .ec-footerGuide__itemText {
        flex: 1; }
        .ec-footerGuide .ec-footerGuide__itemText p {
          line-height: 1.3; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerGuide {
      padding-top: 3.906vw;
      padding-bottom: 3.906vw; }
      .ec-footerGuide .ec-footerGuide__wrap {
        margin-left: -20px;
        margin-right: -20px; }
      .ec-footerGuide .ec-footerGuide__item {
        padding: 0 20px;
        display: block; }
      .ec-footerGuide .ec-footerGuide__itemIcon {
        margin-bottom: .5em; }
      .ec-footerGuide .ec-footerGuide__itemText p {
        line-height: 1.3; } }
  @media only screen and (min-width: 1200px) {
    .ec-footerGuide {
      padding-top: 40px;
      padding-bottom: 40px; }
      .ec-footerGuide .ec-footerGuide__wrap {
        margin-left: -20px;
        margin-right: -20px; }
      .ec-footerGuide .ec-footerGuide__item {
        padding: 0 20px;
        flex-wrap: wrap; }
      .ec-footerGuide .ec-footerGuide__itemIcon {
        width: 2rem;
        font-size: 28px; }
      .ec-footerGuide .ec-footerGuide__itemText {
        flex: 1; } }

/*
フッターナビ

Markup:
../styleguide/tmpl/11.3.2.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.2
*/
.ec-footerNavi {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 1480px;
  /* 各カラム */
  /* リスト */ }
  @media only screen and (max-width: 767px) {
    .ec-footerNavi {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerNavi {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-footerNavi {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-footerNavi,
      .ec-layoutRole__mainBetweenColumn .ec-footerNavi {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-footerNavi .ec-footerNavi__wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
  .ec-footerNavi .ec-footerNavi__item {
    flex: 0 0 auto; }
    .ec-footerNavi .ec-footerNavi__item h3 {
      font-family: "Oswald", "Noto Sans JP", sans-serif;
      font-weight: 400;
      font-size: 1.5em;
      color: #fff;
      margin: 0;
      margin-bottom: 1em; }
  .ec-footerNavi .ec-footerNavi__itemList {
    margin: 0;
    padding: 0;
    list-style: none; }
    .ec-footerNavi .ec-footerNavi__itemList li a {
      display: block;
      padding: 0.3em 0;
      color: inherit; }
  @media only screen and (max-width: 767px) {
    .ec-footerNavi {
      padding-top: 3.125vw; }
      .ec-footerNavi .ec-footerNavi__wrap {
        margin-top: -20px; }
      .ec-footerNavi .ec-footerNavi__item {
        width: 100%;
        margin-top: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerNavi {
      padding-top: 3.906vw;
      /* フッターロゴ以降のカラムを3分割 */
      /* 区切り罫線 */ }
      .ec-footerNavi .ec-footerNavi__wrap {
        margin-left: -15px;
        margin-right: -15px; }
      .ec-footerNavi .ec-footerNavi__item {
        width: 33.33%;
        padding: 0 15px; }
      .ec-footerNavi .ec-footerNavi__item:nth-child(n+2) {
        border-left: solid 1px rgba(255, 255, 255, 0.15); } }
  @media only screen and (min-width: 1200px) {
    .ec-footerNavi {
      padding-top: 40px;
      /* 3等分にはせず「CONTACT US」は最小幅で、リンクナビ側は大きく */
      /* 区切り罫線 */
      /* リンクナビを2カラム表示 */ }
      .ec-footerNavi .ec-footerNavi__wrap {
        margin-left: -20px;
        margin-right: -20px; }
      .ec-footerNavi .ec-footerNavi__item {
        padding: 0 20px; }
      .ec-footerNavi .ec-footerNavi__item:nth-child(1),
      .ec-footerNavi .ec-footerNavi__item:nth-child(2) {
        flex: 1 0 auto; }
      .ec-footerNavi .ec-footerNavi__item:nth-child(3) {
        flex: 0 1 auto; }
      .ec-footerNavi .ec-footerNavi__item:nth-child(n+2) {
        border-left: solid 1px rgba(255, 255, 255, 0.15); }
      .ec-footerNavi .ec-footerNavi__itemList {
        columns: 2;
        column-gap: 1em; } }

/*
CONTACT-US
フッターに配置される「CONTACT US」のスタイリングになります。
*/
.ec-contactUsRole .ec-contactUsRole__address {
  margin: .75em 0;
  display: flex;
  align-items: flex-start;
  line-height: 1.35; }
  .ec-contactUsRole .ec-contactUsRole__address i {
    display: block;
    font-size: 1.35em;
    opacity: 0.5;
    flex: 0 0 1em; }
  .ec-contactUsRole .ec-contactUsRole__address span {
    display: block;
    margin-left: .5em; }
  .ec-contactUsRole .ec-contactUsRole__address a {
    color: inherit; }

/*
フッター ソーシャルボタン

Markup:
../styleguide/tmpl/11.3.3.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.3
*/
.ec-footerSocial {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 1680px;
  padding-top: 20px; }
  @media only screen and (max-width: 767px) {
    .ec-footerSocial {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerSocial {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-footerSocial {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-footerSocial,
      .ec-layoutRole__mainBetweenColumn .ec-footerSocial {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-footerSocial .ec-footerSocial__itemList {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center; }
    .ec-footerSocial .ec-footerSocial__itemList li {
      display: inline-block;
      margin: 5px;
      /* 各ソーシャルボタン毎に色付け（スマホ・タブレット時のみ） */
      /* デスクトップ時はマウスオーバーで色が付くようにします */ }
      .ec-footerSocial .ec-footerSocial__itemList li a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%; }
        .ec-footerSocial .ec-footerSocial__itemList li a:hover {
          text-decoration: none;
          color: #fff; }
      .ec-footerSocial .ec-footerSocial__itemList li i {
        display: block; }
      @media only screen and (max-width: 1199px) {
        .ec-footerSocial .ec-footerSocial__itemList li a {
          color: #fff; }
        .ec-footerSocial .ec-footerSocial__itemList li.facebook a {
          background-color: #3B5999; }
        .ec-footerSocial .ec-footerSocial__itemList li.twitter a {
          background-color: #1B95E0; }
        .ec-footerSocial .ec-footerSocial__itemList li.instagram a {
          background-color: #f00075; } }
      @media only screen and (min-width: 1200px) {
        .ec-footerSocial .ec-footerSocial__itemList li a {
          color: #fff;
          background-color: rgba(255, 255, 255, 0.2);
          transition: .2s; }
        .ec-footerSocial .ec-footerSocial__itemList li.facebook a:hover {
          background-color: #3B5999; }
        .ec-footerSocial .ec-footerSocial__itemList li.twitter a:hover {
          background-color: #1B95E0; }
        .ec-footerSocial .ec-footerSocial__itemList li.instagram a:hover {
          background-color: #f00075; } }

/*
フッタータイトル

Markup:
../styleguide/tmpl/11.3.4.html

sg-wrapper:
<div class="ec-footerRole">
  <div class="ec-footerRole__inner">
    <sg-wrapper-content/>
  </div>
</div>

Styleguide 11.3.4
*/
.ec-footerTitle {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 1680px;
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  text-align: center;
  line-height: 1.2; }
  @media only screen and (max-width: 767px) {
    .ec-footerTitle {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerTitle {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-footerTitle {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-footerTitle,
      .ec-layoutRole__mainBetweenColumn .ec-footerTitle {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-footerTitle {
      padding-top: 1.5625vw;
      padding-bottom: 1.5625vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-footerTitle {
      padding-top: 1.953vw;
      padding-bottom: 1.953vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-footerTitle {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .ec-footerTitle .ec-footerTitle__logo {
    font-size: 1.5em;
    color: #fff;
    margin-bottom: 0.5em; }
    .ec-footerTitle .ec-footerTitle__logo a {
      color: inherit; }
  .ec-footerTitle .ec-footerTitle__copyright {
    font-size: 12px; }

/*
左カラムコンテンツ

まず、左カラムに配置される各ブロックの共通スタイリングします。

* ブロック間の余白
* slickのprev,nextボタン

セクション見出し「.ec-secHeading」は「_12.4.heading.scss」にて左カラム用のセクション見出しをスタイリングしています。

レスポンシブによりスマホ・タブレット時は、左カラムのコンテンツはメインカラムの下に配置されます。

Styleguide 11.4
*/
.ec-layoutRole__left {
  /* .ec-layoutRole__left（2カラムレイアウト）の基本スタイリングは「_11.1.role.scss」にて定義しています */
  /* slickのprev,nextボタンはブロック枠の右上に配置させます */ }
  .ec-layoutRole__left .leftBlock {
    position: relative; }
    @media only screen and (max-width: 767px) {
      .ec-layoutRole__left .leftBlock {
        margin-top: 9.375vw; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-layoutRole__left .leftBlock {
        margin-top: 11.718vw; } }
    @media only screen and (min-width: 1200px) {
      .ec-layoutRole__left .leftBlock {
        margin-top: 60px; } }
  .ec-layoutRole__left .slickArrows {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end; }
    .ec-layoutRole__left .slickArrows .slick-arrow {
      font-size: 20px;
      cursor: pointer; }
      .ec-layoutRole__left .slickArrows .slick-arrow i {
        display: block; }
      .ec-layoutRole__left .slickArrows .slick-arrow.slick-next {
        margin-left: 5px; }

/*
左カラムカテゴリナビブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.1.html

Styleguide 11.4.1
*/
@media only screen and (min-width: 1200px) {
  .ec-categoryNavRole {
    margin-top: 0 !important;
    /* セクション見出し上書き調整 */ }
    .ec-categoryNavRole .ec-secHeading {
      margin-bottom: 0; } }

.ec-categoryNavRole ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .ec-categoryNavRole ul li {
    margin: 0;
    padding: 0; }
    .ec-categoryNavRole ul li.has-child {
      position: relative; }
    .ec-categoryNavRole ul li a {
      display: block;
      padding: .75em 0;
      color: inherit;
      transition: .2s; }
      .ec-categoryNavRole ul li a:hover {
        text-decoration: none;
        background-color: #f0f1f2;
        padding-left: 4px; }

.ec-categoryNavRole__name-en {
  display: block;
  padding-left: 1em;
  margin-top: 2px;
  font-family: 'Dynalight', serif;
  position: relative;
  line-height: 1.4; }
  .ec-categoryNavRole__name-en:before {
    content: "";
    position: absolute;
    top: 0.6em;
    left: 1px;
    height: 1px;
    width: 0.5em;
    background: #000; }

.ec-categoryNavRole ul.parent > li {
  border-top: 1px solid #eee; }

.ec-categoryNavRole ul.child {
  margin-left: .75em;
  padding-left: .75em;
  border-left: solid 1px #ccc;
  margin-top: .5em;
  margin-bottom: .5em;
  display: none; }

.ec-categoryNavRole .categoyNavOpenBtn {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: .55em;
  transition: .2s; }
  .ec-categoryNavRole .categoyNavOpenBtn:after {
    font-family: "jeiafont";
    content: "\e902";
    font-size: 1em;
    display: block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    cursor: pointer;
    text-align: center; }
  .ec-categoryNavRole .categoyNavOpenBtn.is-open {
    transform: rotate(90deg); }

/*
ON-SALEブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.2.html

Styleguide 11.4.2
*/
.ec-onsaleRole .ec-productItemRole .ec-productItemRole__content {
  text-align: left; }

/*
BLOG-POSTSブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.3.html

Styleguide 11.4.3
*/
.ec-blogpostsRole {
  /* ブログ投稿item */
  /* ブログ投稿item間の余白 */
  /* ブログサムネイル */
  /* ブログ投稿コンテンツ */ }
  .ec-blogpostsRole .ec-blogpostsRole__item a {
    display: flex;
    color: inherit; }
    .ec-blogpostsRole .ec-blogpostsRole__item a:hover {
      text-decoration: none; }
      .ec-blogpostsRole .ec-blogpostsRole__item a:hover img {
        filter: brightness(1.1); }
  .ec-blogpostsRole .ec-blogpostsRole__item + .ec-blogpostsRole__item {
    margin-top: 10px; }
    @media only screen and (min-width: 1200px) {
      .ec-blogpostsRole .ec-blogpostsRole__item + .ec-blogpostsRole__item {
        margin-top: 20px; } }
  .ec-blogpostsRole .ec-blogpostsRole__image {
    flex: 0 0 auto;
    width: 6em; }
    @media only screen and (min-width: 1200px) {
      .ec-blogpostsRole .ec-blogpostsRole__image {
        width: 100px; } }
    .ec-blogpostsRole .ec-blogpostsRole__image img {
      transition: .2s; }
  .ec-blogpostsRole .ec-blogpostsRole__content {
    flex: 1 1 auto;
    font-size: 0.933rem;
    padding-left: 1em;
    /* 投稿日時 */
    /* 投稿コメント */ }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-blogpostsRole .ec-blogpostsRole__content {
        /* 横2列ずつのスライドなのでコンテンツに右余白を */
        padding-right: 1em; } }
    .ec-blogpostsRole .ec-blogpostsRole__content time {
      display: block;
      line-height: 1;
      color: #666;
      margin-bottom: 1em; }
    .ec-blogpostsRole .ec-blogpostsRole__content p {
      line-height: 1.2; }

/*
CUSTOMER-VOICEブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.4.html

Styleguide 11.4.4
*/
.ec-customerVoiceRole {
  /* お客様コメント */
  /* お客様情報 */ }
  .ec-customerVoiceRole .ec-customerVoiceRole__item {
    font-size: 0.933rem; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-customerVoiceRole .ec-customerVoiceRole__item {
        padding: 0 10px; } }
  .ec-customerVoiceRole .ec-customerVoiceRole__comment {
    border: solid 1px #eee;
    padding: 1.5em 2em;
    margin-bottom: 1em;
    line-height: 1.2; }
  .ec-customerVoiceRole .ec-customerVoiceRole__customer {
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* アイコン表示 */
    background: url(../icon/user_sns.svg) no-repeat left center;
    background-size: 50px;
    min-height: 50px;
    padding-left: 50px; }
    @media only screen and (min-width: 1200px) {
      .ec-customerVoiceRole .ec-customerVoiceRole__customer {
        background-size: 60px;
        min-height: 60px;
        padding-left: 60px; } }
    .ec-customerVoiceRole .ec-customerVoiceRole__customer .name {
      display: block;
      margin: 0 0 0 1em; }
    .ec-customerVoiceRole .ec-customerVoiceRole__customer time {
      display: block;
      color: #666;
      margin: 0 0 0 1em; }

/*
動画メディア・バナーブロック

タブレット時は横に2枚ずつ配置するレイアウトになります。

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.5.html

Styleguide 11.4.5
*/
.ec-movieMediaRole {
  /* メディア格納item（動画・バナー共通） */
  /* メディア格納要素 */ }
  .ec-movieMediaRole .ec-movieMediaRole__wrap {
    /* タブレット時は横に2枚ずつ配置します */ }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-movieMediaRole .ec-movieMediaRole__wrap {
        display: flex;
        flex-flow: row wrap;
        margin-top: -20px; } }
  @media only screen and (max-width: 767px) {
    .ec-movieMediaRole .ec-movieMediaRole__item {
      margin-bottom: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-movieMediaRole .ec-movieMediaRole__item {
      flex: 0 0 auto;
      width: calc((100% - 20px) / 2) !important;
      margin-left: 20px;
      margin-top: 20px; }
      .ec-movieMediaRole .ec-movieMediaRole__item:nth-child(2n+1) {
        margin-left: 0; } }
  @media only screen and (min-width: 1200px) {
    .ec-movieMediaRole .ec-movieMediaRole__item {
      margin-bottom: 40px; } }
  .ec-movieMediaRole .ec-mediaframe {
    /* 動画用 */ }
    .ec-movieMediaRole .ec-mediaframe.movie {
      width: 100%;
      height: 0;
      padding-top: 56.25%;
      position: relative; }
      .ec-movieMediaRole .ec-mediaframe.movie iframe, .ec-movieMediaRole .ec-mediaframe.movie video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: none; }
    .ec-movieMediaRole .ec-mediaframe a {
      display: block;
      display: block;
      overflow: hidden;
      /* HTML構造によってはIE11でちらつく事があるので保険 */ }
      .ec-movieMediaRole .ec-mediaframe a * {
        overflow: hidden; }
      .ec-movieMediaRole .ec-mediaframe a img {
        transition: .6s; }
      .ec-movieMediaRole .ec-mediaframe a:hover img {
        transform: scale(1.1);
        filter: brightness(1.05); }

/*
インスタグラム最新記事表示ブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.6.html

Styleguide 11.4.6
*/
.ec-instagramRole {
  /* ul要素 */ }
  .ec-instagramRole .ec-instagramRole__list {
    /* li要素（記事単体） */ }
    @media only screen and (max-width: 767px) {
      .ec-instagramRole .ec-instagramRole__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        margin-top: 0px;
        margin-left: -3.125vw;
        margin-right: -3.125vw; }
        .ec-instagramRole .ec-instagramRole__list > li {
          flex: 0 0 auto;
          width: calc((100% - 1px - 0px ) / 3);
          margin-left: 0px;
          margin-top: 0px; }
          .ec-instagramRole .ec-instagramRole__list > li:nth-child(3n+1) {
            margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-instagramRole .ec-instagramRole__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        margin-top: -5px; }
        .ec-instagramRole .ec-instagramRole__list > li {
          flex: 0 0 auto;
          width: calc((100% - 1px - 15px ) / 4);
          margin-left: 5px;
          margin-top: 5px; }
          .ec-instagramRole .ec-instagramRole__list > li:nth-child(4n+1) {
            margin-left: 0; } }
    @media only screen and (min-width: 1200px) {
      .ec-instagramRole .ec-instagramRole__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        margin-top: -10px; }
        .ec-instagramRole .ec-instagramRole__list > li {
          flex: 0 0 auto;
          width: calc((100% - 1px - 10px ) / 2);
          margin-left: 10px;
          margin-top: 10px; }
          .ec-instagramRole .ec-instagramRole__list > li:nth-child(2n+1) {
            margin-left: 0; } }
    .ec-instagramRole .ec-instagramRole__list li {
      position: relative;
      /* 画像メディア */
      /* 動画メディア */
      /* ポップアップ */
      /* hoverでポップアップ表示 */ }
      .ec-instagramRole .ec-instagramRole__list li a {
        display: block; }
      .ec-instagramRole .ec-instagramRole__list li .pic {
        overflow: hidden; }
        .ec-instagramRole .ec-instagramRole__list li .pic .image {
          width: 100%;
          height: 0;
          padding-top: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          transition: .6s; }
        .ec-instagramRole .ec-instagramRole__list li .pic:hover .image {
          transform: scale(1.1);
          filter: brightness(1.05); }
      .ec-instagramRole .ec-instagramRole__list li video {
        display: block;
        width: 100%;
        height: auto; }
      .ec-instagramRole .ec-instagramRole__list li .popup {
        position: absolute;
        bottom: calc(100% + 7px);
        left: 50%;
        width: 100%;
        min-width: 200px;
        font-size: 13px;
        box-sizing: border-box;
        padding: 1em;
        background-color: #000;
        color: #fff;
        font-size: 13px;
        border-radius: 3px;
        z-index: 9;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        transform: translate3d(-50%, 20px, 0);
        transition: .4s;
        /* 記事コメント（キャプション） */
        /* いいね件数 */
        /* ポップアップくちばし */ }
        .ec-instagramRole .ec-instagramRole__list li .popup .likeCount {
          font-size: 0.9em;
          opacity: 0.75;
          text-align: right;
          margin-top: .5em; }
        .ec-instagramRole .ec-instagramRole__list li .popup:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: solid 7px transparent;
          border-top-color: #000;
          position: absolute;
          top: 100%;
          left: calc(50% - 7px); }
        @media only screen and (max-width: 767px) {
          .ec-instagramRole .ec-instagramRole__list li .popup {
            display: none; } }
        @media only screen and (min-width: 768px) and (max-width: 1199px) {
          .ec-instagramRole .ec-instagramRole__list li .popup {
            display: none; } }
      .ec-instagramRole .ec-instagramRole__list li:hover .popup {
        visibility: visible;
        opacity: 1;
        transform: translate3d(-50%, 0, 0); }

/*
左カラムロゴ・CONTACT-US

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.7.html

Styleguide 11.4.7
*/
.ec-leftContactUsRole {
  /* スマホ時は非表示 */
  /* タブレット時は非表示 */
  /* 左カラムロゴ */
  /* CONTACT US
     「CONTACT US」はフッターにも配置してありますのでフッター用に「_11.3.footer.scss」で基本スタイリングしています。
     ここでは左カラム用に文字サイズと色を上書き調整しています。
  */ }
  @media only screen and (max-width: 767px) {
    .ec-leftContactUsRole {
      display: none; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-leftContactUsRole {
      display: none; } }
  .ec-leftContactUsRole .leftLogo {
    margin-bottom: 1.5rem; }
    .ec-leftContactUsRole .leftLogo a {
      display: inline-block; }
    .ec-leftContactUsRole .leftLogo img {
      width: auto;
      max-width: 100%; }
  .ec-leftContactUsRole .ec-contactUsRole {
    font-size: 0.933rem;
    color: #666; }

/*
slick

トッププロモーションや商品ビジュアル画像、商品リストのスライド表示にはEC-CUBE4標準と同じく「slick」を使用しています。

slickを適用する要素は共通してclass「.ec-slickContainer」を設置してその要素に対して適用させるようにしています。

slick適用要素は、slickの準備ができるまではエリアを非表示としておき、準備が整ってからフェードイン表示としています。
slickの準備が完了するまでは画像が一瞬大きく表示されてしまう事がありますのでそれを見せないための処置です。

メモ：
slickドットとslickアロー（prev,next）と両方適用させると、アローボタンは上下中央よりずれてしまいます。

Markup:
../styleguide/tmpl/12.1.html

Styleguide 12.1
*/
.ec-slickContainer {
  visibility: hidden;
  opacity: 0;
  filter: blur(5px);
  transition: 1s; }
  .ec-slickContainer.slick-initialized {
    visibility: visible;
    opacity: 1;
    filter: blur(0); }

/*
slick用パーツ

slick用パーツの共通スタイリングです。

* prev,nextボタン
* ドットボタン
* slickガター
* キャプション表示

Styleguide 12.1.1
*/
/*
prev,nextボタン

ボタンを画像に被せて左右に配置するるタイプのスタイリングです。

Markup:
../styleguide/tmpl/12.1.2.html

Styleguide 12.1.2
*/
.slick-slider {
  position: relative; }
  .slick-slider .slick-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    font-size: 3rem;
    color: #fff;
    cursor: pointer;
    opacity: .5;
    transition: .2s; }
    .slick-slider .slick-arrow.slick-prev {
      left: 0; }
    .slick-slider .slick-arrow.slick-next {
      right: 0; }
    .slick-slider .slick-arrow:hover {
      opacity: 1; }
    .slick-slider .slick-arrow i {
      display: block; }
  .slick-slider .slide-item img {
    margin: auto; }

/*
ドットボタン

Markup:
../styleguide/tmpl/12.1.3.html

Styleguide 12.1.3
*/
.slick-slider .slick-dots {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  padding-top: 22.5px; }
  .slick-slider .slick-dots li {
    display: block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #c8c8c8;
    cursor: pointer; }
    .slick-slider .slick-dots li button {
      display: none; }
    .slick-slider .slick-dots li.slick-active {
      background-color: #000000; }
    .slick-slider .slick-dots li + li {
      margin-left: 10.5px; }

/*
slickガター

slick適用内のアイテム間にガター（間隔）を設けます。

slickを適用する要素（基本は.ec-ec-slickContainer）に併せてclass「slickGutter」を記述しておきます。

※なお、レスポンシブでデバイス事にガター幅を可変できるようにmixin化しています。

メモ：
普通に、.slick-slide要素にマージンを設定し、先祖要素である.slick-listにネガティブマージンを設定して調整します。
しかし、それだけではスライド時に左側にわずかに商品アイテムがはみ出した形で見えてしまいます。これを防ぐ為には、
slick適用の要素（基本は.ec-ec-slickContainer）に「overflow:hidden;」すれば解決しますが、
prev,nectボタンをslick領域の外側に配置したい場合があり（position:absolute;で外に出しています）、overflowだと
この外側に配置したpreve,nextボタンが隠されてしまい見えない事になります。
ですので、ネガティブマージンをかけた.slick-list要素に「clip-path」プロパティを使い表示領域をクリップすることで対応しています。

※IE11、Edgeはclip-path非対応なのでクリップパスは適用されず結果、スライド時に左側が少しはみ出して見えます（2019/09）

Markup:
../styleguide/tmpl/12.1.4.html

Styleguide 12.1.4
*/
.slickGutter .slick-list {
  margin-left: -10px;
  clip-path: inset(0 0 0 10px); }
  .slickGutter .slick-list .slick-slide {
    margin-left: 10px; }

@media only screen and (min-width: 1200px) {
  .slickGutter .slick-list {
    margin-left: -20px;
    clip-path: inset(0 0 0 20px); }
    .slickGutter .slick-list .slick-slide {
      margin-left: 20px; } }

/*
slick キャプション表示

slick適用の各アイテムの中に「.caption」要素（説明文）を記述しておくと画像に被さる形でテキストが表示されます。

商品詳細ページでは、slickを適用する画像ビジュアル部分が動的に出力されますのでキャプション要素を追加できませんが、
フリーエリア内にてキャプション要素を記述しておき、javascirptで各キャプションを各画像アイテム内に挿入させて実装しています。

Markup:
../styleguide/tmpl/12.1.5.html

Styleguide 12.1.5
*/
.slick-slider .slick-slide {
  position: relative; }
  .slick-slider .slick-slide .caption {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: .933rem;
    color: #000;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 1em;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: .5s;
    transition-delay: .5s; }
  .slick-slider .slick-slide.slick-current .caption {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0); }

/*
動画スライダー

slickスライダーに動画を含める事のできる「jeia_movieslider.js」用のスタイリングです。

通常のslickスライダーのスタイリングに対して、動画スライダー用にいくつか上書きと追加スタイリングを施しています。

Markup:
../styleguide/tmpl/12.1.6.html

Styleguide 12.1.6
*/
.ec-movieslider {
  position: relative;
  /* スライドitem */
  /* loader */
  /* SOUNDボタン */ }
  .ec-movieslider .ec-slickContainer {
    transition: height 0s, opacity 0s;
    opacity: 0; }
    .ec-movieslider .ec-slickContainer.is-ready {
      transition: opacity 1s;
      opacity: 1; }
  .ec-movieslider .slick-slide {
    overflow: hidden;
    transition: filter 1s;
    pointer-events: none;
    /* 切り替わり中のエフェクト */ }
    .ec-movieslider .slick-slide.is-change {
      filter: blur(5px); }
    .ec-movieslider .slick-slide .itemContainer {
      /* iframe要素 */
      /* video要素 */ }
      .ec-movieslider .slick-slide .itemContainer > a {
        display: block;
        pointer-events: auto; }
      .ec-movieslider .slick-slide .itemContainer iframe {
        border: none; }
      .ec-movieslider .slick-slide .itemContainer video {
        display: block; }
  .ec-movieslider .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .ec-movieslider .loader img {
      width: 50px;
      height: 50px; }
  .ec-movieslider .soundBtn {
    position: absolute;
    bottom: 1em;
    left: 1em;
    font-size: 25px;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.3));
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition: 1s;
    /* SOUND-ONボタン */
    /* SOUND-OFFボタン */
    /* SOUND出力中のアイコン切り替え */ }
    @media only screen and (max-width: 767px) {
      .ec-movieslider .soundBtn {
        top: 10px;
        right: 10px;
        left: auto;
        bottom: auto; } }
    .ec-movieslider .soundBtn.is-ready {
      visibility: visible;
      opacity: 0.5; }
    .ec-movieslider .soundBtn:hover {
      opacity: 1; }
    .ec-movieslider .soundBtn:before {
      font-family: "jeiafont";
      content: "\e926";
      display: none; }
    .ec-movieslider .soundBtn:after {
      font-family: "jeiafont";
      content: "\e925";
      display: block; }
    .ec-movieslider .soundBtn.sound-on:before {
      display: block; }
    .ec-movieslider .soundBtn.sound-on:after {
      display: none; }

/* 動画メディアキャプション */
.mediaCaption {
  font-family: "Poppins", "Noto Sans JP", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  max-width: 350px;
  position: absolute;
  top: 50%;
  left: 7%;
  transform: translate(0, -50%);
  z-index: 9;
  overflow: hidden;
  /* キャプション表示 */
  /* スマホ用調整（.mediaCaption） */ }
  .mediaCaption .inner {
    background-color: rgba(255, 255, 255, 0.75);
    color: #000;
    padding: 1.5em;
    opacity: 0; }
  .mediaCaption .text_small {
    font-size: 0.9333em; }
  .mediaCaption .text_big {
    font-size: 2em;
    line-height: 1.2;
    margin: .25em 0;
    opacity: 0; }
  .mediaCaption .text_description {
    font-size: 1em; }
  .mediaCaption .linkBtn {
    display: inline-block;
    font-size: 1em;
    margin-top: 1em;
    line-height: 1;
    padding: 1em 1.5em;
    background-color: #000;
    color: #fff;
    pointer-events: auto;
    transition: .2s; }
    .mediaCaption .linkBtn:hover {
      text-decoration: none;
      background-color: #333; }
  .mediaCaption .closeBtn {
    display: none; }
  .mediaCaption.is-active .inner {
    animation: viewCaption .5s forwards 1s; }
    @media only screen and (max-width: 767px) {
      .mediaCaption.is-active .inner {
        animation: viewCaptionSp .5s forwards 1s; } }
  .mediaCaption.is-active .text_big {
    animation: viewCaption .8s forwards 1s; }

@keyframes viewCaption {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

@keyframes viewCaptionSp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }
  @media only screen and (max-width: 767px) {
    .mediaCaption {
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 100%;
      transform: translate(0, 0);
      font-size: 0.9rem;
      transition: transform 0.5s;
      /* キャプション折り畳みボタン */
      /* キャプション折り畳み状態 */ }
      .mediaCaption .text_big {
        font-size: 1.2em; }
      .mediaCaption .linkBtn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0; }
      .mediaCaption .closeBtn {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        font-size: 18px;
        line-height: 1.5;
        width: 1.5em;
        height: 1.5em;
        text-align: center;
        color: #000;
        margin-left: -0.5em;
        pointer-events: auto; }
        .mediaCaption .closeBtn:before {
          font-family: 'jeiafont';
          content: "\e902";
          display: block;
          width: 100%;
          height: 100%;
          background-color: transparent;
          transform: rotate(90deg); }
      .mediaCaption.is-close {
        transform: translate3d(0, 100%, 0);
        overflow: visible; }
        .mediaCaption.is-close .closeBtn {
          transform: translate3d(0, -100%, 0); }
          .mediaCaption.is-close .closeBtn:before {
            transform: rotate(-90deg);
            background-color: rgba(255, 255, 255, 0.75);
            border-radius: 0 5px 5px 0; } }

/*
トップセール

売れ筋商品やセール商品といった3カテゴリで３点ずつ、9点の商品を表示するブロックのスタイリングです。

使用箇所：
* Block/eyecatch.twig

Markup:
../styleguide/tmpl/12.2.html

Styleguide 12.2
*/
.ec-topSaleRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  /* セクション見出しを上書き調整（セクション見出しは「_12.4.heading.scss」で基本スタイリングされています） */
  /* 商品アイテムを上書き調整（商品アイテムは「_13.2.shelf.scss」にて基本スタイリングされています） */
  /* 先にタブレット・デスクトップ用のスタイリングを施します。スマホはレイアウトが違うのでそのあとで上書き定義します。 */
  /* スマホ用（商品情報を非表示とし商品画像を横に3点並べる） */ }
  @media only screen and (max-width: 767px) {
    .ec-topSaleRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-topSaleRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-topSaleRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-topSaleRole,
      .ec-layoutRole__mainBetweenColumn .ec-topSaleRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-topSaleRole .ec-secHeading .ec-secHeading__maintitle:before,
  .ec-topSaleRole .ec-secHeading .ec-secHeading__maintitle:after {
    display: none; }
  @media only screen and (max-width: 767px) {
    .ec-topSaleRole {
      /* セクション見出しを上書き調整 */ }
      .ec-topSaleRole .ec-topSaleRole__category {
        margin-top: 3.125vw; }
      .ec-topSaleRole .ec-secHeading {
        text-align: left;
        margin-bottom: 1rem; }
        .ec-topSaleRole .ec-secHeading .ec-secHeading__maintitle {
          display: inline-block;
          vertical-align: middle;
          font-size: 1.3rem; }
        .ec-topSaleRole .ec-secHeading .ec-secHeading__subtitle {
          display: inline-block;
          vertical-align: middle;
          margin: 0 0 0 0.5em; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-topSaleRole {
      /* セクション見出しを上書き調整 */
      /* 商品レイアウトを横3列表示 */ }
      .ec-topSaleRole .ec-topSaleRole__category {
        margin-top: 3.906vw; }
      .ec-topSaleRole .ec-secHeading {
        text-align: left;
        margin-bottom: 1rem; }
        .ec-topSaleRole .ec-secHeading .ec-secHeading__maintitle {
          display: inline-block;
          vertical-align: middle;
          font-size: 1.3rem; }
        .ec-topSaleRole .ec-secHeading .ec-secHeading__subtitle {
          display: inline-block;
          vertical-align: middle;
          margin: 0 0 0 0.5em; }
      .ec-topSaleRole .ec-topSaleRole__list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between; }
        .ec-topSaleRole .ec-topSaleRole__list .ec-productItemRole {
          flex: 0 0 32%; } }
  @media only screen and (min-width: 1200px) {
    .ec-topSaleRole {
      margin-top: 80px;
      display: flex;
      justify-content: space-between;
      /* セールカテゴリを3列表示 */
      /* セクション見出しを上書き調整 */ }
      .ec-topSaleRole .ec-topSaleRole__category {
        flex: 0 0 32%; }
      .ec-topSaleRole .ec-secHeading {
        text-align: left; }
        .ec-topSaleRole .ec-secHeading .ec-secHeading__maintitle {
          font-size: 1.4rem; } }
  .ec-topSaleRole .ec-productItemRole {
    display: flex;
    flex-flow: row;
    margin: 0; }
    .ec-topSaleRole .ec-productItemRole .ec-productItemRole__content {
      flex: 1 1 auto;
      padding: 0;
      padding-left: .75em;
      text-align: left; }
      .ec-topSaleRole .ec-productItemRole .ec-productItemRole__content .productItem_name {
        font-size: 0.933rem;
        margin-bottom: .5em; }
      .ec-topSaleRole .ec-productItemRole .ec-productItemRole__content .productItem_price {
        font-size: 0.933rem; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-topSaleRole .ec-productItemRole .ec-productItemRole__image {
        flex: 0 0 40%; } }
    @media only screen and (min-width: 1200px) {
      .ec-topSaleRole .ec-productItemRole {
        margin-bottom: 20px; }
        .ec-topSaleRole .ec-productItemRole .ec-productItemRole__image {
          flex: 0 0 40%; } }
  @media only screen and (max-width: 767px) {
    .ec-topSaleRole .ec-topSaleRole__list {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: 0px; }
      .ec-topSaleRole .ec-topSaleRole__list > div {
        flex: 0 0 auto;
        width: calc((100% - 1px - 10px ) / 3);
        margin-left: 5px;
        margin-top: 0px; }
        .ec-topSaleRole .ec-topSaleRole__list > div:nth-child(3n+1) {
          margin-left: 0; }
    .ec-topSaleRole .ec-productItemRole {
      display: block; }
    .ec-topSaleRole .ec-productItemRole__content {
      display: none; } }

/*
ボタン（未使用）

トップページで使用されているボタンのスタイルです（EC-CUBE標準）。
当テンプレートではこのスタイリングはありません。

Styleguide 12.3
*/
/*
セクション見出し

トップページで使用されているセクションの見出しのスタイルです。

当テンプレートでは特集商品ブロック（Block/new_item.twig）等で使用しています。
トップページ用となっていますが、他ページのセクションブロックでも流用される事は十分にあるものと思います。
（※その意味では「_1.1.heading.scss」に属させるべきものかもしれませんが）

使用箇所：
* Block/category.twig
* Block/category_nav_pc.twig
* Block/eyecatch.twig
* Block/news.twig
* Block/new_item.twig

Markup:
../styleguide/tmpl/12.4.html

Styleguide 12.4
*/
.ec-secHeading {
  text-align: center;
  line-height: 1;
  margin-bottom: 2rem; }
  .ec-secHeading .ec-secHeading__maintitle {
    display: inline-block;
    font-family: "Poppins", "Noto Sans JP", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
    position: relative;
    /* 横に付く罫線 */ }
    @media only screen and (max-width: 767px) {
      .ec-secHeading .ec-secHeading__maintitle {
        max-width: 80%; } }
    .ec-secHeading .ec-secHeading__maintitle:before, .ec-secHeading .ec-secHeading__maintitle:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 2em;
      height: 2px;
      background-color: #000;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media only screen and (max-width: 767px) {
        .ec-secHeading .ec-secHeading__maintitle:before, .ec-secHeading .ec-secHeading__maintitle:after {
          width: 1em; } }
    .ec-secHeading .ec-secHeading__maintitle:before {
      margin-right: .5em;
      left: -2.3em; }
      @media only screen and (max-width: 767px) {
        .ec-secHeading .ec-secHeading__maintitle:before {
          left: -1.3em; } }
    .ec-secHeading .ec-secHeading__maintitle:after {
      margin-left: .5em;
      right: -2.3em; }
      @media only screen and (max-width: 767px) {
        .ec-secHeading .ec-secHeading__maintitle:after {
          right: -1.3em; } }
    @media only screen and (min-width: 1200px) {
      .ec-secHeading .ec-secHeading__maintitle {
        font-size: 1.7rem; } }
  .ec-secHeading .ec-secHeading__subtitle {
    font-size: 0.933rem;
    color: #666;
    margin-top: .5em; }

/* 2カラムレイアウトの左カラム内のセクション見出し */
.ec-layoutRole__left .ec-secHeading {
  /* スマホ・タブレット時 */
  /* デスクトップ時 */ }
  @media screen and (max-width: 1199px) {
    .ec-layoutRole__left .ec-secHeading {
      text-align: left;
      margin-bottom: 1rem; }
      .ec-layoutRole__left .ec-secHeading .ec-secHeading__maintitle:before,
      .ec-layoutRole__left .ec-secHeading .ec-secHeading__maintitle:after {
        display: none; }
      .ec-layoutRole__left .ec-secHeading .ec-secHeading__maintitle {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.3rem; }
      .ec-layoutRole__left .ec-secHeading .ec-secHeading__subtitle {
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 0.5em; } }
  @media only screen and (min-width: 1200px) {
    .ec-layoutRole__left .ec-secHeading {
      text-align: left;
      border-bottom: solid 2px #000;
      padding-bottom: 1em; }
      .ec-layoutRole__left .ec-secHeading .ec-secHeading__maintitle {
        font-size: 1rem;
        font-weight: 400; }
        .ec-layoutRole__left .ec-secHeading .ec-secHeading__maintitle:before,
        .ec-layoutRole__left .ec-secHeading .ec-secHeading__maintitle:after {
          display: none; }
      .ec-layoutRole__left .ec-secHeading .ec-secHeading__subtitle {
        display: none; } }

/*
トピック

トップページで使用されているトピックブロックのスタイルです。

トピックとしてピックアップしたいカテゴリや商品等、4点を表示します。

画像間の余白は変更可能です。余白に合わせて各アイテム領域のサイズは調整されます。
スマホ時はレイアウトが変わります。

使用箇所：
* Block/topic.twig

Markup:
../styleguide/tmpl/12.5.html

Styleguide 12.5
*/
.ec-topicRole {
  /* 画像の上に被さるトピックタイトル */ }
  @media only screen and (max-width: 767px) {
    .ec-topicRole {
      /* 周囲余白 */
      padding: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-topicRole {
      /* 周囲余白 */
      padding: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-topicRole {
      /* 上余白 */
      margin-top: 20px; } }
  .ec-topicRole .ec-topicRole__list {
    padding: 0;
    overflow: hidden; }
    .ec-topicRole .ec-topicRole__list:after {
      content: "";
      display: table;
      clear: both; }
  .ec-topicRole .ec-topicRole__listItem {
    float: left;
    height: 0;
    position: relative; }
    .ec-topicRole .ec-topicRole__listItem a {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .ec-topicRole .ec-topicRole__listItem a .pic {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        transition: .6s; }
      .ec-topicRole .ec-topicRole__listItem a:hover {
        text-decoration: none; }
        @media only screen and (min-width: 1200px) {
          .ec-topicRole .ec-topicRole__listItem a:hover .pic {
            transform: scale(1.1);
            filter: brightness(0.8); } }
  @media only screen and (max-width: 767px) {
    .ec-topicRole .ec-topicRole__listItem:nth-child(1) {
      width: 100%;
      padding-top: 90%;
      margin-bottom: 3.125vw; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(2),
    .ec-topicRole .ec-topicRole__listItem:nth-child(3) {
      width: 50%;
      padding-top: 85%; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(4) {
      width: 100%;
      padding-top: 50%;
      margin-top: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-topicRole .ec-topicRole__listItem:nth-child(1) {
      width: calc((100% - 10px) / 2);
      padding-top: 54%;
      margin-right: 10px; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(2) {
      width: calc((((100% - 10px) / 2) - 10px) / 2);
      padding-top: 27%; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(3) {
      width: calc((((100% - 10px) / 2) - 10px) / 2);
      padding-top: 27%;
      margin-left: 10px; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(4) {
      width: calc((100% - 10px) / 2);
      padding-top: calc(27% - 10px);
      margin-top: 10px; } }
  @media only screen and (min-width: 1200px) {
    .ec-topicRole .ec-topicRole__listItem:nth-child(1) {
      width: calc((100% - 20px) / 2);
      padding-top: 54%;
      margin-right: 20px; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(2) {
      width: calc((((100% - 20px) / 2) - 20px) / 2);
      padding-top: 27%; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(3) {
      width: calc((((100% - 20px) / 2) - 20px) / 2);
      padding-top: 27%;
      margin-left: 20px; }
    .ec-topicRole .ec-topicRole__listItem:nth-child(4) {
      width: calc((100% - 20px) / 2);
      padding-top: calc(27% - 20px);
      margin-top: 20px; } }
  .ec-topicRole .ec-topicRole__listItemTitle {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -2em);
    display: inline-block;
    background-color: #fff;
    color: #000;
    padding: .5em 2em;
    font-size: 1rem;
    white-space: nowrap; }
    @media only screen and (max-width: 767px) {
      .ec-topicRole .ec-topicRole__listItemTitle {
        display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-topicRole .ec-topicRole__listItemTitle {
        font-size: .9333rem;
        transform: translate(-50%, -1em); } }

/*
特集商品・特集商品バナー

Styleguide 12.6
*/
/*
特集商品「FEATURED PRODUCTS」

トップページで使用されている特集商品「FEATURED PRODUCTS」ブロックのスタイルです。
レスポンシブにより商品の表示点数が可変します。

使用箇所：
* Block/new_item.twig

Markup:
../styleguide/tmpl/12.6.1.html

Styleguide 12.6.1
*/
.ec-featuredProductsRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-featuredProductsRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-featuredProductsRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-featuredProductsRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-featuredProductsRole,
      .ec-layoutRole__mainBetweenColumn .ec-featuredProductsRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-featuredProductsRole {
      margin-top: 9.375vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-featuredProductsRole {
      margin-top: 11.718vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-featuredProductsRole {
      margin-top: 80px;
      /* 商品アイテム マウスオーバーアクション */ }
      .ec-featuredProductsRole .ec-productItemRole {
        position: relative;
        overflow: hidden;
        /* 商品情報枠 */
        /* 商品名称 */
        /* 商品説明 */
        /* 商品価格 */ }
        .ec-featuredProductsRole .ec-productItemRole .ec-productItemRole__content {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 1em;
          background-color: rgba(255, 255, 255, 0.75);
          pointer-events: none;
          transition: .4s;
          transform: translate3d(0, 100%, 0); }
        .ec-featuredProductsRole .ec-productItemRole .productItem_name {
          opacity: 0;
          transform: translate3d(0, 1em, 0);
          transition: .4s;
          transition-delay: .25s; }
        .ec-featuredProductsRole .ec-productItemRole .productItem_description {
          opacity: 0;
          transform: translate3d(0, 1em, 0);
          transition: .4s;
          transition-delay: .35s; }
        .ec-featuredProductsRole .ec-productItemRole .productItem_price {
          opacity: 0;
          transform: translate3d(0, 1em, 0);
          transition: .4s;
          transition-delay: .35s; }
        .ec-featuredProductsRole .ec-productItemRole:hover .ec-productItemRole__content {
          transform: translate3d(0, 0, 0); }
        .ec-featuredProductsRole .ec-productItemRole:hover .productItem_name {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
        .ec-featuredProductsRole .ec-productItemRole:hover .productItem_description {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
        .ec-featuredProductsRole .ec-productItemRole:hover .productItem_price {
          opacity: 1;
          transform: translate3d(0, 0, 0); } }

/*
特集商品バナー

トップページで使用されている特集商品「FEATURED PRODUCTS」の下につくバナーブロックのスタイルです。

Markup:
../styleguide/tmpl/12.6.2.html

Styleguide 12.6.2
*/
.ec-featuredBannerRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-featuredBannerRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-featuredBannerRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-featuredBannerRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-featuredBannerRole,
      .ec-layoutRole__mainBetweenColumn .ec-featuredBannerRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-featuredBannerRole {
      margin-top: 9.375vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-featuredBannerRole {
      margin-top: 11.718vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-featuredBannerRole {
      margin-top: 80px; } }
  .ec-featuredBannerRole .ec-featuredBannerRole__list {
    /* バナーの配置 */ }
    @media only screen and (max-width: 767px) {
      .ec-featuredBannerRole .ec-featuredBannerRole__list .ec-featuredBannerRole__item + .ec-featuredBannerRole__item {
        margin-top: 3.125vw; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-featuredBannerRole .ec-featuredBannerRole__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        margin-top: 0; }
        .ec-featuredBannerRole .ec-featuredBannerRole__list > div {
          flex: 0 0 auto;
          width: calc((100% - 1px - 10px ) / 2);
          margin-left: 10px;
          margin-top: 0; }
          .ec-featuredBannerRole .ec-featuredBannerRole__list > div:nth-child(2n+1) {
            margin-left: 0; } }
    @media only screen and (min-width: 1200px) {
      .ec-featuredBannerRole .ec-featuredBannerRole__list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        margin-top: 0; }
        .ec-featuredBannerRole .ec-featuredBannerRole__list > div {
          flex: 0 0 auto;
          width: calc((100% - 1px - 20px ) / 2);
          margin-left: 20px;
          margin-top: 0; }
          .ec-featuredBannerRole .ec-featuredBannerRole__list > div:nth-child(2n+1) {
            margin-left: 0; } }
    @media only screen and (max-width: 767px) {
      .ec-featuredBannerRole .ec-featuredBannerRole__list + .ec-featuredBannerRole__list {
        margin-top: 6.25vw; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-featuredBannerRole .ec-featuredBannerRole__list + .ec-featuredBannerRole__list {
        margin-top: 10px; } }
    @media only screen and (min-width: 1200px) {
      .ec-featuredBannerRole .ec-featuredBannerRole__list + .ec-featuredBannerRole__list {
        margin-top: 20px; } }
  .ec-featuredBannerRole .ec-featuredBannerRole__item a {
    display: block;
    overflow: hidden;
    /* HTML構造によってはIE11でちらつく事があるので保険 */ }
    .ec-featuredBannerRole .ec-featuredBannerRole__item a * {
      overflow: hidden; }
    .ec-featuredBannerRole .ec-featuredBannerRole__item a img {
      transition: .6s; }
    .ec-featuredBannerRole .ec-featuredBannerRole__item a:hover img {
      transform: scale(1.1);
      filter: brightness(1.05); }

.ec-newItemRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-newItemRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-newItemRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-newItemRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-newItemRole,
      .ec-layoutRole__mainBetweenColumn .ec-newItemRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-newItemRole {
      margin-top: 9.375vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-newItemRole {
      margin-top: 11.718vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-newItemRole {
      margin-top: 80px;
      /* 商品アイテム マウスオーバーアクション */ }
      .ec-newItemRole .ec-productItemRole {
        position: relative;
        overflow: hidden;
        /* 商品情報枠 */
        /* 商品名称 */
        /* 商品説明 */
        /* 商品価格 */ }
        .ec-newItemRole .ec-productItemRole .ec-productItemRole__content {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 1em;
          background-color: rgba(255, 255, 255, 0.75);
          pointer-events: none;
          transition: .4s;
          transform: translate3d(0, 100%, 0); }
        .ec-newItemRole .ec-productItemRole .productItem_name {
          opacity: 0;
          transform: translate3d(0, 1em, 0);
          transition: .4s;
          transition-delay: .25s; }
        .ec-newItemRole .ec-productItemRole .productItem_description {
          opacity: 0;
          transform: translate3d(0, 1em, 0);
          transition: .4s;
          transition-delay: .35s; }
        .ec-newItemRole .ec-productItemRole .productItem_price {
          opacity: 0;
          transform: translate3d(0, 1em, 0);
          transition: .4s;
          transition-delay: .35s; }
        .ec-newItemRole .ec-productItemRole:hover .ec-productItemRole__content {
          transform: translate3d(0, 0, 0); }
        .ec-newItemRole .ec-productItemRole:hover .productItem_name {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
        .ec-newItemRole .ec-productItemRole:hover .productItem_description {
          opacity: 1;
          transform: translate3d(0, 0, 0); }
        .ec-newItemRole .ec-productItemRole:hover .productItem_price {
          opacity: 1;
          transform: translate3d(0, 0, 0); } }
  .ec-newItemRole .ec-newItemRole__list {
    visibility: hidden;
    opacity: 0;
    filter: blur(5px);
    transition: 1s; }
    .ec-newItemRole .ec-newItemRole__list.slick-initialized {
      visibility: visible;
      opacity: 1;
      filter: blur(0); }
  .ec-newItemRole .ec-newItemRole__item a {
    display: block;
    overflow: hidden;
    /* HTML構造によってはIE11でちらつく事があるので保険 */ }
    .ec-newItemRole .ec-newItemRole__item a * {
      overflow: hidden; }
    .ec-newItemRole .ec-newItemRole__item a img {
      transition: .6s; }
    .ec-newItemRole .ec-newItemRole__item a:hover img {
      transform: scale(1.1);
      filter: brightness(1.05); }
  .ec-newItemRole__trim-vertical {
    position: relative;
    width: 100%; }
    .ec-newItemRole__trim-vertical:before {
      content: "";
      display: block;
      padding-top: 150%;
      /* 高さを幅の75%に固定 */ }
    .ec-newItemRole__trim-vertical img {
      height: 100% !important;
      width: auto !important;
      max-width: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 50%;
      -webkit-transform: translateX(50%);
      transform: translateX(50%); }
    .ec-newItemRole__trim-vertical:hover img {
      height: 110% !important;
      top: -5%;
      -webkit-transform: translateX(50%);
      transform: translateX(50%) !important; }
    .ec-newItemRole__trim-vertical > .appear_itemImage {
      height: 100% !important;
      width: 100% !important;
      max-width: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0; }

/*
ピックアップカテゴリ

トップページで使用されているピックアップカテゴリブロックのスタイルです。

カテゴリごとに5点の商品表示をします。
1つ目の商品が大きく表示されるレイアウトになっています。

1つ目の大画像は通常は左配置ですが、「.ec-pickupCategoryRole__list」にclass「.rev」を併記することで大画像の配置を右配置に切り替えます。

スマホ時はレイアウトを解除してスライド表示（slick）に切り替えます。
ブラウザリサイズの度にデバイス幅によってslickスライダーの実装・破棄を行います。（タブレット・デスクトップ時はslickスライダーを破棄）

使用箇所：
* Block/category.twig

Markup:
../styleguide/tmpl/12.7.html

Styleguide 12.7
*/
.ec-pickupCategoryRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-pickupCategoryRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-pickupCategoryRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-pickupCategoryRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-pickupCategoryRole,
      .ec-layoutRole__mainBetweenColumn .ec-pickupCategoryRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-pickupCategoryRole {
      margin-top: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-pickupCategoryRole {
      margin-top: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-pickupCategoryRole {
      margin-top: 80px; } }
  .ec-pickupCategoryRole .ec-pickupCategoryRole__list {
    /* スマホ用 */
    /* タブレット時レイアウト */
    /* デスクトップ時レイアウト */ }
    .ec-pickupCategoryRole .ec-pickupCategoryRole__list:after {
      content: "";
      display: table;
      clear: both; }
    .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole {
      float: left;
      /* 商品アイテム マウスオーバーアクション */
      position: relative;
      overflow: hidden;
      /* 商品情報枠 */
      /* 商品名称 */
      /* 商品説明 */
      /* 商品価格 */ }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole .ec-productItemRole__content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1em;
        background-color: rgba(255, 255, 255, 0.75);
        pointer-events: none;
        transition: .4s;
        transform: translate3d(0, 100%, 0); }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole .productItem_name {
        opacity: 0;
        transform: translate3d(0, 1em, 0);
        transition: .4s;
        transition-delay: .25s; }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole .productItem_description {
        opacity: 0;
        transform: translate3d(0, 1em, 0);
        transition: .4s;
        transition-delay: .35s; }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole .productItem_price {
        opacity: 0;
        transform: translate3d(0, 1em, 0);
        transition: .4s;
        transition-delay: .35s; }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:hover .ec-productItemRole__content {
        transform: translate3d(0, 0, 0); }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:hover .productItem_name {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:hover .productItem_description {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:hover .productItem_price {
        opacity: 1;
        transform: translate3d(0, 0, 0); }
    @media only screen and (max-width: 767px) {
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list {
        /* 商品情報は非表示 */ }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole__content {
          display: none; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list {
        /* 反転レイアウト */ }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(1) {
          width: calc((100% - 10px) / 2); }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(n+2) {
          width: calc((((100% - 10px) / 2) - 10px) / 2);
          margin-left: 10px; }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(2),
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(3) {
          margin-bottom: 12px; }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list.rev .ec-productItemRole:nth-child(1) {
          float: right; }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list.rev .ec-productItemRole:nth-child(n+2) {
          margin-left: 0;
          margin-right: 10px; } }
    @media only screen and (min-width: 1200px) {
      .ec-pickupCategoryRole .ec-pickupCategoryRole__list {
        /* 反転レイアウト */ }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(1) {
          width: calc((100% - 20px) / 2); }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(n+2) {
          width: calc((((100% - 20px) / 2) - 20px) / 2);
          margin-left: 20px; }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(2),
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list .ec-productItemRole:nth-child(3) {
          margin-bottom: 25px; }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list.rev .ec-productItemRole:nth-child(1) {
          float: right; }
        .ec-pickupCategoryRole .ec-pickupCategoryRole__list.rev .ec-productItemRole:nth-child(n+2) {
          margin-left: 0;
          margin-right: 20px; } }

/*
新着情報

トップページで使用されている新着情報ブロックのスタイルです。

使用箇所：
Block/news.twig

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/12.8.html

Styleguide 12.8
*/
.ec-newsRole {
  /* ニュース一覧枠 */
  /* ニュースアイテム*/
  /* 左枠（日付） */
  /* 右枠（ニュースタイトル、本文）*/
  /* ニュースタイトル */
  /* MOREボタン */
  /* ニュース本文 */ }
  @media only screen and (max-width: 767px) {
    .ec-newsRole {
      margin-top: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-newsRole {
      margin-top: 7.812vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-newsRole {
      margin-top: 80px; } }
  .ec-newsRole .ec-newsRole__news {
    margin-top: -1rem; }
  .ec-newsRole .ec-newsRole__newsItem {
    display: flex;
    flex-flow: row wrap;
    padding: 1rem 0;
    border-bottom: 1px solid #eee; }
  .ec-newsRole .ec-newsRole__newsHeading {
    min-width: 6em;
    margin-right: 1rem; }
    .ec-newsRole .ec-newsRole__newsHeading time {
      font-family: "Poppins";
      font-size: 0.933rem; }
    @media only screen and (max-width: 767px) {
      .ec-newsRole .ec-newsRole__newsHeading {
        width: 100%;
        margin: 0; } }
  .ec-newsRole .ec-newsRole__newsColumn {
    flex: 1 0 auto;
    /* OPEN時の見せ方 */
    transition: .2s; }
    @media only screen and (max-width: 767px) {
      .ec-newsRole .ec-newsRole__newsColumn {
        width: 100%;
        margin: 0; } }
    .ec-newsRole .ec-newsRole__newsColumn.is-active {
      padding: .5em;
      background-color: #f8f8f8; }
  .ec-newsRole .ec-newsRole__newsTitle {
    font-size: 1rem;
    font-weight: 500;
    display: inline-block;
    vertical-align: middle; }
  .ec-newsRole .ec-newsRole__moreBtn {
    display: inline-block;
    font-family: "Poppins";
    font-size: 0.8rem;
    line-height: 1;
    padding: 0.2rem 0.3rem;
    border: solid 1px #666;
    background-color: #fff;
    margin-left: 1rem;
    cursor: pointer; }
  .ec-newsRole .ec-newsRole__newsDescription {
    margin-top: 1em;
    display: none; }

/*
商品一覧ヘッダーナビ

商品一覧ページのカテゴリトピックパスや、検索結果、また並び替えフォームをまとめたコンポーネントになります。

使用箇所：
Product/list.twig

Markup:
../styleguide/tmpl/13.1.html

Styleguide 13.1
*/
.ec-searchnavRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 100%;
  /*
  inner
  トピックパス枠（__topicspath）と表示件数＆並び替え枠（__infos）の2つのエリアをflexにて左右両極に配置
  */
  /* トピックパス枠 */
  /* 表示件数と並び替えフォーム枠 */
  /* 表示件数 */
  /* 並び替えフォーム */ }
  @media only screen and (max-width: 767px) {
    .ec-searchnavRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-searchnavRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-searchnavRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-searchnavRole,
      .ec-layoutRole__mainBetweenColumn .ec-searchnavRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-searchnavRole .ec-searchnavRole__inner {
    background-color: #f4f4f4;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .ec-searchnavRole .ec-searchnavRole__topicpath {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: .5em;
    font-size: 1rem; }
  .ec-searchnavRole .ec-searchnavRole__infos {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .ec-searchnavRole .ec-searchnavRole__counter {
    font-size: 0.933rem;
    margin-right: 1em; }
  .ec-searchnavRole .ec-searchnavRole__actions select {
    background-color: #fff; }
  @media only screen and (max-width: 767px) {
    .ec-searchnavRole .ec-searchnavRole__inner {
      margin-left: -3.125vw;
      margin-right: -3.125vw;
      padding: 10px 10px;
      flex-flow: row wrap; }
    .ec-searchnavRole .ec-searchnavRole__topicpath {
      width: 100%;
      padding: 0;
      font-size: 0.9rem; }
    .ec-searchnavRole .ec-searchnavRole__infos {
      width: 100%;
      border-top: solid 1px #ddd;
      margin-top: 10px;
      padding-top: 10px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-searchnavRole .ec-searchnavRole__inner {
      margin-left: -3.906vw;
      margin-right: -3.906vw;
      padding: 10px 3.906vw;
      flex-flow: row wrap; }
    .ec-searchnavRole .ec-searchnavRole__topicpath {
      width: 100%;
      padding: 0;
      font-size: 0.9rem; }
    .ec-searchnavRole .ec-searchnavRole__infos {
      width: 100%;
      border-top: solid 1px #ddd;
      margin-top: 10px;
      padding-top: 10px; } }

/*
トピックパス本体

sg-wrapper:
<div class="ec-searchnavRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/13.1.2.html

Styleguide 13.1.2
*/
.ec-topicpath {
  line-height: 1; }
  .ec-topicpath ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
  .ec-topicpath .ec-topicpath__item {
    white-space: nowrap;
    padding: 4px 0;
    /* 連結の「>」 */
    /* タイトルラベル */
    /* 検索キーワード */
    /* 最後のli要素を太字 */ }
    .ec-topicpath .ec-topicpath__item a {
      color: inherit;
      padding-top: 2px;
      padding-bottom: 2px;
      position: relative;
      text-decoration: none; }
      .ec-topicpath .ec-topicpath__item a:hover {
        text-decoration: none !important; }
      .ec-topicpath .ec-topicpath__item a:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: left bottom;
        transform: scaleX(0);
        transition: transform .3s; }
      .ec-topicpath .ec-topicpath__item a:hover:after {
        transform: scaleX(1); }
    .ec-topicpath .ec-topicpath__item:before {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 0.85em;
      margin-left: 4px; }
    .ec-topicpath .ec-topicpath__item.titleLabel {
      font-family: "Oswald"; }
    .ec-topicpath .ec-topicpath__item.titleFirst:before {
      display: none; }
    .ec-topicpath .ec-topicpath__item.is-search span {
      margin-left: 0.2em; }
    .ec-topicpath .ec-topicpath__item:last-child a,
    .ec-topicpath .ec-topicpath__item:last-child span {
      font-weight: 500;
      pointer-events: none; }

/*
子カテゴリ表示

商品一覧ページにおいて現在表示しているカテゴリに、子カテゴリがあればそれを一覧表示します。

Markup:
../styleguide/tmpl/13.1.3.html

Styleguide 13.1.3
*/
.ec-childCategoryRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 100%;
  margin: 20px 0; }
  @media only screen and (max-width: 767px) {
    .ec-childCategoryRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-childCategoryRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-childCategoryRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-childCategoryRole,
      .ec-layoutRole__mainBetweenColumn .ec-childCategoryRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-childCategoryRole ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 0.933rem; }
    .ec-childCategoryRole ul li {
      display: inline-block; }
      .ec-childCategoryRole ul li:before {
        font-family: "jeiafont";
        content: "\e91a";
        vertical-align: -1px; }
      .ec-childCategoryRole ul li a {
        color: inherit;
        padding-top: 2px;
        padding-bottom: 2px;
        position: relative;
        text-decoration: none; }
        .ec-childCategoryRole ul li a:hover {
          text-decoration: none !important; }
        .ec-childCategoryRole ul li a:after {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: #000;
          position: absolute;
          bottom: 0;
          left: 0;
          transform-origin: left bottom;
          transform: scaleX(0);
          transition: transform .3s; }
        .ec-childCategoryRole ul li a:hover:after {
          transform: scaleX(1); }
    .ec-childCategoryRole ul li.titleLabel {
      font-family: "Oswald";
      font-weight: 500;
      vertical-align: -1px; }
      .ec-childCategoryRole ul li.titleLabel:before {
        display: none; }
  @media only screen and (min-width: 1200px) {
    .ec-childCategoryRole__main {
      display: flex;
      align-items: center;
      flex-wrap: wrap; } }
  .ec-childCategoryRole__main__image {
    width: 90px;
    margin-right: 20px; }
    .ec-childCategoryRole__main__image img {
      display: block;
      margin: auto; }
  .ec-childCategoryRole__main__text {
    flex: 1;
    font-size: 14px;
    margin-top: 5px; }
    @media only screen and (min-width: 1200px) {
      .ec-childCategoryRole__main__text {
        margin-top: 0; } }
  .ec-childCategoryRole__main__title {
    flex: 1;
    font-size: 16px; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-childCategoryRole__main__title {
        font-size: 18px; } }
    @media only screen and (min-width: 1200px) {
      .ec-childCategoryRole__main__title {
        font-size: 18px; } }

/*
商品一覧

商品一覧 に関する Project コンポーネントを定義します。

使用箇所：
Product/list.twig

Markup:
../styleguide/tmpl/13.2.html

Styleguide 13.2
*/
.ec-shelfRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 100%; }
  @media only screen and (max-width: 767px) {
    .ec-shelfRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-shelfRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-shelfRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-shelfRole,
      .ec-layoutRole__mainBetweenColumn .ec-shelfRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-shelfRole#RelatedProduct-product_area {
    max-width: 1480px;
    margin-top: 80px; }

/*
商品一覧グリッド

横1列あたりの商品配置数を2～5個で、デバイス幅のブレイクポイントを4パターンで定義します。

陳列レイアウト用のmixinを使用します。（_projects.scss）
@mixin shelfLayout($amount: 5, $gutter_w: 20px, $gutter_h: 20px, $item: li) {}
* $amount: 横に配置する数
* $gutter_W: アイテム間の左右余白
* $gutter_h: アイテム間の上下余白
* $item: 子要素のタグ名称・class名称（デフォルトはli要素）

使用箇所：
Product/list.twig

Markup:
../styleguide/tmpl/13.2.1.html

Styleguide 13.2.1
*/
.ec-shelfGrid {
  /* 商品レイアウト */ }
  @media (max-width: 767px) {
    .ec-shelfGrid {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -20px; }
      .ec-shelfGrid > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 15px ) / 2);
        margin-left: 15px;
        margin-top: 20px; }
        .ec-shelfGrid > li:nth-child(2n+1) {
          margin-left: 0; } }
  @media (min-width: 768px) and (max-width: 979px) {
    .ec-shelfGrid {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -30px; }
      .ec-shelfGrid > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 30px ) / 3);
        margin-left: 15px;
        margin-top: 30px; }
        .ec-shelfGrid > li:nth-child(3n+1) {
          margin-left: 0; } }
  @media (min-width: 980px) and (max-width: 1199.99px) {
    .ec-shelfGrid {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -40px; }
      .ec-shelfGrid > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 60px ) / 4);
        margin-left: 20px;
        margin-top: 40px; }
        .ec-shelfGrid > li:nth-child(4n+1) {
          margin-left: 0; } }
  @media (min-width: 1200px) {
    .ec-shelfGrid {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      margin-top: -40px; }
      .ec-shelfGrid > li {
        flex: 0 0 auto;
        width: calc((100% - 1px - 40px ) / 3);
        margin-left: 20px;
        margin-top: 40px; }
        .ec-shelfGrid > li:nth-child(3n+1) {
          margin-left: 0; } }
  #RelatedProduct-product_area .ec-shelfGrid {
    margin-top: 0; }
  #page_product_detail .ec-shelfGrid {
    display: block;
    flex-flow: unset; }

/*
商品アイテム

商品一覧だけでなく特集商品など、一覧表示される商品アイテムの共通スタイリングになります。

sg-wrapper:
<div class="ec-shelfRole">
  <ul class="ec-shelfGrid">
    <li class="ec-shelfGrid__item">
      <sg-wrapper-content/>
    </li>
  </ul>
</div>

Markup:
../styleguide/tmpl/13.2.2.html

Styleguide 13.2.2
*/
.ec-productItemRole {
  height: 100%;
  display: flex;
  flex-flow: column;
  /* 商品画像ブロック */
  /* 商品説明欄欄ブロック */
  /* 底配置ブロック */ }
  .ec-productItemRole .ec-productItemRole__image {
    position: relative;
    min-height: 0%;
    /* 商品属性アイコンタグ（商品画像の上に被せて表示する静的タグ） */
    /* 品切れ表示 */ }
    .ec-productItemRole .ec-productItemRole__image a {
      display: block;
      display: block;
      overflow: hidden;
      /* HTML構造によってはIE11でちらつく事があるので保険 */ }
      .ec-productItemRole .ec-productItemRole__image a * {
        overflow: hidden; }
      .ec-productItemRole .ec-productItemRole__image a img {
        transition: .6s; }
      .ec-productItemRole .ec-productItemRole__image a:hover img {
        transform: scale(1.1);
        filter: brightness(1.05); }
      .ec-productItemRole .ec-productItemRole__image a img {
        width: 100%;
        height: auto; }
    .ec-productItemRole .ec-productItemRole__image .productItem_tags {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 1;
      pointer-events: none;
      /* タグ形状 */ }
      .ec-productItemRole .ec-productItemRole__image .productItem_tags .tag {
        margin: 0;
        margin-top: 3px;
        margin-right: 3px;
        font-family: "Roboto", sans-serif;
        font-size: 0.85rem;
        line-height: 1;
        padding: .5em .75em;
        white-space: nowrap;
        background-color: #119bdf;
        color: #fff;
        /* 「NEW」タグ */
        /* 「SALE」タグ */ }
        .ec-productItemRole .ec-productItemRole__image .productItem_tags .tag.tag-new {
          background-color: #ff8000; }
        .ec-productItemRole .ec-productItemRole__image .productItem_tags .tag.tag-sale {
          background-color: #EB3D98; }
        @media only screen and (min-width: 1200px) {
          .ec-productItemRole .ec-productItemRole__image .productItem_tags .tag {
            font-size: 0.933rem;
            padding: .55em 0.75em; } }
    .ec-productItemRole .ec-productItemRole__image .productItem_stockout {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 3.3rem;
      font-family: 'Dynalight', serif;
      font-style: italic;
      font-size: 1rem;
      background-color: rgba(189, 0, 0, 0.4);
      color: #fff;
      text-align: center;
      pointer-events: none; }
      @media only screen and (max-width: 767px) {
        .ec-productItemRole .ec-productItemRole__image .productItem_stockout {
          font-size: 0.9rem; } }
  .ec-productItemRole .ec-productItemRole__content {
    padding-top: 1em;
    background-color: #fff;
    /* 中央寄せ */
    text-align: center;
    /* 商品名称 */
    /* 商品属性タグ（商品説明欄内に表示されるタグ機能による動的タグ） */
    /* 商品説明（一覧用） */
    /* 商品価格 */ }
    .ec-productItemRole .ec-productItemRole__content .productItem_name {
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
      margin-bottom: 10px;
      /* テキストが長くて改行される場合は左寄せになるよう */
      display: inline-block;
      text-align: left; }
    .ec-productItemRole .ec-productItemRole__content .productItem_tags {
      margin: 0;
      padding: 0;
      margin-left: -3px;
      display: flex;
      justify-content: center;
      flex-flow: row wrap; }
      .ec-productItemRole .ec-productItemRole__content .productItem_tags .tag {
        border: solid 1px #ddd;
        margin: 0;
        margin-left: 3px;
        margin-bottom: 3px;
        border-radius: 3px;
        font-size: 0.8rem;
        padding: 0.4em 0.75em;
        line-height: 1;
        white-space: nowrap; }
    .ec-productItemRole .ec-productItemRole__content .productItem_description {
      font-size: 0.933rem;
      margin-bottom: 10px;
      /* テキストが長くて改行される場合は左寄せになるよう */
      display: inline-block;
      text-align: left; }
    .ec-productItemRole .ec-productItemRole__content .productItem_price {
      font-size: 1rem;
      line-height: 1.2; }
  .ec-productItemRole .ec-productItemRole__bottom {
    margin: auto 0 0; }

/*
商品アイテム内 カートインアクション

当テンプレートではコメントアウトにて除去している部分ですが、最低限のスタイリングをしております。
カートインアクション機能が必要な場合はlist.twigのカートイン部分とモーダル表示部分のコメントを解除しCSSスタイリングを調整してください。

sg-wrapper:
<div class="ec-shelfRole">
  <ul class="ec-shelfGrid">
    <li class="ec-shelfGrid__item">
      <sg-wrapper-content/>
    </li>
  </ul>
</div>

Markup:
../styleguide/tmpl/13.2.3.html

Styleguide 13.2.3
*/
.ec-productItemRole {
  /* タイトルラベル */
  /* 数量エリア */
  /* カートに入れるボタン */ }
  .ec-productItemRole .ec-productItemRole__actions select {
    max-width: 100%; }
  .ec-productItemRole .ec-productItemRole__label {
    margin: 0;
    margin-top: 1em; }
  .ec-productItemRole .quantityLabel {
    display: inline-block;
    vertical-align: baseline;
    margin-top: 0;
    margin-right: .5em; }
  .ec-productItemRole .ec-quantityRole {
    margin-top: 1em;
    display: inline-flex;
    vertical-align: baseline; }
    @media only screen and (max-width: 767px) {
      .ec-productItemRole .ec-quantityRole .ec-quantityRole__btn {
        width: 2.65rem;
        height: 2.65rem;
        line-height: 2.65rem; }
      .ec-productItemRole .ec-quantityRole .ec-numberInput {
        height: 2.65rem; }
        .ec-productItemRole .ec-quantityRole .ec-numberInput input {
          height: 2.65rem;
          width: 2.65rem; } }
  .ec-productItemRole .ec-productItemRole__btn {
    margin-top: 1em; }
    .ec-productItemRole .ec-productItemRole__btn .ec-blockBtn--cartin {
      height: 3rem;
      line-height: 3rem; }

/*
ページャ

商品一覧やマイページコンテンツで使われるページ送りコンポーネントを定義します。

使用箇所：
* Product/list.twig
* Mypage/favorite.twig
* Mypage/index.twig

※ページャ本体は「pager.twig」ブロックとなっており各ページからincludeされています。

Markup:
../styleguide/tmpl/13.3.html

Styleguide 13.3
*/
.ec-pagerRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-pagerRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-pagerRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-pagerRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-pagerRole,
      .ec-layoutRole__mainBetweenColumn .ec-pagerRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

※EC-CUBE4標準のままです

使用箇所：
* Product/list.twig
* Product/detail.twig

Markup:
../styleguide/tmpl/13.4.html

Styleguide 13.4
*/
.ec-modal .checkbox {
  display: none; }

.ec-modal .ec-modal-overlay {
  opacity: 0;
  transition: all 0.3s ease;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  transform: scale(1);
  display: flex;
  background-color: rgba(0, 0, 0, 0.3); }

.ec-modal .ec-modal-wrap {
  background-color: #fff;
  border: 1px solid #333;
  width: 90%;
  margin: 20px;
  padding: 40px 5px;
  border-radius: 2px;
  transition: all 0.5s ease;
  -ms-flex-item-align: center;
  align-self: center; }
  .ec-modal .ec-modal-wrap .ec-modal-box {
    text-align: center; }
  .ec-modal .ec-modal-wrap .ec-modal-box div {
    margin-top: 20px; }
  @media only screen and (min-width: 1200px) {
    .ec-modal .ec-modal-wrap {
      padding: 40px 10px;
      width: 50%;
      margin: 20px auto; } }
  .ec-modal .ec-modal-wrap.small {
    width: 30%; }
  .ec-modal .ec-modal-wrap.full {
    width: 100%;
    height: 100%; }

.ec-modal .ec-modal-overlay .ec-modal-close {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 20px;
  height: 30px;
  width: 20px; }
  .ec-modal .ec-modal-overlay .ec-modal-close:hover {
    cursor: pointer;
    color: #4b5361; }

.ec-modal .ec-modal-overlay-close {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -100; }

.ec-modal input:checked ~ .ec-modal-overlay-close {
  z-index: 9998; }

.ec-modal input:checked ~ .ec-modal-overlay {
  transform: scale(1);
  opacity: 1;
  z-index: 9997;
  overflow: auto; }

.ec-modal input:checked ~ .ec-modal-overlay .ec-modal-wrap {
  transform: translateY(0);
  z-index: 9999; }

/*
商品詳細

商品詳細ページに関する Project コンポーネントを定義します。

使用箇所：
Product/detail.twig

Markup:
../styleguide/tmpl/14.1.html

Styleguide 14.1
*/
.ec-productRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  max-width: 1480px;
  /* デスクトップ時のみ左右2分割レイアウト */ }
  @media only screen and (max-width: 767px) {
    .ec-productRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-productRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-productRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-productRole,
      .ec-layoutRole__mainBetweenColumn .ec-productRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (min-width: 1200px) {
    .ec-productRole .ec-productRole__grid {
      display: flex;
      justify-content: space-between; } }
  @media only screen and (max-width: 767px) {
    .ec-productRole .ec-productRole__gridCell.cellLeft {
      margin-left: -3.125vw;
      margin-right: -3.125vw; }
    .ec-productRole .ec-productRole__gridCell.cellRight {
      margin-top: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-productRole .ec-productRole__gridCell.cellLeft {
      margin-left: -3.906vw;
      margin-right: -3.906vw; }
    .ec-productRole .ec-productRole__gridCell.cellRight {
      margin-top: 30px; } }
  @media only screen and (min-width: 1200px) {
    .ec-productRole .ec-productRole__gridCell.cellLeft {
      flex: 0 0 auto;
      width: 57%; }
    .ec-productRole .ec-productRole__gridCell.cellRight {
      width: auto;
      margin-left: 40px;
      overflow: hidden;
      flex: 1; } }

/*
商品ビジュアル表示

EC-CUBE4標準と同じく、slickを利用して画像ビジュアルを実装しています

管理機能「フリーエリア」に、HTMLコードを記述するとビジュアル画像それぞれに説明文を表示させることができます。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.1.html

Styleguide 14.1.1
*/
.ec-productRole__visual {
  /* メインビジュアル格納枠 */
  /* ビジュアルナビ */ }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-productRole__visual {
      display: flex;
      justify-content: space-between; } }
  @media only screen and (min-width: 1200px) {
    .ec-productRole__visual {
      display: flex;
      justify-content: space-between; } }
  .ec-productRole__visual .ec-productVisualMain {
    position: relative;
    overflow: hidden; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-productRole__visual .ec-productVisualMain {
        flex: 0 0 auto;
        width: 83.334%; } }
    @media only screen and (min-width: 1200px) {
      .ec-productRole__visual .ec-productVisualMain {
        flex: 0 0 auto;
        width: 83.25%; } }
  .ec-productRole__visual .ec-productVisualNav {
    /* デスクトップ・タブレット時はビジュアルナビをメインビジュアルの横に配置して縦スライド機能をつけています。
     * スマホ時はスライド機能は使わずにメインビジュアルの下にタイルレイアウトで配置します。
     * レスポンシブにおいて、スライド機能のON/OFFやレイアウト変更はロジックが煩雑になるかと思いましたので、
     * ビジュアルナビは、あらかじめデスクトップ・タブレット用のナビと、スマホ用のナビをそれぞれ組み込んでおきレスポンシブで表示を切り替えています。
     */
    /* スマホ用ナビ */
    /* デスクトップ・タブレット用ナビ */ }
    @media only screen and (max-width: 767px) {
      .ec-productRole__visual .ec-productVisualNav.sphone {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-flow: row wrap;
        margin-top: -1px;
        margin-top: 0; }
        .ec-productRole__visual .ec-productVisualNav.sphone > .slideThumb {
          flex: 0 0 auto;
          width: calc((100% - 1px - 4px ) / 5);
          margin-left: 1px;
          margin-top: 1px; }
          .ec-productRole__visual .ec-productVisualNav.sphone > .slideThumb:nth-child(5n+1) {
            margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-productRole__visual .ec-productVisualNav.sphone {
        display: none; } }
    @media only screen and (min-width: 1200px) {
      .ec-productRole__visual .ec-productVisualNav.sphone {
        display: none; } }
    .ec-productRole__visual .ec-productVisualNav.desktop {
      flex: 0 0 auto;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      /* slickの準備ができたら表示 */
      /* prev,nextボタンのスタイリングを上書き調整します */
      /* ナビエリアにマウスオーバーしたらprev,nextボタンを表示します */
      /* サムネイル */ }
      @media only screen and (min-width: 768px) and (max-width: 1199px) {
        .ec-productRole__visual .ec-productVisualNav.desktop {
          width: 16.666%; } }
      @media only screen and (min-width: 1200px) {
        .ec-productRole__visual .ec-productVisualNav.desktop {
          width: 16.35%; } }
      .ec-productRole__visual .ec-productVisualNav.desktop.slick-initialized {
        opacity: 1;
        visibility: visible; }
      .ec-productRole__visual .ec-productVisualNav.desktop .slick-arrow {
        font-size: 18px;
        padding: 0;
        width: 100%;
        height: 30px;
        background-color: rgba(255, 255, 255, 0.5);
        color: #333;
        opacity: 1; }
        .ec-productRole__visual .ec-productVisualNav.desktop .slick-arrow:hover {
          background-color: rgba(255, 255, 255, 0.85); }
        .ec-productRole__visual .ec-productVisualNav.desktop .slick-arrow.slick-prev {
          top: 0;
          left: 0;
          transform: translate3d(0, -100%, 0); }
          .ec-productRole__visual .ec-productVisualNav.desktop .slick-arrow.slick-prev i {
            transform: rotate(-90deg); }
        .ec-productRole__visual .ec-productVisualNav.desktop .slick-arrow.slick-next {
          top: auto;
          bottom: 0;
          left: 0;
          transform: translate3d(0, 100%, 0); }
          .ec-productRole__visual .ec-productVisualNav.desktop .slick-arrow.slick-next i {
            transform: rotate(90deg); }
      .ec-productRole__visual .ec-productVisualNav.desktop:hover .slick-arrow {
        transform: translate3d(0, 0, 0); }
      @media only screen and (max-width: 767px) {
        .ec-productRole__visual .ec-productVisualNav.desktop {
          display: none; } }
      .ec-productRole__visual .ec-productVisualNav.desktop .slideThumb {
        border: none;
        cursor: pointer;
        display: block;
        overflow: hidden;
        /* HTML構造によってはIE11でちらつく事があるので保険 */ }
        .ec-productRole__visual .ec-productVisualNav.desktop .slideThumb * {
          overflow: hidden; }
        .ec-productRole__visual .ec-productVisualNav.desktop .slideThumb img {
          transition: .6s; }
        .ec-productRole__visual .ec-productVisualNav.desktop .slideThumb:hover img {
          transform: scale(1.1);
          filter: brightness(1.05); }
        @media only screen and (min-width: 768px) and (max-width: 1199px) {
          .ec-productRole__visual .ec-productVisualNav.desktop .slideThumb {
            margin-bottom: 0px; } }
        @media only screen and (min-width: 1200px) {
          .ec-productRole__visual .ec-productVisualNav.desktop .slideThumb {
            margin-bottom: 4px; } }

/*
商品プロフィール（商品データ）

画像ビジュアル枠に対してこちらは右枠の（デスクトップ時）、商品データ枠になります。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.2.html

Styleguide 14.1.2
*/
.ec-productRole {
  /* 商品プロフィール枠 */
  /* 商品タイトル */
  /* 通常価格 */
  /* 通常価格：価格部分 */
  /* 通常価格：税込 */
  /* 販売価格 */
  /* 詳細コメント */
  /* 商品コード（商品タイトルの中で展開） */
  /* 属性タグ */
  /* 関連カテゴリ */
  /* アクション枠 */
  /* 規格枠 */
  /* カートイン枠数量・カートイン・お気に入り） */
  /* シェアボタン */ }
  .ec-productRole .ec-productRole__title {
    margin-bottom: 1.5rem; }
    .ec-productRole .ec-productRole__title h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0; }
  .ec-productRole__subTitle-en {
    font-family: 'Dynalight', serif;
    margin-top: 7px;
    display: block;
    line-height: 1.4; }
  .ec-productRole .ec-productRole__priceRegular {
    margin-bottom: 0.5em; }
  .ec-productRole .ec-productRole__priceRegularPrice span {
    margin-left: .5em; }
  .ec-productRole .ec-productRole__priceRegularTax {
    font-size: .8em; }
  .ec-productRole .ec-productRole__price {
    font-size: 1.5rem; }
    .ec-productRole .ec-productRole__price .ec-price__price {
      font-weight: 500; }
  .ec-productRole .ec-productRole__description {
    font-size: 1rem;
    margin: 30px 0;
    padding-top: 30px;
    line-height: 1.7;
    color: #666;
    border-top: 1px solid #eee; }
    .ec-productRole .ec-productRole__description .header {
      margin-top: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      border-top: 1px solid #eee; }
      .ec-productRole .ec-productRole__description .header > br {
        display: none; }
      .ec-productRole .ec-productRole__description .header h1, .ec-productRole .ec-productRole__description .header h2 {
        text-align: center;
        line-height: 1;
        margin-bottom: 2rem; }
      .ec-productRole .ec-productRole__description .header h1 {
        font-family: "Poppins", "Noto Sans JP", sans-serif;
        font-size: 1.5rem;
        font-weight: normal;
        margin: 0; }
      .ec-productRole .ec-productRole__description .header h2 {
        font-size: 0.933rem;
        color: #666;
        margin-top: .5em; }
    .ec-productRole .ec-productRole__description [class^="img-flex-"] {
      margin-bottom: 20px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between; }
    .ec-productRole .ec-productRole__description .img-flex-2 > img,
    .ec-productRole .ec-productRole__description .img-flex-2 a {
      width: 49%;
      height: 49%; }
    .ec-productRole .ec-productRole__description .img-flex-3 > img,
    .ec-productRole .ec-productRole__description .img-flex-3 a {
      width: 32%;
      height: 32%; }
    .ec-productRole .ec-productRole__description .img-flex-4 > img,
    .ec-productRole .ec-productRole__description .img-flex-4 a {
      width: 24%;
      height: 24%; }
    .ec-productRole .ec-productRole__description table {
      width: 100%;
      font-size: 0.8rem; }
      @media only screen and (min-width: 1200px) {
        .ec-productRole .ec-productRole__description table {
          font-size: 1rem; } }
      .ec-productRole .ec-productRole__description table th, .ec-productRole .ec-productRole__description table td {
        border: 1px solid #ccc;
        padding: 10px 5px;
        line-height: 2em; }
        @media only screen and (min-width: 1200px) {
          .ec-productRole .ec-productRole__description table th, .ec-productRole .ec-productRole__description table td {
            padding: 20px; } }
    .ec-productRole .ec-productRole__description video {
      width: 300px; }
  .ec-productRole .ec-productRole__code {
    font-family: 'Dynalight', serif;
    font-size: 0.933rem;
    color: #666;
    margin: 0.5rem 0;
    padding-left: 0.4rem; }
  .ec-productRole .ec-productRole__tags {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin: 0.5rem 0;
    margin-left: -7px;
    font-size: 0.933rem; }
    .ec-productRole .ec-productRole__tags li {
      margin-left: 7px;
      line-height: 1;
      padding: 0.4em 0.7em;
      border: solid 1px #ccc;
      color: #666; }
  .ec-productRole .ec-productRole__category {
    margin: 1rem 0;
    font-size: 0.933rem; }
    .ec-productRole .ec-productRole__category .titleLabel {
      color: #666; }
    .ec-productRole .ec-productRole__category .ec-topicpath .ec-topicpath__item {
      /* 最後のli要素を太字 */ }
      .ec-productRole .ec-productRole__category .ec-topicpath .ec-topicpath__item:first-child:before {
        content: "・"; }
      .ec-productRole .ec-productRole__category .ec-topicpath .ec-topicpath__item:last-child a {
        font-weight: normal;
        pointer-events: auto; }
  .ec-productRole .ec-productRole__actions {
    border-top: 1px solid #eee;
    margin: 1rem 0; }
  .ec-productRole .ec-productRole__class {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .ec-productRole .ec-productRole__class.classCategory1 {
      margin-top: 10px; }
    .ec-productRole .ec-productRole__class.classCategory2 {
      margin-top: 10px; }
    .ec-productRole .ec-productRole__class .classLabel {
      margin: 0;
      margin-right: 1em;
      min-width: 6em; }
      .ec-productRole .ec-productRole__class .classLabel:after {
        content: "："; }
    .ec-productRole .ec-productRole__class .ec-select select, .ec-productRole .ec-productRole__class .ec-birth select {
      max-width: 100%; }
  .ec-productRole .ec-productRole__cartinRow {
    display: flex;
    align-items: flex-end;
    margin-top: 1em;
    /* 数量タイトルラベル */
    /* 数量 */
    /* 次回入荷予定日 */
    /* カートインボタン・品切れ */
    /* お気に入りボタン */ }
    .ec-productRole .ec-productRole__cartinRow .quantityLabel {
      margin: 0;
      margin-right: 1em;
      display: none; }
    .ec-productRole .ec-productRole__cartinRow .ec-quantityRole {
      margin-right: 10px; }
    .ec-productRole .ec-productRole__cartinRow .ec-nextArrival {
      width: 100%;
      text-align: center;
      font-family: 'Dynalight', serif;
      font-style: italic; }
      @media only screen and (max-width: 767px) {
        .ec-productRole .ec-productRole__cartinRow .ec-nextArrival {
          font-size: 0.9rem; } }
    .ec-productRole .ec-productRole__cartinRow .ec-nextArrivals .ec-nextArrival {
      display: none; }
    .ec-productRole .ec-productRole__cartinRow .ec-productRole__btn {
      flex: 1 1 auto; }
      .ec-productRole .ec-productRole__cartinRow .ec-productRole__btn button {
        height: 3.33rem;
        line-height: 3.33rem;
        width: 100%; }
    .ec-productRole .ec-productRole__cartinRow .ec-blockBtn--favorite {
      margin-left: 10px; }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__cartinRow {
        flex-flow: row wrap; }
        .ec-productRole .ec-productRole__cartinRow .quantityLabel {
          display: block; }
        .ec-productRole .ec-productRole__cartinRow .ec-quantityRole {
          margin-bottom: 10px; } }
  .ec-productRole__contactBtn .contact {
    background-color: #fff;
    border: 2px solid #888888;
    text-decoration: none;
    border-radius: 40px;
    padding: 5px 10px;
    width: 100%; }
    .ec-productRole__contactBtn .contact:hover {
      opacity: 0.7; }
  .ec-productRole .ec-productRole__share {
    margin-top: 2rem; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-productRole .ec-productRole__share {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }
    @media only screen and (min-width: 1200px) {
      .ec-productRole .ec-productRole__share {
        display: flex;
        flex-wrap: wrap;
        align-items: center; } }
    @media only screen and (max-width: 767px) {
      .ec-productRole .ec-productRole__share__right {
        padding-left: 0;
        margin-top: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-productRole .ec-productRole__share__right {
        padding-left: 20px;
        flex: 1; } }
    @media only screen and (min-width: 1200px) {
      .ec-productRole .ec-productRole__share__right {
        padding-left: 20px;
        flex: 1; } }
    .ec-productRole .ec-productRole__share .shareLabel {
      margin: 0;
      margin-right: .5em;
      display: inline-block; }
    .ec-productRole .ec-productRole__share .shareBtnList {
      display: inline-block;
      list-style: none;
      margin: 0;
      padding: 0; }
      .ec-productRole .ec-productRole__share .shareBtnList li {
        display: inline-block;
        margin-left: 3px; }
        .ec-productRole .ec-productRole__share .shareBtnList li a {
          display: block;
          font-size: 1.1rem;
          width: 2em;
          height: 2em;
          line-height: 2em;
          text-align: center;
          background: #666;
          color: #fff;
          border-radius: 50%;
          cursor: pointer;
          transition: .3s; }
          .ec-productRole .ec-productRole__share .shareBtnList li a i + span {
            display: none; }
          .ec-productRole .ec-productRole__share .shareBtnList li a.twitter:hover {
            background-color: #1B95E0; }
          .ec-productRole .ec-productRole__share .shareBtnList li a.facebook:hover {
            background-color: #3B5999; }
          .ec-productRole .ec-productRole__share .shareBtnList li a.googleplus:hover {
            background-color: #db4a39; }
          .ec-productRole .ec-productRole__share .shareBtnList li a.pinterest:hover {
            background-color: #cb2027; }
          .ec-productRole .ec-productRole__share .shareBtnList li a.line:hover {
            background-color: #20c962; }

/*
商品フリーエリア

フリーエリアのスタイリングになります。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.90.html

Styleguide 14.1.90
*/
.ec-productRole .ec-productRole__freearea {
  margin-top: 30px; }
  @media only screen and (min-width: 1200px) {
    .ec-productRole .ec-productRole__freearea {
      margin-top: 60px; } }

/*
商品詳細タブ切り替えコンテンツ

管理機能「フリーエリア」に、HTMLコードを記述するとタブ切り替えコンテンツを表示させることができます。

サンプルコンテンツの、table要素、dl-table要素、dlレスポンシブの表組みスタイリングは、_0.1.base.scssにて行っています。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/14.1.91.html

Styleguide 14.1.91
*/
.ec-productDetailTab {
  /* タブ */
  /* コンテンツ枠 */
  /* コンテンツ */ }
  .ec-productDetailTab .ec-productDetailTab__nav {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee; }
    .ec-productDetailTab .ec-productDetailTab__nav li {
      font-size: 1rem;
      margin-right: 1.5em;
      padding: 0.25em 0;
      color: #666;
      border-bottom: solid 2px transparent;
      cursor: pointer;
      transition: .2s; }
      .ec-productDetailTab .ec-productDetailTab__nav li:hover {
        border-color: #000; }
      .ec-productDetailTab .ec-productDetailTab__nav li.is-active {
        color: #222;
        border-color: #000; }
  .ec-productDetailTab .ec-productDetailTab__content {
    display: none; }
    .ec-productDetailTab .ec-productDetailTab__content.is-active {
      display: block;
      display: block;
      opacity: 1;
      visibility: visible;
      animation: fadeIn 150ms linear 0s; }
    .ec-productDetailTab .ec-productDetailTab__content h2 {
      font-size: 1.4rem;
      margin: 0;
      margin-bottom: 1em; }

.product_collection .ec-breadcrumbRole {
  display: none; }

.ec-productIntroductionRole {
  font-size: 71.4%;
  color: #000;
  font-family: "Noto Serif JP", serif; }
  .ec-productIntroductionRole div, .ec-productIntroductionRole h2, .ec-productIntroductionRole h3, .ec-productIntroductionRole h4, .ec-productIntroductionRole h5, .ec-productIntroductionRole h6, .ec-productIntroductionRole i, .ec-productIntroductionRole img, .ec-productIntroductionRole li, .ec-productIntroductionRole nav, .ec-productIntroductionRole ol, .ec-productIntroductionRole p, .ec-productIntroductionRole section, .ec-productIntroductionRole small, .ec-productIntroductionRole span, .ec-productIntroductionRole strong, .ec-productIntroductionRole ul, .ec-productIntroductionRole video {
    background: 0 0;
    border: 0;
    font-size: 100%;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
    text-align: left; }
  .ec-productIntroductionRole small {
    display: inline-block; }
  .ec-productIntroductionRole img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: bottom; }
  .ec-productIntroductionRole ul {
    list-style: none; }
  .ec-productIntroductionRole a {
    background: 0 0;
    margin: 0;
    padding: 0;
    color: #000;
    font-size: 10px;
    vertical-align: baseline;
    text-decoration: none;
    cursor: pointer !important; }
  .ec-productIntroductionRole .-indent {
    text-indent: -.5em; }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .mt-1 {
      margin-top: 10px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .mt-1 {
      margin-top: .73vw; } }
  .ec-productIntroductionRole .intro-image,
  .ec-productIntroductionRole .block_6 .item > li:nth-of-type(1),
  .ec-productIntroductionRole .block_8 .scene,
  .ec-productIntroductionRole .block_5 .scene {
    margin-left: auto;
    margin-right: auto; }
  .ec-productIntroductionRole .js-fadeIn {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
    transition: transform 1s,opacity 1s; }
  .ec-productIntroductionRole .js-fadeIn.is-active {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  @media (max-width: 767px) {
    .ec-productIntroductionRole br.forPC {
      display: none; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole br.forPC {
      display: inline; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole br.forSP {
      display: inline; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole br.forSP {
      display: none; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .main,
    .ec-productIntroductionRole .main.forPC {
      display: none !important; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .main.forSP {
      display: block !important; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .main.forSP {
      display: none !important; } }
  .ec-productIntroductionRole .mainvisual {
    width: 100%; }
    .ec-productIntroductionRole .mainvisual .kv {
      position: relative; }
      .ec-productIntroductionRole .mainvisual .kv video {
        display: block;
        width: 100%; }
  .ec-productIntroductionRole .main.forSP .intro-text {
    padding-left: 14.67vw;
    margin-top: 13.33vw; }
    .ec-productIntroductionRole .main.forSP .intro-text .base-text {
      color: #666;
      letter-spacing: .1em; }
    .ec-productIntroductionRole .main.forSP .intro-text .small {
      font-size: 2.93vw; }
    .ec-productIntroductionRole .main.forSP .intro-text .base-text {
      font-size: 3.73vw;
      line-height: 1.43; }
    .ec-productIntroductionRole .main.forSP .intro-text .base-text + .base-text {
      margin-top: 6.67vw; }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .intro {
      padding-top: 100px;
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .intro {
      max-width: 79.06vw;
      padding-top: 7.32vw; } }
  .ec-productIntroductionRole .intro .base-text {
    color: #666;
    letter-spacing: .1em; }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .intro .base-text {
        font-size: 3.73vw;
        line-height: 1.43; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .intro .base-text {
        font-size: 14px;
        line-height: 1.71; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .intro .base-text + .base-text {
        margin-top: 25px; } }
    @media (min-width: 768px) and (max-width: 1365px) {
      .ec-productIntroductionRole .intro .base-text + .base-text {
        margin-top: 1.83vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .intro .small {
      font-size: 12px; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .intro-inner {
      padding-top: 12.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .intro-inner {
      display: flex; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .intro-image {
      width: 71.33vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .intro-image {
      width: 46.11%;
      padding-left: 185px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .intro-image {
      padding-left: 13.54vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .intro-image img {
      width: 210px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .intro-image img {
      width: 15.37vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .intro-text {
      width: 53.89%; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .collection-wrap {
      padding-top: 24vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .collection-wrap {
      padding-top: 100px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .collection-wrap {
      padding-top: 7.32vw; } }
  .ec-productIntroductionRole .block {
    font-family: 'Playfair Display', serif; }
    .ec-productIntroductionRole .block .price {
      font-family: 'Dynalight', serif; }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block .left,
      .ec-productIntroductionRole .block .right {
        color: #666;
        font-size: 2.93vw; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .block .left,
      .ec-productIntroductionRole .block .right {
        letter-spacing: .08em;
        color: #222;
        font-size: 11px; } }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block .brand {
        color: #666;
        font-size: 2.93vw; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .block .brand {
        letter-spacing: .08em;
        color: #222;
        font-size: 12px; } }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block .item-info {
        margin-top: 8vw;
        padding: 0 5.33vw; } }
    .ec-productIntroductionRole .block .item-info li {
      letter-spacing: .08em; }
      .ec-productIntroductionRole .block .item-info li a {
        text-decoration: underline;
        letter-spacing: .08em;
        transition: opacity .3s; }
        @media (max-width: 767px) {
          .ec-productIntroductionRole .block .item-info li a {
            color: #666;
            font-size: 3.47vw; } }
        @media (min-width: 768px) {
          .ec-productIntroductionRole .block .item-info li a {
            color: #222;
            font-size: 14px; } }
        .ec-productIntroductionRole .block .item-info li a:hover {
          opacity: .7; }
      @media (max-width: 767px) {
        .ec-productIntroductionRole .block .item-info li {
          color: #666;
          font-size: 3.47vw;
          line-height: 1.15; } }
      @media (min-width: 768px) {
        .ec-productIntroductionRole .block .item-info li {
          color: #222;
          font-size: 14px;
          line-height: 1.5; } }
      @media (max-width: 767px) {
        .ec-productIntroductionRole .block .item-info li + li {
          margin-top: 4vw; } }
      @media (min-width: 768px) {
        .ec-productIntroductionRole .block .item-info li + li {
          margin-top: 9px; } }
    .ec-productIntroductionRole .block .item-color {
      margin-top: 2vw; }
      @media (min-width: 768px) {
        .ec-productIntroductionRole .block .item-color {
          margin-top: 9px; } }
      .ec-productIntroductionRole .block .item-color p + ul {
        margin-top: 2vw; }
        @media (min-width: 768px) {
          .ec-productIntroductionRole .block .item-color p + ul {
            margin-top: 8px; } }
      .ec-productIntroductionRole .block .item-color ul {
        display: flex;
        gap: 2vw; }
        @media (min-width: 768px) {
          .ec-productIntroductionRole .block .item-color ul {
            gap: 8px; } }
        .ec-productIntroductionRole .block .item-color ul li {
          width: 8vw;
          margin: 0 !important; }
          @media (min-width: 768px) {
            .ec-productIntroductionRole .block .item-color ul li {
              width: 60px; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_1 {
      margin-top: 32vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_1 {
      margin-bottom: 28.27vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_1 {
      max-width: 792px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 182px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_1 {
      max-width: 57.98vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_1 .item {
      flex-wrap: wrap; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_1 .item {
      position: relative; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_1 .item > li:nth-of-type(1) {
      margin: 0 auto 16.53vw;
      padding: 0 10vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_1 .item > li:nth-of-type(1) {
      width: 400px;
      margin: 0 auto 63px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_1 .item > li:nth-of-type(1) {
      width: 29.28vw;
      margin: 0 auto 4.61vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_1 .item > li:nth-of-type(2) {
      order: -1; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_1 .item > li:nth-of-type(2) {
      width: 100%; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_1 .item-info {
      margin-top: 30px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_1 .item-info {
      margin-top: 2.2vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_2 {
      margin-top: 32vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_2 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 182px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_2 {
      max-width: 79.06vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_2 .item {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_2 .item > li:nth-of-type(1) {
      order: -1;
      padding: 0 10vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_2 .item > li:nth-of-type(1) {
      width: 468px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_2 .item > li:nth-of-type(1) {
      width: 34.26vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_2 .item > li:nth-of-type(2) {
      margin-top: 16.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_2 .item > li:nth-of-type(2) {
      width: 582px;
      padding-top: 120px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_2 .item > li:nth-of-type(2) {
      padding-top: 8.78vw;
      width: 42.61vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_2 .item-info {
      position: absolute;
      bottom: -7px;
      left: 612px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_2 .item-info {
      left: 44.8vw;
      bottom: -.51vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3 {
      margin-top: 32vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 182px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3 {
      max-width: 79.06vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3 .item {
      display: flex;
      justify-content: space-between;
      position: relative; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3 .item > li:nth-of-type(1) {
      margin-top: 8vw;
      padding: 0 10vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3 .item > li:nth-of-type(1) {
      width: 43.33%;
      padding-top: 400px;
      display: block; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3 .item > li:nth-of-type(1) {
      padding-top: 29.28vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3 .item > li:nth-of-type(2) {
      margin-top: 16.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3 .item > li:nth-of-type(2) {
      width: 53.89%; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3 .item-info {
      position: absolute;
      bottom: -7px;
      left: 500px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3 .item-info {
      left: 36.6vw;
      bottom: -.51vw; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3 .item-info li {
      font-size: 1.02vw; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3 .item-info li a {
      font-size: 1.02vw; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3 .item-info li + li,
    .ec-productIntroductionRole .block_3 .item-info li .item-color {
      margin-top: 0; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3-2 {
      margin-top: 32vw;
      margin-bottom: 28.53vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3-2 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 212px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3-2 {
      max-width: 79.06vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3-2 .item {
      margin-bottom: 16vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3-2 .item {
      display: flex;
      justify-content: space-between;
      position: relative;
      margin-bottom: 84px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3-2 .item {
      margin-bottom: 6.15vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3-2 .item > li:nth-of-type(1) {
      margin-top: 8vw;
      padding: 0 10vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3-2 .item > li:nth-of-type(1) {
      width: 43.33%;
      padding-top: 267px;
      display: block; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3-2 .item > li:nth-of-type(1) {
      padding-top: 19.55vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3-2 .item > li:nth-of-type(2) {
      margin-top: 16.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3-2 .item > li:nth-of-type(2) {
      width: 53.89%; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3-2 .item-info {
      position: absolute;
      bottom: -7px;
      left: 500px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3-2 .item-info {
      left: 36.6vw;
      bottom: -.51vw; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3-2 .item-info li + li,
    .ec-productIntroductionRole .block_3-2 .item-info li .item-color {
      margin-top: 0; } }
  .ec-productIntroductionRole .block_3-2 .item2 {
    margin: 0 auto; }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block_3-2 .item2 {
        padding: 0 5.33vw; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .block_3-2 .item2 {
        width: 400px; } }
    @media (min-width: 768px) and (max-width: 1365px) {
      .ec-productIntroductionRole .block_3-2 .item2 {
        width: 29.28vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_3-2 .item2-info {
      margin-top: 8vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_3-2 .item2-info {
      margin-top: 28px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_3-2 .item2-info {
      margin-top: 2.05vw; } }
  .ec-productIntroductionRole .block_3-2 .item2-info p {
    letter-spacing: .08em; }
    .ec-productIntroductionRole .block_3-2 .item2-info p a {
      text-decoration: underline;
      letter-spacing: .08em;
      transition: opacity .3s; }
      @media (max-width: 767px) {
        .ec-productIntroductionRole .block_3-2 .item2-info p a {
          color: #666;
          font-size: 3.47vw; } }
      @media (min-width: 768px) {
        .ec-productIntroductionRole .block_3-2 .item2-info p a {
          color: #222;
          font-size: 14px; } }
      .ec-productIntroductionRole .block_3-2 .item2-info p a:hover {
        opacity: .7; }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block_3-2 .item2-info p {
        color: #666;
        font-size: 3.47vw;
        line-height: 1.15; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .block_3-2 .item2-info p {
        color: #222;
        font-size: 14px;
        line-height: 1.5; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_4 {
      margin-top: 32vw;
      margin-bottom: 28.53vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_4 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 207px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_4 {
      max-width: 79.06vw;
      margin-bottom: 15.15vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_4 .item {
      display: flex;
      flex-direction: column-reverse;
      flex-wrap: wrap;
      position: relative;
      margin-bottom: 84px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_4 .item {
      margin-bottom: 6.15vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(1) {
      margin-top: 8vw;
      padding: 0 10vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(1) {
      width: 100%;
      padding-top: 60px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(1) {
      padding-top: 4.39vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(1) img {
      width: 480px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(1) img {
      width: 35.14vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(2) {
      margin-top: 16.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(2) {
      width: 600px;
      margin: 0 auto; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_4 .item > li:nth-of-type(2) {
      width: 43.92vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_4 .item-info {
      position: absolute;
      bottom: -7px;
      left: 510px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_4 .item-info {
      left: 37.34vw;
      bottom: -.51vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_5 {
      margin-top: 29.33vw;
      margin-bottom: 31.2vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_5 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_5 {
      max-width: 79.06vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_5 .item {
      margin-bottom: 29.73vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_5 .item {
      display: flex;
      align-items: flex-end;
      margin-bottom: 215px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_5 .item {
      margin-bottom: 15.74vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_5 .item-image {
      margin-top: 29.33vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_5 .item-image {
      width: 582px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_5 .item-image {
      width: 42.61vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_5 .item-info {
      padding-left: 30px;
      margin-bottom: -7px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_5 .item-info {
      padding-left: 2.2vw;
      margin-bottom: -.51vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_5 .scene {
      width: 60vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_5 .scene {
      width: 33.33%;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_6 {
      margin-top: 29.33vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_6 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_6 {
      max-width: 79.06vw; } }
  .ec-productIntroductionRole .block_6 .item {
    position: relative; }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block_6 .item > li:nth-of-type(1) {
        width: 80vw; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .block_6 .item > li:nth-of-type(1) {
        width: 44.44%; } }
    .ec-productIntroductionRole .block_6 .item > li:nth-of-type(2) {
      position: absolute; }
      @media (max-width: 767px) {
        .ec-productIntroductionRole .block_6 .item > li:nth-of-type(2) {
          width: 66.67vw;
          right: 0;
          top: 94.67vw; } }
      @media (min-width: 768px) {
        .ec-productIntroductionRole .block_6 .item > li:nth-of-type(2) {
          width: 37.04%;
          left: 640px;
          top: 575px; } }
      @media (min-width: 768px) and (max-width: 1365px) {
        .ec-productIntroductionRole .block_6 .item > li:nth-of-type(2) {
          left: 46.85vw;
          top: 42.09vw; } }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .block_6 .item > li:nth-of-type(3) {
        margin-top: 93.33vw; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .block_6 .item > li:nth-of-type(3) {
        display: flex;
        align-items: flex-end;
        margin-top: 415px; } }
    @media (min-width: 768px) and (max-width: 1365px) {
      .ec-productIntroductionRole .block_6 .item > li:nth-of-type(3) {
        margin-top: 30.38vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_6 .item-image {
      width: 68.52%;
      padding-left: 140px;
      flex-shrink: 0; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_6 .item-image {
      padding-left: 10.25vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_6 .item-info {
      padding-left: 30px;
      margin-bottom: -7px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_6 .item-info {
      padding-left: 2.2vw;
      margin-bottom: -.51vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_7 {
      margin-top: 29.33vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_7 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_7 .item {
      width: 73.61%;
      margin-left: auto;
      margin-right: auto; } }
  @media (min-width: 767px) {
    .ec-productIntroductionRole .block_7 .item-info {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_8 {
      margin-top: 29.33vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_8 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 200px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_8 {
      max-width: 79.06vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_8 .item {
      display: flex;
      align-items: flex-end;
      margin-top: 200px; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_8 .item-info {
      padding-left: 30px;
      margin-bottom: -7px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_8 .item-info {
      padding-left: 2.2vw;
      margin-bottom: -.51vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_8 .scene {
      width: 60vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_8 .scene {
      width: 33.33%;
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_8 .item-image {
      margin-top: 29.33vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_8 .item-image {
      width: 52.41%; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_9 {
      margin-top: 28vw;
      margin-bottom: 18.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_9 {
      max-width: 1080px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 100px; } }
  @media (min-width: 768px) and (max-width: 1365px) {
    .ec-productIntroductionRole .block_9 {
      margin-bottom: 7.32vw; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_9 .item {
      margin-top: 14.67vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_9 .item {
      width: 52.41%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 90px; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_9 .item-info {
      font-size: 26px;
      line-height: 1.15;
      margin-top: 30px; } }
  @media (max-width: 767px) {
    .ec-productIntroductionRole .block_9 .scene {
      padding: 0 10vw; } }
  @media (min-width: 768px) {
    .ec-productIntroductionRole .block_9 .scene {
      width: 44.44%;
      margin-left: auto;
      margin-right: auto; } }
  .ec-productIntroductionRole .notes {
    color: #666;
    display: flex;
    justify-content: center;
    letter-spacing: .08em; }
    @media (max-width: 767px) {
      .ec-productIntroductionRole .notes {
        margin-top: 32vw;
        font-size: 3.47vw;
        line-height: 1.15; } }
    @media (min-width: 768px) {
      .ec-productIntroductionRole .notes {
        font-size: 14px;
        line-height: 2.14; } }

/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

使用箇所：
* Cart/index.twig
* Shopping/complete.twig
* Shopping/confirm.twig
* Shopping/index.twig
* Shopping/nonmember.twig

※ここで定義される`.ec-cartRole__error`はShopping/alert.twigでも使用されている。

Markup:
../styleguide/tmpl/15.1.html

Styleguide 15.1
*/
.ec-cartRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-cartRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-cartRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-cartRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-cartRole,
      .ec-layoutRole__mainBetweenColumn .ec-cartRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-cartRole .ec-cartRole__progress {
    margin-bottom: 30px; }
    @media only screen and (max-width: 767px) {
      .ec-cartRole .ec-cartRole__progress {
        margin-bottom: 20px; } }
  .ec-cartRole .ec-cartRole__error {
    margin-bottom: 20px; }
  .ec-cartRole .ec-cartRole__totalText {
    text-align: center;
    margin-bottom: 20px; }
    .ec-cartRole .ec-cartRole__totalText p {
      margin: 0; }
  .ec-cartRole .ec-cartRole__cart {
    margin: 2em 0; }
  .ec-cartRole .ec-cartRole__actions {
    width: 300px;
    margin-left: auto;
    margin-right: 0; }
    @media only screen and (max-width: 767px) {
      .ec-cartRole .ec-cartRole__actions {
        width: 100%;
        margin: 0; } }
  .ec-cartRole .ec-cartRole__total {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 1em 0 1.5em;
    border-bottom: 1px solid #eee; }
  .ec-cartRole .ec-cartRole__totalAmount {
    font-size: 1.6rem;
    font-weight: 500; }
  .ec-cartRole .ec-blockBtn--action {
    margin-bottom: 10px; }

/*
カートページのコンテナ違反？

cart/index.twig
.ec-cartRoleの中に、さらに.ec-cartRoleが指定されていて2重コンテナになっているのをCSSにて吸収対処

shopping/nonmemberでも同様に「.ec-customerRole」の中に.ec-cartRoleが入っているので対処

Styleguide 15.1.1
*/
.ec-cartRole .ec-cartRole,
.ec-customerRole .ec-cartRole {
  padding: 0; }

/*
カート商品表示

カート内の商品をを表示するテーブル枠です。

タブレット・デスクトップはtableでレイアウトを組みますが、スマホは.ec-cartRowごとにflexでレイアウトを組んでいます。

使用箇所：
Cart/index.twig

Markup:
../styleguide/tmpl/15.1.2.html

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  width: 100%;
  display: table;
  border-collapse: collapse; }
  @media only screen and (max-width: 767px) {
    .ec-cartTable {
      display: block;
      border-top: 1px solid #eee; } }

/*
カート商品表示：テーブルヘッダ

カート商品表示のテーブルのヘッダです。
タブレット・デスクトップのみで、スマホでは非表示となります。

使用箇所：
Cart/index.twig

Markup:
../styleguide/tmpl/15.1.3.html

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.3
*/
.ec-cartHeader {
  display: table-row;
  background-color: #f3f1eb; }
  .ec-cartHeader .ec-cartHeader__label {
    display: table-cell;
    font-size: 0.9rem;
    padding: 0.75em 0;
    text-align: center;
    overflow-x: hidden;
    color: #222; }
  @media only screen and (max-width: 767px) {
    .ec-cartHeader {
      display: none; } }

/*
カート商品単位（共通パーツ）

カート内のアイテムを表示する単位です。

スマホと、タブレット・デスクトップとでレイアウトの組み方を変えてあります。
タブレット・デスクトップではtable行としてのレイアウトですが、スマホはflexを使ってレイアウトを組んでいます。

デバイス事のCSSスタイリングが煩雑ですので、共通パーツをスタイリングしておき、あとはデバイス個別にスタイリングをする流れとなっております。

使用箇所：
Cart/index.twig

Markup:
../styleguide/tmpl/15.1.4.html

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.4
*/
.ec-cartRow {
  list-style: none;
  margin: 0;
  padding: 0;
  /* ■ 削除カラム（デバイス共通） */
  /* ■ 商品内容カラム（デバイス共通） */
  /* 商品画像 */
  /* 商品サマリ */
  /* ■ 数量カラム（デバイス共通） */
  /* ■ 小計カラム（デバイス共通） */ }
  .ec-cartRow a:hover {
    text-decoration: none; }
  .ec-cartRow .ec-cartRow__delColumn .ec-icon {
    display: block;
    color: inherit; }
    .ec-cartRow .ec-cartRow__delColumn .ec-icon:before {
      font-family: "jeiafont";
      content: "\e918";
      display: block;
      font-size: 18px;
      width: 1.5em;
      height: 1.5em;
      line-height: 1.5em;
      text-align: center;
      color: #666;
      border: solid 1px #ccc;
      border-radius: 50%; }
    .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
      display: none; }
  .ec-cartRow .ec-cartRow__contentColumn {
    text-align: left; }
  .ec-cartRow .ec-cartRow__img {
    display: inline-block;
    vertical-align: top; }
    .ec-cartRow .ec-cartRow__img a {
      display: block;
      overflow: hidden;
      /* HTML構造によってはIE11でちらつく事があるので保険 */ }
      .ec-cartRow .ec-cartRow__img a * {
        overflow: hidden; }
      .ec-cartRow .ec-cartRow__img a img {
        transition: .6s; }
      .ec-cartRow .ec-cartRow__img a:hover img {
        transform: scale(1.1);
        filter: brightness(1.05); }
  .ec-cartRow .ec-cartRow__summary {
    display: inline-block;
    vertical-align: top;
    padding-left: 1em;
    /* 商品価格 */ }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
      /* 規格名をくくる要素はないので、まず規格名称を基準にスタイリングしておいて、
        * それから商品名が格納されるa要素でスタイリングを施す
        */
      font-size: 0.9em;
      line-height: 1.2;
      margin-left: 0.5rem;
      margin-bottom: .5em; }
      .ec-cartRow .ec-cartRow__summary .ec-cartRow__name a {
        display: block;
        color: inherit;
        font-size: 1rem;
        font-weight: 500;
        margin-left: -0.5rem;
        margin-bottom: 0.4em; }
        .ec-cartRow .ec-cartRow__summary .ec-cartRow__name a + br {
          display: none; }
    .ec-cartRow .ec-cartRow__summary .ec-cartRow__unitPrice {
      font-size: 1rem; }
  .ec-cartRow .ec-cartRow__amountColumn {
    /* 数量表示 */
    /* 数量変更ボタンエリア */
    /* 数量変更ボタン共通 */
    /* 数量プラスボタン */
    /* ボタン無効 */ }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
      display: inline-flex; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton,
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton,
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
      display: block;
      width: 36px;
      height: 36px;
      margin-left: 1px;
      color: inherit; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon,
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton__icon,
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: #f3f1eb;
        color: #000;
        padding: 5px;
        box-sizing: border-box; }
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon:before,
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton__icon:before,
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled__icon:before {
          font-family: "jeiafont";
          content: "\e917";
          font-size: 1.5em; }
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon img,
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton__icon img,
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled__icon img {
          display: none; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton__icon:before {
      content: "\e916"; }
    .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon {
      color: #bbb; }
  .ec-cartRow .ec-cartRow__subtotalColumn {
    font-weight: 500; }
  .ec-cartRow .ec-cartRow__sutbtotalSP {
    display: none; }
  .ec-cartRow .ec-cartRow__amountSP {
    display: none; }

/*
カート商品単位（スマホ用）

flexを用いてまず、「商品内容カラム」を100%フル幅にして、
改行する形で「数量カラム」と「小計カラム」を左右2列で配置。
削除ボタンはpositionで右上に配置するレイアウトです。
*/
@media only screen and (max-width: 767px) {
  .ec-cartRow {
    display: flex;
    flex-flow: row wrap;
    font-size: 1rem;
    padding-top: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid #eee;
    position: relative;
    /* ■ 削除カラム */
    /* ■ 商品内容カラム */
    /* 商品画像 */
    /* ■ 数量カラム */
    /* ■ 小計カラム */ }
    .ec-cartRow .ec-cartRow__delColumn {
      position: absolute;
      top: 0.5em;
      right: 0; }
    .ec-cartRow .ec-cartRow__contentColumn {
      order: 1;
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 5px; }
    .ec-cartRow .ec-cartRow__img {
      width: 22vw;
      max-width: 120px; }
    .ec-cartRow .ec-cartRow__amountColumn {
      order: 3;
      flex: 1 1 auto;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      /* 数量表示 */
      /* 数量変更ボタンエリア */
      /* 数量変更ボタンサイズ */ }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
        margin-right: 1em; }
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount:before {
          content: "数量";
          display: inline-block;
          margin-right: .5em; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton,
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton,
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
        width: 36px;
        height: 36px; }
    .ec-cartRow .ec-cartRow__subtotalColumn {
      order: 4;
      flex: 0 0 auto;
      min-width: 25%;
      padding-left: 1em;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: flex-end; } }

/*
カート商品単位（タブレット・デスクトップ用用）

*/
@media only screen and (min-width: 768px) {
  .ec-cartRow {
    display: table-row;
    font-size: 1rem;
    border-bottom: 1px solid #eee;
    /* ■ 削除カラム */
    /* ■ 商品内容カラム */
    /* 商品画像 */
    /* ■ 数量カラム */
    /* ■ 小計カラム */ }
    .ec-cartRow .ec-cartRow__delColumn {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      width: 5em; }
      .ec-cartRow .ec-cartRow__delColumn .ec-icon {
        display: inline-block; }
        .ec-cartRow .ec-cartRow__delColumn .ec-icon:before {
          border: none; }
    .ec-cartRow .ec-cartRow__contentColumn {
      display: table-cell;
      padding-top: 1em;
      padding-bottom: 1em; }
    .ec-cartRow .ec-cartRow__img {
      width: 100px; }
    .ec-cartRow .ec-cartRow__amountColumn {
      text-align: center;
      display: table-cell;
      vertical-align: middle;
      /* 数量表示 */ }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1em; }
        .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount:before {
          content: "数量";
          display: inline-block;
          margin-right: .5em; }
      .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
        vertical-align: middle; }
    .ec-cartRow .ec-cartRow__subtotalColumn {
      text-align: right;
      display: table-cell;
      vertical-align: middle; } }

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

使用箇所：
* Cart/index.twig
* Shopping/shipping.twig
* Shopping/alert.twig

Markup:
../styleguide/tmpl/15.1.6.html

Styleguide 15.1.6
*/
.ec-alert-warning {
  background-color: #ff9999;
  color: #fff;
  font-size: 1rem;
  padding: 1em;
  text-align: center; }
  .ec-alert-warning .ec-alert-warning__icon {
    display: inline-block;
    width: 2em;
    height: auto;
    margin-bottom: 0.5em; }

/*
送料無料等のメッセージ

「送料無料」「あと〇〇円で送料無料になります」のメッセージ

このエリアのclass名が「.ec-cartRole__progress」となっており、これはカートやショッピングフローの
ページ冒頭にある「1-2-3-4」進捗状況を表示する部分と同じclass名称となっています。
コーティング上のミスだと思いますが、本来であればTwig側を編集すべきだと思いますが、
テンプレートとしては極力、必要以外の編集を加えたくないのでCSSで吸収したいと思います。

ただ、メッセージが空の場合でも要素自体は空のまま出力されてしまっているのでこのメッセージ欄の余白は影響を及ぼします。
その前提で上下のパーツの調整が必要となるかもしれません。

もし、Twig側を編集するのでありましたら、こちらのCSSも調整または削除してください。

Markup:
../styleguide/tmpl/15.1.8.html

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.8
*/
.ec-cartRole .ec-cartRole__cart + .ec-cartRole__progress {
  margin: 0;
  padding: 0;
  margin-top: 1em;
  text-align: right; }

/*
ショッピング完了コンテナ
*/
.ec-cartCompleteRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-cartCompleteRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-cartCompleteRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-cartCompleteRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-cartCompleteRole,
      .ec-layoutRole__mainBetweenColumn .ec-cartCompleteRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

/*
注文内容確認

ショッピングフロー（入力、確認）と、マイページの注文履歴詳細

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/15.2.html

Styleguide 15.2
*/
/*
ページレイアウト

タブレット・デスクトップは注文内容とカートサマリーを左右2分割。スマホは縦1列配置。
*/
.ec-orderRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-orderRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-orderRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-orderRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-orderRole,
      .ec-layoutRole__mainBetweenColumn .ec-orderRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-orderRole {
      display: block; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-orderRole {
      display: flex;
      justify-content: space-between; }
      .ec-orderRole .ec-orderRole__detail {
        flex: 1 1 auto;
        padding-right: 30px; }
      .ec-orderRole .ec-orderRole__summary {
        flex: 0 0 250px;
        /*
      スクロールしてもサマリー欄をページ内に粘着（sticky）させる
      EC-CUBE4標準ではJavascriptで実装している機能ですが、CSSの「position:sticky;」を使って実装してみます。
      当テンプレートではヘッダーがページ上部に固定させる仕様のため、そのままではヘッダーに隠れてしまいます。
      スクリプトを調整するよりはstickyでの実装が簡単だと判断しました。（※IE11では動作しません。）
      */ }
        .ec-orderRole .ec-orderRole__summary .ec-totalBox {
          margin: 0;
          position: sticky;
          top: 70px; } }
  @media only screen and (min-width: 1200px) {
    .ec-orderRole {
      display: flex;
      justify-content: space-between; }
      .ec-orderRole .ec-orderRole__detail {
        flex: 1 1 auto;
        padding-right: 40px; }
      .ec-orderRole .ec-orderRole__summary {
        flex: 0 0 27%;
        /* スクロール粘着（タブレット時と同様） */ }
        .ec-orderRole .ec-orderRole__summary .ec-totalBox {
          margin: 0;
          position: sticky;
          top: 150px; } }

/* 注文内容各項目の共通（下マージン） */
@media only screen and (max-width: 767px) {
  .ec-orderOrder,
  .ec-orderAccount,
  .ec-orderDelivery,
  .ec-orderPayment {
    margin-bottom: 3.125vw; } }

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ec-orderOrder,
  .ec-orderAccount,
  .ec-orderDelivery,
  .ec-orderPayment {
    margin-bottom: 3.906vw; } }

@media only screen and (min-width: 1200px) {
  .ec-orderOrder,
  .ec-orderAccount,
  .ec-orderDelivery,
  .ec-orderPayment {
    margin-bottom: 40px; } }

.ec-orderConfirm {
  margin-bottom: 0; }
  @media only screen and (max-width: 767px) {
    .ec-orderConfirm {
      margin-bottom: 3.125vw; } }

/*
オーダー情報（注文履歴詳細）

マイページ 注文履歴詳細の冒頭、注文日時や注文番号の記載の部分

使用箇所：
* Mypage/history.twig

※メモ：dt,dd要素で組まれているがdl親要素が無いのでできれば調整しておいたほうがベスト

Markup:
../styleguide/tmpl/15.2.1.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.1
*/
.ec-orderOrder {
  font-size: 0.933rem; }
  .ec-orderOrder .ec-definitions, .ec-orderOrder .ec-definitions--soft {
    padding-bottom: 0.5em;
    border-bottom: dotted 1px #ccc; }
    .ec-orderOrder .ec-definitions dt, .ec-orderOrder .ec-definitions--soft dt {
      font-weight: normal; }

/*
お客様情報（ショッピングフロー）

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig

Markup:
../styleguide/tmpl/15.2.2.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.2
*/
.ec-orderAccount {
  /* お客様情報（確定情報） */
  /* ゲスト購入時の編集入力枠 */
  /* 変更ボタン */ }
  .ec-orderAccount .ec-orderAccount__account {
    border: solid 1px #ddd;
    padding: 10px; }
    .ec-orderAccount .ec-orderAccount__account .ec-halfInput,
    .ec-orderAccount .ec-orderAccount__account .ec-input,
    .ec-orderAccount .ec-orderAccount__account .ec-halfInput,
    .ec-orderAccount .ec-orderAccount__account .ec-numberInput,
    .ec-orderAccount .ec-orderAccount__account .ec-zipInput,
    .ec-orderAccount .ec-orderAccount__account .ec-telInput,
    .ec-orderAccount .ec-orderAccount__account .ec-zipInput,
    .ec-orderAccount .ec-orderAccount__account .ec-telInput {
      margin: 0;
      margin-bottom: 0.5em; }
    .ec-orderAccount .ec-orderAccount__account .ec-zipInput {
      margin-bottom: 0; }
    .ec-orderAccount .ec-orderAccount__account .ec-telInput:before {
      content: "電話番号　"; }
    .ec-orderAccount .ec-orderAccount__account .customer-email:before {
      content: "メールアドレス　"; }
  .ec-orderAccount .ec-borderedDefs {
    margin-bottom: 1.5rem; }
    @media only screen and (max-width: 767px) {
      .ec-orderAccount .ec-borderedDefs {
        margin-bottom: 0; } }
  .ec-orderAccount .ec-orderAccount__change {
    text-align: right;
    margin-bottom: 10px; }

/*
配送情報

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

※メモ：dt,dd要素で組まれているがdl親要素が無いのでできれば調整しておいたほうがベスト

Markup:
../styleguide/tmpl/15.2.3.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.3
*/
.ec-orderDelivery {
  /* タイトル「お届け先」 */
  /* 変更ボタン */
  /* 商品内容 */
  /* お届け先住所 */
  /* 配送方法・お届け日時 */
  /* お届け先追加ボタンエリア */
  /*
  複数配送先の場合　各お届け先ごとのエリア区分が判別しやすいように調整
  少し太めのborderを敷いています。
  
  ※お届け先ごとにグルーピングされる要素が無いので、2つ目以降のお届け先は、
  「__actions」に続く「__title」でしか判別できません
  */ }
  .ec-orderDelivery .ec-orderDelivery__title {
    font-size: 1rem;
    line-height: 40px;
    font-weight: 700;
    position: relative;
    margin-bottom: 1rem; }
  .ec-orderDelivery .ec-orderDelivery__change {
    position: absolute;
    right: 0;
    top: 0; }
  .ec-orderDelivery .ec-orderDelivery__item {
    /* 軽減税率対象商品である事のコメント */ }
    .ec-orderDelivery .ec-orderDelivery__item ul + p {
      font-size: 0.933rem;
      margin-top: .5em; }
  .ec-orderDelivery .ec-orderDelivery__address {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
    .ec-orderDelivery .ec-orderDelivery__address p {
      margin: 0; }
  .ec-orderDelivery .ec-orderDelivery__actions label {
    font-weight: normal;
    min-width: 6em; }
    @media only screen and (min-width: 1200px) {
      .ec-orderDelivery .ec-orderDelivery__actions label {
        min-width: 5em; } }
  .ec-orderDelivery .ec-orderDelivery__edit {
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 20px; }
  .ec-orderDelivery .ec-orderDelivery__actions + .ec-orderDelivery__title {
    margin-top: 1em;
    border-top: solid 2px #ddd;
    padding-top: 1em; }
    .ec-orderDelivery .ec-orderDelivery__actions + .ec-orderDelivery__title .ec-orderDelivery__change {
      top: 1em; }

/*
注文詳細 お支払情報

※「お支払い方法」欄のほか、「利用ポイント」も同じclassを使用

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/15.2.4.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.4
*/
.ec-orderPayment .ec-input input, .ec-orderPayment .ec-halfInput input, .ec-orderPayment .ec-numberInput input, .ec-orderPayment .ec-zipInput input, .ec-orderPayment .ec-telInput input {
  width: 10em;
  text-align: right; }

/*
注文詳細 お問い合わせ

使用箇所：
* Shopping/index.twig
* Shopping/confirm.twig
* Mypage/history.twig

Markup:
../styleguide/tmpl/15.2.5.html

sg-wrapper:
<div class="ec-orderRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.2.5
*/
/*
お届け先の複数指定

お届け先の複数指定に関するコンポーネントを定義します。

ex [ショッピングページ　お届け先の複数指定](http://eccube-4.0.2.local/shopping/shipping_multiple)
(商品購入画面 → 「お届け先を追加する」を押下)

使用箇所：
* Shopping/shipping_multiple.twig

Markup:
../styleguide/tmpl/15.2.6.html

Styleguide 15.2.6
*/
.ec-AddAddress {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  /* 新規お届け先追加ボタンエリア */
  /* 商品単位のフレーム */
  /* 商品アイテム（画像とコンテンツ） */
  /* 商品アイテム：画像 */
  /* 商品アイテム：コンテンツ */
  /* 商品アイテムコンテンツ：商品名 */
  /* 商品アイテムコンテンツ：商品規格名 */
  /* 商品アイテムコンテンツ：商品価格 */
  /* 商品アイテムコンテンツ：数量 */
  /* 住所・数量選択欄 */
  /* 住所選択 */
  /* 数量指定 */
  /* お届け先追加ボタンエリア */
  /* 選択したお届け先に送るボタンエリア */ }
  @media only screen and (max-width: 767px) {
    .ec-AddAddress {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-AddAddress {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-AddAddress {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-AddAddress,
      .ec-layoutRole__mainBetweenColumn .ec-AddAddress {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-AddAddress .ec-AddAddress__new {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee; }
    @media only screen and (max-width: 767px) {
      .ec-AddAddress .ec-AddAddress__new {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem; } }
  .ec-AddAddress .ec-AddAddress__add {
    border-bottom: 1px solid #eee;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    font-size: 1rem; }
    @media only screen and (max-width: 767px) {
      .ec-AddAddress .ec-AddAddress__add {
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem; } }
  .ec-AddAddress .ec-AddAddress__item {
    display: flex; }
  .ec-AddAddress .ec-AddAddress__itemThumb {
    flex: 0 0 auto;
    width: 22vw;
    max-width: 120px; }
    .ec-AddAddress .ec-AddAddress__itemThumb img {
      width: 100%; }
  .ec-AddAddress .ec-AddAddress__itemtContent {
    flex: 1 1 auto;
    padding-left: 1em; }
  .ec-AddAddress .ec-AddAddress__itemtTitle {
    font-weight: 500; }
  .ec-AddAddress .ec-AddAddress__itemtSize {
    font-size: 0.9em;
    margin-left: 0.75em; }
  .ec-AddAddress .ec-AddAddress__itemtPrice {
    margin-top: 0.5em;
    display: inline-block; }
  .ec-AddAddress .ec-AddAddress__itemtNumber {
    display: inline-block;
    margin-left: 1em; }
  .ec-AddAddress .ec-AddAddress__select {
    margin: 20px 0;
    background-color: #f3f1eb;
    padding: 1em;
    /* 削除ボタン */ }
    .ec-AddAddress .ec-AddAddress__select .ec-inlineBtn.delete {
      display: inline-block;
      margin-left: 1em; }
    @media only screen and (max-width: 767px) {
      .ec-AddAddress .ec-AddAddress__select {
        position: relative; }
        .ec-AddAddress .ec-AddAddress__select .ec-inlineBtn.delete {
          position: absolute;
          bottom: 1em;
          right: 1em;
          padding: 8px 1em; } }
  .ec-AddAddress .ec-AddAddress__selectAddress {
    display: inline-block; }
    .ec-AddAddress .ec-AddAddress__selectAddress label {
      font-weight: normal;
      margin-right: .5em; }
    .ec-AddAddress .ec-AddAddress__selectAddress .select_ui {
      margin: 0; }
    @media only screen and (max-width: 767px) {
      .ec-AddAddress .ec-AddAddress__selectAddress {
        display: block; }
        .ec-AddAddress .ec-AddAddress__selectAddress label {
          margin: 0 0 1em; }
        .ec-AddAddress .ec-AddAddress__selectAddress select {
          max-width: 100%; } }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-AddAddress .ec-AddAddress__selectAddress select {
        max-width: 50vw; } }
  .ec-AddAddress .ec-AddAddress__selectNumber {
    display: inline-block;
    margin-left: 1em; }
    .ec-AddAddress .ec-AddAddress__selectNumber label {
      font-weight: normal;
      margin-right: .5em; }
    .ec-AddAddress .ec-AddAddress__selectNumber input {
      display: inline-block;
      width: 80px; }
    @media only screen and (max-width: 767px) {
      .ec-AddAddress .ec-AddAddress__selectNumber {
        display: block;
        margin-left: 0;
        margin-top: 1em; } }
  .ec-AddAddress .ec-AddAddress__actions .ec-blockBtn--action {
    margin-bottom: 10px; }

/*
補正

EC-CUBE4標準では、フォーム送信系のページの確認ページにおいて、入力フォームではない部位に「.ec-input」などのフォーム部品包括要素が
そのまま入力ページと同じ構成で使われています。
これが仕様なのかコーディングミスなのかは判別つきませんが、それにより少々CSSスタイリングがしづらい面があります。

※フォームパーツの特性がそれぞれにあるからこそ包括要素もそれぞれにあるのだろうと思うのですが、確認ページはそもそもテキスト表示のみですから、
そこは意図するものが違いますから別物としてコーディングすべきかとも思います。


ショッピングフローの「確認ページ」において、「お支払い方法」「利用ポイント」は「.ec-blockRadio」が使われていますがそれにより崩れが生じます。
（※「.ec-blockRadio」はラジオ部品を縦に並べる際に部品間に与えた余白を、ネガティブマージンで全体調整しています）
「.ec-blockRadio」のネガティブマージンを別の手法に置き換えるか考えるところですが、しかし、「お支払い方法」は入力ページでは別要素「.ec-radio」で
括られており入力ページと確認ページとで包括要素が別物になってるあたりコーディングミスなのだろうと思いますので、
.ec-blockRadioを調整するよりは、部分的な補正にとどめるのがベストかと思います。
さらに言えば「利用ポイント」はblockRadioの要素はまったくないわけですし…。次期バージョンアップで修正を希望します。

確認ページ：お支払い方法、利用ポイントの補正

Styleguide 15.2.90
*/
body#page_shopping_confirm .ec-blockRadio {
  margin-top: 0; }

/*
マイページ　注文履歴一覧

使用箇所：
Mypage/index.twig

Markup:
../styleguide/tmpl/16.1.html

Styleguide 16.1
*/
.ec-historyRole {
  font-size: .933rem;
  /* 左枠：注文データ */
  /* 右枠：商品内容 */ }
  .ec-historyRole .ec-historyRole__contents {
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid #eee; }
    @media only screen and (max-width: 767px) {
      .ec-historyRole .ec-historyRole__contents {
        display: block; } }
  .ec-historyRole .ec-historyRole__header {
    flex: 0 0 25%;
    background-color: #f7f6f6;
    padding: 1em; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-historyRole .ec-historyRole__header {
        flex-basis: 33%; } }
    @media only screen and (max-width: 767px) {
      .ec-historyRole .ec-historyRole__header {
        width: 100%;
        padding: 10px; } }
  .ec-historyRole .ec-historyRole__detail {
    flex: 1 1 auto;
    padding-left: 10px;
    /* 商品内容 */
    /* 商品名称 */
    /* 商品価格＆数量 */ }
    @media only screen and (max-width: 767px) {
      .ec-historyRole .ec-historyRole__detail {
        width: 100%;
        padding: 0;
        margin-top: 10px; } }
    .ec-historyRole .ec-historyRole__detail .ec-imageGrid + .ec-imageGrid {
      margin-top: 10px; }

/*
注文履歴一覧ヘッダ内容
*/
.ec-historyListHeader {
  /* 日付 */
  /* ご注文番号・状況*/ }
  .ec-historyListHeader .ec-definitions dt, .ec-historyListHeader .ec-definitions--soft dt {
    font-weight: normal;
    min-width: auto; }
  .ec-historyListHeader .ec-historyListHeader__action {
    margin: 0;
    margin-top: 1.5em; }
  @media only screen and (max-width: 767px) {
    .ec-historyListHeader {
      position: relative; }
      .ec-historyListHeader .ec-definitions, .ec-historyListHeader .ec-definitions--soft {
        margin: 0; }
      .ec-historyListHeader .ec-historyListHeader__action {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0; } }

/*
注文履歴詳細

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

注文履歴詳細の構造は`.ec-orderRole`と同じで、これはスタイルガイド15.2「注文内容確認」でスタイリングが完了しておりここでは追加定義は何もありません。

Markup:
../styleguide/tmpl/16.2.html

Styleguide 16.2
*/
/*
注文履歴詳細 メール履歴

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

使用箇所：
* Mypage/history.twig

Styleguide 16.2.5
*/
/*
注文履歴詳細 メール履歴個別

マイページ 注文履歴詳細部に関する Project コンポーネントを定義します。

使用箇所：
* Mypage/history.twig

メモ：
メール本文の`.ec-orderMail__body`は初期時は「display:none;」されており、function.jsにより`.ec-orderMail__link`クリックで展開されるようです。

Markup:
../styleguide/tmpl/16.2.6.html

Styleguide 16.2.6
*/
.ec-orderMail {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee; }
  .ec-orderMail .ec-orderMail__time {
    margin: 0; }
  .ec-orderMail .ec-orderMail__body {
    display: none; }
  .ec-orderMail .ec-orderMail__time {
    margin-bottom: .5em; }
  .ec-orderMail .ec-orderMail__link {
    margin-bottom: 4px; }
    .ec-orderMail .ec-orderMail__link a {
      color: #0092C4;
      text-decoration: none;
      cursor: pointer; }
    .ec-orderMail .ec-orderMail__link a:hover {
      color: #33A8D0; }
  .ec-orderMail .ec-orderMail__close a {
    color: #0092C4;
    text-decoration: none;
    cursor: pointer; }
  .ec-orderMail .ec-orderMail__close a:hover {
    color: #33A8D0; }

/*
住所一覧

マイページ「お届け先一覧」と、ショッピングページ「お届け先の指定」にて使用

ショッピングページ「お届け先の指定」は、配送情報の「変更」ボタンから入ることができます。
しかしながら、この「お届け先の指定」の選択ボタンの部位が、マイページ「お届け先一覧」の削除ボタン部位と同じ扱いとなっており、
その包括classが「.ec-addressList__remove」となっているのは非常に気持ち悪い所ですのでTwigの編集ができるのであればそうしたいところ

当テンプレートではそのままですが、レイアウト的に、選択ボタンは左に寄せ、削除ボタンは右に寄せるようにスタイリングしています。

使用箇所：
* Mypage/delivery.twig
* Shopping/shipping.twig

Markup:
../styleguide/tmpl/17.1.html

Styleguide 17.1
*/
.ec-addressRole {
  /* 新規お届け先を追加ボタンエリア */ }
  .ec-addressRole .ec-addressRole__actions {
    margin-bottom: 2em; }

/* 住所一覧 */
.ec-addressList {
  /* 削除ボタンエリア、兼、選択ボタンエリア */
  /* 住所エリア */
  /* 変更ボタン（マイページお届け先一覧のみ） */ }
  .ec-addressList .ec-addressList__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee; }
    .ec-addressList .ec-addressList__item:first-child {
      border-top: 1px solid #eee; }
  .ec-addressList .ec-addressList__remove {
    flex: 0 0 auto;
    min-width: 3em;
    text-align: center;
    /*
    選択ボタン（ラジオボタン）においては、ここでは.ec-radioがコーディングされておりませんので、javascriptで.ec-radioとlabelを挿入しています。
    また、住所内容にもlabelを挿入して、住所のクリックでもラジオボタンが反応するようにしてあります。
    javascriptで挿入するのは気がひけますが、もし編集可能であればTwig側をほかのフォームページと同等になるように修正してください（EC-CUBE時期バージョンで修正希望）
    */ }
  .ec-addressList .ec-addressList__address {
    flex: 1 1 auto;
    padding: 1em;
    padding-left: 0;
    /*
    住所エリアにlabelはコーディングされておりませんがUI的にあったほうがいいと判断しjavascriptで挿入させています。（「お届け先の指定」ページ）
    */ }
    .ec-addressList .ec-addressList__address label {
      font-weight: normal;
      /* 氏名欄（識別classは振られていないので、やむなく1番目の要素を氏名と識別） */ }
      .ec-addressList .ec-addressList__address label > :first-child {
        font-weight: 500; }
  .ec-addressList .ec-addressList__action {
    flex: 0 0 auto;
    padding: 0; }

/*
マイページ「お届け先一覧」用のカスタマイズ
削除ボタンエリアを右端に来るように変更し、削除アイコンは使わずインラインボタンを生成
*/
body#page_mypage_delivery .ec-addressList .ec-addressList__remove {
  order: 3;
  width: auto;
  padding-left: 10px; }
  body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after {
    content: "削除";
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 0.6em 1em;
    font-size: 1rem;
    line-height: 1;
    border-radius: 0px;
    font-weight: normal;
    text-decoration: none;
    transition: .3s;
    color: #fff;
    background-color: #242930;
    border-color: #444;
    /*
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    color: $color;
    background-color: darken($background, 10%);
        border-color: darken($border, 12%);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: darken($background, 17%);
          border-color: darken($border, 25%);
    }
  }
  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-image: none;
  }
  */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      @include tab-focus;
    }
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }
  */
    font-size: 0.9rem;
    padding: 0.6em 1em;
    font-weight: normal;
    font-size: 0.9rem; }
    body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.focus {
      color: #fff;
      /* タブフォーカスを有効にする場合はコメントアウトを解除してください。
  outline: none;
  box-shadow: 0 0 0 1px $input-border-focus;// フォーム部品のfocusカラーを利用
  */ }
    body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:hover {
      text-decoration: none;
      color: #fff;
      background-color: #0e1013;
      border-color: #252525; }
    body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled],
    fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after {
      background-color: #999;
      border-color: #999;
      cursor: not-allowed; }
      body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled:hover, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled:focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.disabled.focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled]:hover, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled]:focus, body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after[disabled].focus,
      fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:hover,
      fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after:focus,
      fieldset[disabled] body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after.focus {
        background-color: #999;
        border-color: #999; }
    body#page_mypage_delivery .ec-addressList .ec-addressList__remove:after .badge {
      color: #242930;
      background-color: #fff; }
  body#page_mypage_delivery .ec-addressList .ec-addressList__remove .ec-icon {
    display: none; }

/*
パスワードリセット

パスワードリセットのスタイルです。

使用箇所：
* Forgot/index.twig

Markup:
../styleguide/tmpl/18.1.html

sg-wrapper:
<div class="ec-role">
  <sg-wrapper-content/>
</div>

Styleguide 18.1
*/
.ec-forgotRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-forgotRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-forgotRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-forgotRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-forgotRole,
      .ec-layoutRole__mainBetweenColumn .ec-forgotRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-forgotRole .ec-forgotRole__form {
    margin-bottom: 0; }
    @media only screen and (min-width: 768px) and (max-width: 1199px) {
      .ec-forgotRole .ec-forgotRole__form {
        margin-bottom: 3.906vw; } }
    @media only screen and (min-width: 1200px) {
      .ec-forgotRole .ec-forgotRole__form {
        margin-bottom: 40px; } }
  .ec-forgotRole .ec-forgotRole__intro {
    font-size: 1rem; }

/*
会員登録系

会員登録 に関する Project コンポーネントを定義します。

会員登録ページのみでなく、会員情報を扱いフォームページにて使用されている様子

メモ：
.ec-registerRole__actionsの上マージンについて、入力ページと確認ページとでは「利用規約チェック」の有無の違いがあるので余白の微調整をしたいところだが、
入力ページと確認ページで識別classが付くわけではない様子なので、区別ができない（たぶん）。
Twigを編集すれば可能となるがテンプレートではTwigは極力変更しない考えなので、この件は保留。jsで実装するのもちょっとあれなので。

使用箇所：
* Entry/index.twig
* Entry/confirm.twig
* Forgot/reset.twig
* Shopping/shipping.twig
* Shopping/shipping_edit.twig
* Shopping/shipping_multiple_edit.twig

Markup:
../styleguide/tmpl/19.1.html

Styleguide 19.1
*/
.ec-registerRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-registerRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-registerRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-registerRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-registerRole,
      .ec-layoutRole__mainBetweenColumn .ec-registerRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-registerRole .ec-registerRole__actions .ec-checkbox, .ec-registerRole .ec-registerRole__actions .ec-blockCheckbox {
    margin: 1em 0; }

.ec-registerCompleteRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-registerCompleteRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-registerCompleteRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-registerCompleteRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-registerCompleteRole,
      .ec-layoutRole__mainBetweenColumn .ec-registerCompleteRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

/*
.ec-registerRole__actions

`.ec-registerRole__actions`は、会員情報系のフォームページ専用のアクションボタンの括り要素という位置づけで、
コンテナ`.ec-registerRole`に属するコンポーネントという設計思想かと思いましたが、実際のところ、
「お問い合わせ」ページのボタン部分にも使われており、しかし「お問い合わせ」ページはコンテナが`.ec-registerRole`ではありません。
どうやら`.ec-registerRole__actions`は、必ずしも`.ec-registerRole`の中に包含されているとは限らないようで、
ほかのさまざまなコンテナの中で使われています。コーディングルールから外れているように思いますが、CSS側で吸収してスタイリングし直しています。

※送信ボタンRole的なものは様々なフォームページで登場しますので、汎用な意味を持たせる意味で「register」とは違う名称を付けるべきかもしれません。

「.ec-registerRole__actions」使用箇所：
* Contact/confirm.twig
* Contact/index.twig
* Entry/confirm.twig
* Entry/index.twig
* Forgot/reset.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/nonmember.twig
* Shopping/shipping.twig
* Shopping/shipping_edit.twig
* Shopping/shipping_multiple_edit.twig

また、いくつかのページでは「.ec-RegisterRole__actions」と「r」が大文字「R」になっており、これはおそらくコーディングミスだと思われますが、
これもCSS側で吸収しておきます。（Twigの必要以外の編集はテンプレート的には極力避けたい所です）

大文字R該当ページ：

* Contact/confirm.twig
* Contact/index.twig
* Mypage/change.twig
* Mypage/delivery_edit.twig
* Shopping/nonmember.twig
* Shopping/shipping_edit.twig

Styleguide 19.1.2
*/
.ec-registerRole .ec-registerRole__actions,
.ec-registerRole .ec-RegisterRole__actions,
.ec-mypageRole .ec-registerRole__actions,
.ec-mypageRole .ec-RegisterRole__actions,
.ec-contactRole .ec-registerRole__actions,
.ec-contactRole .ec-RegisterRole__actions,
.ec-contactConfirmRole .ec-registerRole__actions,
.ec-contactConfirmRole .ec-RegisterRole__actions,
.ec-customerRole .ec-registerRole__actions,
.ec-customerRole .ec-RegisterRole__actions {
  margin-top: 3.125vw; }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-registerRole .ec-registerRole__actions,
    .ec-registerRole .ec-RegisterRole__actions,
    .ec-mypageRole .ec-registerRole__actions,
    .ec-mypageRole .ec-RegisterRole__actions,
    .ec-contactRole .ec-registerRole__actions,
    .ec-contactRole .ec-RegisterRole__actions,
    .ec-contactConfirmRole .ec-registerRole__actions,
    .ec-contactConfirmRole .ec-RegisterRole__actions,
    .ec-customerRole .ec-registerRole__actions,
    .ec-customerRole .ec-RegisterRole__actions {
      margin-top: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-registerRole .ec-registerRole__actions,
    .ec-registerRole .ec-RegisterRole__actions,
    .ec-mypageRole .ec-registerRole__actions,
    .ec-mypageRole .ec-RegisterRole__actions,
    .ec-contactRole .ec-registerRole__actions,
    .ec-contactRole .ec-RegisterRole__actions,
    .ec-contactConfirmRole .ec-registerRole__actions,
    .ec-contactConfirmRole .ec-RegisterRole__actions,
    .ec-customerRole .ec-registerRole__actions,
    .ec-customerRole .ec-RegisterRole__actions {
      margin-top: 40px; } }
  .ec-registerRole .ec-registerRole__actions .ec-blockBtn--action,
  .ec-registerRole .ec-RegisterRole__actions .ec-blockBtn--action,
  .ec-mypageRole .ec-registerRole__actions .ec-blockBtn--action,
  .ec-mypageRole .ec-RegisterRole__actions .ec-blockBtn--action,
  .ec-contactRole .ec-registerRole__actions .ec-blockBtn--action,
  .ec-contactRole .ec-RegisterRole__actions .ec-blockBtn--action,
  .ec-contactConfirmRole .ec-registerRole__actions .ec-blockBtn--action,
  .ec-contactConfirmRole .ec-RegisterRole__actions .ec-blockBtn--action,
  .ec-customerRole .ec-registerRole__actions .ec-blockBtn--action,
  .ec-customerRole .ec-RegisterRole__actions .ec-blockBtn--action {
    margin-bottom: 1rem; }

/*
コンテナ違反？

`.ec-registerRole`は「@include container」されて「コンテナ」として振舞いますが（EC-CUBE標準仕様）、
ゲスト購入時のショッピングページ「お届け先変更」フォームでは、同じコンテナの「.ec-role」の中に包含される形でコーディングされており、
結果、左右余白が2重にかかってしまいます。
会員ログイン時では2重コンテナにはなっておらずコンテナの住み分けができているので、こちらはコーディングミスではないかと思われます。
テンプレートとしてはTwig編集は極力避けたいのでCSS側にて吸収します。

Styleguide 19.1.3
*/
.ec-role .ec-registerRole {
  padding-left: 0;
  padding-right: 0; }

/*
お問い合わせ

お問い合わせ に関する Project コンポーネントを定義します。

使用箇所：
* Contact/index.twig

Markup:
../styleguide/tmpl/19.2.html

Styleguide 19.2
*/
.ec-contactRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  /* .ec-contactRole__actions
   * EC-CUBE4では実際にはこれは使われておらず、「ec-RegisterRole__actions」となっています（しかもrの字が大文字R）
   * おそらくコーディングミスと思われますが、Twigを編集せずにCSS側で吸収して調整しています。（_19.1.register.scss）
   */ }
  @media only screen and (max-width: 767px) {
    .ec-contactRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-contactRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-contactRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-contactRole,
      .ec-layoutRole__mainBetweenColumn .ec-contactRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

.ec-contactConfirmRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-contactConfirmRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-contactConfirmRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-contactConfirmRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-contactConfirmRole,
      .ec-layoutRole__mainBetweenColumn .ec-contactConfirmRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

.ec-contactCompleteRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-contactCompleteRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-contactCompleteRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-contactCompleteRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-contactCompleteRole,
      .ec-layoutRole__mainBetweenColumn .ec-contactCompleteRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

/*
お客様情報の入力

ログインせずゲストとして商品を購入する際の、お客様情報の入力 に関する Project コンポーネントを定義します。

使用箇所：
* Shopping/nonmember.twig

Markup:
../styleguide/tmpl/19.3.html

Styleguide 19.3
*/
.ec-customerRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-customerRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-customerRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-customerRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-customerRole,
      .ec-layoutRole__mainBetweenColumn .ec-customerRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }

/*
404ページ

404 エラー画面で使用するページコンポーネントです。

ex [404エラー画面](http://eccube-4.0.2.local/404)

使用箇所：
* error.twig

警告アイコンを包括するdiv要素で、Twig内で「style="font-size:100px;text-align:center;"」とインラインCSSが記述されており少々手荒な感じがしますが、
当テンプレートでのCSSスタイリングに影響は微小ととらえTwigは編集せずそのままにしておきます。

Markup:
../styleguide/tmpl/20.1.html

Styleguide 20.1
*/
.ec-404Role {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  margin-top: 30px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .ec-404Role {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-404Role {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-404Role {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-404Role,
      .ec-layoutRole__mainBetweenColumn .ec-404Role {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-404Role .ec-icon {
    line-height: 1rem; }
    .ec-404Role .ec-icon img {
      width: 60px;
      height: auto; }
  .ec-404Role .ec-404Role__title {
    font-size: 1.5rem;
    margin: 1em 0;
    text-align: center !important; }
  .ec-404Role .ec-404Role__description {
    font-size: 1.1rem;
    text-align: center !important; }
  .ec-404Role .ec-blockBtn--cancel {
    margin-top: 30px;
    width: 100%;
    box-sizing: border-box; }

/*
退会手続き（フロントページ）

使用箇所：
* Mypage/withdraw.twig

警告アイコンを包括するdiv要素で、Twig内で「style="font-size:100px;text-align:center;"」とインラインCSSが記述されており少々手荒な感じがしますが、
当テンプレートでのCSSスタイリングに影響は微小ととらえTwigは編集せずそのままにしておきます。

Markup:
../styleguide/tmpl/21.1.html

Styleguide 21.1
*/
.ec-withdrawRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .ec-withdrawRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-withdrawRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-withdrawRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-withdrawRole,
      .ec-layoutRole__mainBetweenColumn .ec-withdrawRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-withdrawRole .ec-icon {
    line-height: 1rem; }
    .ec-withdrawRole .ec-icon img {
      width: 60px;
      height: 60px; }
  .ec-withdrawRole .ec-withdrawRole__title {
    font-size: 1.5rem;
    margin: 1em 0; }
  .ec-withdrawRole .ec-withdrawRole__description {
    font-size: 1.1rem; }
  .ec-withdrawRole .ec-blockBtn--cancel {
    margin-top: 30px; }

/*
退会手続き（確認ページ）

使用箇所：
Mypage\withdraw_confirm.twig

フロントページでは「.ec-withdrawRole」がコンテナになっていますが、こちらの確認ページでは「.ec-withdrawConfirmRole」でスタイリングされているものの、
コンテナ要素にはなっておりません。この親要素として「.ec-maypageRole」がつけられておりこちらがコンテナとなっています。

Markup:
../styleguide/tmpl/21.1.2.html

Styleguide 21.1.2
*/
.ec-withdrawConfirmRole {
  text-align: center; }
  .ec-withdrawConfirmRole .ec-icon {
    line-height: 1rem; }
    .ec-withdrawConfirmRole .ec-icon img {
      width: 60px;
      height: auto; }
  .ec-withdrawConfirmRole .ec-withdrawConfirmRole__title {
    font-size: 1.5rem;
    margin: 1em 0; }
  .ec-withdrawConfirmRole .ec-withdrawConfirmRole__description {
    font-size: 1.1rem; }
  .ec-withdrawConfirmRole .ec-blockBtn--cancel {
    margin-top: 30px;
    margin-bottom: 10px; }
  .ec-withdrawConfirmRole .ec-blockBtn {
    background-color: #a80000;
    border-color: #a80000; }

/*
会員情報編集完了

会員情報編集完了で使用するページコンポーネントです。

ex [会員情報編集完了](http://eccube-4.0.2.local/mypage/change_complete)

使用箇所：なし

※現バージョンでの編集完了画面は`.ec-registerCompleteRole`となっており、これは「19.1 会員登録」の中で定義されていますので、
こちらのスタイリングは未使用につき不要と思われます。

Markup:
../styleguide/tmpl/22.1.html

Styleguide 22.1
*/
/*
.ec-userEditCompleteRole {
  @include container;
  text-align: center;
  padding: 0 16px;

  & &__title{
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 24px;
    @include media_desktop(){
      font-size: 32px;
    }
  }
  
  & &__description{
    margin-bottom: 32px;
    font-size: 16px;
  }
}
*/
/*
ヘルプ

各ヘルプページに関する Project コンポーネントを定義します。

該当ページ：
* Help/about.twig
* Help/agreement.twig
* Help/guide.twig
* Help/privacy.twig
* Help/tradelaw.twig

Styleguide 23.1
*/
.ec-helpRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px; }
  @media only screen and (max-width: 767px) {
    .ec-helpRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-helpRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-helpRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-helpRole,
      .ec-layoutRole__mainBetweenColumn .ec-helpRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  @media only screen and (max-width: 767px) {
    .ec-helpRole .ec-navlistRole {
      margin-bottom: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-helpRole .ec-navlistRole {
      margin-bottom: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-helpRole .ec-navlistRole {
      margin-bottom: 40px; } }
  .ec-helpRole h3 {
    font-size: 1.1em;
    font-weight: normal;
    margin: 2em 0 1em;
    border-left: solid 5px #cdc4ab;
    padding: .25em .5em; }
  .ec-helpRole h4 {
    font-size: 1em;
    font-weight: 500;
    margin: 1em 0; }
    .ec-helpRole h4:before {
      content: "【"; }
    .ec-helpRole h4:after {
      content: "】"; }
  .ec-helpRole .fig img {
    width: auto;
    max-width: 100%;
    height: auto; }

/*
規約条文

規約条文の汎用スタイルです。

該当ページ：
* Help/agreement.twig
* Help/privacy.twig

Styleguide 23.2
*/
.ec-provision .ec-provision__heading {
  font-size: 1rem;
  margin-bottom: 2em; }

.ec-provision dl {
  font-size: 1rem;
  margin-bottom: 2em; }
  .ec-provision dl dt {
    font-weight: 700;
    margin-bottom: 0.5em; }
  .ec-provision dl dd {
    font-size: 1em;
    font-weight: normal;
    margin: 0; }

/*
利用規約

agreement.twigでは{{ '（規約本文）'|raw|nl2br }}で全文がpre要素のごとく出力されていますが、内容を記述しやすいようにするためか、
アポストロフィ「'」で改行してコーディングされていますので、冒頭に<br>が入ってしまう形になります。
それをCSSで吸収しておきたいのでネガティブマージンを使用しています。
↓
利用規約もプライバシーポリシーと同じスタイルとしておきたいと思い、Twigを編集して規約条文と同じ「.ec-provision」で書き換えました。これにより「.ec-agreement」は未使用となります。

Styleguide 23.3
*/
.ec-agreement {
  margin-top: -1em; }

.ec-userDataRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  font-size: 16px;
  max-width: 1480px; }
  @media only screen and (max-width: 767px) {
    .ec-userDataRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-userDataRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-userDataRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-userDataRole,
      .ec-layoutRole__mainBetweenColumn .ec-userDataRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .ec-userDataRole h5 {
    margin-top: 25px;
    margin-bottom: 25px; }

.ec-breadcrumbRole {
  margin: 0 auto;
  box-sizing: content-box;
  width: auto !important;
  max-width: 1200px;
  /*
  inner
  トピックパス枠（__topicspath）と表示件数＆並び替え枠（__infos）の2つのエリアをflexにて左右両極に配置
  */
  /* トピックパス枠 */ }
  @media only screen and (max-width: 767px) {
    .ec-breadcrumbRole {
      padding-left: 3.125vw;
      padding-right: 3.125vw; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-breadcrumbRole {
      padding-left: 3.906vw;
      padding-right: 3.906vw; } }
  @media only screen and (min-width: 1200px) {
    .ec-breadcrumbRole {
      /* 1カラムレイアウト時はコンテナ自身に左右余白を持たせる */
      padding-left: 40px;
      padding-right: 40px;
      /* 2～3カラムレイアウト自はコンテナ自体に左右余白は持たせません。
     * 親フレームである「.ec-layoutRole__contents」が余白を持つようにしています（_11.1.role.scss）。
     * 余談的な補足となりますが、1カラムの場合に「.ec-layoutRole__contents」にmax-widthを定義せずコンテナ側にmax-widthを持たせるようにしているのは、
     * コンテナによっては背景色をブラウザいっぱいに敷くデザインにも対応させるためにそうしてあります。
     * 2～3カラムではそういうコンテナはありえませんのでコンテナのmax-widthや余白はリセットさせておきます。
    */ }
      .ec-layoutRole__mainWithColumn .ec-breadcrumbRole,
      .ec-layoutRole__mainBetweenColumn .ec-breadcrumbRole {
        max-width: auto;
        padding-left: 0;
        padding-right: 0; } }
  .product_page .ec-breadcrumbRole {
    max-width: 1480px; }
  @media only screen and (min-width: 1200px) {
    #page_product_detail .ec-breadcrumbRole {
      margin-bottom: 40px; } }
  .full_screen_page .ec-breadcrumbRole {
    padding: 0;
    max-width: none; }
  .ec-breadcrumbRole .ec-breadcrumbRole__inner {
    background-color: #f4f4f4;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .ec-breadcrumbRole .ec-breadcrumbRole__breadcrumb {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: .5em;
    font-size: 1rem; }
  @media only screen and (max-width: 767px) {
    .ec-breadcrumbRole .ec-breadcrumbRole__inner {
      margin-left: -3.125vw;
      margin-right: -3.125vw;
      padding: 10px 10px;
      flex-flow: row wrap; }
    .ec-breadcrumbRole .ec-breadcrumbRole__breadcrumb {
      width: 100%;
      padding: 0;
      font-size: 0.9rem; } }
  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .ec-breadcrumbRole .ec-breadcrumbRole__inner {
      margin-left: -3.906vw;
      margin-right: -3.906vw;
      padding: 10px 3.906vw;
      flex-flow: row wrap; }
    .ec-breadcrumbRole .ec-breadcrumbRole__breadcrumb {
      width: 100%;
      padding: 0;
      font-size: 0.9rem; } }

/*
トピックパス本体

sg-wrapper:
<div class="ec-searchnavRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/13.1.2.html

Styleguide 13.1.2
*/
.ec-breadcrumb {
  line-height: 1; }
  .ec-breadcrumb ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
  .ec-breadcrumb .ec-breadcrumb__item {
    white-space: nowrap;
    padding: 4px 0;
    /* 連結の「>」 */
    /* タイトルラベル */
    /* 最後のli要素を太字 */ }
    .ec-breadcrumb .ec-breadcrumb__item a {
      color: inherit;
      padding-top: 2px;
      padding-bottom: 2px;
      position: relative;
      text-decoration: none; }
      .ec-breadcrumb .ec-breadcrumb__item a:hover {
        text-decoration: none !important; }
      .ec-breadcrumb .ec-breadcrumb__item a:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: left bottom;
        transform: scaleX(0);
        transition: transform .3s; }
      .ec-breadcrumb .ec-breadcrumb__item a:hover:after {
        transform: scaleX(1); }
    .ec-breadcrumb .ec-breadcrumb__item:before {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 0.85em;
      margin-left: 4px; }
    .ec-breadcrumb .ec-breadcrumb__item.titleLabel {
      font-family: "Oswald"; }
    .ec-breadcrumb .ec-breadcrumb__item.titleFirst:before {
      display: none; }
    .ec-breadcrumb .ec-breadcrumb__item:last-child a,
    .ec-breadcrumb .ec-breadcrumb__item:last-child span {
      font-weight: 500;
      pointer-events: none; }

.ec-productOptions > div {
  padding: 15px 0 10px; }
  .ec-productOptions > div + div {
    border-top: 1px solid #eee;
    margin-top: 5px !important; }
  .ec-productOptions > div > div + div {
    margin-top: 5px; }
  .ec-productOptions > div:last-child {
    border-bottom: 1px solid #eee; }

.clearfix {
  clear: both; }

.wp-light {
  color: #999;
  font-size: 80%; }

#wp-nav {
  list-style: none; }

#post-list {
  list-style: none;
  padding: 0; }

#post-list h2 {
  padding: 0; }

.border-b {
  border-bottom: 1px #eee solid; }

#post-list li {
  padding: 0px; }

.wp-row {
  padding: 20px 0; }
  @media only screen and (min-width: 1200px) {
    .wp-row {
      display: flex; } }

.wp-row:after {
  content: " ";
  clear: both;
  display: block; }

.wp-thumb, .wp-row-content {
  display: block; }

.content-width {
  flex: 1; }

.wp-thumb {
  margin-right: 20px;
  margin-top: 10px;
  max-width: 300px; }
  #page_wp_post .wp-thumb {
    width: 600px;
    max-width: 90%; }
  .wp-thumb img {
    display: block; }

.more-link-wrapper {
  margin: 20px 0; }

.more-link {
  border: 1px #0092C4 solid;
  padding: 10px;
  line-height: 26px;
  margin: 24px 0;
  box-sizing: border-box;
  clear: both;
  color: #0092C4;
  font-size: 14px; }

.more-link:hover {
  background: #0092C4;
  color: #fff;
  text-decoration: none;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear; }

.wp-block {
  list-style: none;
  padding: 0; }

@media screen and (min-width: 768px) {
  .ec-faqRole .faq .tabContainer ul.tabMenu {
    display: flex;
    margin: 0 -1px; } }

.ec-faqRole .faq .tabContainer ul.tabMenu li {
  background: transparent; }
  @media screen and (min-width: 768px) {
    .ec-faqRole .faq .tabContainer ul.tabMenu li {
      min-width: auto;
      flex: 1;
      margin: 0 1px 1px 1px; } }
  .ec-faqRole .faq .tabContainer ul.tabMenu li a {
    background: #999999;
    white-space: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }
    .ec-faqRole .faq .tabContainer ul.tabMenu li a:hover {
      color: #fff;
      background: #000; }
  @media screen and (min-width: 768px) {
    .ec-faqRole .faq .tabContainer ul.tabMenu li:first-child {
      min-width: auto; } }
  .ec-faqRole .faq .tabContainer ul.tabMenu li.active {
    background: transparent; }
    .ec-faqRole .faq .tabContainer ul.tabMenu li.active a {
      background: #000; }

.ec-faqRole .faq .tabContainer dl dt:before, .ec-faqRole .faq .tabContainer dl dd:before {
  font-size: 28px;
  background: transparent;
  -webkit-text-fill-color: initial;
  padding-top: 0;
  padding-bottom: 0;
  font-family: 'Playfair Display', serif;
  font-weight: 400; }

.ec-faqRole .faq .tabContainer dl dt {
  position: relative;
  padding: 0 5px 10px 45px;
  margin-bottom: 10px; }
  .ec-faqRole .faq .tabContainer dl dt:before {
    position: absolute;
    left: 5px;
    top: -5px; }
    @media only screen and (min-width: 1200px) {
      .ec-faqRole .faq .tabContainer dl dt:before {
        top: -10px; } }

.ec-faqRole .faq .tabContainer dl dd {
  position: relative;
  padding: 0 5px 10px 45px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ebebeb; }
  .ec-faqRole .faq .tabContainer dl dd:before {
    position: absolute;
    left: 5px;
    top: -3px;
    color: red; }
    @media only screen and (min-width: 1200px) {
      .ec-faqRole .faq .tabContainer dl dd:before {
        top: -7px; } }
  .ec-faqRole .faq .tabContainer dl dd p {
    margin-bottom: 10px; }

@media screen and (min-width: 768px) {
  .ec-faqRole .faq h3.title {
    text-align: center; } }
