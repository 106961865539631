///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
見出し

ページ内で見出しとして機能する要素のスタイル群です。

Styleguide 1.1
*/



/*
商品詳細ページ見出し（未使用）

EC-CUBE4では商品詳細ページのみ見出しに使用されているスタイルですが、当テンプレートでは使用していません。

使用箇所：なし

Markup:
../styleguide/tmpl/1.1.1.html

Styleguide 1.1.1
*/
.ec-headingTitle {
  margin: 0 0 8px;
  font-size: 32px;
  font-weight: normal;
  color: #525263;
}



/*
ページヘッダ（h1見出し）

各種ページで用いられるページヘッダのデザインです。

EC-CUBE4のHTML構成でページヘッダ見出しは「コンテナ」の中に含まれており、コンテナには左右余白が定義されています。<br>
当テンプレートではh1見出しには背景色を敷いたデザインですが、スマホ・タブレット時ではブラウザ幅フルにフィットさせたいので、
これをネガティブマージンを用いて左右余白を相殺することでスタイリングしています。

もし背景色を敷かないデザインに変更するのであればネガティブマージンによる余白相殺と余白の再定義は不要です。

h1要素に下マージンを持たせて、h1タイトル部とページコンテンツ本体との余白としています。

使用箇所：
Cart/index.twig
Contact/complete.twig
Contact/confirm.twig
Contact/index.twig
Entry/activate.twig
Entry/complete.twig
Entry/confirm.twig
Entry/index.twig
Forgot/complete.twig
Forgot/index.twig
Forgot/reset.twig
Help/about.twig
Help/agreement.twig
Help/guide.twig
Help/privacy.twig
Help/tradelaw.twig
Mypage/change.twig
Mypage/change_complete.twig
Mypage/delivery.twig
Mypage/delivery_edit.twig
Mypage/favorite.twig
Mypage/history.twig
Mypage/index.twig
Mypage/login.twig
Mypage/withdraw.twig
Mypage/withdraw_complete.twig
Mypage/withdraw_confirm.twig
Shopping/complete.twig
Shopping/confirm.twig
Shopping/index.twig
Shopping/login.twig
Shopping/nonmember.twig
Shopping/shipping.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple.twig
Shopping/shipping_multiple_edit.twig
Shopping/shopping_error.twig

Markup:
../styleguide/tmpl/1.1.2.html

Styleguide 1.1.2
*/
.ec-pageHeader {

  h1 {
    font-family: $ff-en;
    margin: 0;
    background-color: #f4f4f4;
    font-weight: 500;

    margin-bottom: 1.5em;

    @include media_sphone {
      // ネガティブマージンによる左右余白相殺で背景色をブラウザ幅フルに
      margin-left: $padding-sphone * -1;
      margin-right: $padding-sphone * -1;
      padding: 0.85em $padding-sphone;
      font-size: 1.2rem;
      border-bottom: solid 1px #ddd;
      margin-bottom: 1em;
    }

    @include media_tablet {
      // ネガティブマージンによる左右余白相殺で背景色をブラウザ幅フルに
      margin-left: $padding-tablet * -1;
      margin-right: $padding-tablet * -1;
      padding: 0.85em $padding-tablet;
      font-size: 1.2rem;
      border-bottom: solid 1px #ddd;
    }

    @include media_desktop {
      padding: 0.75em 0.75em;
      font-size: 1.3em;
    }
  }

  &--en {
    display: inline-block;
    font-family: 'Dynalight', serif;
    margin-left: 10px;
    font-size: 1rem;
  }
}



/*
サブ見出し（未使用）

文字主体のページで用いられるサブ見出しです。

使用箇所：なし

後述の「.ec-heading-bold」に対して太字でないスタイリングという位置づけでしょうか。
使用箇所はありませんが一応、スタイリングとして調整して残しておきます。

Markup:
../styleguide/tmpl/1.1.3.html

Styleguide 1.1.3
*/
.ec-heading {
  font-weight: 1.1rem;
  font-weight: normal;
  margin-bottom: 1em;
}



/*
サブ見出し(太字)

文字主体のページで用いられるサブ見出しの太字のスタイルです。

EC-CUBE4では「プライバシーポリシー」の太字見出しで使用されているスタイルですが、当テンプレートでは規約条文用のコンポーネントを用意しましたので、
結果、使用箇所なしとなります。

使用箇所：なし

Markup:
../styleguide/tmpl/1.1.4.html

Styleguide 1.1.4
*/
.ec-heading-bold {
  font-weight: 1.1rem;
  font-weight: 500;
  margin-bottom: 1em;
}



/*
背景付き見出し

h2の見出し要素を装飾するスタイリングです。<br>
h3以降の使用箇所はありません。使う場面があればh3以降も追加スタイリングしてください。

使用箇所：
Mypage/history.twig
Shopping/index.twig
Shopping/confirm.twig
Help/guide.twig

Markup:
../styleguide/tmpl/1.1.5.html

Styleguide 1.1.5
*/
.ec-rectHeading {
  margin: 0;
  margin-bottom: 1rem;

  h2 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: normal;
    background-color: $cart-bgcolor;// カート背景色を使用
    padding: 0.6em 0.75em;
  }

  h3 {
    //使用箇所なし
  }

  &--en {
    display: inline-block;
    font-family: 'Dynalight', serif;
    margin-left: 10px;
    font-size: 0.8rem;
  }

}



/*
メッセージ見出し

ユーザが行った操作に対する、完了報告やエラー表示のページで使用される見出しのスタイルです。

使用箇所：
error.twig
Contact/complete.twig
Entry/activate.twig
Entry/complete.twig
Forgot/complete.twig
Mypage/change_complete.twig
Mypage/withdraw_complete.twig
Shopping/complete.twig

Markup:
../styleguide/tmpl/1.1.6.html

Styleguide 1.1.6
*/
.ec-reportHeading {
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: 1em;
  padding: 1em 0;
  text-align: center;
  @include borderTop;
  @include borderBottom;

  h2 {
    margin: 0;
    font-size: inherit;
    line-height: inherit;
    text-align: inherit;
  }

  @include media_sphone {
    text-align: left;
  }
}
