///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
左カラムコンテンツ

まず、左カラムに配置される各ブロックの共通スタイリングします。

* ブロック間の余白
* slickのprev,nextボタン

セクション見出し「.ec-secHeading」は「_12.4.heading.scss」にて左カラム用のセクション見出しをスタイリングしています。

レスポンシブによりスマホ・タブレット時は、左カラムのコンテンツはメインカラムの下に配置されます。

Styleguide 11.4
*/
.ec-layoutRole__left {

  /* .ec-layoutRole__left（2カラムレイアウト）の基本スタイリングは「_11.1.role.scss」にて定義しています */

  .leftBlock {
    position: relative;// slickボタン用

    @include media_sphone {
      margin-top: $padding-sphone * 3;
    }

    @include media_tablet {
      margin-top: $padding-tablet * 3;
    }

    @include media_desktop {
      margin-top: $padding-desktop * 1.5;
    }
  }

  /* slickのprev,nextボタンはブロック枠の右上に配置させます */
  .slickArrows {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;

    .slick-arrow {
      font-size: 20px;
      cursor: pointer;

      i {
        display: block;
      }

      &.slick-next {
        margin-left: 5px;
      }
    }
  }
}



/*
左カラムカテゴリナビブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.1.html

Styleguide 11.4.1
*/
.ec-categoryNavRole {
  @include media_sphone {
  }

  @include media_tablet {
  }

  @include media_desktop {
    margin-top: 0 !important;

    /* セクション見出し上書き調整 */
    .ec-secHeading {
      margin-bottom: 0;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0;
      padding: 0;

      &.has-child {
        position: relative;// 開閉ボタン用
      }

      a {
        display: block;
        padding: .75em 0;
        color: inherit;
        transition: .2s;

        &:hover {
          text-decoration: none;
          background-color: #f0f1f2;
          padding-left: 4px;
        }
      }
    }
  }

  &__name-ja {

  }

  &__name-en {
    display: block;
    padding-left: 1em;
    margin-top: 2px;
    font-family: 'Dynalight', serif;
    position: relative;
    line-height: 1.4;

    &:before {
      content: "";
      position: absolute;
      top: 0.6em;
      left: 1px;
      height: 1px;
      width: 0.5em;
      background: #000;
    }
  }

  // 親カテゴリ
  ul.parent {

    > li {
      @include borderTop;
    }
  }

  // 子孫カテゴリ（入れ子になるごとに字下げ）
  ul.child {
    margin-left: .75em;
    padding-left: .75em;
    border-left: solid 1px #ccc;

    margin-top: .5em;
    margin-bottom: .5em;

    display: none;
  }

  // カテゴリ開閉ボタン
  .categoyNavOpenBtn {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: .55em;//上下位置調整
    transition: .2s;

    &:after {
      font-family: "jeiafont";
      content: "\e902";
      font-size: 1em;
      display: block;
      width: 2em;
      height: 2em;
      line-height: 2em;
      cursor: pointer;
      text-align: center;
    }

    // OPEN状態
    &.is-open {
      transform: rotate(90deg);
    }
  }
}



/*
ON-SALEブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.2.html

Styleguide 11.4.2
*/
.ec-onsaleRole {

  .ec-productItemRole {
    .ec-productItemRole__content {
      text-align: left;
    }
  }
}



/*
BLOG-POSTSブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.3.html

Styleguide 11.4.3
*/
.ec-blogpostsRole {

  & &__container {
  }

  /* ブログ投稿item */
  & &__item {

    a {
      display: flex;
      color: inherit;

      &:hover {
        text-decoration: none;

        img {
          filter: brightness(1.1);
        }
      }
    }
  }

  /* ブログ投稿item間の余白 */
  & &__item + &__item {
    margin-top: 10px;

    @include media_desktop {
      margin-top: 20px;
    }
  }

  /* ブログサムネイル */
  & &__image {
    flex: 0 0 auto;
    width: 6em;

    @include media_desktop {
      width: 100px;
    }

    img {
      transition: .2s;
    }
  }

  /* ブログ投稿コンテンツ */
  & &__content {
    flex: 1 1 auto;
    font-size: 0.933rem;
    padding-left: 1em;

    @include media_tablet {
      /* 横2列ずつのスライドなのでコンテンツに右余白を */
      padding-right: 1em;
    }

    /* 投稿日時 */
    time {
      display: block;
      line-height: 1;
      color: $clrDefaultThin;
      margin-bottom: 1em;
    }

    /* 投稿コメント */
    p {
      line-height: 1.2;
    }
  }
}



/*
CUSTOMER-VOICEブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.4.html

Styleguide 11.4.4
*/
.ec-customerVoiceRole {

  & &__item {
    font-size: 0.933rem;

    @include media_tablet {
      padding: 0 10px;
    }
  }

  /* お客様コメント */
  & &__comment {
    border: solid 1px #eee;
    padding: 1.5em 2em;
    margin-bottom: 1em;
    line-height: 1.2;
  }

  /* お客様情報 */
  & &__customer {
    display: flex;
    flex-flow: column;
    justify-content: center;

    /* アイコン表示 */
    background: url(../icon/user_sns.svg) no-repeat left center;
    background-size: 50px;
    min-height: 50px;
    padding-left: 50px;

    @include media_desktop {
      background-size: 60px;
      min-height: 60px;
      padding-left: 60px;
    }

    .name {
      display: block;
      margin: 0 0 0 1em;
    }

    time {
      display: block;
      color: $clrDefaultThin;
      margin: 0 0 0 1em;
    }
  }
}



/*
動画メディア・バナーブロック

タブレット時は横に2枚ずつ配置するレイアウトになります。

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.5.html

Styleguide 11.4.5
*/
.ec-movieMediaRole {

  & &__wrap {

    /* タブレット時は横に2枚ずつ配置します */
    @include media_tablet {
      display: flex;
      flex-flow: row wrap;
      margin-top: -20px;
    }
  }

  /* メディア格納item（動画・バナー共通） */
  & &__item {

    @include media_sphone {
      margin-bottom: $padding-sphone;
    }

    @include media_tablet {
      flex: 0 0 auto;
      width: calc((100% - 20px) / 2) !important;
      margin-left: 20px;
      margin-top: 20px;

      &:nth-child(2n+1) {
        margin-left: 0;
      }
    }

    @include media_desktop {
      margin-bottom: $padding-desktop;
    }
  }

  /* メディア格納要素 */
  .ec-mediaframe {

    /* 動画用 */
    &.movie {
      width: 100%;
      height: 0;
      padding-top: 9 / 16 * 100%;
      position: relative;

      iframe, video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: none;
      }
    }

    a {
      display: block;
      @include imageHover();
    }
  }
}



/*
インスタグラム最新記事表示ブロック

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.6.html

Styleguide 11.4.6
*/
.ec-instagramRole {

  /* ul要素 */
  & &__list {

    // 記事の配置レイアウト
    @include media_sphone {
      @include shelfLayout(3, 0px, 0px, li);

      // ネガティブマージンで左右余白を0にします
      margin-left: $padding-sphone * -1;
      margin-right: $padding-sphone * -1;
      // フッターとの余白も0にしてさらにバランスを調整します
      //margin-bottom: $padding-sphone * -1;
    }

    @include media_tablet {
      @include shelfLayout(4, 5px, 5px, li);
    }

    @include media_desktop {
      @include shelfLayout(2, 10px, 10px, li);
    }

    /* li要素（記事単体） */
    li {
      position: relative;

      a {
        display: block;
      }

      /* 画像メディア */
      .pic {
        overflow: hidden;

        .image {
          width: 100%;
          height: 0;
          padding-top: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          transition: .6s;
        }

        &:hover {

          .image {
            transform: scale(1.1);
            filter: brightness(1.05);
          }
        }
      }

      /* 動画メディア */
      video {
        display: block;
        width: 100%;
        height: auto;
      }

      /* ポップアップ */
      .popup {
        position: absolute;
        bottom: calc(100% + 7px);
        left: 50%;
        width: 100%;
        min-width: 200px;
        font-size: 13px;
        box-sizing: border-box;
        padding: 1em;
        background-color: #000;
        color: #fff;
        font-size: 13px;
        border-radius: 3px;
        z-index: 9;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
        pointer-events: none;

        visibility: hidden;
        opacity: 0;
        transform: translate3d(-50%,20px,0);
        transition: .4s;

        /* 記事コメント（キャプション） */
        .comment {
        }

        /* いいね件数 */
        .likeCount {
          font-size: 0.9em;
          opacity: 0.75;
          text-align: right;
          margin-top: .5em;
        }

        /* ポップアップくちばし */
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: solid 7px transparent;
          border-top-color: #000;
          position: absolute;
          top: 100%;
          left: calc(50% - 7px);
        }

        //スマホ・タブレット時はポップアップ非表示
        @include media_sphone {
          display: none;
        }

        @include media_tablet {
          display: none;
        }
      }

      /* hoverでポップアップ表示 */
      &:hover .popup {
        visibility: visible;
        opacity: 1;
        transform: translate3d(-50%,0,0);
      }
    }
  }
}



/*
左カラムロゴ・CONTACT-US

sg-wrapper:
<div class="ec-layoutRole__contents">
  <div class="ec-layoutRole__left" style="width:25%;">
    <sg-wrapper-content/>
  </div>
</div>

Markup:
../styleguide/tmpl/11.4.7.html

Styleguide 11.4.7
*/
.ec-leftContactUsRole {

  /* スマホ時は非表示 */
  @include media_sphone {
    display: none;
  }

  /* タブレット時は非表示 */
  @include media_tablet {
    display: none;
  }

  /* 左カラムロゴ */
  .leftLogo {
    margin-bottom: 1.5rem;

    a {
      display: inline-block;
    }

    img {
      width: auto;
      max-width: 100%;
    }
  }

  /* CONTACT US
     「CONTACT US」はフッターにも配置してありますのでフッター用に「_11.3.footer.scss」で基本スタイリングしています。
     ここでは左カラム用に文字サイズと色を上書き調整しています。
  */
  .ec-contactUsRole {
    font-size: 0.933rem;
    color: $clrDefaultThin;
  }
}
