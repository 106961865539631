///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ヘッダー＆ドロワー

Styleguide 11.2
*/



/*
ヘッダーフレーム構造

まずはヘッダーのフレーム構造について定義しています。
ヘッダーは横2列のフレーム構造になっております。

sg-wrapper:
<div class="ec-layoutRole__header">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/11.2.2.html

Styleguide 11.2.2
*/
.ec-layoutRole__header {
  background-color: $clrHeaderBase;
}

/* .ec-headerRole フレーム構造 */
.ec-headerRole {
  @include container;
  max-width: $container-maxwidth2;
  display: flex;
  justify-content: space-between;

  // スマホ用
  @include media_sphone {
    height: $sphone-header-height;
    padding: 0;

    & &__left {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-start;
    }

    & &__right {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;// 右余白
    }
  }

  // タブレット用
  @include media_tablet {
    height: $tablet-header-height;
    padding: 0;

    & &__left {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-start;
    }

    & &__right {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;// 右余白
    }
  }

  // デスクトップ用
  @include media_desktop {
    height: $desktop-header-height;

    & &__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-right: 20px;
    }

    & &__right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}



/*
ヘッダーナビ

ヘッダーナビは、ヘッダー左フレーム、ロゴ横に配置されます。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__center">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.3.html

Styleguide 11.2.3
*/
.ec-headerNav {
  font-family: $ff-en;
  font-size: 14px;
  color: $clrHeaderText;
  margin-left: 40px;

  @include media_sphone {
    display: none;
  }

  @include media_tablet {
    display: none;
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
      margin-right: 0;
    }

    > a {
      display: block;
      color: inherit;
      @include hoverBottomLine($clrHeaderText);
    }

    /* サブメニュー枠組み */
    &.has-submenu {
      position: relative;

      .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        width: auto;
        min-width: 300px;
        padding-top: 14px;/*くちばし分の余白*/
        pointer-events: none;//サブメニューが消える間際、隣のメニューに被さるので干渉しないように
        z-index: 999;

        visibility: hidden;
        opacity: 0;
        transform: translate3d(0,-15px,0);
        transition: .2s;

        &__body {
          background-color: $clrHeaderPopupBg;
          color: $clrHeaderPopupText;
          padding: 20px;
          filter: drop-shadow(0 0 10px rgba(0,0,0,.2));
          position: relative;

          /* くちばし */
          &:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: 100%;
            left: 15px;
            border: solid 10px transparent;
            border-bottom-color: $clrHeaderPopupBg;
          }
        }
      }

      &:hover {
        .submenu {
          visibility: visible;
          opacity: 1;
          transform: translate3d(0,0,0);
          pointer-events: auto;//隣のメニューに干渉しないようにnoneとしていたので解除
       }
      }
    }
  }
}

/* サブメニュー内容（カテゴリツリー） */
.ec-categoryTree {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0;
    padding: 0;
    border-bottom: solid 1px $clrHeaderPopupBorder;
    white-space: nowrap;

    position: relative;

    a {
      display: block;
      padding: 0.4em 0;
      transition: .2s;
      color: inherit;

      &:hover {
        text-decoration: none;
        background-color: $clrHeaderPopupHoverBg;
        padding-left: 3px;
      }
    }

    ul.child {
      margin: 0;
      padding: 0;
      list-style-type: none;
      min-width: 10em;

      position: absolute;
      top: -10px;
      left: calc(100% - .5em);
      background-color: $clrHeaderPopupBg;
      color: inherit;
      padding: 20px;
      z-index: 9;
      filter: drop-shadow(-5px 0 5px rgba(0,0,0,.1));

      visibility: hidden;
      opacity: 0;
      transition: .2s;
    }

    &:hover > ul.child {
      visibility: visible;
      opacity: 1;
    }
  }

}



/*
ドロワー開閉ボタン

ヘッダー左枠にドロワー開閉ボタンが格納されます。
ドロワー開閉ボタンはスマホ・タブレット時に表示されます。

Markup:
../styleguide/tmpl/11.2.4.html

Styleguide 11.2.4
*/
/* ドロワーボタン（サイドメニュー開閉ボタン） */
.ec-drawerButton {
  display: block;
  cursor: pointer;
  position: relative;

  @include media_sphone {
    width: $sphone-header-height;
    height: $sphone-header-height;
    padding: 10px;
  }
  @include media_tablet {
    width: $tablet-header-height;
    height: $tablet-header-height;
    padding: 12px;
  }
  @include media_desktop {
    display: none;
  }

  .burger {
    display: block;
    width: 100%;
    height: 2px;
    background-color: $clrHeaderBurger;
    position: relative;
    top: 50%;
    text-indent: -9999px;
    transition: .3s;

    &:before,
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: $clrHeaderBurger;
      position: absolute;
      left: 0;
      transition: .3s;
    }

    &:before {
      top: -8px;
    }

    &:after {
      bottom: -8px;
    }

    /* ドロワー（サイドメニュー）表示時 */
    body.have_curtain & {
      background-color: transparent;

      &:before {
        top: 0;
        transform: rotate(225deg);
      }

      &:after {
        bottom: 0;
        transform: rotate(135deg);
      }
    }
  }
}



/*
ヘッダータイトル

ヘッダー左枠にヘッダータイトルが格納されます。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__left">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.5.html

Styleguide 11.2.5
*/
.ec-headerTitle {
  display: block;

  a {
    transition: .3s;

    &:hover {
      opacity: 0.8;
    }
  }

  // スマホ用調整
  @include media_sphone {
    height: 100%;
    max-width: 92px;

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;

      img {
        //height: 35px;//ロゴの大きさを調整
        //width: auto;
      }
    }
  }

  // タブレット用調整
  @include media_tablet {
    height: 100%;
    max-width: 118px;


    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      width: 100%;

      img {
        //height: 45px;//ロゴの大きさを調整
        //width: auto;
      }
    }
  }

  // デスクトップ用調整
  /*
   * デスクトップ時はページスクロールされるとヘッダー高さを縮小させますので、縮小に伴い、
   * ヘッダーロゴ画像が連動して縮小されるようにスタイリングします。
   * ロゴ画像自体の高さ（Sass変数）をmax-heightに設定しておくことでロゴ画像が引き延ばされて大きくならないようにしています。
   *
   * ロゴ画像のデザインにもよりますが、モバイル時のロゴの収まりも優先して画像生成して、デスクトップ時はそれに合わせて調整（上下中央配置）できた方がいいと思います。
   */
  @include media_desktop {
    height: 100%;
    max-width: 210px;
    transition: 0.6s;

    .scrolled & {
      max-width: 130px;
    }

    a {
      // ロゴ画像が中央配置になるよう
      display: flex;
      height: 100%;
      justify-content: flex-start;
      align-items: center;

      img {
        //width: auto;
        //height: 100%;
        //max-height: $headerlogo-height;// ヘッダーロゴ画像の高さを設定
      }
    }
  }
}



/*
ヘッダーメニュー（ユーザメニュー）

ヘッダーメニューは、ヘッダー右枠フレームに配置されます。<br>
会員登録、ログインや、商品検索、カートといったユーザメニューが含まれます。<br>
またマウスホバーでボタンガイド的なポップアップを表示させる事もできます。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <sg-wrapper-content/>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.6.html

Styleguide 11.2.6
*/
.ec-headerMenu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  color: $clrHeaderText;
  width: 100%;

  & &__item {
    padding: 0;//0 .25em;

    > a {
      display: block;
      color: inherit;
      line-height: 1;
      padding: 7px 7px;
      border-radius: 18px;// アイコンサイズが20px + padding 7px + border 1px
      border: solid 1px transparent;
      transition: .2s;

      &:hover {
        text-decoration: none;
        background-color: $clrHeaderMenuHoverBg;
      }
    }

    /* スマホ時に非表示とするボタン */
    &.hidden_mobile {
      @include media_sphone {
        display: none;
      }
    }


    /* 最後のitemは右余白を除去 */
    &:last-child {
      padding-right: 0;
    }
  }

  & &__itemIcon {
    font-size: 20px;
  }

  & &__itemText {
    display: none;// ボタンテキスト不要なら
    //line-height: 20px;// アイコンと同値にしておいたほうが無難

    @include media_sphone {
      display: none;
    }
  }
}

/* ポップアップ */
//「.ec-headerMenu__item」にclass「.has-popup」と追加し、下層にポップアップ用のHTML「.ec-headerMenu__itemPopup」を記述します。
//不要であれば削除してください。
.ec-headerMenu {

  & &__item.has-popup {
    position: relative;

    &:hover {
      .ec-headerMenu__itemPopup {
        visibility: visible;
        opacity: 1;
        transform: translate3d(-50%,0,0);
        pointer-events: auto;//隣のメニューに干渉しないようにnoneとしていたので解除
      }
    }
  }

  & &__itemPopup {
    position: absolute;
    top: 100%;
    left: 50%;
    width: auto;
    min-width: 200px;
    padding-top: 15px;/*くちばし分の余白*/

    pointer-events: none;//サブメニューが消える間際、隣のメニューに被さるので干渉しないように
    z-index: 999;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(-50%,-15px,0);
    transition: .2s;

    .itemPopupInner {
      background-color: $clrHeaderPopupBg;
      color: $clrHeaderPopupText;
      padding: 20px;
      text-align: center;
      white-space: nowrap;// 内容を改行させたくない場合
      filter: drop-shadow(0 0 10px rgba(0,0,0,.2));
      position: relative;

      /* くちばし */
      &:after {
        content: "";
        width: 0;
        height: 0;
        border: solid 10px transparent;
        border-bottom-color: $clrHeaderPopupBg;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    @include media_sphone {
      display: none;
    }

    @include media_tablet {
      display: none;
    }

    /* ポップアップ内のブロックボタン（ログアウトボタン）.ec-blockBtnを上書き調整します */
    .ec-blockBtn {
      font-size: inherit;
      height: 2.7em;
      line-height: 2.7em;
    }
  }
}



/*
商品検索ボックス

ヘッダーメニューにある虫眼鏡ボタンをクリックする事で表示される商品検索ボックスのコンポーネントです。

※当テンプレートでは商品検索ボックスはヘッダーのみに実装する考えです。モバイル時でも検索ボックスの開閉ボタンがヘッダーにあります。

一応、ドロワー内にも「商品検索」ブロックは設置可能です（最低限のスタイリングはしています）。

Markup:
../styleguide/tmpl/11.2.7.html

Styleguide 11.2.7
*/
.ec-headerSearch {
  $SearchHeight: 36px;

  @include media_desktop {
    flex: 1;
  }

  /* 検索ボックス開閉ボタン */
  & &__button {
  }

  /* 検索ボックス全体枠 */
  & &__body {
    background-color: $clrHeaderBase;
    color: $clrHeaderText;
    padding: 10px;
    padding-top: 0;
  }

  /* 検索ボックス：カテゴリ&入力欄 レイアウト枠 */
  & &__wrap {
    display: flex;
    align-items: center;
    height: $SearchHeight;// 高さ
    border-radius: ($SearchHeight / 2);
    border: solid 1px $clrDrawerBorder;
    padding: 4px;
    overflow: hidden;
  }

  /* 検索ボックス：カテゴリ選択 */
  & &__category {
    border-right: solid 1px $clrDrawerBorder;

    // フォーム部品.ec-selectを検索ボックス用に調整
    .ec-select {
      margin: 0;

      .select_ui {

        // selectボタンに施した独自UIを上書き調整
        &:after {
          background-color: transparent;
          content: "\e902";
          transform: rotate(90deg);
          color: inherit;
          font-size: .7em;
          right: 3px;
        }

        select {
          display: block;
          font-size: 13px;
          height: 100%;
          padding: 0px 0.7em !important;
          background-color: transparent;
          color: inherit;
          border: none;
        }
      }
    }
  }

  /* 検索ボックス：入力欄 */
  & &__keyword {
    flex: 1 1 auto;
    position: relative;//検索ボタン配置用

    input[type="search"] {
      border: none;
      margin: 0;
      border-radius: 0;
      font-size: 13px;
      height: 100%;
      padding-left: 1em;
      padding-right: $SearchHeight;
      background-color: transparent;
      color: inherit;
    }
  }

  /* 検索ボタン */
  & &__keywordBtn {
    border: none;
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    // ボタンサイズ
    width: $SearchHeight;
    height: $SearchHeight;
    font-size: 20px;
    color: inherit;
  }
}

/*
ヘッダーメニューに設置した商品検索コンポーネントのスタイリング
「商品検索ブロック」はレイアウト管理でドロワー内にも設置可能なので、お互いにスタイリングが汚染影響しないようヘッダーメニュー内に限定したスタイリングを施す
*/
.ec-headerMenu {

  /* 検索ボックス全体枠 */
  .ec-headerSearch__body {
    position: absolute;
    z-index: 998;
    // 閉状態
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-15px,0);
    transition: .3s;

    // 開状態
    &.is-active {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
    }
  }
}

// スマホ用調整
@include media_sphone {
  // 検索ボックスの表示position位置は.ec-headerRole基準
  .ec-headerRole {
    position: relative;
  }
  .ec-headerMenu {
    .ec-headerSearch__body {
      top: 100%;
      width: 100%;
      left: 0;
    }
  }
}

// タブレット用調整
@include media_tablet {
  // 検索ボックスの表示position位置は.ec-headerRole基準
  .ec-headerRole {
    position: relative;
  }
  .ec-headerMenu {
    .ec-headerSearch__body {
      top: 100%;
      width: 100%;
      left: 0;
    }
  }
}

// デスクトップ用調整
@include media_desktop {
  /* デスクトップ時は開閉ギミック無しの出っぱなし状態 */

  /* 開閉用ボタン不要 */
  .ec-headerSearch__button {
    display: none !important;
  }

  // 検索ボックスの表示position位置は.ec-headerMenu基準
  .ec-headerMenu {
    position: relative;

    .ec-headerSearch__body {
      top: 0%;
      right: calc(100% + 10px);
      width: 100%;
      max-width: 380px;
      padding: 0 7px;

      // デスクトップ時は開閉ギミック無しの出っぱなし状態
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
      transition: 0s;

      position: initial;
      margin-left: auto;
    }
  }
}

/*
ドロワー内に設置した商品検索コンポーネントのスタイリング
※当テンプレートでは商品検索はヘッダーにある物を使用する仕様ですが、一応、ドロワー用スタイリングも用意しています。
*/
.ec-drawerRole {
  .ec-headerSearch {
    padding: 10px 0;

    .ec-headerSearch__button {
        display: none;
    }
    /* 検索ボックス全体枠 */
    .ec-headerSearch__body {
      padding: 0;
    }
  }
}



/*
カートナビゲーション

カートインがあればカートアイコンに数量をバッジ表示します。<br>
カートボタンにマウスオンでカートナビゲーション（カート内容詳細）を表示します。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <div class="ec-headerMenu">
        <sg-wrapper-content/>
      </div>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.8.html

Styleguide 11.2.8
*/
.ec-cartNavi {
  $parent: &;//親要素名の記憶
  position: relative;

  a {
    /* ボタンが小さい分、クリックカブル領域を少し拡張しておきたい理由と、同時に、
     * この余白分がバッジの右へずらす量、位置決めにもなっています。
     * これによりバッジ全体がクリック領域に含まれるので収まりもいいと思います。
     */
    padding-right: 0.75em;
  }

  /* カート数バッジ */
  & &__badge {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $ff-en;
    font-size: 10px;
    line-height: 1;
    width: 1.6em;
    height: 1.6em;
    border-radius: 50%;
    background-color: #f55;
    color: #fff;
    pointer-events: none;

    position: absolute;
    top: 0px;
    right: 0px;
  }

  /* カート内容詳細ポップアップ枠組み */
  & &__body {
    position: absolute;
    top: 100%;
    right: 0;
    width: 320px;// 収まりが悪い場合は調整
    display: inline-block;

    padding-top: 14px;/*くちばし分の余白*/
    pointer-events: none;//サブメニューが消える間際、隣のメニューに被さるので干渉しないように
    z-index: 999;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(0,-15px,0);
    transition: .2s;

    .ec-cartNaviWrap {
      // ToDo:ポップアップはmixin化しておきたいかも
      background-color: $clrHeaderPopupBg;
      color: $clrHeaderPopupText;
      padding: 20px;
      filter: drop-shadow(0 0 10px rgba(0,0,0,.2));
      position: relative;

      /* くちばし */
      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 10px;
        border: solid 10px transparent;
        border-bottom-color: $clrHeaderPopupBg;
      }
    }
  }

  &:hover {
    #{$parent}__body {
      visibility: visible;
      opacity: 1;
      transform: translate3d(0,0,0);
      pointer-events: auto;//隣のメニューに干渉しないようにnoneとしていたので解除
    }
  }
}



/*
カートナビゲーションのポップアップ(カートイン在り)

カートに商品が追加されている場合のカートナビゲーションのスタイリングです。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <div class="ec-headerMenu">
        <div class="ec-headerMenu__item ec-cartNavi">
          <sg-wrapper-content/>
        </div>
      </div>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.9.html

Styleguide 11.2.9
*/
.ec-cartNaviIsset {
  font-size: 13px;

  & &__cart {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px $clrHeaderPopupBorder;
  }

  /* 商品画像 */
  & &__cartImage {
    flex: 0 0 90px;
    order: 2;
    position: relative;// カート削除ボタン配置長

    > a {
      display: block;
    }
  }

  /* カート削除ボタン */
  & &__cartDelete {


    a {
      // 削除ボタン
      @include closeBtnCircle(20px);

      position: absolute;
      top: -10px;
      right: -10px;
    }
  }

  /* 商品詳細 */
  & &__cartContent {
    flex: 0 1 auto;
    order: 1;
    padding-right: 10px;
  }

  /* 商品名 */
  & &__cartContentTitle {
    margin-bottom: 0.5em;
    font-weight: 700;
  }

  /* 商品規格 */
  & &__cartContentClass {
    margin: 0;
    margin-left: 10px;
    margin-bottom: 0.5em;
    font-size: 0.85em;
  }

  /* 商品価格 */
  & &__cartContentPrice {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 10px;

    .price {
      display: inline-block;
    }

    .tax {
      display: inline-block;
      font-size: 0.85em;
    }

    .quantity {
      display: inline-block;
      margin-left: 10px;
    }
  }

  & &__TotalPrice {
    margin: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 500;

    dt {
      font-weight: inherit;
    }
  }

  & &__action {
    margin-top: 30px;

    .ec-blockBtn--action {
      background-color: #000;
      color: #fff;
      border: none;
    }
  }
}



/*
カートナビゲーションのポップアップ(カートイン無し)

カートが空の状態でのカートナビゲーションのスタイリングです。

sg-wrapper:
<div class="ec-layoutRole__header">
  <div class="ec-headerRole">
    <div class="ec-headerRole__right">
      <div class="ec-headerMenu">
        <div class="ec-headerMenu__item ec-cartNavi">
          <sg-wrapper-content/>
        </div>
      </div>
    </div>
  </div>
</div>

Markup:
../styleguide/tmpl/11.2.10.html

Styleguide 11.2.10
*/
.ec-cartNaviNull {
  font-size: 13px;

  & &__message {
    text-align: center;

    p {
      margin: 0;
    }
  }
}



/*
ドロワー

スマホ・タブレット時に出現するドロワー（サイドメニュー）コンポーネントです。

ここではドロワーの枠組みを定義しています。<br>
EC-CUBE標準ではドロワーOPEN時はドロワー閉ボタンも表示されますが当テンプレートではヘッダーにあるドロワー開閉ボタンがありますので他の閉ボタンは排除しています。

ドロワーOPEN時にはページコンテンツを暗くするカーテンが表示されます。<br>
またその際、ページコンテンツをぼかすエフェクトも追加しています（デバイス幅が420px以上でコンテンツが見えます）。不要なら削除してください。

Markup:
../styleguide/tmpl/11.2.11.html

Styleguide 11.2.11
*/
.ec-drawerRole {
  position: fixed;
  width: 100%;
  max-width: 420px;// ドロワーコンテンツが幅的に収まらない場合は拡張、もしくは100%化
  left: 0;
  z-index: 1000;
  box-sizing: border-box;
  overflow-y: scroll;
  background-color: $clrDrawerBase;
  color: $clrDrawerText;

  visibility: hidden;
  transform: translate3d(-100%,0,0);
  transition: .3s;

  // 当テンプレートではヘッダーが上部固定でありヘッダー内のドロワー開閉ボタンを活かしたいので、ドロワーをヘッダーの高さ分だけ短くしておきます
  @include media_sphone {
    height: calc(100vh - #{$sphone-header-height});
    top: $sphone-header-height;
    padding: 5px 20px 50px;
  }

  @include media_tablet {
    height: calc(100vh - #{$tablet-header-height});
    top: $tablet-header-height;
    padding: 10px 30px 50px;
  }

  @include media_desktop {
    display: none;
  }

  &.is-active {
    visibility: visible;
    transform: translate3d(0,0,0);
  }
}

/* ドロワー閉ボタン（※不使用） */
.ec-drawerRoleClose {
  display: none;
}

/* ドロワー背景カーテン */
.ec-overlayRole {
  position: fixed;
  width: 100%;
  //height: 100vh;
  //top: 0;
  left: 0;
  background: hsla(0,0%,0%,.75);

  visibility: hidden;
  opacity: 0;
  transition: .4s;

  /* ドロワーと同様、ヘッダーが上部固定仕様でありヘッダー内にあるドロワー開閉ボタンを活かしたいので、
   * 背景かーテンもヘッダーの高さ分だけ短くしておく
   */
  @include media_sphone {
    height: calc(100vh - #{$sphone-header-height});
    top: $sphone-header-height;
  }

  @include media_tablet {
    height: calc(100vh - #{$tablet-header-height});
    top: $tablet-header-height;
  }

  @include media_desktop {
    display: none;
  }

  /* 表示状態 */
  .have_curtain & {
    visibility: visible;
    opacity: 1;
  }
}

/* 背景下のページコンテンツをぼかす（不要なら削除） */
.ec-layoutRole {

  > *:not(.ec-layoutRole__header) {
    transition: .4s;

    .have_curtain & {
      filter: blur(3px);
    }
  }
}



/*
ドロワーメニュー

Styleguide 11.2.12
*/
.ec-drawerMenu {
  font-size: 0.9285rem;
  //margin-bottom: 20px;// .ec-drawerMenuごとに上下余白を設ける場合

  & &__item {
    border-bottom: dotted 1px $clrDrawerBorder;

    a {
      display: block;
      padding: 0.75em 0;
      color: inherit;
      position: relative;
    }
  }

  & &__itemText {
  }

  & &__itemIcon {
    display: inline-block;
    vertical-align: middle;
    //font-size: 1.2em;
    color: $clrDrawerIcon;
    margin-left: 5px;
  }

  & &__itemBadge {
    display: inline-block;
    font-size: 10px;
    line-height: 1.6em;
    width: 1.6em;
    height: 1.6em;
    text-align: center;
    border-radius: 50%;
    background-color: #f55;
    color: #fff;
    font-family: $ff-en;
    pointer-events: none;
  }

  /*
  カテゴリ折り畳み表示
  商品カテゴリとマイページカテゴリにて使用
  */
  & &__item.has-category {
    position: relative;// 開閉ボタン用
  }

  // ドロワーカテゴリ基本スタイリング
  & &__category {
    //padding-left: 1em;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        margin: 0;
        padding: 0;

        a {
          display: block;
          padding: .5em 0;
          color: inherit;
        }

        &.has-child {
          position: relative;// 開閉ボタン用
        }
      }
    }

    &__name-ja {

    }

    &__name-en {
      display: block;
      padding-left: 1em;
      margin-top: 2px;
      margin-left: 1em;
      font-family: 'Dynalight', serif;
      position: relative;
      line-height: 1.4;

      &:before {
        content: "";
        position: absolute;
        top: 0.6em;
        left: 1px;
        height: 1px;
        width: 0.5em;
        background: #000;
      }
    }

    .child .ec-drawerMenu__category__name-en {
      margin-left: 0;
    }

    // 親カテゴリ
    ul.parent {
      display: none;
      margin-bottom: 1em;

      // キャレット追加
      > li > a {
        &:before {
          font-family: "jeiafont";
          content: "\e91a";
          color: $clrDrawerIcon;
        }
      }
    }

    // 子孫カテゴリ（入れ子になるごとに字下げされる）
    ul.child {
      margin-left: .75em;
      padding-left: .75em;
      border-left: solid 1px $clrDrawerBorder2;
      display: none;
    }

    // カテゴリ開閉ボタン
    .categoyOpenBtn {
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0.5em;
      transition: .2s;

      &:after {
        font-family: "jeiafont";
        content: "\e902";
        font-size: 1em;
        display: block;
        width: 2em;
        height: 2em;
        line-height: 2em;
        cursor: pointer;
        text-align: center;
      }

      // OPEN状態
      &.is-open {
        transform: rotate(90deg);
      }
    }

  }

  /* ドロワーフッターナビ */
  &.footerNav {
    margin-top: 20px;// 上余白を設ける場合
    //font-size: 0.8em;
  }
}



/*
ヘッダー挙動 その１

当テンプレートではヘッダーをページ上部に固定させる仕様となっております。
ヘッダーを固定しますので、.ec-layoutRoleに上部余白を設けておく必要があります。

またデスクトップ時はページスクロールが発生するとヘッダーの高さを縮小させます。

Styleguide 11.2.90
*/
.ec-layoutRole {

  & &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
  }


}

.ec-layoutRole:not(.full_screen_page .ec-layoutRole) {
  @include media_sphone {
    padding-top: $sphone-header-height;
  }

  @include media_tablet {
    padding-top: $tablet-header-height;
  }

  @include media_desktop {
    //padding-top: $desktop-header-height + 30px;
    /* ヘッダー＆ドロワーの背景色を黒系か白系かに切り替えできる仕組みを「_variables.scss」にて実装していますが、
     * 黒系ヘッダー（色付きヘッダー）の場合はヘッダーとコンテンツの間に少し余白を設けたほうがバランスがいいと思います。
     * 白系ヘッダー（ヘッダーとメインコンテンツが同色）の場合はヘッダーとコンテンツの間は空けないほうがまとまってみえるかもしれません。
     * ですので、黒系・白系切り替えフラグをみてその余白を切り替えます。
     */
    @if $headerThemeDark {
      // 黒系ヘッダー（色付きヘッダー）の場合はヘッダーとコンテンツの間に余白を加えます
      padding-top: $desktop-header-height + 30px;
    } @else {
      // 白ヘッダーはヘッダーとコンテンツの間の余白は無しとします
      padding-top: $desktop-header-height;
    }

    .ec-headerRole {
      transition: 0.6s;

      /* 縮小ヘッダー高さ */
      body.scrolled & {
        height: 50px;//($desktop-header-height * 0.5);
      }
    }
  }

}


// IE11ではロゴが縦横比を維持しない様子なので固定値を指定（運用時にロゴサイズ比率が違う場合は調整してください）
body.scrolled .ec-layoutRole__header .ec-headerTitle img {
  @include hack-ie11 {
    width: 130px;
  }
}

/*
ヘッダー挙動　その２
 .product_collection ページ
 */
.product_collection {
  .ec-layoutRole {
    padding-top: 0 !important;
    &__header {
      background-color: transparent;
    }
  }
  .ec-headerSearch {
    &__body {
      background-color: transparent;
    }
    input::placeholder {
      color: inherit;
      transition: color 0.3s;
    }
  }
}

// KVを通過後
.product_collection.header-white {
  .ec-layoutRole__header  {
    background-color: $clrHeaderBase;
  }
  .ec-headerSearch {
    input::placeholder {
      color: #ddd;
    }
  }
}
