///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
特集商品・特集商品バナー

Styleguide 12.6
*/



/*
特集商品「FEATURED PRODUCTS」

トップページで使用されている特集商品「FEATURED PRODUCTS」ブロックのスタイルです。
レスポンシブにより商品の表示点数が可変します。

使用箇所：
* Block/new_item.twig

Markup:
../styleguide/tmpl/12.6.1.html

Styleguide 12.6.1
*/
.ec-featuredProductsRole {
  @include container;

  @include media_sphone {
    margin-top: $padding-sphone * 3;
  }

  @include media_tablet {
    margin-top: $padding-tablet * 3;
  }

  @include media_desktop {
    margin-top: $padding-desktop * 2;

    /* 商品アイテム マウスオーバーアクション */
    .ec-productItemRole {
      @include productItemHoverAction;
    }
  }
}



/*
特集商品バナー

トップページで使用されている特集商品「FEATURED PRODUCTS」の下につくバナーブロックのスタイルです。

Markup:
../styleguide/tmpl/12.6.2.html

Styleguide 12.6.2
*/
.ec-featuredBannerRole {
  @include container;

  @include media_sphone {
    margin-top: $padding-sphone * 3;
  }

  @include media_tablet {
    margin-top: $padding-tablet * 3;
  }

  @include media_desktop {
    margin-top: $padding-desktop * 2;
  }

  & &__list {
    /* バナーの配置 */
    @include media_sphone {
      .ec-featuredBannerRole__item + .ec-featuredBannerRole__item {
        margin-top: $padding-sphone;
      }
    }

    @include media_tablet {
      @include shelfLayout(2, 10px, 0, 'div');
    }

    @include media_desktop {
      @include shelfLayout(2, 20px, 0, 'div');
    }

    + .ec-featuredBannerRole__list {
      @include media_sphone {
        margin-top: 6.25vw;
      }

      @include media_tablet {
        margin-top: 10px;
      }

      @include media_desktop {
        margin-top: 20px;

      }
    }
  }

  & &__item {

    a {
      @include imageHover();
    }
  }
}

.ec-newItemRole {
  @include container;

  @include media_sphone {
    margin-top: $padding-sphone * 3;
  }

  @include media_tablet {
    margin-top: $padding-tablet * 3;
  }

  @include media_desktop {
    margin-top: $padding-desktop * 2;

    /* 商品アイテム マウスオーバーアクション */
    .ec-productItemRole {
      @include productItemHoverAction;
    }
  }

  & &__list {
    // slick準備整うまでは非表示
    visibility: hidden;
    opacity: 0;
    filter: blur(5px);// ぼかしエフェクト
    transition: 1s;

    // slick準備完了で表示開始
    &.slick-initialized {
      visibility: visible;
      opacity: 1;
      filter: blur(0);
    }
  }

  & &__item {

    a {
      @include imageHover();
    }
  }

  &__trim-vertical {
    position: relative;
    width: 100%;

    &:before {
      content:"";
      display: block;
      padding-top: 150%; /* 高さを幅の75%に固定 */
    }

    img {
      height: 100% !important;
      width: auto !important;
      max-width: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right:50%;
      -webkit-transform: translateX(50%);
      transform: translateX(50%);
    }

    &:hover {
      img {
        height: 110% !important;
        top: -5%;
        -webkit-transform: translateX(50%);
        transform: translateX(50%) !important;
      }
    }

    > .appear_itemImage {
      height: 100% !important;
      width: 100% !important;
      max-width: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
    }

  }

}
