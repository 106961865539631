///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
カート追加モーダル

カート追加モーダルに関する Project コンポーネントを定義します。

※EC-CUBE4標準のままです

使用箇所：
* Product/list.twig
* Product/detail.twig

Markup:
../styleguide/tmpl/13.4.html

Styleguide 13.4
*/
.ec-modal {

  .checkbox {
    display: none;
  }

  .ec-modal-overlay {
    opacity: 0;
    transition: all 0.3s ease;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
    transform: scale(1);
    display: flex;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .ec-modal-wrap {
    background-color: #fff;
    border: 1px solid #333;
    width: 90%;
    margin: 20px;
    padding: 40px 5px;
    border-radius: 2px;
    transition: all 0.5s ease;
    -ms-flex-item-align: center;
    align-self: center;

    .ec-modal-box {
      text-align: center;
    }

    .ec-modal-box div {
      margin-top: 20px;
    }

    @include media_desktop {
      & {
        padding: 40px 10px;
        width: 50%;
        margin: 20px auto;
      }
    }

    &.small {
      width: 30%;
    }

    &.full {
      width: 100%;
      height: 100%;
    }
  }

  .ec-modal-overlay {
    .ec-modal-close {
      position: absolute;
      right: 20px;
      top: 10px;
      font-size: 20px;
      height: 30px;
      width: 20px;

      &:hover {
        cursor: pointer;
        color: #4b5361;
      }
    }
  }

  .ec-modal-overlay-close {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -100;
  }

  input:checked {
    ~ .ec-modal-overlay-close {
      z-index: 9998;
    }

    ~ .ec-modal-overlay {
      transform: scale(1);
      opacity: 1;
      z-index: 9997;
      overflow: auto;
    }

    ~ .ec-modal-overlay .ec-modal-wrap {
      transform: translateY(0);
      z-index: 9999;
    }
  }
}
