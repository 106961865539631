.clearfix{clear: both;}
.wp-light { color: #999; font-size: 80%}
#wp-nav {
  list-style: none;
}
#post-list {
  list-style: none;
  padding: 0;
}
#post-list h2 {
  padding: 0;
}
.border-b {
  border-bottom: 1px #eee solid;
}
#post-list li {
  padding: 0px;
}
.wp-row {
  padding: 20px 0;
  @include media_desktop {
    display: flex;
  }
}
.wp-row:after {
  content: " ";
  clear:both;
  display:block;
}
.wp-thumb, .wp-row-content {
  display: block;
}
.content-width {
  flex: 1;
}
.wp-thumb {
  margin-right: 20px;
  margin-top: 10px;
  max-width: 300px;
  #page_wp_post & {
    width: 600px;
    max-width: 90%;
  }

  img {
    display: block;
  }
}
.more-link-wrapper {
  margin: 20px 0;
}
.more-link {
  border: 1px #0092C4 solid;
  padding: 10px;
  line-height: 26px;
  margin: 24px 0;
  box-sizing: border-box;
  clear: both;
  color: #0092C4;
  font-size: 14px;
}
.more-link:hover {
  background: #0092C4;
  color: #fff;
  text-decoration: none;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.wp-block {
  list-style: none;
  padding: 0;
}