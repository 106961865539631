///////////////////////////////////////////////////////////////////////
//
// This template is part of EC-CUBE Design template.
//
// Copyright(c) JAPAN ELECTRONIC INDUSTRIAL ARTS CO.LTD.
//
// http://www.jeia.co.jp/
//
// For the full copyright and license information, please view the LICENSE
// file that was distributed with this source code.
//
// 本テンプレートの著作権は、「株式会社日本電子工藝社」に帰属いたします。
//
// ※本テンプレートを譲渡・再頒布・転用・第三者へ開示することは禁止いたします。
// ただし、事業者によるショップ運営者への設置代行を禁止するものではございません。
// ※本テンプレートを申請されたご利用サイトへの設置以外の目的で複製することは禁止いたします。
// ※本テンプレートデータ内のライセンス条項、および著作権に関する記述を削除することはできません。
// ※ご利用サイトを変更することはできません。申請と異なるサイトにてご利用になる場合は、
// 別途、ご利用費用をお支払いただく必要がございます。
// ※本テンプレートの改変にあたり、一切のサポートはいたしかねます。
// ※本テンプレートは、すべての設置環境で動作する事は保証しておりません。
//
///////////////////////////////////////////////////////////////////////

/*
ボタン生成

mixinを利用して各パターンのボタンを生成します。

Styleguide 2.1
*/



/*
通常ボタン（インラインボタン）

インラインの要素としてボタンを定義出来ます。

〇.ec-inlineBtn
使用箇所：
* Mypage/delivery.twig
* Mypage/history.twig
* Mypage/index.twig
* Shopping/index.twig
* Shopping/shipping.twig
* Shopping/shipping_multiple.twig

〇.ec-inlineBtn--primary
使用箇所：なし

〇.ec-inlineBtn--action
使用箇所：
* Product/list.twig
* Product/detail.twig

〇.ec-inlineBtn--cancel
使用箇所：
* Product/list.twig
* Product/detail.twig

Markup:
../styleguide/tmpl/2.1.1.html

Styleguide 2.1.1
*/
.ec-inlineBtn {
  @include btn-default;
}
.ec-inlineBtn--primary {
  @include btn-primary
}
.ec-inlineBtn--action {
  @include btn-action
}
.ec-inlineBtn--cancel {
  @include btn-cancel
}



/*
ブロックボタン（全幅）

ボタンサイズは em で指定するため、テキストサイズの変更でボタンサイズを変更できます。

使用箇所：
error.twig
Block/cart.twig
Block/eyecatch.twig
Cart/index.twig
Contact/index.twig
Contact/complete.twig
Contact/confirm.twig
Entry/index.twig
Entry/activate.twig
Entry/complete.twig
Entry/confirm.twig
Forgot/index.twig
Forgot/reset.twig
Mypage/change.twig
Mypage/change_complete.twig
Mypage/delivery_edit.twig
Mypage/history.twig
Mypage/login.twig
Mypage/withdraw.twig
Mypage/withdraw_complete.twig
Mypage/withdraw_confirm.twig
Product/detail.twig
Product/list.twig
Shopping/index.twig
Shopping/complete.twig
Shopping/confirm.twig
Shopping/login.twig
Shopping/nonmember.twig
Shopping/shipping.twig
Shopping/shipping_edit.twig
Shopping/shipping_multiple.twig
Shopping/shipping_multiple_edit.twig
Shopping/shopping_error.twig

Markup:
../styleguide/tmpl/2.1.2.html

Styleguide 2.1.2
*/
.ec-blockBtn {
  @include blockBtn-default;

  &__name {
    &--en {
      display: block;
      width: 100%;
      margin-top: 5px;
      text-align: center;
      font-family: 'Dynalight', serif;
      font-size: 0.8rem;

      @include media_tablet {
        font-size: 0.8rem;
      }

      @include media_desktop {
        font-size: 0.8rem;

      }

    }
  }
}
.ec-blockBtn--primary {
  @include blockBtn-primary
}
.ec-blockBtn--action {
  @include blockBtn-action
}
.ec-blockBtn--cancel {
  @include blockBtn-cancel
}

/*
カートインボタン

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/2.1.3.html

Styleguide 2.1.3
*/
.ec-blockBtn--cartin {
 // ボタンは1種類のみなのでmixinを作らず直接、生成
  @include _btn($btn-cartin-color, $btn-cartin-bg, $btn-cartin-bg);
  display: block;
  width: 100%;
  height: $btn-height;
  line-height: $btn-height;
  padding-top: 0;
  padding-bottom: 0;

  &:before {
    font-family: "jeiafont";
    content: "\e900";
    margin-right: 0.5em;
    // 大きさ調整（font-sizeよりscale使った方が収まりがいい？）
    display: inline-block;
    transform: scale(1.4);
  }

  &:hover {
    // 黒ボタン
    //background-color: lighten($btn-cartin-bg, 15%);
    //border-color: lighten($btn-cartin-bg, 15%);

    // hover時、アクションボタンカラーにしてみる
    background-color: $btn-action-bg;
    border-color: $btn-action-bg;    
  }
}

/*
お気に入りボタン

お気に入りボタンのサイズは商品詳細ページの「カートイン枠（横一列）」に合わせて、$cartinRow-sizeで生成しています。

sg-wrapper:
<div class="ec-productRole">
  <sg-wrapper-content/>
</div>

Markup:
../styleguide/tmpl/2.1.4.html

Styleguide 2.1.4
*/
.ec-blockBtn--favorite {
   display: block;

  i {
    display: block;
    margin: 0;

    width: $cartinRow-size;
    height: $cartinRow-size;;
    text-align: center;
    border-radius: 50%;
    background-color: #666;
    color: $btn-favorite-color;
    transition: .2s;

    position: relative;// ポップアップ用

    &:before {
      font-size: ($cartinRow-size * 0.5);// アイコンサイズ
      line-height: $cartinRow-size * 1.1;// 係数はアイコン位置微調整
    }

    /* ポップアップ */
    span {
      position: absolute;
      bottom: calc(100% + 12px);
      left: calc(50% - 18px);

      // ブラウザ幅狭くなるとポップアップがはみ出す形になるので位置を右側に沿わせる
      @media ( max-width: 1450px ) {
        left: auto;
        right: 0;
      }

      display: block;
      padding: 0.75em 1em;
      background-color: #333;
      color: #fff;
      font-size: 0.8rem;
      line-height: 1;
      white-space: nowrap;
      border-radius: 2px;
      
      opacity: 0;
      visibility: hidden;
      transform: translate3d(0,10px,0);
      transition: .2s;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border: solid 8px transparent;
        border-top-color: #333;
        position: absolute;
        top: 100%;
        left: 10px;

        @media ( max-width: 1450px ) {
          left: auto;
          right: 10px;
        }
      }
    }

    &:hover {
      background-color: $btn-favorite-bg;

      span {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0,0,0);            
      }
    }
  }

  &.added i {
    background-color: $btn-favorite-bg;
  }
}
